import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  token_response: any = {};
  token: any;
  formSectionId: any;
  emailId: string;
  phoneId: string;
  addressId: string;

  constructor(
    private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  Get_CRMTimeLinesByCRMCustomerId(cRMCustomerId: any) {
    console.log(cRMCustomerId);
    return this.http.get(environment.GetCRMTimeLinesByCRMCustomerId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCRMTimeLinesByCRMTimeLineId(cRMCustomerId:any,cRMTimeLineId: any) {
    return this.http.get(environment.GetCRMTimeLinesByCRMTimeLineId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId + '/' + cRMTimeLineId
    ).pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));;
  }

  SaveOrUpdateCRMNotes(timelineNotes: any) {
    return this.http.post(environment.SaveOrUpdateCRMNotes, timelineNotes, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
