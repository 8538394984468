import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


@Pipe({
    name: 'formatDate'
})
export class FormatedDatePipe implements PipeTransform {

    constructor(public datepipe: DatePipe) {
    }

    transform(givenDate: string): any {
        return this.datepipe.transform(givenDate, 'MM/dd/yyyy');
    }

}

