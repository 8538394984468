<div class="row px-4 py-0 pb-5">
    <div class="bgcolor stickybar">
        <ul class="breadcrumb pb-2" *ngIf="userRole != 'Volunteer'">
            <li><a class="routingcontacts" routerLink="/profiles">Profiles </a> >> </li>
            <li> Profile</li>
        </ul>
        <div class="card card-body py-3">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="row mt-0">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark ">{{profilelist.PrimaryContact}}</span>
                                </a></div>
                            <div class="text-secondary cursor-pointer item-hover">{{profilelist.AccountNumber}}</div>
                            <div class="text-secondary cursor-pointer item-hover" *ngFor="let emaillist of Emaillist">
                                <div *ngIf="emaillist.IsPrimary==true">
                                    {{emaillist.EmailAddress}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div *ngFor="let address of Addresslist ">
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.Address1}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true && address.Address2 != '' && address.Address2 != null">
                                    {{address.Address2}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.City}}, {{address.StateAbbreviation}} {{address.PostalCode}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-secondary cursor-pointer item-hover"
                                *ngFor="let phonelist of phonenumberlist">
                                <div *ngIf="phonelist.IsPrimary==true">
                                    {{phonelist.PhoneType}} {{phonelist.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex my-4">
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                        </div>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Payments</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Generate
                                    Bill</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Settings</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="card-header card-header-stretch overflow-auto mt-4 ">
            <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="setActiveTab('Profile')" role="tab">
                        <fa-icon [icon]="faUserCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5 " *ngIf="userRole != 'Volunteer'">

                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Timeline' }"
                        (click)="redirecttotimeline()" role="tab">
                        <fa-icon [icon]="faTimeline" class="fs-4 me-3  cursor-pointer"></fa-icon>
                        History
                    </a>
                </li>
                <li class="nav-item py-2 px-5">

                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="redirecttoavailability()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Availability
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'RelationShips' }"
                        (click)="redirecttorelationships()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        RelationShips
                    </a>
                </li>

            </ul>
            <div *ngIf="activeTab=='Profile'" class="desktopshowedit">
                <button
                    class="btn background-secondary text-white px-2 py-1 m-0 rounded-0 cursor-pointer float-end topedit fs-6 text-center fw-semibold rounded-0 mw-500px "
                    (click)="redirecttoeditprofile()" data-toggle="tooltip" title="Edit Profile"><i
                        class="fa fa-pencil fs-7 text-white"></i>Edit</button>
            </div>
        </div>
        <div *ngIf="activeTab=='Profile'" class="mobileshowedit m-2">
            <button
                class="btn background-secondary text-white px-2 py-1 m-0 rounded-0 cursor-pointer float-end  fs-6 text-center fw-semibold rounded-0 mw-500px "
                data-toggle="tooltip" title="Edit Profile" (click)="redirecttoeditprofile()" data-toggle="tooltip"
                title="Edit Profile"><i class="fa fa-pencil fs-7 text-white"></i>Edit</button>
        </div>
    </div>

    <div class="card-custom">
        <div class="tab-content pt-0">
            <div class="tab-pane" [ngClass]="{ active: activeTab === 'Profile' }">
                <div class="row mt-1">
                    <div class="col-lg-10 col-md-8 col-sm-8"></div>
                    <div class="col-lg-2 col-md-4 col-sm-4">
                    </div>
                </div>
                <div class="row mt-0">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="card my-2 basicinfocard">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title ">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Basic Info</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                </div>
                            </div>
                            <div class="card-body px-5 py-2 componentscroll">
                                <ul class="list-unstyled">

                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Status</div>
                                        <div class="fw-7  fs-6">{{profilelist.Status}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Primary Contact</div>
                                        <div class="fw-7  fs-6">{{profilelist.PrimaryContact}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Informal Name</div>
                                        <div class="fw-7  fs-6">{{profilelist.InformalName}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Formal Name</div>
                                        <div class="fw-7  fs-6">{{profilelist.FormalName}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Envelope Name</div>
                                        <div class="fw-7fs-6">{{profilelist.EnvelopeName}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Recognition Name</div>
                                        <div class="fw-7  fs-6">{{profilelist.RecognitionName}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card my-2 Personalinfocard">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Personal Information</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                </div>
                            </div>
                            <div class="card-body px-5 py-2 componentscroll">
                                <ul class="list-unstyled">
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Facebook</div>
                                        <a href={{profilelist.FacebookId}} target="_blank">
                                            <div class="fw-7 fs-6">{{profilelist.FacebookId}}</div>
                                        </a>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Twitter</div>
                                        <a href={{profilelist.TwitterId}} target="_blank">
                                            <div class="fw-7  fs-6">{{profilelist.TwitterId}}</div>
                                        </a>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">LinkedIn</div>
                                        <a href={{profilelist.LinkedInId}} target="_blank">
                                            <div class="fw-7  fs-6">{{profilelist.LinkedInId}}</div>
                                        </a>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Instagram</div>
                                        <a href={{profilelist.InstagramId}} target="_blank">
                                            <div class="fw-7  fs-6">{{profilelist.InstagramId}}</div>
                                        </a>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Website</div>
                                        <a href={{profilelist.Website}} target="_blank">
                                            <div class="fw-7  fs-6">{{profilelist.Website}}</div>
                                        </a>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Gender</div>
                                        <div class="fw-7 fs-6">{{profilelist.Gender}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Birthday</div>
                                        <div class="fw-7  fs-6">{{profilelist.Birthday| date:'MM/dd/yyyy'}}
                                        </div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Employer</div>
                                        <div class="fw-7  fs-6">{{profilelist.Employer}}</div>
                                    </li>
                                    <li class="border-bottom py-2">
                                        <div class="fs-4  text-secondary fw-semibold">Job</div>
                                        <div class="fw-7 fs-6">{{profilelist.JobTitle}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="card my-2">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Addresses</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                    <i class="fa fa-plus fs-7 p-1 text-white cursor-pointer"
                                        (click)="openmodal(addaddress)" data-toggle="tooltip" title="Add Address"></i>
                                </div>
                            </div>
                            <div class="card-body static-card-height componentscroll vault-list-h px-5 py-2">
                                <div class="d-flex flex-wrap flex-sm-nowrap" *ngFor="let address of Addresslist ">
                                    <div class="flex-grow-1">
                                        <div class="d-flex justify-content-between align-items-start flex-wrap">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-items-center">
                                                    <div class="text-secondary fs-6">{{address.AddressType}}
                                                    </div>
                                                    <a class="ms-2">
                                                        <span *ngIf="address.IsPrimary==true"
                                                            class="badge badge-success fs-6">Primary</span>
                                                    </a>
                                                </div>
                                                <div class="d-flex flex-wrap fw-semibold text-dark pe-2">
                                                    <div class="d-flex align-items-center  me-5 fs-6">
                                                        {{address.Address1}}
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap fw-semibold text-dark pe-2" *ngIf="address.Address2 != '' && address.Address2 != null">
                                                    <div class="d-flex align-items-center  me-5 fs-6">
                                                        {{address.Address2}}
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap fw-semibold pe-2">
                                                    <div class="d-flex align-items-center  text-dark me-5 fs-6">
                                                        {{address.City}}, {{address.StateAbbreviation}}
                                                        {{address.PostalCode}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex my-4">
                                                <div class="me-0">
                                                    <a class=" fw-semibold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                        (click)="openmodal(addaddress)"
                                                        (click)="get_CustomerAddressDetailsbyCrmId(address.CRMAddressId)">
                                                        <fa-icon [icon]="faEdit"
                                                            class="fs-4 me-3 nav-link cursor-pointer text-primary"
                                                            data-toggle="tooltip" title="Edit Address">
                                                        </fa-icon>
                                                    </a>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Action</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Activate</a>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Inactivate</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Delete</a>
                                                        </div>
                                                    </div>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Payments</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Generate
                                                                Bill</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Settings</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-2">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Emails</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                    <i class="fa fa-plus fs-7 p-1 text-white cursor-pointer"
                                        (click)="openmodal(addemail)" data-toggle="tooltip" title="Add Email"></i>
                                </div>
                            </div>
                            <div class="card-body static-card-height componentscroll vault-list-h px-5 py-2">
                                <div class="d-flex flex-wrap flex-sm-nowrap">
                                    <div class="flex-grow-1">
                                        <div class="d-flex justify-content-between align-items-start flex-wrap"
                                            *ngFor="let emaillist of Emaillist">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-items-center">
                                                    <div class="text-secondary fs-6">{{emaillist.EmailType}}
                                                    </div>
                                                    <a class="ms-2">
                                                        <span *ngIf="emaillist.IsPrimary==true"
                                                            class="badge badge-success fs-6">Primary</span>
                                                    </a>
                                                </div>
                                                <div class="d-flex flex-wrap fw-semibold pe-2">
                                                    <div class="d-flex align-items-center  me-5 fs-6">
                                                        {{emaillist.EmailAddress}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex my-4">
                                                <div class="me-0">
                                                    <a class="fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                        (click)="openmodal(addemail)"
                                                        (click)="get_editemail(emaillist.CRMEmailId)">
                                                        <fa-icon [icon]="faEdit" class="fs-4 me-3  text-primary"
                                                            data-toggle="tooltip" title="Edit Email">
                                                        </fa-icon>
                                                    </a>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Action</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Activate</a>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Inactivate</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Delete</a>
                                                        </div>
                                                    </div>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Payments</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Generate
                                                                Bill</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Settings</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-2">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Phone Numbers</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                    <i class="fa fa-plus fs-7 p-1 text-white cursor-pointer"
                                        (click)="openmodal(addphone)" data-toggle="tooltip"
                                        title="Add Phone Number"></i>
                                </div>
                            </div>
                            <div class="card-body static-card-height componentscroll vault-list-h px-5 py-2">
                                <div class="d-flex flex-wrap flex-sm-nowrap">
                                    <div class="flex-grow-1">
                                        <div class="d-flex justify-content-between align-items-start flex-wrap"
                                            *ngFor="let phonelist of phonenumberlist">
                                            <div class="d-flex flex-column">
                                                <div class="d-flex align-items-center">
                                                    <div class=" text-secondary fs-6">
                                                        {{phonelist.PhoneType}}</div>
                                                    <a class="ms-2">
                                                        <span *ngIf="phonelist.IsPrimary==true"
                                                            class="badge badge-success fs-6">Primary</span>
                                                    </a>
                                                </div>
                                                <div class="d-flex flex-wrap fw-semibold pe-2">
                                                    <div class="d-flex align-items-center me-5 fs-6">
                                                        {{phonelist.PhoneNumber}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex my-4">
                                                <div class="me-0">
                                                    <a class=" fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                        (click)="openmodal(addphone)"
                                                        (click)="get_phonenumberdetails(phonelist.CRMPhoneId)"
                                                        data-toggle="tooltip" title="Edit Phone Number">
                                                        <fa-icon [icon]="faEdit"
                                                            class="fs-4 me-3  text-primary"></fa-icon>
                                                    </a>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Action</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Activate</a>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Inactivate</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Delete</a>
                                                        </div>
                                                    </div>
                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                        data-kt-menu="true">
                                                        <div class="menu-item px-3">
                                                            <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                                Payments</div>
                                                        </div>
                                                        <div class="menu-item px-3">
                                                            <a class="menu-link px-3">Generate
                                                                Bill</a>
                                                        </div>
                                                        <div class="menu-item px-3 my-1">
                                                            <a class="menu-link px-3">Settings</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-2">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        Communication Preferences</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                </div>
                            </div>
                            <div class="card-body px-5 py-2">
                                <ul class="list-unstyled">
                                    <li class="border-bottom py-2">
                                        <div class="fs-4 text-secondary fw-semibold">Email Interests</div>
                                        <div class="fw-7  fs-6">{{profilelist.EmailInterestType}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let section of selectedFormSections">
                        <div class="card card-height my-2">
                            <div class="card-header px-1 min-h-30px background-secondary">
                                <div class="card-title ">
                                    <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        {{section.FormSectionName}}
                                        ({{section.SelectedFormComponentModelList.length}})</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3">
                                </div>
                            </div>
                            <div class="card-body px-5 py-2 componentscroll vault-list-h">
                                <ul class="list-unstyled ">
                                    <li class="border-bottom py-2"
                                        *ngFor="let component of section.SelectedFormComponentModelList">
                                        <div class="fs-4 text-secondary fw-semibold">
                                            {{component.FormComponentName}}<span *ngIf="component.IsRequired == true"
                                                class="required"></span>
                                        </div>
                                        <div class="fw-7 fs-6"
                                            *ngFor="let value of component.SelectedFormOptionsModelList">
                                            {{(component.FormComponentDataType == 'Date' && component.FormComponentOptionType == 'PickOne' && value.UserOptedValues != "" && value.UserOptedValues != null && value.UserOptedValues != undefined) ? (value.UserOptedValues | formatDate) :  value.UserOptedValues}}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #addaddress let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrAddress}}</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="ClearCustomerAddressFields()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="pb-1 text-dark fs-4">Address Type</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <ng-select data-control="select2" data-hide-search="false" [(ngModel)]="newaddress.AddressType"
                        [clearable]="false" placeholder="Show All">
                        <ng-option [value]="''" disabled>Select Address Type
                        </ng-option>
                        <ng-option *ngFor='let addrtype of addresstype' [value]="addrtype" class="fs-6">
                            {{addrtype}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="pb-1 text-dark fs-4">Address 1</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" ngx-google-places-autocomplete [options]="options"
                    #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [(ngModel)]="newaddress.Address1">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="pb-1 text-dark fs-4">Address 2</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter Street" [(ngModel)]="newaddress.Address2">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">City</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter City" [(ngModel)]="newaddress.City">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">State</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter State" [(ngModel)]="newaddress.State">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">State Abbreviation</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter State Abbreviation"
                        [(ngModel)]="newaddress.StateAbbreviation">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Postal Code</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter Postal Code"
                        [(ngModel)]="newaddress.PostalCode">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Country</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter Country" [(ngModel)]="newaddress.Country">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Country Code</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter Country Code"
                        [(ngModel)]="newaddress.CountryCode">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" name="toc" value="1"
                            [(ngModel)]="newaddress.IsPrimary" [disabled]="isPrimaryAddress" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Is Primary</span>
                    </label>
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="ClearCustomerAddressFields()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                (click)="save_addressdeeatils(newaddress)"><i class="fa fa-save fs-7 text-white"></i> {{btnAddress}}
            </button>
        </div>
    </ng-template>

    <ng-template #addemail let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrEmail}}</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="ClearEmailFields()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Email Type</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <ng-select data-control="select2" data-hide-search="false" [(ngModel)]="newemail.EmailType"
                        [clearable]="false" placeholder="Show All">
                        <ng-option [value]="''" disabled>Select Email Type
                        </ng-option>
                        <ng-option *ngFor='let emailtype of emailtype' [value]="emailtype" class="fs-6">
                            {{emailtype}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Email Address</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" [(ngModel)]="newemail.EmailAddress"
                        placeholder="Enter Email Address">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="newemail.IsPrimary" [disabled]="isPrimaryEmail" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Is Primary</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="ClearEmailFields()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                (click)="SaveOrUpdate_emaildetails(newemail)"><i class="fa fa-save fs-7 text-white"></i>
                {{btnEmail}} </button>
        </div>
    </ng-template>

    <ng-template #addphone let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrPhone}}</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="ClearPhoneFields()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Phone Type</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <ng-select data-control="select2" data-hide-search="false" [(ngModel)]="newphone.PhoneType"
                        [clearable]="false" placeholder="Show All">
                        <ng-option [value]="''" disabled>Select Phone Type
                        </ng-option>
                        <ng-option *ngFor='let phntype of phonetype' [value]="phntype" class="fs-6">
                            {{phntype}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-4">Phone Number</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" maxlength="14" mask="(000) 000-0000"
                        [dropSpecialCharacters]="false" [(ngModel)]="newphone.PhoneNumber" placeholder="(000) 000-0000">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="newphone.IsPrimary" [disabled]="isPrimaryPhone" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Is Primary</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="ClearPhoneFields()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                (click)="SaveOrUpdate_phonedetails(newphone)"><i class="fa fa-save fs-7 text-white"></i>
                {{btnPhone}} </button>
        </div>
    </ng-template>

</div>