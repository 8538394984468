import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  token_response: any = {};
  token: any;
  formSectionId: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit() {
  }


  GetScheduleDetailsByTenantId() {
    return this.http.get(environment.GetScheduleDetailsByTenantId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId
    ).pipe(map((res: any) => {
      console.log(this.token_response.CRMPersonId);
      console.log(this.token_response.tenantId);

      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Save_CRMSchedule(newcalendaroptions: any) {
    return this.http.post(environment.SaveCRMSchedule, newcalendaroptions, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetListOfParticipants() {
    return this.http.get(environment.GetListOfParticipants + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetScheduleDetailsByCRMScheduleDetailId(cRMScheduleDetailId:any) {
    return this.http.get(environment.GetScheduleDetailsByCRMScheduleDetailId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMScheduleDetailId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetScheduleDetailsByCRMScheduleIdOccurrence(cRMScheduleId:any, cRMScheduleDetailId:any, seriesOrOccurrence:any) {
    return this.http.get(environment.GetScheduleDetailsByCRMScheduleIdOccurrence + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMScheduleId + '/' + cRMScheduleDetailId + '/' + seriesOrOccurrence
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteCRMSchedule(scheduleData: any) {
    return this.http.post(environment.SaveDeleteCRMSchedule, scheduleData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SentScheduleEmailsToUsers(scheduleData: any) {
    return this.http.post(environment.SentScheduleEmailsToUsers, scheduleData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetListOfOrganizations(type :any) {
    return this.http.get(environment.GetListOfOrganizations + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + type
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}
