import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormsService } from 'src/app/services/forms.service';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastralertService } from 'src/app/services/toastralert.service';

export interface section {
	FormSectionName: string;
	FormComponentsIdModelList: component[]
}

export interface component {
	FormComponentName: string;
}

@Component({
	selector: 'app-setup',
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.scss']
})

export class SetupComponent implements OnInit {
	@ViewChild('SectionList') SectionList?: ElementRef;
	@ViewChild('componentList') componentList?: ElementRef;
	formsectionId: any;
	closeResult: any;
	formslist: any;
	newsection: any = { CRMPersonId: '', FormSectionName: '', FormId: '', IsRequired: true, IsActive: true, IsArchived: false };
	newcomponent: any = { FormComponentName: '', FormId: '', FormSectionId: '', FormComponentDataType: '', FormComponentOptionType: '', IsRequired: true, IsActive: true, IsArchived: false };
	component: any;
	saveformlist: any;
	FormId: any;
	FormSectionId: any;
	sectiondetails: any = { FormSectionName: '', FormId: '', IsRequired: true, IsActive: true, IsArchived: false };
	formId: any;
	formdetails: any = { formid: '' };
	get_formdetails: any = [];
	options: any = ["Freeform", "PickOne", "PickMultiple"];
	datatypes: any = ["Date", "Decimal", "Text", "Year"];
	sectionlist: any;
	formreorderedlist: any = [];
	savesectionorder: any = { formlist: '', formreorderlist: '' };
	formcomponentreorderedlist: any = {};
	componentorderlist: any = { formid: '', componentreorderlist: '', sectionid: '' };
	formslists: any;
	tempformid: any;
	btnSection: string = 'Save';
	hdrSection: string = 'Add Section';
	formSectionId: string;
	token_response: any;
	token: any;
	comSectionId: any;
	UserRole : string = "";

	constructor(private modalService: NgbModal, public router: Router, private formsService: FormsService,
		public route: ActivatedRoute, config: NgbModalConfig, public toastralert: ToastralertService) {
		config.backdrop = 'static';
		config.keyboard = false;
		this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
		this.token = this.token_response.access_token;
	}

	ngOnInit(): void {
		this.UserRole = this.token_response.UserRole;
		this.route.params.subscribe(params => {
			this.formId = params['formid'];
		});
		this.get_formdata();
	}

	get_formdetailsdropdown(dropdownevent: Event) {
		var val;
		var localStorageformId = localStorage.getItem("localFormId");
		if (localStorageformId != "" && localStorageformId != null) {
			val = localStorageformId;
			this.tempformid = localStorageformId;
			localStorage.setItem("localFormId", "");
		}
		else {
			if (dropdownevent) {
				const selectelement = dropdownevent.target as HTMLInputElement;
				val = selectelement.value;
			}
			else {
				val = this.tempformid;
			}
		}
		this.formdetails.formid = val;
		this.formsService.get_FormDetailsByFormId(this.formdetails).subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.formslist = results;
				this.sectionlist = this.formslist.FormSectionModelList;
				this.get_form(this.formslist);
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
			}
		);

	}

	get_form(formlist: any) {
		this.get_formdetails = [formlist];
		console.log(this.get_formdetails)
	}

	get_formdata() {
		this.formsService.get_FormsByTenantId().subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.formslists = results;
				console.log(this.formslists );
				console.log(localStorage.getItem("localFormId"));
				for (let index = 0; index < this.formslists.length; index++) {
					if (index == 0) {
						this.tempformid = this.formslists[index].FormId;
					}
				}
				setTimeout(() => {
					this.get_formdetailsdropdown(event!);
				}, 2000);
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
			}
		);
	}

	get_sectiondetails(sectionId: any) {
		this.formsService.get_SectionDeatilsBySectionId(sectionId).subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.sectiondetails = results;
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get section details.Please try again after some time.");
			}
		);
	}

	saveformsection(newsection: { FormSectionName: any; FormId: any; IsRequired: any; IsActive: any, IsArchived: any, FormSectionId: any }) {
		if ((typeof newsection.FormSectionName == 'undefined' || newsection.FormSectionName == "" || newsection.FormSectionName == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Section Name');
			return false;
		}
		else {
			if (newsection.FormSectionId == "") {
				this.formSectionId = "";
			}
			else {
				this.formSectionId = newsection.FormSectionId;
			}

			let newsectiondetails = {
				CRMPersonId: this.token_response.CRMPersonId,
				TenantId: this.token_response.tenantId,
				FormSectionId: this.formSectionId,
				FormId: this.formslist.FormId,
				FormSectionName: newsection.FormSectionName,
				IsRequired: newsection.IsRequired,
				IsActive: newsection.IsActive,
				IsArchived: newsection.IsArchived,
			};
			this.formsService.save_formsectionsbyformid(newsectiondetails).subscribe((results) => {
				if (results != null && results != "" && results != 'undefined') {
					if (results == '99999999-9999-9999-9999-999999999999') {
						this.toastralert.toastrerrorbottom("This section is already exits, please check once.");
					}
					else {
						this.formslist = results;
						this.toastralert.toastersuccesstop('Section has been saved successfully');
						this.clearmodals();
						this.get_formdata();
					}
				}
				else {
					this.toastralert.toastrerror('Error occurred while saving section details');
				}
			},
				(err) => {
					this.toastralert.toastrerrorbottom("Unable to save section details.Please try again after some time.");
				}
			);
		}
	}

	updateformsection(newsection: { FormSectionName: any; FormId: any; IsRequired: any; IsActive: any, IsArchived: any, FormSectionId: any, CRMPersonId: any }) {
		if ((typeof newsection.FormSectionName == 'undefined' || newsection.FormSectionName == "" || newsection.FormSectionName == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Section Name');
			return false;
		}
		else {
			let newsectiondetails = {
				CRMPersonId: this.token_response.CRMPersonId,
				TenantId: this.token_response.tenantId,
				FormSectionId: newsection.FormSectionId,
				FormId: this.formslist.FormId,
				FormSectionName: newsection.FormSectionName,
				IsRequired: newsection.IsRequired,
				IsActive: newsection.IsActive,
				IsArchived: newsection.IsArchived,
			};
			this.formsService.save_formsectionsbyformid(newsectiondetails).subscribe((results) => {
				if (results != null && results != "" && results != 'undefined') {
					if (results == '99999999-9999-9999-9999-999999999999') {
						this.toastralert.toastrerrorbottom("This section is already exits, please check once.");
					}
					else {
						this.formslist = results;
						this.toastralert.toastersuccesstop('Section has been updated successfully');
						this.clearmodals();
						this.get_formdata();
					}
				}
				else {
					this.toastralert.toastrerror('Error occurred while updating section details');
				}
			},
				(err) => {
					this.toastralert.toastrerrorbottom("Unable to update section details.Please try again after some time.");
				}
			);
		}
	}

	saveformcomponent(newcomponent: { FormComponentName: any; FormId: any; FormSectionId: any; FormComponentDataType: any; FormComponentOptionType: any; IsRequired: any; IsActive: any, IsArchived: any, FormComponentId: any }) {
		if ((typeof newcomponent.FormComponentName == 'undefined' || newcomponent.FormComponentName == "" || newcomponent.FormComponentName == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Component Name');
			return false;
		}
		else if ((typeof newcomponent.FormComponentDataType == 'undefined' || newcomponent.FormComponentDataType == "" || newcomponent.FormComponentDataType == null)) {
			this.toastralert.toastrwarnningbottomright('Please select Component Data Type');
			return false;
		}
		else if ((typeof newcomponent.FormComponentOptionType == 'undefined' || newcomponent.FormComponentOptionType == "" || newcomponent.FormComponentOptionType == null)) {
			this.toastralert.toastrwarnningbottomright('Please select Component Option Type');
			return false;
		}
		else {
			let newcomponentdetails = {
				CRMPersonId: this.token_response.CRMPersonId,
				TenantId: this.token_response.tenantId,
				FormId: this.formslist.FormId,
				FormSectionId: this.formsectionId,
				FormComponentName: newcomponent.FormComponentName,
				FormComponentDataType: newcomponent.FormComponentDataType,
				FormComponentOptionType: newcomponent.FormComponentOptionType,
				IsRequired: newcomponent.IsRequired,
				IsActive: newcomponent.IsActive,
				IsArchived: newcomponent.IsArchived,
			};
			if (this.formsectionId != "" && this.formsectionId != null && this.formsectionId != undefined) {
				this.formsService.save_Formcomponentsbyformid(newcomponentdetails).subscribe((results) => {
					if (results != null && results != "" && results != 'undefined') {
						if (results == '99999999-9999-9999-9999-999999999999') {
							this.toastralert.toastrerrorbottom("This component is already exits, please check once.");
						}
						else {
							this.saveformlist = results;
							this.toastralert.toastersuccesstop('Component has been saved successfully');
							this.comSectionId = this.formsectionId;
							if (newcomponent.IsRequired && (newcomponent.FormComponentOptionType == "PickMultiple" || newcomponent.FormComponentOptionType == "PickOne")) {
								this.clearmodals();
								this.redirecttoOptions(this.comSectionId, results);
							}
							this.clearmodals();
							this.get_formdata();
						}
					}
					else {
						this.toastralert.toastrerror('Error occurred while saving component details');
					}
				},
					(err) => {
						this.toastralert.toastrerrorbottom("Unable to save component details.Please try again after some time.");
					}
				);
			}
		}
	}

	redirecttoOptions(SectionId: any, CompnentId: any) {
		this.router.navigate(['setup/options/' + '/' + this.formslist.FormId + '/' + SectionId + '/' + CompnentId])
	}

	openmodal(content: any) {
		this.modalService.open(content, { centered: true }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	editsectiondetails(content: any, sectionId: any) {
		this.formsectionId = sectionId;
		this.modalService.open(content, { size: 'lg', centered: true }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	drop(event: CdkDragDrop<section[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {

			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
		this.get_formdetails.forEach((i: any, item: any) => {
			this.emptyarray();
			let count1 = i.FormSectionModelList.length;
			i.FormSectionModelList.forEach((i: any, item: any) => {
				const modal = {
					FormSectionId: i.FormSectionId,
					DisplayOrder: item + 1
				}
				this.formreorderedlist.push(modal);
			})
			this.savesectionorderlist(this.formreorderedlist);
		});
	}

	savesectionorderlist(formreorderlist: any) {
		let sectionorder = {
			CRMPersonId: this.token_response.CRMPersonId,
			TenantId: this.token_response.tenantId,
			FormId: this.formslist.FormId,
			FormSectionOrderModelList: formreorderlist
		};
		this.formsService.save_Sectionorder(sectionorder).subscribe((results) => {
			if (results != null && results != "" && results != 'undefined') {
				this.saveformlist = results;
			}
			else {
				this.toastralert.toastrerror('Error occurred while saving section order list');
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to save section order list.Please try again after some time.");
			}
		);
	}

	emptyarray() {
		this.formreorderedlist = [];
		this.formcomponentreorderedlist = [];
	}

	dropcomponent(event: CdkDragDrop<component[]>) {
		let list = event.item.data.FormComponentsIdModelList;
		let sectionid = event.item.data.FormSectionId;
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
		this.emptyarray();
		list.forEach((item: any, i: any) => {
			const modal = {
				FormComponentId: item.FormComponentId,
				DisplayOrder: i + 1
			}
			this.formcomponentreorderedlist.push(modal);
		})
		this.saveComponentorder(this.formcomponentreorderedlist, sectionid);
	}

	saveComponentorder(componentorderlist: any, sectionid: any) {
		let componentorder = {
			CRMPersonId: this.token_response.CRMPersonId,
			TenantId: this.token_response.tenantId,
			FormId: this.formslist.FormId,
			FormSectionId: sectionid,
			FormComponentOrderModelList: componentorderlist
		};
		this.formsService.update_FormComponentsDisplayOrder(componentorder).subscribe((results) => {
			if (results != null && results != "" && results != 'undefined') {
				//this.formslist = results;
			}
			else {
				this.toastralert.toastrerror('Error occurred while saving component orderlist');
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom(" Unable to save component order list.Please try again after some time.")
			}
		);
	}

	returncomponent(event: CdkDragDrop<component[]>) {
		let persons: component[] = event.item.data.component;
		if (!persons && event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			for (; 0 < persons.length;) {
				transferArrayItem(persons,
					event.container.data,
					0,
					event.currentIndex);
			};
			event.item.data.component = [];
		}
	}

	issectionPredicate(item: CdkDrag<section>) {
		if (item.data.FormSectionName) return true;
		return false;
	}

	iscomponentPredicate(item: CdkDrag<component>) {
		if (item.data.FormComponentName) return true;
		return false;
	}

	clearmodals() {
		this.newcomponent = {
			FormComponentName: '',
			FormId: '',
			FormSectionId: '',
			FormComponentDataType: '',
			FormComponentOptionType: '',
			IsRequired: true,
			IsActive: true,
			IsArchived: false
		};
		this.newsection = {
			FormSectionName: '',
			FormId: '',
			IsRequired: true,
			IsActive: true,
			IsArchived: false
		};
		this.modalService.dismissAll();
	}
}
