<div [ngClass]="footerContainerCssClasses" class="
    d-flex
    flex-column flex-md-row
    align-items-center
    justify-content-between
  ">
  <!-- begin::Copyright  -->
  <div class=" fw-bold order-1">
    <div *ngIf="companyName=='Volunteers.LLC'">
      <img alt="Logo" src="./assets/media/logos/lifespark.jpg" class="mh-25px" />
    </div>
  </div>
  <div class="text-dark order-2 order-md-1" *ngIf="companyName!='Volunteers.LLC'">
    <span class="text-muted fw-bold me-5">{{ currentDateStr }} &copy;</span>
  </div>
  <!-- end::Copyright  -->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted fw-semibold me-10">{{companyName}} | {{UserRole}} - {{UserName}} | {{buildVersion}}</span>
  </div>
  <!-- begin::Nav  -->
  <div class=" fw-bold order-1">
    <div >
      <img alt="Logo" src="./assets/media/logos/apoorva.png" class="mh-25px apoorvalogo" />
    </div>
  </div>
  <!-- end::Nav  -->
</div>