import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {

  token_response: any = {};
  token: any;
  companyName : string = "Volunteers.LLC"
  UserRole : string = "";
  UserName : string = "";
  footerContainerCssClasses: string = '';
  buildVersion:string = '';
  currentDateStr: string = new Date().getFullYear().toString();
  constructor(private layout: LayoutService) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.footerContainerCssClasses =
      this.layout.getStringCSSClasses('footerContainer');
      this.UserRole = this.token_response.UserRole;
      this.UserName = this.token_response.UserName;
      this.buildVersion = environment.buildVersion;
  }
  ngAfterViewInit(): void {
  
  }
}
