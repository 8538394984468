<!-- <div class="row px-4 py-0 smartcontainerpagescroll">
    <div class="app-content flex-column-fluid card">
        <div class="row mx-0 ">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <ul class="nav nav-stretch nav-line-tabs fs-5 border-transparent flex-nowrap" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link cursor-pointer text-dark" [ngClass]="{ active: activeTab === 'Volunteers' }"
                            (click)="setActiveTab('Volunteers')" role="tab">
                            Volunteers
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link cursor-pointer text-dark" [ngClass]="{ active: activeTab === 'Beneficiaries' }"
                            (click)="setActiveTab('Beneficiaries')" role="tab">
                            Beneficiaries
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link cursor-pointer text-dark" [ngClass]="{ active: activeTab === 'Admins' }"
                            (click)="setActiveTab('Admins')" role="tab">
                            Admins
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="float-end text-dark p-2 fs-5 text-center fw-semibold rounded-0 mw-500px">
                    <smart-button #exportexcelbutton id="xlsxBtn"><i
                            class='fas fa-file-export fs-7 text-dark '></i>Excel</smart-button>
                </div>
            </div>
        </div>
        <div class="pt-0 ">
         
            <div class="tab-content">
                <div class="tab-pane" [ngClass]="{ active: activeTab === 'Volunteers' }">
                    <smart-grid [grouping]="grouping" [behavior]="behavior" [paging]="paging" [pager]="pager"
                        [header]="header" [layout]="layout" [sorting]="sorting" [filtering]="filtering"
                        [dataSource]="contactsgridsource" [columns]="columns" [grouping]="grouping"
                        [appearance]="appearance" #contactsgrid id="contactsgrid"
                        style="height: calc(100vh - 200px);"></smart-grid>
                    <div class="float-end mt-0">Total Number of Records in Table: {{members.length}}</div>
                </div>
                <div class="tab-pane" [ngClass]="{ active: activeTab === 'Beneficiaries' }">
                    <smart-grid [grouping]="grouping" [behavior]="behavior" [paging]="paging" [pager]="pager"
                        [header]="header" [layout]="layout" [sorting]="sorting" [filtering]="filtering"
                        [dataSource]="contactsgridsource" [columns]="columns" [grouping]="grouping"
                        [appearance]="appearance" #contactsgrid id="contactsgrid"
                        style="height: calc(100vh - 200px);"></smart-grid>
                </div>
                <div class="tab-pane" [ngClass]="{ active: activeTab === 'Admins' }">
                    <smart-grid [grouping]="grouping" [behavior]="behavior" [paging]="paging" [pager]="pager"
                        [header]="header" [layout]="layout" [sorting]="sorting" [filtering]="filtering"
                        [dataSource]="contactsgridsource" [columns]="columns" [grouping]="grouping"
                        [appearance]="appearance" #contactsgrid id="contactsgrid"
                        style="height: calc(100vh - 200px);"></smart-grid>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush ">
            <div class="card-header">
                <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1 fs-5 text-dark">Profiles</h3>
                </div>
            </div>
            <div class="card-body bg-white pt-0 px-1">
                <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                    <li class="nav-item py-2 px-5 ">
                        <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Volunteers' }"
                            (click)="setActiveTab('Volunteers')" role="tab"  routerLink="/profiles/volunteers">
                            <fa-icon [icon]="faUserGroup" class="fs-4 me-3 cursor-pointer"></fa-icon>
                            Volunteers
                        </a>
                    </li>
                    <li class="nav-item py-2 px-5 ">

                        <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Beneficiaries' }"
                            (click)="setActiveTab('Beneficiaries')" role="tab"  routerLink="/profiles/beneficiaries">
                            <fa-icon [icon]="faUserGroup" class="fs-4 me-3 cursor-pointer"></fa-icon>
                            Beneficiaries
                        </a>
                    </li>
                    <li class="nav-item py-2 px-5 ">
                        <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Admins' }"
                            (click)="setActiveTab('Admins')" role="tab"  routerLink="/profiles/Admins">
                            <fa-icon [icon]="faUserGroup" class="fs-4 me-3 cursor-pointer"></fa-icon>
                            Admins
                        </a>
                    </li>
                </ul>
                <div class="tab-content pt-0">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>