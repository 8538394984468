import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { ToastralertService } from 'src/app/services/toastralert.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registerCredentials = { Email: '', UserPassword: '', ConfirmUserPassword: '', FirstName: '', LastName: '' };
  hasError: boolean;
  isLoading$: Observable<boolean>;
  visible: boolean = false;
  changetype: boolean = true;
  confirmvisible: boolean = false;
  confirmchangetype: boolean = true;
  constructor(
    public http: HttpClient,
    public router: Router,
    private userService: UserService,
    public toastralert: ToastralertService
  ) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  trim(str: string) {
    return str.replace(/ /g, '');
  }

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  confirmviewpass() {
    this.confirmvisible = !this.confirmvisible;
    this.confirmchangetype = !this.confirmchangetype;
  }

  register(registerCredentials: any) {
    let capletrfrmt = /[A-Z]/;
    let numberformat = /\d/;
    let specialcharfrmt = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (typeof registerCredentials.FirstName == 'undefined' || registerCredentials.FirstName == "" || registerCredentials.FirstName == null) {
      this.toastralert.toastrwarnning('Please Enter your First Name');
      return false;
    }
    else if (typeof registerCredentials.LastName == 'undefined' || registerCredentials.LastName == "" || registerCredentials.LastName == null) {
      this.toastralert.toastrwarnning('Please Enter your Last Name');
      return false;
    }
    else if (typeof registerCredentials.Email == 'undefined' || registerCredentials.Email == "" || registerCredentials.Email == null) {
      this.toastralert.toastrwarnning('Please Enter your Email');
      return false;
    }
    else if (!this.validateEmail(registerCredentials.Email)) {
      this.toastralert.toastrwarnning('Please Enter a Valid Email');
      return false;
    }
    else if (typeof registerCredentials.UserPassword == 'undefined' || registerCredentials.UserPassword == "" || registerCredentials.UserPassword == null) {
      this.toastralert.toastrwarnning('Please Enter Your Password');
      return false;
    }
    else if (registerCredentials.UserPassword !== "" && !registerCredentials.UserPassword.match(capletrfrmt)) {
      this.toastralert.toastrwarnning('Password must contain at least one capital letter.');
      return false;
    }
    else if (registerCredentials.UserPassword !== "" && !registerCredentials.UserPassword.match(numberformat)) {
      this.toastralert.toastrwarnning('Password must contain at least one number.');
      return false;
    }
    else if (registerCredentials.UserPassword !== "" && !registerCredentials.UserPassword.match(specialcharfrmt)) {
      this.toastralert.toastrwarnning('Password must contain at least one special character.');
      return false;
    }
    else if (registerCredentials.UserPassword.length < 8) {
      this.toastralert.toastrwarnning('Password must contain at least 8 characters.');
      return false;
    }
    else if (typeof registerCredentials.ConfirmUserPassword == 'undefined' || registerCredentials.ConfirmUserPassword == "" || registerCredentials.ConfirmUserPassword == null) {
      this.toastralert.toastrwarnning('Please Enter Confirm Password.');
      return false;
    }
    else if (registerCredentials.UserPassword !== registerCredentials.ConfirmUserPassword) {
      this.toastralert.toastrerror('Password doesnt match.');
      return false;
    }
    else {
      let data = {
        Email: registerCredentials.Email,
        UserPassword: registerCredentials.UserPassword,
        FirstName: registerCredentials.FirstName,
        LastName: registerCredentials.LastName
      };
      this.userService.userregistration(data).subscribe(results => {
         if (results != null && results != "" && results !='undefined') {
          if (results == "99999999-9999-9999-9999-999999999999") {
            this.toastralert.toastrwarnning('Email already exist.');
          }
          else if (results !== null) {
            Swal.fire({
              icon: 'success',
              title: 'Registration process completed successfully',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['/login']);
          }
          else {
            this.toastralert.toastrerror('Error occurred while saving the Registration Details');
          }
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving the Registration Details');
        }
      }, err => {
        this.toastralert.toastrwarnning('Email already exist.');
      });
    }

  }

  Redirecttologin() {
    this.router.navigate(['/login']);
  }
}