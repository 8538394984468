import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FormsService {
  token_response: any = {};
  token: any;
  formSectionId: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit() {
  }
    
  get_FormsByTenantId() {
    return this.http.get(environment.GetFormsByTenantId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_FormDetailsByFormId(formdetails: any) {
    return this.http.get(environment.GetFormDetailsByFormId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + formdetails.formid).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_SectionDeatilsBySectionId(sectiondetails: any) {
    return this.http.get(environment.GetSectionDeatilsBySectionId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + sectiondetails).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));;
  }

  save_formsectionsbyformid(newsection: any) {
    return this.http.post(environment.SaveOrUpdateFormSections, newsection, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  save_Formcomponentsbyformid(newcomponent: any) {
    return this.http.post(environment.SaveOrUpdateFormComponents, newcomponent, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  save_Sectionorder(sectionlist: any) {
    return this.http.post(environment.UpdateFormSectionsDisplayOrder, sectionlist, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  update_FormComponentsDisplayOrder(componentreorder: any) {
    return this.http.post(environment.UpdateFormComponentsDisplayOrder, componentreorder, {
      responseType: 'text',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}

