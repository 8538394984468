import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { faClock, faEdit, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';

type Tabs = 'Profile' | 'Timeline' | 'Availability';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {
  getselectedFormSections: any;
  formName: any;
  cRMCustomerid: any;
  customerDetails: any = { Status: '' };
  faUserCircle = faUserCircle;
  faTimeline = faTimeline;
  faTimesCircle = faClock;
  faEdit = faEdit;
  genderlist: any = ['Male', 'Female', 'Other'];
  statuslist: any = ['Inactive', 'Active'];
  activeTab: Tabs = 'Profile';
  token_response: any;
  token: any;
  customerSelecetdValuesList: any = [];
  selectedFormSections: any;
  profilelist: any = { PrimaryContact: '' };
  phonenumberlist: any;
  Addresslist: any;
  Emaillist: any;
  submited: any = false;
  isRequiredSave: any = false;
  userRole: any = "";
  isBtndisabled:boolean = false;
  constructor(private profileService: ProfileService,
    public toastralert: ToastralertService,
    private modalService: NgbModal,
    public router: Router,
    public route: ActivatedRoute,
    public config: NgbModalConfig,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
    this.formName = 'Profile';
  }

  ngOnInit(): void {
    this.userRole = this.token_response.UserRole;
    this.route.params.subscribe(params => {
      this.cRMCustomerid = params['cRMCustomerId'];
    });
    this.get_profiledetails();
    this.Get_ComponentOptionsSelectedValues();
    this.get_cRMCustomerDetailsbyCRMCustomerId();
    this.Get_ComponentOptionsSelectedValuesDetailsById();
  }

  Get_ComponentOptionsSelectedValuesDetailsById() {
    this.profileService.Get_ComponentOptionsSelectedValuesDetailsById(this.formName, this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.getselectedFormSections = results;
        console.log(results);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Error Occured While Getting Dynamic Form Components Data.");
      }
    );
  }

  get_cRMCustomerDetailsbyCRMCustomerId() {
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.customerDetails = results;
        this.customerDetails.Birthday = new DatePipe('en-US').transform(this.customerDetails.Birthday, 'MM/dd/yyyy')
        if (this.customerDetails.FacebookId == null || this.customerDetails.FacebookId == "" || this.customerDetails.FacebookId == 'undefined') {
          this.customerDetails.FacebookId = "https://www.facebook.com/";
        }
        if (this.customerDetails.TwitterId == null || this.customerDetails.TwitterId == "" || this.customerDetails.TwitterId == 'undefined') {
          this.customerDetails.TwitterId = "https://twitter.com/";
        }
        if (this.customerDetails.LinkedInId == null || this.customerDetails.LinkedInId == "" || this.customerDetails.LinkedInId == 'undefined') {
          this.customerDetails.LinkedInId = "https://www.linkedin.com/in/";
        }
        if (this.customerDetails.InstagramId == null || this.customerDetails.InstagramId == "" || this.customerDetails.InstagramId == 'undefined') {
          this.customerDetails.InstagramId = "https://www.instagram.com/";
        }
        if (this.customerDetails.Website == null || this.customerDetails.Website == "" || this.customerDetails.Website == 'undefined') {
          this.customerDetails.Website = "https://www.website.com/";
        }
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try after some time.");
      }
    );
  }

  SaveOrUpdate_ComponentOptionsSelectedValues(getselectedFormSections: any) {
    this.isBtndisabled = true;
    this.submited = true;
    this.spinner.show();
    getselectedFormSections.forEach((component: any, item: any) => {
      let FormComponentOptiondetails = component.SelectedFormComponentDetailsModelList;
      FormComponentOptiondetails.forEach((Values: any, item: any) => {
        let selecteduseroptionlist = Values.SelectedFormValuesDetailsModelList;
        let selectedoptionlist = Values.SelectedFormOptionsDetailsModelList;
        console.log(Values);
        //validate form begin
        if (Values.IsRequired == true && (selectedoptionlist.length > 0 && selecteduseroptionlist.length == 0)) {
          console.log(Values.FormComponentName);
          this.isRequiredSave = true;
        }
        //validate form end
        selecteduseroptionlist.forEach((selectedValues: any, item: any) => {
          //validate form begin
          if (Values.IsRequired == true && (selectedValues.FormComponentOptionValue == '' || selectedValues.FormComponentOptionValue == null || selectedValues.FormComponentOptionValue == undefined)) {
            console.log(Values.FormComponentName);
            this.isRequiredSave = true;
          }
          //validate form end
          if (selectedValues.SelectedFormValuesDetailsModelList != undefined) {
            if (selectedValues.SelectedFormValuesDetailsModelList.length > 0) {
              selectedValues.SelectedFormValuesDetailsModelList.forEach((selectedMultiple: any, item: any) => {
                console.log(Values.IsRequired);
                if (selectedMultiple.CRMCustomerFormValueId != null && selectedMultiple.CRMCustomerFormValueId != undefined && selectedMultiple.FormComponentOptionValue == "") {
                  let model = {
                    CRMCustomerFormValue: selectedMultiple.FormComponentOptionValue,
                    CRMCustomerFormValueId: selectedMultiple.CRMCustomerFormValueId,
                    FormComponentId: selectedMultiple.FormComponentId,
                    FormComponentOptionId: null,
                    IsActive: false,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else if ((selectedMultiple.CRMCustomerFormValueId == null || selectedMultiple.CRMCustomerFormValueId == undefined) && selectedMultiple.FormComponentOptionValue != "") {
                  if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                    selectedMultiple.FormComponentOptionId = null;
                  }
                  else {
                    // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                    const findList1 = selectedoptionlist.filter((item: any) => {
                      return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                    });
                    if (findList1 != undefined && findList1.length > 0) {
                      if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                        selectedMultiple.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                          return item.FormComponentOptionValue === selectedMultiple.FormComponentOptionValue; // Filter out the appropriate one
                        })[0].FormComponentOptionId;

                      }
                    }
                    else {
                      selectedValues.FormComponentOptionId = null;
                    }
                    // }
                    // else {
                    //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                    // }


                  }
                  let model = {
                    CRMCustomerFormValue: selectedMultiple.FormComponentOptionValue,
                    CRMCustomerFormValueId: null,
                    FormComponentId: selectedMultiple.FormComponentId,
                    FormComponentOptionId: selectedMultiple.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else if (selectedMultiple.CRMCustomerFormValueId != null && selectedMultiple.CRMCustomerFormValueId != undefined && selectedMultiple.FormComponentOptionValue != "") {
                  if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                    selectedMultiple.FormComponentOptionId = null;
                  }
                  else {
                    // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                    const findList1 = selectedoptionlist.filter((item: any) => {
                      return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                    });
                    if (findList1 != undefined && findList1.length > 0) {
                      if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                        selectedMultiple.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                          return item.FormComponentOptionValue === selectedMultiple.FormComponentOptionValue; // Filter out the appropriate one
                        })[0].FormComponentOptionId;

                      }
                    }
                    else {
                      selectedValues.FormComponentOptionId = null;
                    }
                    //   }
                    //  else{
                    //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                    //  }
                  }
                  let model = {
                    CRMCustomerFormValue: selectedMultiple.FormComponentOptionValue,
                    CRMCustomerFormValueId: selectedMultiple.CRMCustomerFormValueId,
                    FormComponentId: selectedMultiple.FormComponentId,
                    FormComponentOptionId: selectedMultiple.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else {

                }
              });
            }
            else {
              if (selectedValues.CRMCustomerFormValueId != null && selectedValues.CRMCustomerFormValueId != undefined && selectedValues.FormComponentOptionValue == "") {
                let model = {
                  CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                  CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId != undefined ? selectedValues.FormComponentOptionId : null,
                  IsActive: false,
                };
                this.customerSelecetdValuesList.push(model);
              }
              else if ((selectedValues.CRMCustomerFormValueId == null || selectedValues.CRMCustomerFormValueId == undefined) && selectedValues.FormComponentOptionValue != "") {
                if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                  selectedValues.FormComponentOptionId = null;
                }
                else {
                  // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                  const findList1 = selectedoptionlist.filter((item: any) => {
                    return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                  });
                  if (findList1 != undefined && findList1.length > 0) {
                    if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                      selectedValues.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                        return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                      })[0].FormComponentOptionId;

                    }
                  }
                  else {
                    selectedValues.FormComponentOptionId = null;
                  }
                  // }
                  // else {
                  //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                  // }
                }

                if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                  let model = {
                    CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                    CRMCustomerFormValueId: null,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else {
                  let model = {
                    CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                    CRMCustomerFormValueId: null,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
              }
              else if (selectedValues.CRMCustomerFormValueId != null && selectedValues.CRMCustomerFormValueId != undefined && selectedValues.FormComponentOptionValue != "") {
                if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                  selectedValues.FormComponentOptionId = null;
                }
                else {
                  // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                  const findList1 = selectedoptionlist.filter((item: any) => {
                    return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                  });
                  if (findList1 != undefined && findList1.length > 0) {
                    if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                      selectedValues.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                        return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                      })[0].FormComponentOptionId;

                    }
                  }
                  else {
                    selectedValues.FormComponentOptionId = null;
                  }
                  // }
                  // else{
                  //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                  // }

                }

                if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                  let model = {
                    CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                    CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else {
                  let model = {
                    CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                    CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
              }
              else if (selectedValues.FormComponentOptionId != undefined && selectedValues.FormComponentOptionId != null && selectedValues.CRMCustomerFormValueId == undefined) {
                if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                  let model = {
                    CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                    CRMCustomerFormValueId: null,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
                else{
                  let model = {
                    CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                    CRMCustomerFormValueId: null,
                    FormComponentId: selectedValues.FormComponentId,
                    FormComponentOptionId: selectedValues.FormComponentOptionId,
                    IsActive: true,
                  };
                  this.customerSelecetdValuesList.push(model);
                }
              }
              else {
              }
            }
          }
          else {
            if (selectedValues.CRMCustomerFormValueId != null && selectedValues.CRMCustomerFormValueId != undefined && selectedValues.FormComponentOptionValue == "") {
              if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                let model = {
                  CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                  CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: null,
                  IsActive: false,
                };
                this.customerSelecetdValuesList.push(model);
              }
              else{
                let model = {
                  CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                  CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: null,
                  IsActive: false,
                };
                this.customerSelecetdValuesList.push(model);
              }
            }
            else if ((selectedValues.CRMCustomerFormValueId == null || selectedValues.CRMCustomerFormValueId == undefined) && selectedValues.FormComponentOptionValue != "") {
              if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                selectedValues.FormComponentOptionId = null;
              }
              else {
                // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                const findList1 = selectedoptionlist.filter((item: any) => {
                  return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                });
                if (findList1 != undefined && findList1.length > 0) {
                  if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                    selectedValues.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                      return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                    })[0].FormComponentOptionId;

                  }
                }
                else {
                  selectedValues.FormComponentOptionId = null;
                }
                // }
                // else{
                //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                // }
              }
              if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                let model = {
                  CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                  CRMCustomerFormValueId: null,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
              else{
                let model = {
                  CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                  CRMCustomerFormValueId: null,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
            }
            else if (selectedValues.CRMCustomerFormValueId != null && selectedValues.CRMCustomerFormValueId != undefined && selectedValues.FormComponentOptionValue != "") {
              if (Values.FormComponentOptionType == "Freeform" || (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne")) {
                selectedValues.FormComponentOptionId = null;
              }
              else {
                // if (selectedValues.FormComponentOptionId == undefined || selectedValues.FormComponentOptionId == null || selectedValues.FormComponentOptionId == "") {
                // const findList1 = selectedoptionlist.indexOf(selectedValues.FormComponentOptionValue);
                const findList1 = selectedoptionlist.filter((item: any) => {
                  return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                });
                if (findList1 != undefined && findList1.length > 0) {
                  if (selectedoptionlist.length > 0 && selectedoptionlist != null) {
                    selectedValues.FormComponentOptionId = selectedoptionlist.filter((item: any) => {
                      return item.FormComponentOptionValue === selectedValues.FormComponentOptionValue; // Filter out the appropriate one
                    })[0].FormComponentOptionId;

                  }
                }
                else {
                  selectedValues.FormComponentOptionId = null;
                }
                // }
                // else{
                //   selectedValues.FormComponentOptionId = selectedValues.FormComponentOptionId;
                // }

              }
              if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                let model = {
                  CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                  CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
              else{
                let model = {
                  CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                  CRMCustomerFormValueId: selectedValues.CRMCustomerFormValueId,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
            }
            else if (selectedValues.FormComponentOptionId != undefined && selectedValues.FormComponentOptionId != null && selectedValues.CRMCustomerFormValueId == undefined) {
              if (Values.FormComponentDataType == "Date" && Values.FormComponentOptionType == "PickOne") {
                let model = {
                  CRMCustomerFormValue: this.datePipe.transform(selectedValues.FormComponentOptionValue, 'MM/dd/yyyy'),
                  CRMCustomerFormValueId: null,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
              else{
                let model = {
                  CRMCustomerFormValue: selectedValues.FormComponentOptionValue,
                  CRMCustomerFormValueId: null,
                  FormComponentId: selectedValues.FormComponentId,
                  FormComponentOptionId: selectedValues.FormComponentOptionId,
                  IsActive: true,
                };
                this.customerSelecetdValuesList.push(model);
              }
            }
            else {
            }
          }
        });
      });
    });

    let componentoptionsdetailsupdate = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMCustomerId: this.cRMCustomerid,
      CRMCustomerFormValuesList: this.customerSelecetdValuesList
    };
    console.log(componentoptionsdetailsupdate, 'componentoptionsdetailsupdate');
    console.log(this.isRequiredSave);
    if (this.isRequiredSave) {
      this.toastralert.toastrerrorbottom("Please fill all the mandatory fields.");
      this.isRequiredSave = false;
      this.isBtndisabled = false;
      this.customerSelecetdValuesList = [];
      this.spinner.hide();
    }
    else {
      this.profileService.SaveOrUpdate_ComponentOptionsSelectedValues(componentoptionsdetailsupdate).subscribe((results) => {
        if (results != null && results != "" && results != undefined) {
          this.toastralert.toastersuccesstop('Profile details updated successfully');
          this.redirecttoprofile();
          this.customerSelecetdValuesList = [];
          this.closeModal();
          this.get_profiledetails();
          this.Get_ComponentOptionsSelectedValues();
          this.submited = false;
          this.isBtndisabled = false;
          this.spinner.hide();
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving Profile details');
          this.spinner.hide();
          this.customerSelecetdValuesList = [];
          this.submited = false;
          this.isBtndisabled = false;


        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save details.Please try after some time.");
          this.customerSelecetdValuesList = [];
          this.submited = false;
          this.isBtndisabled = false;
          this.spinner.hide();
        }
      );
    }

  }

  Get_ComponentOptionsSelectedValues() {
    this.profileService.Get_ComponentOptionsSelectedValues(this.formName, this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.selectedFormSections = results;
        // this.selectedFormSections.forEach((components: any, item: any) => {
        //   components.SelectedFormComponentModelList.forEach((selectedValues: any, item: any) => {
        //     if(selectedValues.FormComponentDataType == 'Date'){
        //       selectedValues.SelectedFormOptionsModelList.forEach((selectedDate: any, item: any) => {
        //        console.log( selectedDate.FormComponentOptionValue);
        //       })
        //     }
        //   })
        // })
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try after some time.");
      }
    );
  }

  update_CRMCustomerDetailsByCRMCustomerId(customersavedetails: any) {
    let customerdetailsupdate = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMCustomerId: this.cRMCustomerid,
      Status: customersavedetails.Status,
      PhoneNumber: customersavedetails.PhoneNumber == null ? "" : customersavedetails.PhoneNumber,
      InformalName: customersavedetails.InformalName,
      FormalName: customersavedetails.FormalName,
      EnvelopeName: customersavedetails.EnvelopeName,
      RecognitionName: customersavedetails.RecognitionName,
      Gender: customersavedetails.Gender,
      Website: customersavedetails.Website,
      FacebookId: customersavedetails.FacebookId,
      TwitterId: customersavedetails.TwitterId,
      LinkedInId: customersavedetails.LinkedInId,
      InstagramId: customersavedetails.InstagramId,
      Employer: customersavedetails.Employer,
      JobTitle: customersavedetails.JobTitle,
      DOB: this.datePipe.transform(customersavedetails.Birthday, 'MM/dd/yyyy'),
      FullName: customersavedetails.PrimaryContact,
      IsArchived: false
    };
    this.profileService.update_CRMCustomerDetailsByCRMCustomerId(customerdetailsupdate).subscribe((results) => {
      if (results != null && results != "" && results != undefined) {
        this.closeModal();
        this.get_profiledetails();
      }
      else {
        this.toastralert.toastrerror('Error occurred while saving Customer details');
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to save details.Please try after some time.");
      }
    );
  }

  get_profiledetails() {
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.profilelist = results;
        console.log(this.profilelist);
        this.phonenumberlist = this.profilelist.CRMPhoneNumbersModelList;
        this.Addresslist = this.profilelist.CRMAddressModelList;
        this.Emaillist = this.profilelist.CRMEmailsModelList;
      }
    },
      (err) => {
        console.log(err);
        this.toastralert.toastrerror('Unable to get profile details.Please try after some time.');
      }
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  redirecttotimeline() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/timeline/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/timeline/' + this.cRMCustomerid]);
    }
  }

  redirecttoavailability() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/availability/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/availability/' + this.cRMCustomerid]);
    }
  }

  redirecttoprofile() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/profile/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/' + this.cRMCustomerid]);
    }
  }
  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }
}
