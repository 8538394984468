<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush ">
            <div class="card-header">
                <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1 fs-5 text-dark">App Reports</h3>
                </div>
            </div>
            <div class="card-body bg-white pt-0 px-1">
                <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                    <li class="nav-item py-2 px-5 ">
                        <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'ApiLog' }"
                            (click)="setActiveTab('ApiLog')" role="tab" routerLink="/appreports/apiLogErrorReport">
                            <fa-icon [icon]="faLayerGroup" class="fs-4 me-3 cursor-pointer"></fa-icon>
                            API Log Error Report
                        </a>
                    </li>
                    <li class="nav-item py-2 px-5 ">

                        <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'DbLog' }"
                            (click)="setActiveTab('DbLog')" role="tab" routerLink="/appreports/dbLogErrorReport">
                            <fa-icon [icon]="faLayerGroup" class="fs-4 me-3 cursor-pointer"></fa-icon>
                            DB Log Error Report
                        </a>
                    </li>
                </ul>
                <div class="tab-content pt-0">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>