<div class="row px-4 py-0 pb-5">
    <div class="bgcolor stickybar ">
        <ul class="breadcrumb pb-2" *ngIf="userRole != 'Volunteer'">
            <li><a class="routingcontacts" routerLink="/profiles">Profiles </a> >> </li>
            <li> Profile</li>
        </ul>
        <div class="card card-body py-3">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark ">{{profilelist.PrimaryContact}}</span>

                                </a></div>
                            <div class="text-secondary cursor-pointer item-hover">{{profilelist.AccountNumber}}</div>
                            <div class="text-secondary cursor-pointer item-hover" *ngFor="let emaillist of Emaillist">
                                <div *ngIf="emaillist.IsPrimary==true">
                                    {{emaillist.EmailAddress}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div *ngFor="let address of Addresslist ">
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.Address1}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover"
                                    *ngIf="address.IsPrimary==true && address.Address2 != '' && address.Address2 != null">
                                    {{address.Address2}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.City}}, {{address.StateAbbreviation}} {{address.PostalCode}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-secondary cursor-pointer item-hover"
                                *ngFor="let phonelist of phonenumberlist">
                                <div *ngIf="phonelist.IsPrimary==true">
                                    {{phonelist.PhoneType}} {{phonelist.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex my-4">
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                        </div>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Payments</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Generate
                                    Bill</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Settings</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a (click)="openmodal(addnewnote)" class="menu-link px-3">New Note</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card-header card-header-stretch overflow-auto mt-4">
            <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="redirecttoprofile()" role="tab">
                        <fa-icon [icon]="faUserCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5" *ngIf="userRole != 'Volunteer'">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Timeline' }"
                        (click)="redirecttotimeline()" role="tab">
                        <fa-icon [icon]="faTimeline" class="fs-4 me-3  cursor-pointer"></fa-icon>
                        History
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="redirecttoavailability()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Availability
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'RelationShips' }" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        RelationShips
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="card-custom">

        <div class="tab-content pt-3">
            <div class="tab-pane" [ngClass]="{ active: activeTab === 'RelationShips' }">
                <div class="card mt-0">
                    <div class="card-header px-1 min-h-30px background-secondary">
                        <div class="card-title ">
                            <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                <i class="fa fa-home text-white me-1"></i>{{profilelist.PrimaryContact}}
                            </h3>
                        </div>
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                        </div>
                    </div>
                    <div class="card-body px-5 py-2 componentscroll">
                        <ul class="list-unstyled">
                            <li class="border-bottom" *ngFor="let householdItem of HouseholdRelationshipList">
                                <div class="row py-2">
                                    <div class="col-lg-10 col-md-10 col-sm-10">
                                        <a href="#" class="text-dark">{{householdItem.FullName}}</a>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2 text-end">
                                        <span class="me-2"  *ngIf="householdItem.IsHeadOfHousehold == false" ><i>Member</i> </span>
                                        <span class="me-2"  *ngIf="householdItem.IsHeadOfHousehold == true"><i>Head of Household</i> </span>
                                        <span class="dropdown ms-1" ngbDropdown>
                                            <a class="text-muted" type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                <i class="bx bxs-flag font-size-25"></i>
                                            </a>
                                            <ul class="dropdown-menu dropdown-menu-end"
                                                *ngIf="householdItem.IsHeadOfHousehold == true"
                                                aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                                                <li><a class="dropdown-item" href="javascript:void(0);">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Edit</a>
                                                </li>
                                                <li><a class="dropdown-item" href="javascript:void(0);">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Leave Household</a>
                                                </li>
                                            </ul>
                                            <ul class="dropdown-menu dropdown-menu-end"
                                                *ngIf="householdItem.IsHeadOfHousehold == false"
                                                aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                                                <li><a class="dropdown-item" href="javascript:void(0);">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Edit</a>
                                                </li>
                                                <li><a class="dropdown-item" href="javascript:void(0);"
                                                    (click)=" UpdateHouseholdRelation(householdItem.CRMCustomerId)"
                                                    (click)="openmodal(headOfHousehold)">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Make Head Of Household</a>
                                                </li>
                                                <li><a class="dropdown-item" href="javascript:void(0);">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Leave Household</a>
                                                </li>
                                                <!-- <li><a class="dropdown-item" href="javascript:void(0);"
                                                        (click)="openDeletemodal(deleteRelationship)">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Delete</a>
                                                </li> -->
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="card mt-5">
                    <div class="card-header px-1 min-h-30px background-secondary">
                        <div class="card-title ">
                            <h3 class="fs-4 fw-semibold ms-1 text-white text-truncate mw-500px">
                                Relationships
                            </h3>
                        </div>
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            <i data-toggle="tooltip" title="Add New Relationships"
                                (click)="openmodal(addnewrelationship)"
                                class="fa fa-plus fs-7 p-1 text-white cursor-pointer"></i>
                        </div>
                    </div>
                    <div class="card-body px-5 py-2 componentscroll">
                        <ul class="list-unstyled">
                            <li class="border-bottom" *ngFor="let customerItem of customerReationshipList">
                                <div class="row py-2 cursormove">
                                    <div class="col-lg-9 col-md-9 col-sm-9">
                                        <label class="text-dark px-2 labelsize">{{customerItem.FullName}}</label>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3 text-end">
                                        <span class="me-2"><i>{{customerItem.RelationshipRole1_Name}}</i> </span>
                                        <span class="dropdown ms-1" ngbDropdown>
                                            <a class="text-muted" type="button" id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                                <i class="bx bxs-flag font-size-25"></i>
                                            </a>
                                            <ul class="dropdown-menu dropdown-menu-end"
                                                aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                                                <li (click)="openmodal(addnewrelationship)"
                                                    (click)="UpdateRelation(customerItem.CRMConstituentRelationshipId)">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Edit</a>
                                                </li>
                                                <li
                                                    (click)="DeleteRelationship(customerItem.CRMConstituentRelationshipId)">
                                                    <a class="dropdown-item" href="javascript:void(0);"
                                                        (click)="openDeletemodal(deleteRelationship)">
                                                        <i class="bx bxs-flag pe-2 text-danger"></i>Delete</a>
                                                </li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #addnewnote let-modal>
        <div class="modal-lg">
            <div class="modal-header modal-lg background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">Add New Note</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <label class="py-1 text-dark fs-5 text-end">New Note</label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <input class="form-control p-2 fs-7" placeholder="Enter Note">
                        <div class="mt-3">

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="modal.dismiss('Cross click')">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal"><i class="fa fa-save fs-7 text-white"></i>
                    Save</button>
            </div>
        </div>
    </ng-template>

    <ng-template #addnewrelationship let-modal modal-lg>
        <!-- <div class="modal-lg"> -->
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrRelationship}}</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="closeRelationshipModel()"></a>
        </div>
        <div class="modal-body py-2">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <!-- <label class="py-1 text-dark fs-5 text-end"><button class="btn btn-primary btn-sm me-2 p-1">Find
                            Member</button>is the...</label> -->
                    <label class="py-1 fs-5">Find Member</label>
                    <ng-select data-control="select2" data-hide-search="false" (change)="getselectedMember()"
                        [(ngModel)]="relationshipDetails.RelationCRMCustomerId" placeholder="Select Member"
                        [clearable]="false">
                        <ng-option [value]="''" class="fs-6">Select Member</ng-option>
                        <ng-option *ngFor="let item of membersList" [value]="item.CRMCustomerId"
                            class="fs-6">{{item.FullName}}</ng-option>
                    </ng-select>
                    <label class="pt-1 text-dark fs-5 text-end"
                        *ngIf="dependentName != ''"><span>{{dependentName}}</span> is the </label>
                    <ng-select data-control="select2" class="pt-1" data-hide-search="true"
                        [(ngModel)]="relationshipDetails.RelationshipRole1_Id" [clearable]="false"
                        data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true"
                        placeholder="Select Relation">
                        <ng-option value="">Select Relation</ng-option>
                        <ng-option *ngFor='let item of relationshipLists' [value]="item.CRMRelationshipRoleId"
                            class="text-dark">
                            {{item.Name}}</ng-option>
                    </ng-select>
                    <!-- <ng-select data-control="select2" data-hide-search="false" [clearable]="false">
                        <ng-option [value]="'2'" class="fs-6">Teacher/Student</ng-option>
                        <ng-option [value]="'3'" class="fs-6">Business</ng-option>
                        <ng-option [value]="'4'" class="fs-6">Colleague/Co-Worker</ng-option>
                        <ng-option [value]="'5'" class="fs-6">Owner</ng-option>
                        <ng-option [value]="'6'" class="fs-6">Manager</ng-option>
                    </ng-select> -->
                    <label class="py-1 text-dark fs-5 text-end">{{profilelist.PrimaryContact}} is the </label>
                    <ng-select data-control="select2" data-hide-search="true" [clearable]="false" data-kt-select2="true"
                        data-placeholder="Select option" [(ngModel)]="relationshipDetails.RelationshipRole2_Id"
                        data-allow-clear="true" placeholder="Select Relation">
                        <ng-option value="">Select Relation</ng-option>
                        <ng-option *ngFor='let item of relationshipLists' [value]="item.CRMRelationshipRoleId"
                            class="text-dark">
                            {{item.Name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <label class="py-1 text-dark fs-5 text-end">Note</label>
                    <textarea class="form-control p-2 fs-7" [(ngModel)]="relationshipDetails.Note"
                        placeholder="Enter Note"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="closeRelationshipModel()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal" (click)="SaveorUpdateRelationShip(relationshipDetails)"><i
                    class="fa fa-save fs-7 text-white"></i>
                {{btnRelationship}}</button>
        </div>
        <!-- </div> -->
    </ng-template>

    <ng-template #editrelationship let-modal>
        <div class="modal-lg">
            <div class="modal-header modal-lg background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">Edit Relationship</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="py-1 text-dark fs-5 text-end">Theresa Dunn is the...</label>
                        <ng-select data-control="select2" data-hide-search="false" [clearable]="false"
                            placeholder="Participant in Lifespark">
                            <ng-option [value]="'2'" class="fs-6">Teacher/Student</ng-option>
                            <ng-option [value]="'3'" class="fs-6">Business</ng-option>
                            <ng-option [value]="'4'" class="fs-6">Colleague/Co-Worker</ng-option>
                            <ng-option [value]="'5'" class="fs-6">Owner</ng-option>
                            <ng-option [value]="'6'" class="fs-6">Manager</ng-option>
                        </ng-select>
                        <label class="py-1 text-dark fs-5 text-end">Lyn Bierbach is the ...</label>
                        <ng-select data-control="select2" data-hide-search="false" [clearable]="false"
                            placeholder="Provider in Lifespark">
                            <ng-option [value]="'2'" class="fs-6">Teacher/Student</ng-option>
                            <ng-option [value]="'3'" class="fs-6">Business</ng-option>
                            <ng-option [value]="'4'" class="fs-6">Colleague/Co-Worker</ng-option>
                            <ng-option [value]="'5'" class="fs-6">Owner</ng-option>
                            <ng-option [value]="'6'" class="fs-6">Manager</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="py-1 text-dark fs-5 text-end">Note</label>
                        <textarea class="form-control p-2 fs-7" placeholder="Enter Note"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="modal.dismiss('Cross click')">Delete</button>
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="modal.dismiss('Cross click')">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal"><i class="fa fa-save fs-7 text-white"></i>
                    Save</button>
            </div>
        </div>
    </ng-template>
    <ng-template #deleteRelationship let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Delete</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="clearDeletModel()"></a>
        </div>
        <div class="modal-body">
            <h3 class="fs-6 fw-semibold">Do you want to delete this Relationship?</h3>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="clearDeletModel()">Cancel</button>
            <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal" (click)="DeleteCustomerRelationship()"><i
                    class="fa fa-trash fs-7 text-white"></i> Delete</button>
        </div>
    </ng-template>

    <ng-template #headOfHousehold let-modal>
        <div class="modal-header background-secondary px-3 py-2" >
            <h5 class="modal-title text-white fs-4 fw-semibold">Update Head of Household? </h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="clearUpdateModel()"></a>
        </div>
        <div class="modal-body">
            <h3 class="fs-6 fw-semibold">Are you sure you want yo make this account the Head of Household?</h3>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white" (click)="clearUpdateModel()">No</button>
            <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
            (click)="Update_HeadOfHouseholdStatusByCRMCustomerId()"
                data-bs-dismiss="modal"><i class="fa fa-trash fs-7 text-white"></i> Yes</button>
        </div>
    </ng-template>
</div>
  
