<div class="
    d-flex
    flex-column flex-column-fluid
    bgi-position-y-bottom
    position-x-center
    bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-secondary
  ">
  <div class="flex-center flex-column flex-column-fluid p-10">
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2">
        <img alt="Logo" src="./assets/media/logos/volunteers_llc.png" height="150px" width="150px" />
      </div>
      <div class="col-sm-4 col-lg-2 col-md-2 text-center d-flex welcometext">
        <div class="row">
          Welcome to
          Volunteers.llc
        </div>
      </div>
      <div class="col-sm-6 col-lg-8 col-md-8 userdetailscard">
        <div class="w-lg-500px bg-white rounded shadow-sm p-lg-15 mx-auto cardpadding">
          <div class="text-center mb-10">
            <h1 class="text-primary mb-3">Forgot Password?</h1>
          </div>
          <div>
            <div class="fv-row mb-5 mt-2">
              <input [(ngModel)]='email' placeholder="Enter your Email" class="inputpading form-control"
                (keydown.enter)='forgotpswd(email)'>
            </div>
            <button (click)="forgotpswd(email)" expand="block"
              class="btn btn-lg btn-primary1 w-100 mb-5">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>