import { Routes } from '@angular/router';
import { CalenderComponent } from './calendar/calendar.component';
import { ContactsComponent } from './contacts/contacts.component';
import { NewcontactComponent } from './newcontact/newcontact.component';
import { EventComponent } from './event/event.component';
import { EventdetailsComponent } from './eventdetails/eventdetails.component';
import { NeweventComponent } from './newevent/newevent.component';
import { ContactprofileComponent } from './contactprofile/contactprofile.component';
import { JobsComponent } from './jobs/jobs.component';
import { JobdetailsComponent } from './jobdetails/jobdetails.component';
import { ReportsComponent } from './reports/reports.component';
import { SetupComponent } from './setup/setup.component';
import { FacilityComponent } from './facility/facility.component';
import { HistoryofeventsComponent } from './historyofevents/historyofevents.component';
import { ProviderComponent } from './provider/provider.component';
import { ParticipantComponent } from './participant/participant.component';
import { MemberslistComponent } from './memberslist/memberslist.component';
import { AuthguardService } from '../services/authguard.service';
import { ProfileComponent } from './profile/profile.component';
import { CategoryComponent } from './category/category.component';
import { OptionsComponent } from './options/options.component';
import { Category1Component } from './category1/category1.component';
import { AvailabilityComponent } from './availability/availability.component';
import { TimelineComponent } from './timeline/timeline.component';
import { EditprofileComponent } from './editprofile/editprofile.component';
import { CanDeactivateGurdService } from '../services/candeactive-guard.service';
import { LogoutComponent } from './logout/logout.component';
import { ApiLogErrorReportsComponent } from './api-log-error-reports/api-log-error-reports.component';
import { DbLogErrorReportsComponent } from './db-log-error-reports/db-log-error-reports.component';
import { SchedularComponent } from './schedular/schedular.component';
import { LocationMappingComponent } from './location-mapping/location-mapping.component';
import { MatchStatusComponent } from './match-status/match-status.component';
import { VolunteersreportsComponent } from './volunteersreports/volunteersreports.component';
import { ReportListComponent } from './report-list/report-list.component';
import { RelationshipsComponent } from './relationships/relationships.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { BeneficiariesComponent } from './beneficiaries/beneficiaries.component';
import { AdminsComponent } from './admins/admins.component';

const Routing: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate: [AuthguardService], data: {
        role: ["Admin","Staff"]
      },
  },
  // {
  //   path: 'profiles', component: ContactsComponent, canActivate: [AuthguardService], data: {
  //     role: ["Admin","Staff","Volunteer"]
  //   },
  // },
  {
    path: 'profiles/editprofile/:cRMCustomerId', component: EditprofileComponent, canActivate: [AuthguardService]
  },
  {
    path: 'myprofile/editprofile/:cRMCustomerId', component: EditprofileComponent, canActivate: [AuthguardService]
  },
  {
    path: 'calendar', component: SchedularComponent, canActivate: [AuthguardService]
  },
  {
    path: 'newcontact', component: NewcontactComponent, canActivate: [AuthguardService]
  },
  {
    path: 'profiles/contactprofile', component: ContactprofileComponent, canActivate: [AuthguardService]
  },
  {
    path: 'event', component: EventComponent, canActivate: [AuthguardService]
  },
  {
    path: 'provider', component: ProviderComponent, canActivate: [AuthguardService]
  },
  {
    path: 'participant', component: ParticipantComponent, canActivate: [AuthguardService]
  },
  {
    path: 'memberslist', component: MemberslistComponent, canActivate: [AuthguardService]
  },
  {
    path: 'jobs', component: JobsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'historyofevents', component: HistoryofeventsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'event/eventdetails', component: EventdetailsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'event/newevent', component: NeweventComponent, canActivate: [AuthguardService]
  },
  {
    path: 'jobs/jobdetails', component: JobdetailsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'schedular', component: SchedularComponent, canActivate: [AuthguardService]
  },
  {
    path: 'locationmapping', component: LocationMappingComponent, canActivate: [AuthguardService], data: {
      role: ["Admin","Staff"]
    },
  },
  {
    path: 'appreports', component: ReportsComponent, canActivateChild: [AuthguardService], data: {
      role: ["Admin","Staff"]
    },
    children:[
      {
        path: '',
        redirectTo: 'apiLogErrorReport',
        pathMatch: 'full',
      },
      {path:'apiLogErrorReport', component: ApiLogErrorReportsComponent ,data: {
        role: ["Admin","Staff"]
      }},
      {path: 'dbLogErrorReport', component: DbLogErrorReportsComponent, data: {
        role: ["Admin","Staff"]
      }}
    ],
   
  },
  {
    path: 'profiles', component: ContactsComponent, canActivateChild: [AuthguardService], data: {
      role: ["Admin","Staff","Volunteer"]
    },
    children:[
      {
        path: '',
        redirectTo: 'volunteers',
        pathMatch: 'full',
      },
      {path:'volunteers', component: VolunteersComponent ,data: {
        role: ["Admin","Staff","Volunteer"]
      }},
      {path: 'beneficiaries', component: BeneficiariesComponent, data: {
        role: ["Admin","Staff","Volunteer"]
      }},
      {path: 'Admins', component: AdminsComponent, data: {
        role: ["Admin","Staff","Volunteer"]
      }}
    ],
   
  },
  {
    path: 'setup', component: SetupComponent, canActivate: [AuthguardService], data: {
      role: ["Admin","Staff"]
    },
  },
  {
    path: 'facility', component: FacilityComponent, canActivate: [AuthguardService]
  },
  {
    path: 'profiles/profile/:cRMCustomerId', component: ProfileComponent, canActivate: [AuthguardService]
  },
  {
    path: 'myprofile/:cRMCustomerId', component: ProfileComponent, canActivate: [AuthguardService]
  },
  {
    path: 'category', component: CategoryComponent, canActivate: [AuthguardService]
  },
  {
    path: 'setup/options/:formid/:sectionid/:componentid', component: OptionsComponent, canActivate: [AuthguardService], canDeactivate:[CanDeactivateGurdService], data: {
      role: ["Admin"]
    },
  },
  {
    path: 'profiles/category1', component: Category1Component, canActivate: [AuthguardService]
  },
  {
    path: 'profiles/availability/:cRMCustomerId', component: AvailabilityComponent, canActivate: [AuthguardService]
  }, 
  {
    path: 'profiles/timeline/:cRMCustomerId', component: TimelineComponent, canActivate: [AuthguardService]
  },
  {
    path: 'myprofile/availability/:cRMCustomerId', component: AvailabilityComponent, canActivate: [AuthguardService]
  }, 
  {
    path: 'myprofile/timeline/:cRMCustomerId', component: TimelineComponent, canActivate: [AuthguardService]
  },
  {
    path: 'matchStatus', component: MatchStatusComponent, canActivate: [AuthguardService]
  },
  {
    path: 'volunteersreports', component:VolunteersreportsComponent, canActivate: [AuthguardService] 
  },
  {
    path: 'volunteersreports/:reportQueryId', component:VolunteersreportsComponent, canActivate: [AuthguardService] 
  },
  {
    path: 'reportList', component:ReportListComponent, canActivate: [AuthguardService] 
  },
  {
    path: 'profiles/relationships/:cRMCustomerId', component: RelationshipsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'myprofile/relationships/:cRMCustomerId', component: RelationshipsComponent, canActivate: [AuthguardService]
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contacts/contacts.component').then((m) => m.ContactsComponent), canActivate: [AuthguardService]
  },
  {
    path: 'builder',
    loadChildren: () =>
      import('./builder/builder.component').then((m) => m.BuilderComponent), canActivate: [AuthguardService]
  },

  {
    path: 'logout', component: LogoutComponent, canActivate: [AuthguardService]
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '/contacts',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
  
];

export { Routing };
