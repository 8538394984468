<div class="card p-3">
    <!-- <smart-grid #grid id="grid" [dataSource]="dataSource" [columns]="columns" [sorting]="sorting"
[grouping]="grouping" class="w-100">
</smart-grid> -->
<div class="fs-4 fw-semibold p-1">Match Status</div>
<smart-grid [paging]="paging" [pager]="pager" [sorting]="sorting"
    [filtering]="filtering" [layout]="layout" [behavior]="behavior"
    [dataSource]="benificiariesgridsource" [columns]="benificiariescolumns"
    [appearance]="appearance" #benificiariesgrid id="benificiariesgrid"
    class="w-100" style="height: calc(100vh - 300px);"></smart-grid>
<div class="float-end mt-0">Total Number of Records in Table: {{participantsLen}}</div>
</div>
<ng-template #delete let-modal>
    <div class="modal-header background-secondary px-3 py-2">
        <h5 class="modal-title text-white fs-4 fw-semibold">Delete</h5>
        <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
            ></a>
    </div>
    <div class="modal-body">
        <h3 class="fs-6 fw-semibold">Do you want to delete?</h3>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
            (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
            data-bs-dismiss="modal"><i class="fa fa-trash fs-7 text-white"></i> Delete</button>
    </div>
</ng-template>