import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent, Smart, GridColumn } from 'smart-webcomponents-angular/grid';
import { ProfileService } from 'src/app/services/profile.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormatedDatePipe } from '../CustomPipes/formatDate.pipe';

type Tabs = 'Profile' | 'Timeline' | 'Availability' | 'RelationShips';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [FormatedDatePipe],
})
export class ProfileComponent implements OnInit {
  @ViewChild('availabilitygrid', { read: GridComponent, static: false }) grid!: GridComponent;
  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  faUserCircle = faUserCircle;
  faTimeline = faTimeline;
  faTimesCircle = faClock;
  faEdit = faEdit;
  closeResult: any;
  activeTab: Tabs = 'Profile';
  configLoading: boolean = false;
  resetLoading: boolean = false;
  cRMCustomerid: any;
  profilelist: any = { PrimaryContact: '' };
  phonenumberlist: any;
  Addresslist: any;
  Emaillist: any;
  newphone: any = { CRMPhoneId: '', PhoneType: '', PhoneNumber: '', IsPrimary: true };
  newemail: any = { CRMEmailId: '', EmailType: '', EmailAddress: '', IsPrimary: true };
  newaddress: any = { CRMAddressId: '', AddressType: '', Street: '', City: '', State: '', StateAbbreviation: '', CountryCode: '', PostalCode: '', Country: '', IsPrimary: true, Address1: '', Address2: '' };
  phonelist: any = { PhoneType: '', PhoneNumber: '', IsPrimary: false };
  emaillist: any;
  addresslist: Object;
  phonedetails: any = { CRMCUSTOMERId: '', CRMPhoneId: '', };
  emaildetails: any;
  btnAddress: string = 'Save';
  hdrAddress: string = 'Add Address';
  hdrPhone: string = 'Add Phone Number';
  btnPhone: string = 'Save';
  btnEmail: string = 'Save';
  hdrEmail: string = 'Add Email';
  token_response: any;
  token: any;
  selectedFormSections: any;
  formName: any;
  addressCount: number = 0;
  emailsCount: number = 0;
  phoneNumbersCount: number = 0;
  isPrimaryAddress: boolean = false;
  isPrimaryPhone: boolean = false;
  isPrimaryEmail: boolean = false;
  addresstype: any = ['Home', 'Work'];
  emailtype: any = ['Home', 'Work'];
  phonetype: any = ['Home', 'Work', 'Mobile', 'Fax'];
  userRole:any = "";

  constructor(
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public router: Router,
    private profileService: ProfileService,
    public config: NgbModalConfig,
    public toastralert: ToastralertService,
    private spinner: NgxSpinnerService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
    this.formName = 'Profile';
  }

  ngOnInit(): void {
    this.userRole = this.token_response.UserRole;
    this.route.params.subscribe(params => {
      this.cRMCustomerid = params['cRMCustomerId'];
    });
    this.get_profiledetails();
    this.Get_ComponentOptionsSelectedValues();

  }

  redirecttotimeline() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/timeline/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/timeline/' + this.cRMCustomerid]);
    }
  }

  redirecttoavailability() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/availability/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/availability/' + this.cRMCustomerid]);
    }
  }
  redirecttorelationships() {
    if(this.token_response.UserRole != "Volunteer"){
      this.router.navigate(['profiles/relationships/' + this.cRMCustomerid]);
    }
    else{
      this.router.navigate(['myprofile/relationships/' + this.cRMCustomerid]);
    }
  }

  get_profiledetails() {
    this.spinner.show();
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.profilelist = results;
        console.log(this.profilelist)
        this.phonenumberlist = this.profilelist.CRMPhoneNumbersModelList;
        this.Addresslist = this.profilelist.CRMAddressModelList;
        this.Emaillist = this.profilelist.CRMEmailsModelList;
        this.addressCount = this.profilelist.CRMAddressModelList != null ? this.profilelist.CRMAddressModelList.length : 0;
        this.phoneNumbersCount = this.profilelist.CRMPhoneNumbersModelList != null ? this.profilelist.CRMPhoneNumbersModelList.length : 0;
        this.emailsCount = this.profilelist.CRMEmailsModelList != null ? this.profilelist.CRMEmailsModelList.length : 0;
        this.newaddress.IsPrimary = this.addressCount == 0 ? true : false;
        this.newphone.IsPrimary = this.phoneNumbersCount == 0 ? true : false;
        this.newemail.IsPrimary = this.emailsCount == 0 ? true : false;
        this.isPrimaryAddress = this.addressCount == 0 ? true : false;
        this.isPrimaryPhone = this.phoneNumbersCount == 0 ? true : false;
        this.isPrimaryEmail = this.emailsCount == 0 ? true : false;
        this.spinner.hide();
      }
      else {
        this.toastralert.toastrwarnningbottomright('No Data Available.');
        this.spinner.hide();
      }
    },
      (err) => {
        console.log(err);
        this.toastralert.toastrerror('Unable to get profile details.Please try after some time.');
        this.spinner.hide();
      }
    );
  }

  options: any = {
    componentRestrictions: { country: ['US','IN'] }
  }

  handleAddressChange(address: Address) {
    //console.log(address);
    //console.log(address.formatted_address)
    this.newaddress.Address1 = address.name;
    console.log(address,'addressaddressaddress');
    console.log(this.newaddress.Address);
    this.newaddress.Address2 = "";
    address.address_components.forEach((Values: any, item: any) => {
      console.log(Values.types[0]);
      console.log(Values.long_name)
      // if (Values.types[0] == 'neighborhood' || Values.types[0] == 'sublocality_level_1') {
      //   if (this.newaddress.Street != "" && this.newaddress.Street != null && this.newaddress.Street != undefined) {
      //     this.newaddress.Street = this.newaddress.Street + ', ' + Values.long_name;
      //   }
      //   else {
      //     this.newaddress.Street = Values.long_name;
      //   }
      // }
      if (Values.types[0] == 'locality') {
        this.newaddress.City = Values.long_name;
      }
      else if (Values.types[0] == 'administrative_area_level_1') {
        this.newaddress.State = Values.long_name;
        this.newaddress.StateAbbreviation = Values.short_name;

      }
      else if (Values.types[0] == 'country') {
        this.newaddress.Country = Values.long_name;
        this.newaddress.CountryCode = Values.short_name;
      }
      else if (Values.types[0] == 'postal_code') {
        this.newaddress.PostalCode = Values.long_name;
      }
      else {

      }
    });

  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  resetPreview(): void {
    this.resetLoading = true;
  }

  submitPreview(): void {
    this.configLoading = true;
  }

  openmodal(content: any) {
    this.newaddress.CRMAddressId = '';
    this.newphone.CRMPhoneId = '';
    this.newemail.CRMEmailId = '';
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.get_DismissReason(reason)}`;
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  private get_DismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validatePhone(phone: any) {
    var re = /^\(\d{3}\) \d{3}-\d{4}$/;
    return re.test(phone);
  }

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  validatePostalcode(postalcode: any) {
    var re = /^[1-9]{1}[0-9]{2}\\s{0, 1}[0-9]{3}$/;
    return re.test(postalcode);
  }

  SaveOrUpdate_phonedetails(newphone: { PhoneType: any; PhoneNumber: any; IsPrimary: any, CRMCustomerId: any, CRMPhoneId: any }) {
    if ((typeof newphone.CRMPhoneId == 'undefined' || newphone.CRMPhoneId == "" || newphone.CRMPhoneId == null)) {
      this.phoneNumbersCount = this.phoneNumbersCount + 1;
    }
    this.spinner.show();
    this.newphone.CRMCustomerId = this.cRMCustomerid;
    if ((typeof newphone.PhoneType == 'undefined' || newphone.PhoneType == "" || newphone.PhoneType == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Phone Type");
      this.spinner.hide();
      return false;
    }
    else if ((typeof newphone.PhoneNumber == 'undefined' || newphone.PhoneNumber == "" || newphone.PhoneNumber == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Phone Number");
      this.spinner.hide();
      return false;
    }
    else if (this.phoneNumbersCount > 4) {
      this.toastralert.toastrwarnningbottomright("You cannot add more than four Phone Numbers");
      this.phoneNumbersCount = this.phoneNumbersCount - 1;
      this.closeModal();
      this.ClearPhoneFields();
      this.spinner.hide();
      return false;
    }
    else if ((!this.validatePhone(newphone.PhoneNumber))) {
      this.toastralert.toastrwarnningbottomright("Please Enter Valid Phone Number");
      this.spinner.hide();
      return false;
    }
    else {
      let newphonedetails = {};
      if ((typeof newphone.CRMPhoneId == 'undefined' || newphone.CRMPhoneId == "" || newphone.CRMPhoneId == null)) {
        newphonedetails = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: newphone.CRMCustomerId,
          PhoneType: newphone.PhoneType,
          PhoneNumber: newphone.PhoneNumber,
          Extension: '',
          IsPrimary: newphone.IsPrimary,
        };
      }
      else {
        newphonedetails = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: newphone.CRMCustomerId,
          CRMPhoneId: newphone.CRMPhoneId,
          PhoneType: newphone.PhoneType,
          PhoneNumber: newphone.PhoneNumber,
          Extension: '',
          IsPrimary: newphone.IsPrimary,
        };
      }
      this.profileService.SaveOrUpdate_phonedetails(newphonedetails).subscribe((results) => {
        this.phonelist = results;
        if (results != null && results != "" && results != 'undefined') {
          if (results == '99999999-9999-9999-9999-999999999999') {
            this.toastralert.toastrerrorbottom("This phone number is already exits, please check once.");
            this.spinner.hide();
          }
          else {
            if ((typeof newphone.CRMPhoneId == 'undefined' || newphone.CRMPhoneId == "" || newphone.CRMPhoneId == null)) {
              this.toastralert.toastersuccesstop("Phone Number has been saved successfully");
              this.closeModal();
              this.spinner.hide();
            }
            else {
              this.toastralert.toastersuccesstop("Phone Number has been updated successfully");
              this.closeModal();
              this.spinner.hide();
            }
            this.get_profiledetails();
            this.ClearPhoneFields();
          }
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving phone details');
          this.spinner.hide();
        }

      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save phone details.Please try after some time.");
          this.spinner.hide();
        }
      );
    }
  }

  SaveOrUpdate_emaildetails(newemail: { EmailType: any, EmailAddress: any, IsPrimary: any, CRMEmailId: any, CRMCustomerId: any }) {
    if ((typeof newemail.CRMEmailId == 'undefined' || newemail.CRMEmailId == "" || newemail.CRMEmailId == null)) {
      this.emailsCount = this.emailsCount + 1;
    }
    this.spinner.show();
    this.newemail.CRMCustomerId = this.cRMCustomerid;
    if ((typeof newemail.EmailType == 'undefined' || newemail.EmailType == "" || newemail.EmailType == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Email");
      this.spinner.hide();
      return false;
    }
    else if ((typeof newemail.EmailAddress == 'undefined' || newemail.EmailAddress == "" || newemail.EmailAddress == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Email Address");
      this.spinner.hide();
      return false;
    }
    else if (this.emailsCount > 4) {
      this.toastralert.toastrwarnningbottomright("You cannot add more than four Emails");
      this.emailsCount = this.emailsCount - 1;
      this.closeModal();
      this.ClearEmailFields();
      this.spinner.hide();
      return false;
    }
    else if ((!this.validateEmail(newemail.EmailAddress))) {
      this.toastralert.toastrwarnningbottomright("Please Enter Valid Email Address");
      this.spinner.hide();
      return false;
    }
    else {
      let newemaildetails = {};
      if ((typeof newemail.CRMEmailId == 'undefined' || newemail.CRMEmailId == "" || newemail.CRMEmailId == null)) {
        newemaildetails = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: newemail.CRMCustomerId,
          EmailType: newemail.EmailType,
          EmailAddress: newemail.EmailAddress,
          IsPrimary: newemail.IsPrimary,
        };
      }
      else {
        newemaildetails = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMEmailId: newemail.CRMEmailId,
          CRMCustomerId: newemail.CRMCustomerId,
          EmailType: newemail.EmailType,
          EmailAddress: newemail.EmailAddress,
          IsPrimary: newemail.IsPrimary,
        };
      }
      this.profileService.SaveOrUpdate_CRMEmails(newemaildetails).subscribe((results) => {
        this.emaillist = results;
        if (results != null && results != "" && results != 'undefined') {
          if (results == '99999999-9999-9999-9999-999999999999') {
            this.toastralert.toastrerrorbottom("This email is already exits, please check once.");
            this.spinner.hide();
          }
          else {
            if ((typeof newemail.CRMEmailId == 'undefined' || newemail.CRMEmailId == "" || newemail.CRMEmailId == null)) {
              this.toastralert.toastersuccesstop("Email has been saved successfully");
              this.closeModal();
              this.spinner.hide();
            }
            else {
              this.toastralert.toastersuccesstop("Email has been updated successfully");
              this.closeModal();
              this.spinner.hide();
            }
            this.get_profiledetails();
            this.ClearEmailFields();
          }
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving email details');
          this.spinner.hide();
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save email details.Please try after some time.");
          this.spinner.hide();
        }
      );
    }
  }

  save_addressdeeatils(newaddress: { CRMAddressId: '', AddressType: '', Address2: '', City: '', State: '', StateAbbreviation: '', CountryCode: '', PostalCode: '', Country: '', IsPrimary: false, CRMCustomerId: '', Address1: '' }) {
    console.log(this.newaddress.Address);
    if ((typeof newaddress.CRMAddressId == 'undefined' || newaddress.CRMAddressId == "" || newaddress.CRMAddressId == null)) {
      console.log(newaddress.CRMAddressId);
      this.addressCount = this.addressCount + 1;
    }
    this.spinner.show();
    this.newaddress.CRMCustomerId = this.cRMCustomerid;
    if ((typeof newaddress.AddressType == 'undefined' || newaddress.AddressType == "" || newaddress.AddressType == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Address Type");
      this.spinner.hide();
      return false;
    }
    else if ((typeof newaddress.Address1 == 'undefined' || newaddress.Address1 == "" || newaddress.Address1 == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Address 1");
      this.spinner.hide();
      return false;
    }
    // else if ((typeof newaddress.Street == 'undefined' || newaddress.Street == "" || newaddress.Street == null)) {
    //   this.toastralert.toastrwarnningbottomright("Please Enter Street");
    //   this.spinner.hide();
    //   return false;
    // }
    else if (this.addressCount > 4) {
      this.toastralert.toastrwarnningbottomright("You cannot add more than four Address");
      this.addressCount = this.addressCount - 1;
      this.closeModal();
      this.ClearCustomerAddressFields();
      this.spinner.hide();
      return false;
    }
    else if ((typeof newaddress.City == 'undefined' || newaddress.City == "" || newaddress.City == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter City");
      this.spinner.hide();
      return false;
    }
    else if ((typeof newaddress.State == 'undefined' || newaddress.State == "" || newaddress.State == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter State");
      this.spinner.hide();
      return false;
    }
    else if ((typeof newaddress.PostalCode == 'undefined' || newaddress.PostalCode == "" || newaddress.PostalCode == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Postal code");
      this.spinner.hide();
      return false;
    }
    // else if ((!this.validatePostalcode(newaddress.PostalCode))) {
    //   this.toastralert.toastrwarningbottom("Please Enter valide Postal code");
    //   return false;
    // }
    else if ((typeof newaddress.Country == 'undefined' || newaddress.Country == "" || newaddress.Country == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Country");
      this.spinner.hide();
      return false;
    }
    else {
      let addAddrees = {};
      if ((typeof newaddress.CRMAddressId == 'undefined' || newaddress.CRMAddressId == "" || newaddress.CRMAddressId == null)) {
        addAddrees = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: newaddress.CRMCustomerId,
          AddressType: newaddress.AddressType,
          Address1: newaddress.Address1,
          Address2: newaddress.Address2,
          City: newaddress.City,
          State: newaddress.State,
          PostalCode: newaddress.PostalCode,
          Country: newaddress.Country,
          IsPrimary: newaddress.IsPrimary,
          Note: '',
          CountryCode: newaddress.CountryCode,
          StateAbbreviation: newaddress.StateAbbreviation,
        };
      }
      else {
        addAddrees = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMAddressId: newaddress.CRMAddressId,
          CRMCustomerId: newaddress.CRMCustomerId,
          Address1: newaddress.Address1,
          AddressType: newaddress.AddressType,
          Address2: newaddress.Address2,
          City: newaddress.City,
          State: newaddress.State,
          PostalCode: newaddress.PostalCode,
          Country: newaddress.Country,
          IsPrimary: newaddress.IsPrimary,
          Note: '',
          CountryCode: newaddress.CountryCode,
          StateAbbreviation: newaddress.StateAbbreviation,
        };
      }
      this.profileService.SaveOrUpdate_CRMAddresses(addAddrees).subscribe((results) => {
        this.addresslist = results;
        if (results != null && results != "" && results != 'undefined') {
          if ((typeof newaddress.CRMAddressId == 'undefined' || newaddress.CRMAddressId == "" || newaddress.CRMAddressId == null)) {
            this.toastralert.toastersuccesstop("Address has been saved successfully");
            this.closeModal();
            this.spinner.hide();
          }
          else {
            this.toastralert.toastersuccesstop("Address has been updated successfully");
            this.closeModal();
            this.spinner.hide();
          }
          this.get_profiledetails();
          this.ClearCustomerAddressFields();
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving address details');
          this.spinner.hide();
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save address details.Please try after some time.");
          this.spinner.hide();
        }
      );
    }
  }

  get_phonenumberdetails(phoneId: any) {
    this.btnPhone = "Update";
    this.hdrPhone = "Edit Phone Number";
    this.phonedetails.CRMPhoneId = phoneId,
      this.phonedetails.CRMCUSTOMERId = this.cRMCustomerid;
    this.profileService.Get_CRMPhonesDeatilsByCRMPhoneId(this.phonedetails).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.newphone = results;
        if (results.IsPrimary) {
          this.isPrimaryPhone = true;
        }
        else {
          this.isPrimaryPhone = false;
        }
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get phone details.Please try after some time.");
      }
    );
  }

  get_editemail(emailid: any) {
    this.btnEmail = "Update";
    this.hdrEmail = "Edit Email";
    this.profileService.get_CRMEmailsDeatilsByCRMEmailId(emailid, this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.newemail = results;
        if (results.IsPrimary) {
          this.isPrimaryEmail = true;
        }
        else {
          this.isPrimaryEmail = false;
        }
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get email details.Please try after some time.");
      }
    );
  }

  get_CustomerAddressDetailsbyCrmId(crmAddressId: any) {
    this.btnAddress = 'Update';
    this.hdrAddress = 'Edit Address';
    this.profileService.get_CRMAddressDetailsByCRMAddressId(crmAddressId, this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.newaddress = results;
        console.log(this.newaddress)
        if (results.IsPrimary) {
          this.isPrimaryAddress = true;
        }
        else {
          this.isPrimaryAddress = false;
        }
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to save address details.Please try after some time.");
      }
    );
  }

  ClearCustomerAddressFields() {
    this.btnAddress = 'Save';
    this.hdrAddress = 'Add Address';
    this.newaddress.CRMAddressId = '';
    this.newaddress.AddressType = '';
    this.newaddress.Address1 = '';
    this.newaddress.Address2 = '';
    this.newaddress.City = '';
    this.newaddress.State = '';
    this.newaddress.PostalCode = '';
    this.newaddress.Country = '';
    this.newaddress.StateAbbreviation = '';
    this.newaddress.CountryCode = '';
    this.newaddress.IsPrimary = this.addressCount == 0 ? true : false;
    this.isPrimaryAddress = this.addressCount == 0 ? true : false;

  }

  ClearPhoneFields() {
    this.hdrPhone = 'Add Phone Number';
    this.btnPhone = 'Save';
    this.newphone.CRMPhoneId = '';
    this.newphone.PhoneType = '';
    this.newphone.PhoneNumber = '';
    this.newphone.IsPrimary = this.phoneNumbersCount == 0 ? true : false;
    this.isPrimaryPhone = this.phoneNumbersCount == 0 ? true : false;
  }

  ClearEmailFields() {
    this.hdrEmail = 'Add Email';
    this.btnEmail = 'Save';
    this.newemail.CRMEmailId = '';
    this.newemail.EmailType = '';
    this.newemail.EmailAddress = '';
    this.newemail.IsPrimary = this.emailsCount == 0 ? true : false;
    this.isPrimaryEmail = this.emailsCount == 0 ? true : false;
  }

  update_CRMCustomerDetailsByCRMCustomerId(customersavedetails: any) {
    let customerdetailsupdate = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMCustomerId: this.cRMCustomerid,
      Status: customersavedetails.Status,
      PhoneNumber: customersavedetails.PhoneNumber == null ? "" : customersavedetails.PhoneNumber,
      InformalName: customersavedetails.InformalName,
      FormalName: customersavedetails.FormalName,
      EnvelopeName: customersavedetails.EnvelopeName,
      RecognitionName: customersavedetails.RecognitionName,
      Gender: customersavedetails.Gender,
      Website: customersavedetails.Website,
      FacebookId: customersavedetails.FacebookId,
      TwitterId: customersavedetails.TwitterId,
      LinkedInId: customersavedetails.LinkedInId,
      Employer: customersavedetails.Employer,
      JobTitle: customersavedetails.JobTitle,
      DOB: customersavedetails.Birthday,
      FullName: customersavedetails.PrimaryContact,
      IsArchived: false
    };
    this.profileService.update_CRMCustomerDetailsByCRMCustomerId(customerdetailsupdate).subscribe((results) => {
      if (results != null && results != "" && results != undefined) {
        this.closeModal();
        this.get_profiledetails();
      }
      else {
        this.toastralert.toastrerror('Error occurred while saving Customer details');
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to save details.Please try after some time.");
      }
    );
  }

  Get_ComponentOptionsSelectedValues() {
    this.profileService.Get_ComponentOptionsSelectedValues(this.formName, this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.selectedFormSections = results;
        console.log(this.selectedFormSections);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try after some time.");
      }
    );
  }

  redirecttoeditprofile() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/editprofile/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/editprofile/' + this.cRMCustomerid]);
    }
  }


}

