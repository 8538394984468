import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent, Smart, GridColumn } from 'smart-webcomponents-angular/grid';
import { DataAdapter } from 'smart-webcomponents-angular/grid';
import { ProfileService } from 'src/app/services/profile.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { ToastralertService } from 'src/app/services/toastralert.service';
import Swal from 'sweetalert2';
import { DateTimePickerComponent } from 'smart-webcomponents-angular/datetimepicker';
import { AvailabilityService } from 'src/app/services/availability.service';
import { Availability } from '../availability';
type Tabs = 'Profile' | 'Timeline' | 'Availability' | 'RelationShips';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],

})
export class AvailabilityComponent implements OnInit {
  @ViewChild('edittimeslot') edittimeslot: ElementRef;
  @ViewChild('availabilitygrid', { read: GridComponent, static: false }) grid!: GridComponent;
  @ViewChild('form', { static: true }) form: NgForm;
  @ViewChild('startdate', { read: DateTimePickerComponent }) startdate!: DateTimePickerComponent;
  @ViewChild('enddate', { read: DateTimePickerComponent, static: false }) enddate!: DateTimePickerComponent;
  @ViewChild('datetimepicker', { read: DateTimePickerComponent, static: false }) datetimepicker!: DateTimePickerComponent;
  // @ViewChild('datetimepicker', { read: DateTimePickerComponent, static: false }) datetimepicker!: DateTimePickerComponent;
  faUserCircle = faUserCircle;
  faTimeline = faTimeline;
  faTimesCircle = faClock;
  faEdit = faEdit;
  closeResult: any;
  activeTab: Tabs = 'Profile';
  model: any;
  configLoading: boolean = false;
  resetLoading: boolean = false;
  cRMCustomerid: any;
  profilelist: any = { PrimaryContact: '' };
  phonenumberlist: any;
  Addresslist: any;
  availabilitydetails: Availability[] | any = [];
  saveavailabilitydetails: any;
  token_response: any;
  token: any;
  updateavailable: any = { AvailableDay: '', AvailableDayId: '', AvailableStartTime: '', AvailableEndTime: '', CRMCustomerAvailableTimeSlotId: '' };
  Selectedweek: any = '8';
  name: any;
  updatetimesolt: any;
  Emaillist: any;
  userRole: any = "";
  weekdays = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' },
    { id: 7, name: 'Sunday' },
  ];


  constructor(
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public router: Router,
    private profileService: ProfileService,
    private availabilityService: AvailabilityService,
    public config: NgbModalConfig,
    public toastralert: ToastralertService
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.userRole = this.token_response.UserRole;
    this.route.params.subscribe(params => {
      this.cRMCustomerid = params['cRMCustomerId'];
    });
    this.get_profiledetails();
    this.get_CRMCustomerAvailableTimeSlots();

    this.setActiveTab('Availability');
    this.redirections();
  }

  appearance = {
    alternationStart: 0,
    alternationCount: 2,
    allowSortAnimation: true
  }

  layout: object = {
    rowHeight: 35
  }

  pager = {
    visible: true
  }

  paging = {
    enabled: true,
    pageSize: 15
  }

  onSelectName(week: any) {
    this.updateavailable.AvailableDay = week.name;
    this.updateavailable.AvailableDayId = week.id;
  }

  onSelectweek(week: any) {
    console.log(week);
    this.updateavailable.AvailableDay = week;
    this.updateavailable.AvailableDayId = this.weekdays.filter((v) => {
      return v.name === this.updateavailable.AvailableDay; // Filter out the appropriate one
    })[0].id;

    console.log(this.updateavailable.AvailableDay);
  }

  get_profiledetails() {
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "") {
        this.profilelist = results;
        this.phonenumberlist = this.profilelist.CRMPhoneNumbersModelList;
        this.Addresslist = this.profilelist.CRMAddressModelList;
        this.Emaillist = this.profilelist.CRMEmailsModelList;
      }
    },
      (err) => {
        console.log(err);
        this.toastralert.toastrerror('Unable to get profile details.Please try after some time.');
      }
    );
  }

  get_CRMCustomerAvailableTimeSlots() {
    this.availabilityService.Get_CRMCustomerAvailableTimeSlots(this.cRMCustomerid)
      .subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.availabilitydetails = results;
          this.availabilitygrid.dataSource = results;
          this.availabilitygridsource = results;
          console.log(results);
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to get section details.Please try after some time.");
        });
  }

  setActiveTab(tab: any) {
    this.activeTab = tab;
  }

  redirecttoprofile() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/profile/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/' + this.cRMCustomerid]);
    }
  }

  redirecttotimeline() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/timeline/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/timeline/' + this.cRMCustomerid]);
    }
  }

  redirecttorelationships() {
    if(this.token_response.UserRole != "Volunteer"){
      this.router.navigate(['profiles/relationships/' + this.cRMCustomerid]);
    }
    else{
      this.router.navigate(['myprofile/relationships/' + this.cRMCustomerid]);
    }
  }

  resetPreview(): void {
    this.resetLoading = true;
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }

  timepickers(): void {
    this.startdate.formatString = 't';
    this.enddate.formatString = 't';
  }

  availabilitygrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: [
        'AvailableDay: string',
        'AvailableStartTime: date',
        'AvailableEndTime: date',
      ]
    })

  availabilitygridsource: any = this.availabilitygrid;
  columns =
    [
      { label: 'Day', dataField: 'AvailableDay', align: 'center' },
      { label: 'Start Time', dataField: 'AvailableStartTime', align: 'center', cellsFormat: 'hh:mm tt' },
      { label: 'End Time', dataField: 'AvailableEndTime', align: 'center', cellsFormat: 'hh:mm tt' },
      {
        label: 'Action', dataField: 'action', align: 'center', template: '<div  style="margin-left:10px;" class=" fa fa-pencil cursor-pointer redirect-row-button" data-toggle="tooltip" title="Edit Timeslot"></div>'
      },

    ]

  update_CRMCustomerAvailableTimeSlots(updateavailable: any) {
    var regex = new RegExp(':', 'g');
    if ((typeof this.updateavailable.AvailableDay == 'undefined' || this.updateavailable.AvailableDay == "" || this.updateavailable.AvailableDay == null)) {
      this.toastralert.toastrwarnningbottomright("Please select a day");
      return false;
    }
    else if ((typeof updateavailable.AvailableStartTime == 'undefined' || updateavailable.AvailableStartTime == "" || updateavailable.AvailableStartTime == null)) {
      this.toastralert.toastrwarnningbottomright("Please select a start time slot");
      return false;
    }
    else if ((typeof updateavailable.AvailableEndTime == 'undefined' || updateavailable.AvailableEndTime == "" || updateavailable.AvailableEndTime == null)) {
      this.toastralert.toastrwarnningbottomright("Please select a end time slot");
      return false;
    }
    else if (updateavailable.AvailableStartTime >= updateavailable.AvailableEndTime) {
      this.toastralert.toastrwarnningbottomright("Please select a end time slot");
      return false;
    }
    else {
      let updatetimesolt = {};
      if (updateavailable.CRMCustomerAvailableTimeSlotId == "" || updateavailable.CRMCustomerAvailableTimeSlotId == null || updateavailable.CRMCustomerAvailableTimeSlotId == 'undefined') {

        updatetimesolt = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: this.cRMCustomerid,
          AvailableDay: this.updateavailable.AvailableDay,
          AvailableDayId: this.updateavailable.AvailableDayId,
          AvailableStartTime: updateavailable.AvailableStartTime,
          AvailableEndTime: updateavailable.AvailableEndTime
        };
      }
      else {
        updatetimesolt = {
          CRMPersonId: this.token_response.CRMPersonId,
          TenantId: this.token_response.tenantId,
          CRMCustomerId: this.cRMCustomerid,
          AvailableDay: this.updateavailable.AvailableDay,
          AvailableDayId: this.updateavailable.AvailableDayId,
          CRMCustomerAvailableTimeSlotId: updateavailable.CRMCustomerAvailableTimeSlotId,
          AvailableStartTime: updateavailable.AvailableStartTime,
          AvailableEndTime: updateavailable.AvailableEndTime
        };
      }
      console.log(updatetimesolt);
      this.availabilityService.update_CRMCustomerAvailableTimeSlots(updatetimesolt)
        .subscribe(results => {
          if (results != null && results != 'undefined') {
            if (results == '99999999-9999-9999-9999-999999999999') {
              this.toastralert.toastrerrorbottom("This time slot already exits please check once");
            }
            else {
              this.saveavailabilitydetails = results;
              this.clearmodal();
              if (updateavailable.CRMCustomerAvailableTimeSlotId == "" || updateavailable.CRMCustomerAvailableTimeSlotId == null || updateavailable.CRMCustomerAvailableTimeSlotId == 'undefined') {
                this.toastralert.toastersuccesstop("Timeslot has been saved successfully");
                this.clearmodal();
              }
              else {
                this.toastralert.toastersuccesstop("Timeslot has been updated successfully");
                this.clearmodal();
              }
              this.clearmodal();
              console.log(this.updateavailable);
              this.get_CRMCustomerAvailableTimeSlots();
            }
          }
          else {
            this.toastralert.toastrerror('Error occured while saving timeslot details');
            this.clearmodal();
          }
        },
          (err) => {
            this.toastralert.toastrerrorbottom("Unable to save timeslot details.Please try after some time");
            this.clearmodal();
          });
    }
  }

  clearmodal() {
    this.modalService.dismissAll();
    this.Selectedweek = '8';
    this.updateavailable =
    {
      AvailableDay: '',
      AvailableDayId: '',
      AvailableStartTime: '',
      AvailableEndTime: '',
      CRMCustomerAvailableTimeSlotId: ''
    };
    this.updatetimesolt = '';
  }

  ngAfterViewInit(): void {
    this.redirections();
  }

  getcrmcustomeridforredirect(member: Availability): void {
    this.updatetimesolt = member;
    console.log(this.updatetimesolt);
    // this.redirectprofiles(member.CRMCustomerId);
    this.updateavailable.CRMCustomerAvailableTimeSlotId = this.updatetimesolt.CRMCustomerAvailableTimeSlotId;
    this.updateavailable.AvailableDay = this.updatetimesolt.AvailableDay;
    this.updateavailable.AvailableDayId = this.updatetimesolt.AvailableDayId;
  }

  redirectprofiles(cRMCustomerId: any,) {
    this.router.navigate(['profiles/profile/' + cRMCustomerId]);
  }

  redirections(): void {
    const that = this,
      grid = this.grid;
    grid.addEventListener('rowClick', (event: Event) => {
      if (!isCustomEvent(event))
        throw new Error('not a custom event');
      const button = event.detail.originalEvent.target.closest('div') as HTMLElement;
      if (!button) {
        return;
      }
      const memberIndex = event.detail.row.index;
      if (button.classList.contains('redirect-row-button')) {
        this.getcrmcustomeridforredirect(this.availabilitydetails[memberIndex]);
        this.open(this.edittimeslot);
      }
    });
    function isCustomEvent(event: Event): event is CustomEvent {
      return 'detail' in event;
    }
  }

  open(content: any) {
    this.modalService.open(content, { centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
