<div class="card card-custom" [ngClass]="cssClass">
  <!-- Header -->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark">Agents Stats</span>
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >More than 400+ new members</span
      >
    </h3>
    <div class="card-toolbar">
      <a class="btn btn-success font-weight-bolder font-size-sm cursor-pointer">
        <span
          [inlineSVG]="'./assets/media/svg/icons/Communication/Add-user.svg'"
          class="svg-icon svg-icon-md svg-icon-white"
        ></span
        >Add New Member
      </a>
    </div>
  </div>
  <!--begin::Body-->
  <div class="card-body py-0">
    <!--begin::Table-->
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center"
        id="kt_advance_table_widget_1"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-0" style="width: 20px">
              <label class="checkbox checkbox-lg checkbox-inline">
                <input type="checkbox" value="1" />
                <span></span>
              </label>
            </th>
            <th class="pr-0" style="width: 50px">authors</th>
            <th style="min-width: 200px"></th>
            <th style="min-width: 150px">company</th>
            <th style="min-width: 150px">progress</th>
            <th class="pr-0 text-right" style="min-width: 150px">action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="pl-0">
              <label class="checkbox checkbox-lg checkbox-inline">
                <input type="checkbox" value="1" />
                <span></span>
              </label>
            </td>
            <td class="pr-0">
              <div class="symbol symbol-50 symbol-light mt-1">
                <span class="symbol-label">
                  <span
                    [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                    class="svg-icon h-75 align-self-end"
                  ></span>
                </span>
              </div>
            </td>
            <td class="pl-0">
              <a
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                  cursor-pointer
                "
                >Brad Simmons</a
              >
              <span class="text-muted font-weight-bold text-muted d-block"
                >HTML, JS, ReactJS</span
              >
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                Intertico
              </span>
              <span class="text-muted font-weight-bold">
                Web, UI/UX Design
              </span>
            </td>
            <td>
              <div class="d-flex flex-column w-100 mr-2">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="text-muted mr-2 font-size-sm font-weight-bold">
                    65%
                  </span>
                  <span class="text-muted font-size-sm font-weight-bold">
                    Progress
                  </span>
                </div>
                <div class="progress progress-xs w-100">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 65%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
            <td class="pr-0 text-right">
              <a
                class="
                  btn btn-icon btn-light btn-hover-primary btn-sm
                  cursor-pointer
                "
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/General/Settings-1.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
                class="
                  btn btn-icon btn-light btn-hover-primary btn-sm
                  mx-3
                  cursor-pointer
                "
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Communication/Write.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
                class="
                  btn btn-icon btn-light btn-hover-primary btn-sm
                  cursor-pointer
                "
              >
                <span
                  [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
            </td>
          </tr>
          <tr>
            <td class="pl-0">
              <label class="checkbox checkbox-lg checkbox-inline">
                <input type="checkbox" value="1" />
                <span></span>
              </label>
            </td>
            <td class="pr-0">
              <div class="symbol symbol-50 symbol-light mt-1">
                <span class="symbol-label">
                  <span
                    [inlineSVG]="'./assets/media/svg/avatars/018-girl-9.svg'"
                    class="svg-icon h-75 align-self-end"
                  ></span>
                </span>
              </div>
            </td>
            <td class="pl-0">
              <a
               
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >Jessie Clarcson</a
              >
              <span class="text-muted font-weight-bold text-muted d-block"
                >C#, ASP.NET, MS SQL</span
              >
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                Agoda
              </span>
              <span class="text-muted font-weight-bold"> Houses & Hotels </span>
            </td>
            <td>
              <div class="d-flex flex-column w-100 mr-2">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="text-muted mr-2 font-size-sm font-weight-bold">
                    83%
                  </span>
                  <span class="text-muted font-size-sm font-weight-bold">
                    Progress
                  </span>
                </div>
                <div class="progress progress-xs w-100">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style="width: 83%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
            <td class="pr-0 text-right">
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/General/Settings-1.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Communication/Write.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
            </td>
          </tr>
          <tr>
            <td class="pl-0">
              <label class="checkbox checkbox-lg checkbox-inline">
                <input type="checkbox" value="1" />
                <span></span>
              </label>
            </td>
            <td class="pr-0">
              <div class="symbol symbol-50 symbol-lightv mt-1">
                <span class="symbol-label">
                  <span
                    [inlineSVG]="'./assets/media/svg/avatars/047-girl-25.svg'"
                    class="svg-icon h-75 align-self-end"
                  ></span>
                </span>
              </div>
            </td>
            <td class="pl-0">
              <a
               
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >Lebron Wayde</a
              >
              <span class="text-muted font-weight-bold text-muted d-block"
                >PHP, Laravel, VueJS</span
              >
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                RoadGee
              </span>
              <span class="text-muted font-weight-bold"> Transportation </span>
            </td>
            <td>
              <div class="d-flex flex-column w-100 mr-2">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="text-muted mr-2 font-size-sm font-weight-bold">
                    47%
                  </span>
                  <span class="text-muted font-size-sm font-weight-bold">
                    Progress
                  </span>
                </div>
                <div class="progress progress-xs w-100">
                  <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    style="width: 83%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
            <td class="pr-0 text-right">
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/General/Settings-1.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Communication/Write.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
            </td>
          </tr>
          <tr>
            <td class="pl-0">
              <label class="checkbox checkbox-lg checkbox-inline">
                <input type="checkbox" value="1" />
                <span></span>
              </label>
            </td>
            <td class="pr-0">
              <div class="symbol symbol-50 symbol-light mt-1">
                <span class="symbol-label">
                  <span
                    [inlineSVG]="'./assets/media/svg/avatars/014-girl-7.svg'"
                    class="svg-icon h-75 align-self-end"
                  ></span>
                </span>
              </div>
            </td>
            <td class="pl-0">
              <a
               
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >Natali Trump</a
              >
              <span class="text-muted font-weight-bold text-muted d-block"
                >Python, PostgreSQL, ReactJS</span
              >
            </td>
            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                The Hill
              </span>
              <span class="text-muted font-weight-bold"> Insurance </span>
            </td>
            <td>
              <div class="d-flex flex-column w-100 mr-2">
                <div
                  class="d-flex align-items-center justify-content-between mb-2"
                >
                  <span class="text-muted mr-2 font-size-sm font-weight-bold">
                    71%
                  </span>
                  <span class="text-muted font-size-sm font-weight-bold">
                    Progress
                  </span>
                </div>
                <div class="progress progress-xs w-100">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style="width: 71%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </td>
            <td class="pr-0 text-right">
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/General/Settings-1.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              >
                <span
                  [inlineSVG]="
                    './assets/media/svg/icons/Communication/Write.svg'
                  "
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
              <a
               
                class="btn btn-icon btn-light btn-hover-primary btn-sm"
              >
                <span
                  [inlineSVG]="'./assets/media/svg/icons/General/Trash.svg'"
                  class="svg-icon svg-icon-md svg-icon-primary"
                ></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--end::Table-->
  </div>
  <!--end::Body-->
</div>
