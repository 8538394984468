<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush toolbar-bar">
            <!--begin::Card header-->
            <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1 fs-5 text-white">Facility</h3>
                </div>
                <!--begin::Card title-->
                <!--begin::Card toolbar-->
                <div class="card-toolbar">
                    <!--begin::Actions-->
                    <div class="d-flex align-items-center gap-2 gap-lg-3">
                        <a class="btn btn-sm me-2 p-2  bg-white text-primary">
                            Return to All Facilities
                        </a>
                    </div>

                    <!--end::Actions-->
                </div>
            </div>
            <!--begin::Card toolbar-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body bg-white pt-0 px-5">
            <div class="row">
                <div class="col-lg-1 col-md-6 col-sm-6">
                    <div class="py-6 text-dark fs-6 text-end">
                        Name:
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 py-5">
                    <input type="text" placeholder="Enter Name" name="name" autocomplete="off"
                        class="form-control bg-transparent p-2 fs-8 " />
                </div>

                <div class="col-lg-1 col-md-6 col-sm-6">
                    <div class="py-6 text-dark fs-6 text-end">Address:</div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 py-5">
                    <input type="text" placeholder="Enter Address" name="address" autocomplete="off"
                        class="form-control bg-transparent p-2 fs-8 " />
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Sunday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Monday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Tuesday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Wednesday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Thursday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px ">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Friday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <div class="row my-1 ps-12">

                <div class="col-lg-1 col-md-1 col-sm-1">
                    <div class="text-primary fs-6  fw-semibold">
                        Saturday
                    </div>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1"></div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0 " type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" name="toc" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                    </label>
                </div>
            </div>
            <!--end::Card body-->
        </div>
    </div>