import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  token_response: any = {};
  token: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }


  Get_ApplicationLogErrorsByDateRange(starttime: any, endtime: any) {
    console.log(starttime, endtime);
    return this.http.get(environment.GetApplicationLogErrorsByDateRange + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + starttime + '/' + endtime
    ).pipe(map((res: any) => {
      console.log('hi');
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_DBLogErrorsByDateRange(starttime: any, endtime: any) {
    console.log(starttime, endtime);
    return this.http.get(environment.GetApplicationDBErrorsByDateRange + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + starttime + '/' + endtime
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_FetchDatabase() {
    return this.http.get(environment.GetFetchDatabase
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_ConnectDatabase(databaseName: any, list: any) {
    return this.http.get(environment.GetConnectDatabase + '/' + databaseName + '/' + list
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_Columns(databaseName: any, tableName: any) {
    return this.http.get(environment.GetColumns + '/' + databaseName + '/' + tableName
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GenerateQuery(queryParams: any) {
    return this.http.post(environment.GenerateQuery, queryParams, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  PageLoad() {
    return this.http.get(environment.Page_Load
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  RunSqlQuery(databaseName:any,querytext:any) {
    return this.http.get(environment.RunQuery+'/'+databaseName+'/'+querytext
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetReportsListByTenantId(){
    return this.http.get(environment.ReportsList+'/'+this.token_response.CRMPersonId +'/'+this.token_response.tenantId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdateReportQuery(reportData: any) {
    return this.http.post(environment.SaveOrUpdateReportQuery, reportData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetReportQueryByReportQueryId(reportQueryId:any){
    return this.http.get(environment.GetReportQueryByReportQueryId+'/'+this.token_response.CRMPersonId +'/'+this.token_response.tenantId +'/'+reportQueryId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  update_ReportQuery(reportQueryData:any) {
    return this.http.post(environment.SaveOrUpdateReportQuery,reportQueryData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}