<div class="row px-4 py-0">
    <div class="card mb-xxl-8">
        <div class="card-body py-3">
            <!--begin::Details-->
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <!--begin: Pic-->
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <!--end::Pic-->
                <!--begin::Info-->
                <div class="flex-grow-1">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg-->
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark cursor-pointer">1 Spot
                                        Info</span>
                                    <!--end::Svg Icon-->
                                </a></div>
                            <div class="text-primary cursor-pointer">#1512</div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div class="text-primary cursor-pointer">PO Box 303</div>
                            <div class="text-primary cursor-pointer">Greenwood Village, CO 80111
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-primary cursor-pointer">Work (720) 272-3209</div>
                        </div>
                    </div>
                </div>
                <!--begin::Actions-->
                <div class="d-flex my-4">
                    <!--begin::Menu-->
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <!-- <i class="fa fa-ellipsis-h fs-4"></i> -->
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <!--begin::Menu 3-->
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <!--begin::Heading-->
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <!--end::Heading-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <!--end::Menu item-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <!--end::Menu item-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                            <!--end::Menu item-->
                        </div>
                        <!--begin::Menu 3-->
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <!--begin::Heading-->
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Payments</div>
                            </div>
                            <!--end::Heading-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Generate
                                    Bill</a>
                            </div>
                            <!--end::Menu item-->
                            <!--begin::Menu item-->
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Settings</a>
                            </div>
                            <!--end::Menu item-->
                        </div>
                    </div>
                    <!--end::Menu-->
                </div>
                <!--end::Actions-->
                <!--end::Info-->
            </div>
            <!--end::Details-->
        </div>
    </div>

    <div class="card-custom">
        <!-- begin::Header -->
        <div class="card-header card-header-stretch overflow-auto ">
            <ul class="
          nav nav-stretch nav-line-tabs
          fw-bold
          border-transparent
          flex-nowrap
        " role="tablist">

                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="setActiveTab('Profile')" role="tab">
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Timeline' }"
                        (click)="setActiveTab('Timeline')" role="tab">
                        Timeline
                    </a>
                </li>

            </ul>
            <div *ngIf="activeTab=='Profile'">
            <button
           *ngIf="showMainContent" class="btn background-secondary fw-bold text-white px-2 py-1 m-0 fs-7 rounded-0 cursor-pointer float-end topedit"
            data-bs-target="#" data-bs-toggle="modal" (click)="showEditProfile()"><i
                class="fa fa-pencil fs-7 text-white"></i>Edit</button>
            </div>
               
                  
        </div>
        <!-- end::Header -->
        <!-- begin::Form -->
        <form class="form">
            <!-- begin::Body -->
            <div >
                <div class="tab-content pt-3">
                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Profile' }">
                        <div *ngIf="div1">
                            <div class="row mt-1">
                                <div class="col-lg-10 col-md-8 col-sm-8"></div>
                                <div class="col-lg-2 col-md-4 col-sm-4">                                   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Basic Info</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                            <ul class="list-unstyled">
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Status</div>
                                                    <div class="fw-7 text-primary fs-6">Inactive</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Primary Contact</div>
                                                    <div class="fw-7 text-primary fs-6">Pam Rubins</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Informal Name</div>
                                                    <div class="fw-7 text-primary fs-6">Pam</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Formal Name</div>
                                                    <div class="fw-7 text-primary fs-6">Ms Pam Rubins</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Envelope Name</div>
                                                    <div class="fw-7 text-primary fs-6">1 Spot Info</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Recognition Name</div>
                                                    <div class="fw-7 text-primary fs-6">1 Spot Info</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Personal Information</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                            <ul class="list-unstyled">
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Facebook</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Twitter</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">LinkedIn</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Website</div>
                                                    <div class="fw-7 text-primary fs-6">www.1spotinfo.com
                                                    </div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Gender</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Birthday</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Employer</div>
                                                    <div class="fw-7 text-primary fs-6">1 Spot Info</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Job</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Addresses</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                <a  class="btn btn-light p-1 fs-7 rounded-0" (click)="open(addField)"><i  class="fa fa-plus p-1"></i></a>
                                               
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                            <div class="d-flex flex-wrap flex-sm-nowrap">
                                                <div class="flex-grow-1">
                                                    <!--begin::Title-->
                                                    <div
                                                        class="d-flex justify-content-between align-items-start flex-wrap">
                                                        <!--begin::User-->
                                                        <div class="d-flex flex-column">
                                                            <!--begin::Name-->
                                                            <div class="d-flex align-items-center">
                                                                <a class="text-dark fs-6">Work</a>
                                                                <a class="ms-2">
                                                                    <span class="badge badge-success">Primary</span>
                                                                </a>
                                                            </div>
                                                            <!--end::Name-->
                                                            <!--begin::Info-->
                                                            <div class="d-flex flex-wrap fw-semibold pe-2">
                                                                <a
                                                                    class="d-flex align-items-center text-primary me-5 fs-6">
                                                                    PO Box 303
                                                                </a>
                                                            </div>
                                                            <!--end::Info-->
                                                            <!--begin::Info-->
                                                            <div class="d-flex flex-wrap fw-semibold pe-2">
                                                                <a
                                                                    class="d-flex align-items-center text-gray-400 text-hover-primary me-5 fs-6">
                                                                    Greenwood Village, CO 8011
                                                                </a>
                                                            </div>
                                                            <!--end::Info-->
                                                        </div>
                                                        <!--end::User-->
                                                        <!--begin::Actions-->
                                                        <div class="d-flex my-4">
                                                            <!--begin::Menu-->
                                                            <div class="me-0">
                                                                <a
                                                                    class="btn background-secondary fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                                    data-bs-target="#" data-bs-toggle="modal"><i
                                                                        class="fa fa-pencil fs-7 text-white"></i>Edit</a>
                                                                <!--begin::Menu 3-->
                                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                                    data-kt-menu="true">
                                                                    <!--begin::Heading-->
                                                                    <div class="menu-item px-3">
                                                                        <div
                                                                            class="menu-content text-muted pb-2 px-3 fs-7">
                                                                            Action</div>
                                                                    </div>
                                                                    <!--end::Heading-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a class="menu-link px-3">Activate</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a
                                                                            class="menu-link px-3">Inactivate</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3 my-1">
                                                                        <a class="menu-link px-3">Delete</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                </div>
                                                                <!--begin::Menu 3-->
                                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                                    data-kt-menu="true">
                                                                    <!--begin::Heading-->
                                                                    <div class="menu-item px-3">
                                                                        <div
                                                                            class="menu-content text-muted pb-2 px-3 fs-7">
                                                                            Payments</div>
                                                                    </div>
                                                                    <!--end::Heading-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a class="menu-link px-3">Generate
                                                                            Bill</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3 my-1">
                                                                        <a class="menu-link px-3">Settings</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                </div>
                                                            </div>
                                                            <!--end::Menu-->
                                                        </div>
                                                        <!--end::Actions-->
                                                    </div>
                                                    <!--end::Title-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Emails</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                <a class="btn btn-light p-1 fs-7 rounded-0"
                                                    data-bs-target="#editField" data-bs-toggle="modal"><i
                                                        class="fa fa-plus p-1"></i></a>
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                        </div>
                                    </div>
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Phone Numbers</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                                <a class="btn btn-light p-1 fs-7 rounded-0" data-bs-target="#"
                                                    data-bs-toggle="modal"><i class="fa fa-plus p-1"></i></a>
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                            <div class="d-flex flex-wrap flex-sm-nowrap">
                                                <div class="flex-grow-1">
                                                    <!--begin::Title-->
                                                    <div
                                                        class="d-flex justify-content-between align-items-start flex-wrap">
                                                        <!--begin::User-->
                                                        <div class="d-flex flex-column">
                                                            <!--begin::Name-->
                                                            <div class="d-flex align-items-center">
                                                                <a class="text-dark fs-6">Work</a>
                                                                <a class="ms-2">
                                                                    <span class="badge badge-success">Primary</span>
                                                                </a>
                                                            </div>
                                                            <!--end::Name-->
                                                            <!--begin::Info-->
                                                            <div class="d-flex flex-wrap fw-semibold pe-2">
                                                                <a
                                                                    class="d-flex align-items-center text-primary me-5 fs-6">
                                                                    (720) 272-3209
                                                                </a>
                                                            </div>
                                                            <!--end::Info-->
                                                        </div>
                                                        <!--end::User-->
                                                        <!--begin::Actions-->
                                                        <div class="d-flex my-4">
                                                            <!--begin::Menu-->
                                                            <div class="me-0">
                                                                <a
                                                                class="btn background-secondary fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                                data-bs-target="#" data-bs-toggle="modal"><i
                                                                    class="fa fa-pencil fs-7 text-white"></i>Edit</a>
                                                                <!--begin::Menu 3-->
                                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                                    data-kt-menu="true">
                                                                    <!--begin::Heading-->
                                                                    <div class="menu-item px-3">
                                                                        <div
                                                                            class="menu-content text-muted pb-2 px-3 fs-7">
                                                                            Action</div>
                                                                    </div>
                                                                    <!--end::Heading-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a class="menu-link px-3">Activate</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a
                                                                            class="menu-link px-3">Inactivate</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3 my-1">
                                                                        <a class="menu-link px-3">Delete</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                </div>
                                                                <!--begin::Menu 3-->
                                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                                                    data-kt-menu="true">
                                                                    <!--begin::Heading-->
                                                                    <div class="menu-item px-3">
                                                                        <div
                                                                            class="menu-content text-muted pb-2 px-3 fs-7">
                                                                            Payments</div>
                                                                    </div>
                                                                    <!--end::Heading-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3">
                                                                        <a class="menu-link px-3">Generate
                                                                            Bill</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                    <!--begin::Menu item-->
                                                                    <div class="menu-item px-3 my-1">
                                                                        <a class="menu-link px-3">Settings</a>
                                                                    </div>
                                                                    <!--end::Menu item-->
                                                                </div>
                                                            </div>
                                                            <!--end::Menu-->
                                                        </div>
                                                        <!--end::Actions-->
                                                    </div>
                                                    <!--end::Title-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card my-2">
                                        <div class="card-header px-2 min-h-50px">
                                            <div class="card-title">
                                                <h3 class="fs-4 fw-bold ms-3">Communication Preferences</h3>
                                            </div>
                                            <div class="d-flex align-items-center gap-2 gap-lg-3">
                                            </div>
                                        </div>
                                        <div class="card-body px-5 py-2">
                                            <ul class="list-unstyled">
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Preferred Channel</div>
                                                    <div class="fw-7 text-primary fs-6"></div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Restrictions</div>
                                                    <div class="fw-7 text-primary fs-6">The Constituent does
                                                        not have any restrictions</div>
                                                </li>
                                                <li class="border-bottom py-2">
                                                    <div class="fs-5 fw-semibold">Email Interests</div>
                                                    <div class="fw-7 text-primary fs-6">The constituent will
                                                        receive all emails</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="div2">
                            <div class="card">
                                <div class="row py-2 mt-2">
                                    <div class="col-lg-8 col-md-8 col-sm-8">
                                        <h3 class="fs-4 fw-bold ms-3">Edit Profile</h3>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="btn-group float-end me-3" role="group" aria-label="Basic example">
                                            <a
                                                class="btn background-info fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                data-bs-target="#" data-bs-toggle="modal"
                                                onclick="hideEditProfile()">Cancel</a>
                                            <button
                                                class="btn btn-primary fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                data-bs-target="#" data-bs-toggle="modal" (click)="hideEditProfile()"><i
                                                    class="fa fa-save fs-7 text-white"></i>Save</button>
                                        </div>
                                        <div class="btn-group float-end mx-10" role="group" aria-label="Basic example">
                                            <!-- <a class="btn btn-light p-2 fs-7 rounded-0"
                                                data-bs-target="#" data-bs-toggle="modal">Merge</a> -->
                                            <a
                                                class="btn background-danger fw-bold text-white px-2 py-1 fs-7 rounded-0 cursor-pointer"
                                                data-bs-target="#" data-bs-toggle="modal"><i
                                                    class="fa fa-archive fs-7 text-white"></i>Archive</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary fs-6 mb-2">Organization
                                                Name</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="1 Spot Info" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary fs-6 mb-2">Status</label>
                                            <select data-control="select2" data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm bg-transparent p-2 border-secondary fs-7 fw-normal"
                                                id="statusDropdown">
                                                <option value="1" selected="selected" class="fs-7">Inactive
                                                </option>
                                                <option value="2">Active</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <h3 class="fs-5 fw-bold ms-3 mb-0">Primary Contact</h3>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary fs-6 mb-2">Job Title</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="" />
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary fs-6 mb-2">Title</label>
                                            <select data-control="select2" data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm bg-transparent border-secondary fs-7 fw-normal">
                                                <option value="1" selected="selected">Ms.</option>
                                                <option value="2">Miss</option>
                                            </select>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="m-3 mt-0">
                                                    <label class="text-primary mb-2 fs-6">First</label>
                                                    <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                        placeholder="Pam" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="m-3 mt-0">
                                                    <label class="text-primary mb-2 fs-6">Middle</label>
                                                    <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                        placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Last</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="Rubins" />
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Suffix</label>
                                            <select data-control="select2" data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm bg-transparent border-secondary fs-7 fw-normal">
                                                <option value="1" selected="selected">DDS</option>
                                                <option value="2">Esq.</option>
                                                <option value="3">Ph.D</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Informal
                                                Name</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="Pam" />
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Formal
                                                Name</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="Ms Pam Rubins" />
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Envelope
                                                Name</label>
                                            <textarea class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="1 Spot Info"></textarea>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Recognition
                                                Name</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="1 Spot Info" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-3">
                                <h3 class="fs-5 fw-bold ms-3 py-3">Personal Information</h3>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Gender</label>
                                            <select data-control="select2" data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm bg-transparent border-secondary fs-7 fw-normal">
                                                <option value="1" selected="selected" disabled>Select
                                                </option>
                                                <option value="2">Male</option>
                                                <option value="3">Female</option>
                                                <option value="4">Others</option>
                                            </select>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Birthdate</label>
                                            <input type="date"
                                                class="form-control p-2 border-secondary fs-7 fw-normal" />
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Employer</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="1 Spot Info" />
                                        </div>

                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Facebook Page</label>
                                            <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                            <div class="input-group mb-3 border-0">
                                                <input type="text" class="form-control p-2 border-end-0 fs-7 fw-normal"
                                                    placeholder="">
                                                <div class="input-group-append">
                                                    <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                                        id="basic-addon2"><i
                                                            class="fa fa-facebook text-white"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Twitter Handle</label>
                                            <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                            <div class="input-group mb-3 border-0">
                                                <input type="text" class="form-control p-2 border-end-0 fs-7 fw-normal"
                                                    placeholder="">
                                                <div class="input-group-append">
                                                    <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                                        id="basic-addon2"><i
                                                            class="fa fa-twitter text-white"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">LinkedIn Page</label>
                                            <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                            <div class="input-group mb-3 border-0">
                                                <input type="text" class="form-control p-2 border-end-0 fs-7 fw-normal"
                                                    placeholder="">
                                                <div class="input-group-append">
                                                    <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                                        id="basic-addon2"><i
                                                            class="fa fa-linkedin text-white"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="m-3 mt-0">
                                            <label class="text-primary mb-2 fs-6">Website</label>
                                            <input class="form-control p-2 border-secondary fs-7 fw-normal"
                                                placeholder="www.1spotinfo.com" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-3 pb-2">
                                <h3 class="fs-5 fw-bold ms-3 py-3 ">Communication Preferences</h3>
                                <div class="m-3 mt-0">
                                    <label class="text-primary mb-2 fs-6">Preferred Channel</label>
                                    <select data-control="select2" data-hide-search="true"
                                        class="form-select form-select-solid form-select-sm bg-transparent border-secondary w-600px fs-7 fw-normal">
                                        <option value="1" selected="selected" disabled>Select</option>
                                        <option value="2"></option>
                                        <option value="3"></option>
                                    </select>
                                </div>
                                <div class="text-primary fs-6 ms-3 my-2">Communication Restrictions</div>
                                <div class="fs-6 ms-3 my-2">The constituent does not have any restrictions
                                </div>
                                <a class="btn btn-light p-2 fs-7 rounded-0 w-70px ms-3 my-2" data-bs-target="#"
                                    data-bs-toggle="modal">Modify</a>
                                <div class="text-primary fs-6 ms-3 my-2">Email Interests</div>
                                <div class="fs-6 ms-3 my-2">The constituent will receive all emails</div>
                                <a class="btn btn-light p-2 fs-7 rounded-0 w-70px ms-3 my-2" data-bs-target="#"
                                    data-bs-toggle="modal">Modify</a>
                            </div>
                            <div class="card mt-3 pb-2">
                                <h3 class="fs-5 fw-bold ms-3 py-3 ">General</h3>
                                <div class="m-3 mt-0">
                                    <label class="text-primary mb-2 fs-6">Constituent Comments</label>
                                    <input type="text"
                                        class="form-control p-2 border-secondary w-600px fs-7 fw-normal" />
                                </div>
                            </div>
                            <div class="card mt-3 pb-2">
                                <h3 class="fs-5 fw-bold ms-3 py-3 ">LifeSpark Participant</h3>
                                <div class="m-3 mt-0">
                                    <label class="text-primary mb-2 fs-6">Part: Status</label>
                                    <input type="text"
                                        class="form-control p-2 border-secondary w-600px fs-7 fw-normal" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Sidebar -->

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Timeline' }">

                        <div class="card p-5">
                            <div class="row mt-5">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <!--begin::Timeline-->
                                    <div class="timeline-label">
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6 ">November
                                                22</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Mass Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Newsletter - LifeSpark Thankful for you</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                29</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class="fas fa-users text-success fs-1"></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                $40.00 Recurring Payment
                                                <div class="fw-normal timeline-content text-muted">Ind -
                                                    General</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                27</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge"> <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Mass Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Newsletter - LifeSpark Remote sessions</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                14</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Engagement Survey
                                                <div class="fw-normal timeline-content text-muted">
                                                    Impact/Cultivation - Existing Donor Survey</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                14</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Engagement Survey Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Impact/Cultivation - Existing Donor Survey</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                13</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Mass Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Newsletter - LifeSpark In case you missed it</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                12</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Mass Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Newsletter - LifeSpark Simple ideas have</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                        <!--begin::Item-->
                                        <div class="timeline-item">
                                            <!--begin::Label-->
                                            <div class="timeline-label fw-bold text-gray-800 fs-6">October
                                                11</div>
                                            <!--end::Label-->
                                            <!--begin::Badge-->
                                            <div class="timeline-badge">
                                                <i class='far fa-comment fs-1'></i>
                                            </div>
                                            <!--end::Badge-->
                                            <!--begin::Text-->
                                            <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                                Mass Email
                                                <div class="fw-normal timeline-content text-muted">
                                                    Newsletter - LifeSpark Thank you</div>
                                            </div>
                                            <!--end::Text-->
                                        </div>
                                        <!--end::Item-->
                                    </div>
                                    <!--end::Timeline-->
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="border-form p-6 background-primary">
                                        <div class="text-center text-dark fs-5 fw-bold">Highlights</div>
                                        <div>
                                            <ul class="fs-6">
                                                <li>Upgraded from $2,157.94 to $2,726.00</li>
                                                <li>Have donated 12 years in a row</li>
                                                <li>Has upgraded 2 years in a row</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- end::Sidebar -->


                </div>
            </div>
            <!-- end::Body -->

            <!-- begin::Footer -->


            <!-- end::Footer -->
        </form>
        <!-- end::Form -->
    </div>
     <!-- Models -->
     <ng-template #addCategory let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Category</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="w-100 p-2">
                        <h6>Category</h6>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div>
                        <input type="text" placeholder="Enter Category" name="name" autocomplete="off"
                            class="form-control bg-transparent p-2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary p-1 px-2"
                (click)="modal.dismiss('Cross click')"><small>Cancel</small></button>
            <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="modal.close('Save click')"
                data-bs-dismiss="modal"><small>Save
                    Category</small></button>
        </div>
    </ng-template>
    <ng-template #addValue let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Value</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="w-100 p-2">
                        <h6>Value</h6>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div>
                        <input type="text" placeholder="Enter Value" name="name" autocomplete="off"
                            class="form-control bg-transparent p-2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary p-1 px-2"
                (click)="modal.dismiss('Cross click')"><small>Cancel</small></button>
            <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="modal.close('Save click')"
                data-bs-dismiss="modal"><small>Save
                    Value</small></button>
        </div>
    </ng-template>
    <ng-template #addField let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Address</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="w-100 p-2">
                        <h6>Address</h6>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div>
                        <input type="text" placeholder="Enter Field" name="name" autocomplete="off"
                            class="form-control bg-transparent p-2" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary p-1 px-2"
                (click)="modal.dismiss('Cross click')"><small>Cancel</small></button>
            <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="modal.close('Save click')"
                data-bs-dismiss="modal"><small>Save
                    Field</small></button>
        </div>
    </ng-template>
    <!-- end::: Models -->
</div>