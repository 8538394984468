import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  filterTerm: string;

  jobRecords = [
    {
      jobtitle: "Food Collection volunteer",
      event: "Fall 2022 community Food Drive",
      date:"8/15/2022",
      needed: 10,
      assigned: 5,
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }
  displayStyle = "none";
  
  
 
}
