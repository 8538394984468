import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
   const token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
   const token = token_response.access_token;
    const modifiedReq = request.clone({ 
      setHeaders: {Authorization: `Bearer ${token}`,'Access-Control-Allow-Origin': '*'}
    });
    return next.handle(modifiedReq);
  }
}
