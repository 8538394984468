<div class="
    d-flex
    flex-column flex-column-fluid
    bgi-position-y-bottom
    position-x-center
    bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-secondary
  ">
    <div class="flex-center flex-column flex-column-fluid p-10">
        <div class="row">
            <div class="col-sm-2 col-lg-2 col-md-2">
                <img alt="Logo" src="./assets/media/logos/volunteers_llc.png" height="150px" width="150px" />
            </div>
            <div class="col-sm-4 col-lg-2 col-md-2 text-center d-flex welcometext">
                <div class="row">
                    Welcome to
                    Volunteers.llc
                </div>
            </div>
            <div class="col-sm-6 col-lg-8 col-md-8 userdetailscard">
                <div class="w-lg-500px bg-white rounded shadow-sm p-lg-15 mx-auto">
                    <div class="card-padding">
                        <div class="text-center">
                            <h1 class="mb-10 text-primary">Reset Password</h1>
                        </div>
                        <div class="fv-row mt-10">
                            <div class="d-flex justify-content-between mt-n5">
                                <div class="d-flex flex-stack">
                                    <!-- <label class="form-label fw-bolder text-dark fs-6 mb-0">Password</label> -->
                                </div>
                            </div>
                            <div class="fv-row mb-5 mt-2 position-relative">
                                <input class="form-control" [type]="changetype?'password':'text'"
                                    placeholder="New Password" autocomplete="off"
                                    [(ngModel)]="passwordModel.NewPassword" />
                                <span class="position-absolute translate-middle top-50 end-0 me-4"
                                    (click)="viewpass()"><i
                                        [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                            </div>
                        </div>
                        <div class="fv-row mt-10">
                            <div class="d-flex justify-content-between mt-n5">
                                <div class="d-flex flex-stack">
                                </div>
                            </div>
                            <div class="fv-row mb-5 mt-2 position-relative">
                                <input class="form-control" [type]="confirmchangetype?'password':'text'"
                                    placeholder="Confirm Password" autocomplete="off"
                                    [(ngModel)]="passwordModel.ConfirmPassword" />
                                <span class="position-absolute translate-middle top-50 end-0 me-4"
                                    (click)="confirmviewpass()"><i
                                        [ngClass]="confirmvisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                            </div>
                        </div>
                        <br>
                        <div class="text-center">
                            <button expand="block" color="red" class="btn btn-lg btn-primary1 w-100 mb-5"
                                (click)="UpdatePassword(passwordModel)">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>