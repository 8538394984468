<smart-scheduler #scheduler id="scheduler" [view]="CalendarView" (viewChange)="getview($event)"
  [dataSource]="schedulardatasource" [timelineDayScale]="timelineDayScale" [hourStart]="CalendarStartTime"
  [hourEnd]="CalendarEndTime" style="height: calc(100vh - 115px) !important"></smart-scheduler>
<smart-window #editingWindow [pinned]="true">
  <!-- <h3>{{heading}}</h3> -->
  <div class="w-100">
    <!-- <div class="row"> -->
    <div class="smart-control mx-2" [hidden]="!isMeetingLableEditable">
      <label>Meeting Name</label><br>
      <smart-input #eventLabel [disabled]="isMeetingLableDisabled" placeholder="Enter meeting here.."
        class="col-12"></smart-input>
    </div>

    <!-- </div> -->
    <div class="form-check mt-2 ms-2" *ngIf="isCheckboxDisplayed">
      <label for="remember-check" class="form-check-label"> Do you want edit the end time </label>
      <input type="checkbox" [(ngModel)]="chkDisableOrEnable" id="remember-check" class="form-check-input"
        (change)="setIsDisableOrIsenable()">
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="smart-control m-2">
          <label>Start Time</label>
          <smart-date-time-picker (change)="changeStartTime($event)" #dateStart formatString="MM/dd/yyyy hh:mm tt"
            calendar-button dropDownAppendTo="body" [(ngModel)]="schedularnewevent.ScheduleDetailStartDateTime"
            id="startdate" class="w-auto"></smart-date-time-picker>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="smart-control m-2 ">
          <label>End Time</label>
          <smart-date-time-picker [disabled]="isTimeFieldDisabled" #dateEnd formatString="MM/dd/yyyy hh:mm tt"
            calendar-button dropDownAppendTo="body" [(ngModel)]="schedularnewevent.ScheduleDetailEndDateTime"
            id="enddate" class="w-auto"></smart-date-time-picker>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="UserRole == 'Admin' || UserRole == 'Staff'">
        <div class="smart-control m-2">
          <label>Volunteer(s)</label>
          <ng-select data-hide-search="true" #ddlOrganizers [(ngModel)]="organizerId" [clearable]="false"
            [searchable]="true">
            <ng-option [value]="''">Select Organizer</ng-option>
            <ng-option *ngFor="let options of organizerDataSource" [value]="options.CRMCustomerId">
              {{options.FullName}}</ng-option>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="UserRole != 'Participant'">
        <div class="smart-control m-2">
          <label>Beneficiary(s)</label>
          <!-- <smart-drop-down-list #staffInput id="staffdrop" [dataSource]="staffDataSource" [filterable]="true"
                [selectionMode]="'checkBox'" placeholder="Select participants here...."></smart-drop-down-list> -->
          <!-- <smart-drop-down-list #staffInput  id="staffdrop" [selectionMode]="'zeroOrMany'" [dataSource]="staffDataSource" [selectionDisplayMode]="'tokens'" [filterable]="true"
                [placeholder]="'Select participants here....'"></smart-drop-down-list> -->
          <ng-container *ngIf="isMultiEventParicipants == false">
            <ng-select data-hide-search="true" #ddlAttendeers [(ngModel)]="participantId" [clearable]="false"
              [searchable]="true">
              <ng-option [value]="''">Select Attendee</ng-option>
              <ng-option *ngFor="let options of staffDataSource" [value]="options.ParticipantId">
                {{options.FullName}}</ng-option>
            </ng-select>
          </ng-container>
          <ng-container *ngIf="isMultiEventParicipants == true">
            <ng-select [items]="staffDataSource" bindLabel="FullName" placeholder="Select Beneficiaries here...."
              [multiple]="true" [(ngModel)]="selectedParticipants" [clearable]="false" [searchable]="true"
              [closeOnSelect]="false">
              <!-- <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div class="ng-value" *ngFor="let item of selectedParticipants; let i = index">
                      <ng-container *ngIf="i <= 1">
                        <span class="ng-value-icon right"(click)="clear(item)">×</span>
                        <span class="ng-value-label">{{item.FullName}}</span>
                      </ng-container>
                     </div>
                     <div class="ng-value" *ngIf="items.length > 2">
                      <span class="ng-value-label">{{items.length - 2}}more..</span>
                    </div>
                  </ng-template> -->
            </ng-select>
          </ng-container>
        </div>
      </div>

    </div>
    <!-- <div class="row">
        <div> -->
    <div class="smart-control mx-2">
      <label>Meeting
        Link</label><br>
      <smart-input #meetingLink id="meetingLink" placeholder="Enter Meeting link here.."
        class="col-12 mb-2"></smart-input>
    </div>
    <!-- </div>
      </div> -->
    <!-- <div class="row">
        <div class="col-lg -6 col-md-6 col-sm-6"> -->
    <div class="smart-control m-2" style="display: flex; align-items: center;">
      <label class="me-2" *ngIf="!isRepeatShow">Repeat </label>
      <smart-switch-button [inverted]="true" [hidden]="isRepeatShow" #repeat id="sbtnRepeat"
        (change)="repeatChange($event)"></smart-switch-button>
    </div>
    <!-- </div>
      </div> -->
  </div>

  <div id="repeat-editor" style="display: none;" class="m-lrepeat w-100">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6"><label>Repeat</label>
        <smart-drop-down-list #repeatFreqInput id="ddlRepeat" [dataSource]="[ 'Daily', 'Weekly', 'Monthly']"
          placeholder="select here" (change)="repeatFreqChange1($event)" class="w-auto"></smart-drop-down-list>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <!-- <div  (change)="repeateditorsChange($event)"></div> -->
        <div>Repeat Every</div>
        <smart-number-input #repeatafter id='repeatAfter' [min]="1" class="w-auto"></smart-number-input>
        <div>occurrences</div>
      </div>
    </div>

    <div *ngIf="show1">
      <div class="row mt-3">
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkMon id="chkMon" [checked]="chkweek"
            [value]="'1'">Monday</smart-check-box>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkTue id="chkTue" [checked]="chkweek"
            [value]="'2'">Tuesday</smart-check-box>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkWed id="chkWed" [checked]="chkweek"
            [value]="'3'">Wednesday</smart-check-box>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkThu id="chkThu" [checked]="chkweek"
            [value]="'4'">Thursday</smart-check-box>
        </div>
        <!-- </div>
        <div class="row"> -->
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkFri id="chkFri" [checked]="chkweek"
            [value]="'5'">Friday</smart-check-box>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkSat id="chkSat" [checked]="chkweek"
            [value]="'6'">Saturday</smart-check-box>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3"><smart-check-box #chkSun id="chkSun" [checked]="chkweek"
            [value]="'0'">Sunday</smart-check-box>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="show1">
        <div class="row mt-3">
          <div class="col-3"><smart-check-box #checkbox>Monday</smart-check-box>
          </div>
          <div class="col-3"><smart-check-box #checkbox>Tuesday</smart-check-box>
          </div>
          <div class="col-3"><smart-check-box #checkbox>Wednesday</smart-check-box>
          </div>
          <div class="col-3"><smart-check-box #checkbox>Thursday</smart-check-box>
          </div>
        </div>
        <div class="row">
          <div class="col-3"><smart-check-box #checkbox>Friday</smart-check-box>
          </div>
          <div class="col-3"><smart-check-box #checkbox>Saturday</smart-check-box>
          </div>
          <div class="col-3"><smart-check-box #checkbox>Sunday</smart-check-box>
          </div>
        </div>
      </div> -->
    <div *ngIf="show3">
      <div class="row mt-3">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <smart-radio-button group-name='repeat' (change)="changeOnDayorOnThe()" [checked]="checkOnDay" #onDay
            id='onDay' class="w-auto">On Day:</smart-radio-button>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <smart-number-input #monthOccur id="monthOccur" [value]="monthOccurValue" [min]="1" [max]='31'
            [disabled]="disableOnDay" class="w-auto"></smart-number-input>
        </div>
      </div>
      <span class="row mt-3">
        <div class="col-lg-3 col-md-4 col-sm-2">
          <smart-radio-button group-name='repeat' (change)="changeOnDayorOnThe()" #onThe id='onThe'
            [checked]="checkOnThe">On the:</smart-radio-button>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4">
          <smart-drop-down-list class="w-100" #selectedWeek [selectedIndexes]="selectedWeekIndex" id="selectedWeek"
            [disabled]="disableOnThe" placeholder="select here"
            [dataSource]="[ 'First', 'Second', 'Third','Fourth','Last']">
          </smart-drop-down-list>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <smart-drop-down-list class="w-100" #selectedDay [disabled]="disableOnThe" id="selectedDay"
            [selectedIndexes]="selectedDayIndex"
            [dataSource]="[ 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday','Sunday']"
            placeholder="select here">
          </smart-drop-down-list>
        </div>

      </span>

    </div>
    <!-- <div class="row mt-3">
        <div class="col-6">
          <smart-radio-button group-name='repeat' id='never'>End By:</smart-radio-button>
          <smart-date-time-picker formatString="dd MM yyyy H:mm" calendar-button dropDownAppendTo="body"
            id="dateOfRepeat"></smart-date-time-picker>
        </div>
        <div class="col-6">
          <smart-radio-button group-name='repeat' id='after'>End After:</smart-radio-button><smart-number-input
            id='repeatAfter'></smart-number-input>occurrences
        </div>
      </div> -->
    <div class="row mt-3">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div>End By</div>
        <!-- <smart-date-time-picker #endBy formatString="MM/dd/yyyy H:mm" calendar-button dropDownAppendTo="body"
            id="endBy"></smart-date-time-picker> -->
        <smart-date-input #endBy id="#endBy"></smart-date-input>
        <!-- <input type="date" autocomplete="off"  class="form-control bg-transparent p-2 w-250" [(ngModel)]="daterange.startTime">                           -->

      </div>
      <div class="col-6">
        <!-- <smart-radio-button group-name='repeat' id='after'>End After:</smart-radio-button><smart-number-input
            id='repeatAfter'></smart-number-input>occurrences -->
      </div>
    </div>

  </div>
  <div class="mt-1 float-end" *ngIf="UserRole != 'Participant'">
    <smart-button #cancelBtn class="cancel-btn background-danger text-white m-5 cancelhover">Cancel</smart-button>
    <smart-button #submitBtn class="submit-btn background-success text-white"
      (click)="save_schedularevent(schedularnewevent)">{{btnsubmit}}</smart-button>
  </div>
</smart-window>


<smart-menu #contextMenu *ngIf="UserRole != 'Participant'" [mode]="'dropDown'" id="contextMenu"
  (itemClick)="onItemClick($event)">
  <smart-menu-item [label]="'Edit Occurrence'" [value]="'Edit Occurrence'"></smart-menu-item>
  <smart-menu-item [label]="'Edit Series'" [value]="'Edit Series'"></smart-menu-item>
  <smart-menu-item [label]="'Delete Occurrence'" [value]="'Delete Occurrence'"></smart-menu-item>
  <smart-menu-item [label]="'Delete Series'" [value]="'Delete Series'"></smart-menu-item>
</smart-menu>