<div class="row px-4 py-0">
    <div class="toolbar-bar text-white fw-bolder">
        <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2">
                <div class="p-1">
                    <div class="p-2 fw-bold text-white fs-3">
                        John Smith
                    </div>
                    <div class="px-2 fw-bold text-white">
                        Volunteer
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 p-1 fw-bold text-white mt-6">(555)
                123-4567</div>
            <div class="col-lg-2 col-md-2 col-sm-2 p-1 fw-bold text-white mt-6">
                jsmith1@gmail.com</div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="d-flex align-items-center gap-2 gap-lg-3 mt-7 float-end" (click)="Redirecttocontact()">
                    <a class="btn btn-sm me-5  p-1 bg-white text-primary">Return to All
                        Contacts</a>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-custom">
        <!-- begin::Header -->
        <div class="card-header card-header-stretch overflow-auto ">
            <ul class="
          nav nav-stretch nav-line-tabs
          fw-bold
          border-transparent
          flex-nowrap
        " role="tablist">

                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="setActiveTab('Profile')" role="tab">
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Schedule' }"
                        (click)="setActiveTab('Schedule')" role="tab">
                        Schedule
                    </a>
                </li>
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'History' }"
                        (click)="setActiveTab('History')" role="tab">
                        History
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="setActiveTab('Availability')" role="tab">
                        Availability
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Skills' }"
                        (click)="setActiveTab('Skills')" role="tab">
                        Skills
                    </a>
                </li>
            </ul>
        </div>
        <!-- end::Header -->
        <!-- begin::Form -->
        <form class="form">
            <!-- begin::Body -->
            <div class="card-body">
                <div class="tab-content pt-3">

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Profile' }">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            First Name:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter First Name" name="firstName"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Last
                                            Name:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Last Name" name="lastName"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Date
                                            of Birth:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Date of Birth" name="dateOfBirth"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Address Line 1:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Address" name="addressLine"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Address Line 2:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Address" name="addressLine"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row  p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">City:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter City" name="city" autocomplete="off"
                                            class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">State:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter State" name="state" autocomplete="off"
                                            class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Zip
                                            Code:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Zip Code" name="zipCode"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Mobile
                                            Phone:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Mobile" name="mobilePhone"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Work
                                            Phone:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Work Phone" name="workPhone"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Home
                                            Phone:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Home Phone" name="homePhone"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Primary Phone:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Primary Phone" name="primaryPhone"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Personal Email:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Personal Email" name="personalEmail"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Work
                                            Email:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Work Email" name="workEmail"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Status:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Status" name="status" autocomplete="off"
                                            class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Max
                                            Travel Distance:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Travel Distance" name="maxTravelDistance"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h1 class="page-heading d-flex text-dark fw-bold fs-6 flex-column justify-content-center my-0">
                            Contact Preferences</h1>
                        <div class="row mt-3">
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Phone</span>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">SMS</span>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Email</span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6"></div>
                        </div>
                    </div>
                    <!-- end::Sidebar -->

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Schedule' }">
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table id="kt_profile_overview_table"
                                class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                                <!--begin::Head-->
                                <thead class="fs-6 text-primary text-uppercase">
                                    <tr>
                                        <th class="min-w-50px">S.No</th>
                                        <th class="min-w-50px">Job Title</th>
                                        <th class="min-w-100px">Event</th>
                                        <th class="min-w-150px">Date</th>
                                        <th class="min-w-150px">Location</th>
                                        <th class="min-w-150px">Time</th>
                                        <th class="min-w-150px">Job Status</th>
                                        <th class="min-w-150px"></th>
                                    </tr>
                                </thead>
                                <!--end::Head-->
                                <!--begin::Body-->
                                <tbody class="fs-7">
                                    <tr class="fw-normal">
                                        <td class="p-2">1</td>
                                        <td class="p-2">Food Collection Volunteer</td>
                                        <td class="p-2"><a class="text-dark">Fall 2022
                                                Community Food Drive</a></td>
                                        <td class="p-2">15/10/2022</td>
                                        <td class="p-2">Community Food Bank 123 Main St, Denver,
                                            CO</td>
                                        <td class="p-2">4:00 PM - 7:30 PM</td>
                                        <td class="p-2">Scheduled</td>
                                        <td class="p-2">
                                            <a class="btn btn-sm btn-danger me-2 p-2">Remove</a>
                                        </td>
                                    </tr>
                                </tbody>
                                <!--end::Body-->
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!-- end::Sidebar -->

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'History' }">
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table id="kt_profile_overview_table"
                                class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                                <!--begin::Head-->
                                <thead class="fs-6 text-primary text-uppercase">
                                    <tr>
                                        <th class="min-w-50px">S.No</th>
                                        <th class="min-w-50px">Job Title</th>
                                        <th class="min-w-100px">Event</th>
                                        <th class="min-w-150px">Date</th>
                                        <th class="min-w-150px">Location</th>
                                        <th class="min-w-150px">Volunteer Hours</th>
                                        <th class="min-w-150px">Status</th>
                                    </tr>
                                </thead>
                                <!--end::Head-->
                                <!--begin::Body-->
                                <tbody class="fs-7">
                                    <tr class="fw-normal">
                                        <td class="p-2">1</td>
                                        <td class="p-2">Food Collection Volunteer</td>
                                        <td class="p-2"><a class="text-dark">Summer
                                                2022 Community Food Drive</a></td>
                                        <td class="p-2">15/10/2022</td>
                                        <td class="p-2">Community Food Bank 123 Main St, Denver,
                                            CO</td>
                                        <td class="p-2">3.5 hours</td>
                                        <td class="p-2">Completed</td>
                                    </tr>
                                </tbody>
                                <!--end::Body-->
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!-- end::Sidebar -->
                    <!-- begin::Header -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Availability' }">
                        <div class="row">
                            <div class="col-lg-1 col-md-1 col-sm-1">
                                <div class="py-2">
                                    Sunday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 PM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1">
                                <div class="py-2">
                                    Monday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1">
                                <div class="py-2">
                                    Tuesday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-0 col-md-2 col-sm-2">
                                <div class="py-2">
                                    Wednesday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-2 col-sm-2">
                                <div class="py-2">
                                    Thursday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1">
                                <div class="py-2">
                                    Friday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-2 col-sm-2">
                                <div class="py-2">
                                    Saturday
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                                <div class="py-2">
                                    <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                        class="form-control bg-transparent" />
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-2 col-sm-2"></div>
                        </div>
                        <div class="p-4">
                            <a id="availabilitySave"
                                class="btn btn-sm btn-primary text-white me-2 p-2 float-end">Save</a>
                        </div>
                    </div>
                    <!-- end::Header -->

                    <!-- begin::Toolbar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Skills' }">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 col-sm-3">
                                <div class="p-2">
                                    <select id="arts" name="arts" placeholder="Arts" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Arts" disabled selected>Arts</option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="education" placeholder="Education" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Education" disabled selected>Education
                                        </option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="healthCare" placeholder="HealthCare" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="HealthCare" disabled selected>Healthcare
                                        </option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="technology" placeholder="Technology" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Technology" disabled selected>Technology
                                        </option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3">
                                <div class="p-2">
                                    <select name="communication" placeholder="Communication" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Communication" disabled selected>
                                            Communication</option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="finance" placeholder="Finance" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Technology" disabled selected>Finance
                                        </option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="language" placeholder="Language" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Language" disabled selected>Language
                                        </option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                                <div class="p-2">
                                    <select name="trades" placeholder="Trades" data-control="select2"
                                        data-hide-search="true" class="form-select form-select-solid form-select-sm">
                                        <option value="Trades" disabled selected>Trades</option>
                                        <option value="value1">Option 1</option>
                                        <option value="value2">Option 2</option>
                                        <option value="value3">Option 3</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3"></div>
                        </div>
                        <h1 class="d-flex text-dark fw-bold fs-4 flex-column justify-content-center my-0 mt-4">
                            Preferences
                        </h1>
                        <div class="row pt-3">
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Group Projects</span>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Remote Jobs</span>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Donation Jobs</span>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-2">
                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                    <input class="form-check-input" type="checkbox" value="1" />
                                    <span class="ms-3">Outdoor Jobs</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4"></div>
                        </div>
                    </div>
                    <!-- end::Toolbar -->
                </div>
            </div>
            <!-- end::Body -->

            <!-- begin::Footer -->


            <!-- end::Footer -->
        </form>
        <!-- end::Form -->
    </div>
</div>