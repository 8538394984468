<div class="row px-4 py-0">
    <div class="toolbar-bar text-white fw-bolder">
        <div class="row">
            <div class="col-lg-6 col-md-2 col-sm-2">
                <div class="p-1">
                    <div class="p-2 fw-bold text-white fs-3">
                        Designed Using Matronic Theme
                    </div>
                    <div class="px-2 fw-bold text-white">
                        Fall 2022 Community Food Drive
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="d-flex align-items-center gap-2 gap-lg-3 mt-7 float-end">
                    <a class="btn btn-sm me-3 p-1  bg-white text-primary" routerLink="/jobs">
                        Return To All Jobs
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="card card-custom">
        <!-- begin::Header -->
        <div class="card-header card-header-stretch overflow-auto ">
            <ul class="
          nav nav-stretch nav-line-tabs
          fw-bold
          border-transparent
          flex-nowrap
        " role="tablist">

                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Details' }"
                        (click)="setActiveTab('Details')" role="tab">
                        Details
                    </a>
                </li>
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Volunteers' }"
                        (click)="setActiveTab('Volunteers')" role="tab">
                        Volunteers
                    </a>
                </li>

            </ul>
        </div>
        <!-- end::Header -->
        <!-- begin::Form -->
        <form class="form">
            <!-- begin::Body -->
            <div class="card-body">
                <div class="tab-content pt-1">
                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Details' }">
                        <div class="row">
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 ">
                                            Job Title:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 mt-1">
                                        Food Collection Volunteer
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 ">
                                            Event:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 mt-1">
                                        Fall 2022 Community Food Drive
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-3 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 ">
                                            Job Type:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 mt-1">
                                        In-person
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-5 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 ">
                                            Contact Information:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 mt-1">

                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-1 col-md-4 col-sm-4">

                            </div>
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 px-10 text-dark fs-6 text-end ">Job Visibility</div>
                                        <select name="year" data-control="select2" data-hide-search="true"
                                            class="w-125px form-select form-select-solid form-select-sm float-end ">
                                            <option value="All" selected="selected">Public</option>
                                            <option value="value1">Invite Only</option>
                                            <option value="value2">Denver Volunteers</option>
                                            <option value="value3">Custom List</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row p-3 mt-2">
                            <div class="col-lg-2 col-md-6 col-sm-6">
                                <h1
                                    class="page-heading d-flex text-dark fw-bold fs-6 flex-column justify-content-center my-0">
                                    <b>
                                        Schedule</b></h1>
                            </div>
                            <div class="form-check col-lg-2 form-check-sm form-check-custom form-check-solid">
                                <div><input class="form-check-input" type="checkbox" value="1" /> Ongoing Event</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-to fv-row">
                                <div class="w-100 d-flex align-items-center position-relative mt-3 mb-3">
                                    <span class="card-label fw-bolder ms-5 mb-1">Start Date:</span>
                                </div>
                            </div>
                            <div class="col-lg-2 fv-row">
                                <div class="w-100 d-flex align-items-center position-relative my-1">
                                    <input type="date" class="form-control ms-2 p-2 fs-8" name="daterange" />
                                </div>
                            </div>
                            <div class="col-to fv-row">
                                <div class="w-100 d-flex align-items-center position-relative mt-3 mb-3">
                                    <span class="card-label fw-bolder ms-5 mb-1">End Date:</span>
                                </div>
                            </div>
                            <div class="col-2 fv-row ">
                                <div class="w-100 d-flex align-items-center position-relative my-1">
                                    <input type="date" class="form-control ms-2 p-2 fs-8" name="daterange" />
                                </div>
                            </div>
                        </div>
                        <div class="row m-3 times">
                            <div class="row p-3 ">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-3 ">
                                    <h1
                                        class="page-heading d-flex text-dark fw-bold fs-6 flex-column justify-content-center my-0 ">
                                        <b>
                                            Times Needed:</b>
                                    </h1>
                                </div>

                            </div>
                            <div class="row ms-10 mb-10">
                                <div class="col-times col-md-1 col-sm-1">
                                    <div class="py-2">
                                        Sunday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 PM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-1 col-sm-1">
                                    <div class="py-2">
                                        Monday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-1 col-sm-1">
                                    <div class="py-2">
                                        Tuesday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-2 col-sm-2">
                                    <div class="py-2">
                                        Wednesday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-2 col-sm-2">
                                    <div class="py-2">
                                        Thursday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-1 col-sm-1">
                                    <div class="py-2">
                                        Friday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>
                                <div class="col-times col-md-2 col-sm-2">
                                    <div class="py-2">
                                        Saturday
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="4:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                    <div class="py-2">
                                        <input type="time" placeholder="8:00 AM" name="email" autocomplete="off"
                                            class="form-control bg-transparent" />
                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-2 col-sm-2"></div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Sidebar -->

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Volunteers' }">
                        <div class="row mt-5">
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <h3>Relevant skills</h3>
                                <div class="row">
                                    <div class="col-lg-6 col-md-3 col-sm-3">
                                        <div class="p-2">
                                            <select id="arts" name="arts" placeholder="Arts" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Arts" disabled selected>Arts</option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="education" placeholder="Education" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Education" disabled selected>Education
                                                </option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="healthCare" placeholder="HealthCare" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="HealthCare" disabled selected>Healthcare
                                                </option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="technology" placeholder="Technology" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Technology" disabled selected>Technology
                                                </option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-3 col-sm-3">
                                        <div class="p-2">
                                            <select name="communication" placeholder="Communication"
                                                data-control="select2" data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Communication" disabled selected>
                                                    Communication</option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="finance" placeholder="Finance" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Technology" disabled selected>Finance
                                                </option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="language" placeholder="Language" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Language" disabled selected>Language
                                                </option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                        <div class="p-2">
                                            <select name="trades" placeholder="Trades" data-control="select2"
                                                data-hide-search="true"
                                                class="form-select form-select-solid form-select-sm">
                                                <option value="Trades" disabled selected>Trades</option>
                                                <option value="value1">Option 1</option>
                                                <option value="value2">Option 2</option>
                                                <option value="value3">Option 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-3"></div>
                                </div>

                            </div>
                            <div class="col-lg-1 col-md-4 col-sm-4">
                            </div>
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <h3>Job Attributes</h3>
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 col-sm-3">
                                        <div class="form-check  form-check-sm form-check-custom form-check-solid">
                                            <div><input class="form-check-input " type="checkbox" value="1" />Group
                                                Project</div>
                                        </div>
                                        <div class="form-check  form-check-sm form-check-custom form-check-solid mt-5">
                                            <div><input class="form-check-input" type="checkbox" value="1" />outdoor Job
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-3">
                                        <div class="form-check  form-check-sm form-check-custom form-check-solid">
                                            <div><input class="form-check-input" type="checkbox" value="1" />Remote Job
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-3">
                                        <div class="form-check  form-check-sm form-check-custom form-check-solid">
                                            <div><input class="form-check-input" type="checkbox" value="1" />Donation
                                                Job</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h3 class="mt-5">Find Volunteers</h3>
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table id="kt_profile_overview_table"
                                class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                                <!--begin::Head-->
                                <thead class="fs-6 text-primary text-uppercase">
                                    <tr>
                                        <th class="min-w-150px">Name</th>
                                        <th class="min-w-150px">Type</th>
                                        <th class="min-w-150px">Phone</th>
                                        <th class="min-w-150px">Email</th>
                                        <th class="min-w-150px"></th>
                                    </tr>
                                </thead>
                                <!--end::Head-->
                                <!--begin::Body-->
                                <tbody class="fs-7">
                                    <tr class="fw-normal">
                                        <td class="p-2">Jhon Smith</td>
                                        <td class="p-2">Volunteer</td>
                                        <td class="p-2">(555)123-4567</td>
                                        <td class="p-2">jsmith@gmail.com</td>
                                        <td class="p-2">
                                            <a class="btn btn-sm btn-primary me-2 p-2">Request</a>
                                        </td>
                                    </tr>
                                </tbody>
                                <!--end::Body-->
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!-- end::Sidebar -->


                </div>
            </div>
            <!-- end::Body -->

            <!-- begin::Footer -->


            <!-- end::Footer -->
        </form>
        <!-- end::Form -->
    </div>
</div>