import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  filterTerm: string;

  eventRecords = [
    {
      name: "Full 2022 Community Food Drive	",
      type: "Food Drive",
      date:"Ongoing",
      location: "Community Food Bank 123 Main st, Denver, CO",
      jobs: "View",
    },
    {
      name: "Community clean-up",
      type: "Community Improvement",
      date:"	4/18/2022",
      location: "	city Park 300 washington st, Denver, CO",
      jobs: "View",
    },
  ];
  constructor(public router: Router) { }

  ngOnInit(): void {
  }
 displayStyle = "none";
  
  
  Redirecttonewcontact(){
    this.router.navigate(['/newcontact']);
  }

}
