import { Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
// import { faCalendar } from '@fortawesome/free-regular-svg-icons';
// import { faUser } from '@fortawesome/free-regular-svg-icons';
// import { faFlag } from '@fortawesome/free-regular-svg-icons';
import { faGear,faCalendar,faUser,faFlag,faUserGroup, faMapSigns, faCrosshairs, faNetworkWired, faLink } from '@fortawesome/free-solid-svg-icons';
import { faTachometer } from '@fortawesome/free-solid-svg-icons';
import { UserService } from 'src/app/services/user.service';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';


@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
  appAngularVersion: string = environment.appVersion;
  // appPreviewChangelogUrl: string = environment.appPreviewChangelogUrl;
  faCalendar = faCalendar;
  faUser = faUser;
  faflag = faFlag;
  faGear = faGear;
  faLink = faLink;
  faTachometer = faTachometer;
  displayadmin = false;
  currentrole: any;
  menulist: any
  token_response: any;
  token: any;
  menusList:any = [];
  crmCustomerId:any = "";
  constructor(private route: Router, private userService: UserService, public toastralert: ToastralertService,public library: FaIconLibrary) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.crmCustomerId = this.token_response.CRMCustomerId;
    library.addIcons(faCalendar, faUser, faFlag, faGear, faTachometer,faUserGroup, faMapSigns,faCrosshairs,faNetworkWired,faLink);
    console.log(library);
   }

  ngOnInit(): void { 
       this.GetAllMenusByUserIdAndUserRoleId();
    this.MenuDisplay();
  }

  navcontacts() {
    this.route.navigate(['profiles']);
  }
  calendar() {
    this.route.navigate(['calendar']);

  }

  MenuDisplay() {
    this.currentrole = this.token_response.UserRole;   
    this.displayadmin = this.currentrole == "Admin";     
  }

  redirectToProfile() {
    this.route.navigate(['myprofile/' + this.token_response.CRMCustomerId]);
  }

  GetAllMenusByUserIdAndUserRoleId() {
    this.userService.GetAllMenusByUserIdAndUserRoleId(this.token_response.CRMPersonId,this.token_response.userRoleId)
      .subscribe(results => {
        if (results != null && results != 'undefined') {
          this.menusList = results;
          console.log(this.menusList);
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to get Side Menus details.Please try after some time.");
        });
  }

}
