<div class="app-main flex-column flex-row-fluid ">
  <div class="d-flex flex-column flex-column-fluid">
    <div class="app-toolbar py-1 py-lg-3">
      <div class="container-fluid d-flex flex-stack ps-3">
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <h1 class="page-heading d-flex text-dark fw-bold fs-4 flex-column justify-content-center my-0">
            {{header}} Report</h1>
        </div>
        <div class="d-flex align-items-center gap-2 gap-lg-3">
          <button class="btn btn-secondary btn-sm p-2">Close</button>
          <button class="btn btn-primary btn-sm p-2" (click)="SaveOrUpdateQuery()">Save</button>
        </div>
      </div>
    </div>
    <div class="app-content flex-column-fluid p-4 pt-0">
      <!-- <div class="card mb-xxl-8">
                  <div class="card-header px-1 min-h-30px background-secondary">
                      <div class="card-title">
                          <h3 class="fs-5 fw-semibold ms-1 text-white text-truncate mw-500px">Edit Details
                          </h3>
                      </div>
                      <div class="d-flex align-items-center gap-2 gap-lg-3 me-3">
                          <i class="fa fa-close fs-7 text-white cursor-pointer" data-toggle="tooltip" title="Cancel"
                              data-bs-target="#" data-bs-toggle="modal"></i>
                          <i class="fa fa-trash fs-7 text-white cursor-pointer" data-toggle="tooltip"
                              title="Delete Component"></i>
                          <a><i class="fa fa-save fs-7 text-white cursor-pointer" data-toggle="tooltip"
                                  title="Save Component" data-bs-target="#" data-bs-toggle="modal"></i></a>
                      </div>
                  </div>
                  <div class="card-body p-4"></div>
              </div> -->


      <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block text-dark fw-bold">Report</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="report"></ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>
            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
            <span class="d-none d-sm-block text-dark fw-bold">Details</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="detail"></ng-template>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
      <ng-template #report>
        <div class="card">
          <div class="card-body p-3">
            <!-- <a class="btn btn-success mt-3 p-2 fs-6 text-white  " (click)="GetDatabases()">Fetch Database</a> -->
            <div class="d-flex">
              <div class="fw-bold mt-1">Table List</div>
              <div class="w-25 ms-3">
                <ng-select data-control="select2" data-hide-searclisth="true" id="statusDropdown" placeholder="Select Table" 
                  [clearable]="false" [(ngModel)]="tableName" (change)="GetTableColumns()">
                  <ng-option [value]="''" >Select Table</ng-option>
                  <ng-option [value]="item" class="fs-6" *ngFor="let item of connectionDataBases" >{{item}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <label class="fs-5">Select Columns</label>
                <ng-select data-control="select2" data-hide-search="true" id="statusDropdown" [clearable]="false"
                  [multiple]="true" [(ngModel)]="selectedColumnslist" placeholder="Select Column">
                  <ng-option [value]="''" >Select Columns
                  </ng-option>
                  <ng-option [value]="item" *ngFor="let item of columnNames">{{item}}</ng-option>
                </ng-select>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-8"></div>
            </div>
            <div class="row mt-3">
              <form [formGroup]="form">
                <div formArrayName="rows">
                  <div *ngFor="let row of rowsFormArray.controls; let i = index">
                    <div [formGroupName]="i">
                      <!-- Add your form fields here -->
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <label class="fs-5">Aggregate Functions</label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <label class="fs-5">Where</label>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                          <label class="fs-5">value</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4">  
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                              <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                [clearable]="false" [multiple]="false" formControlName="ReportQueryAggFunctions" placeholder="Select" >
                                <ng-option [value]="''" >Select</ng-option>
                                <ng-option [value]="'Sum'" class="fs-6">Sum</ng-option>
                                <ng-option [value]="'Avg'" class="fs-6">Avg</ng-option>
                                <ng-option [value]="'Count'" class="fs-6">Count</ng-option>
                                <ng-option [value]="'Max'" class="fs-6">Max</ng-option>
                                <ng-option [value]="'Min'" class="fs-6">Min</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                              <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                [clearable]="false" [multiple]="false" formControlName="ReportQueryAggFunColumnName"
                                placeholder="Select Column"> 
                                <ng-option [value]="''" >Select Columns
                                </ng-option>
                                <ng-option [value]="item" *ngFor="let item of columnNames">{{item}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                              <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                [clearable]="false" formControlName="ReportQueryWhereConditionColumnName" placeholder="Select Column">
                                <ng-option [value]="''" >Select Columns
                                </ng-option>
                                <ng-option [value]="item" *ngFor="let item of columnNames">{{item}}</ng-option>
                              </ng-select>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                              <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                [clearable]="false" [multiple]="false" formControlName="ReportQueryWhereConditionOperator" placeholder="Select">
                                <ng-option [value]="''" >Select</ng-option>
                                <ng-option [value]="'>'" class="fs-6">></ng-option>
                                <ng-option [value]="'<'" class="fs-6">
                                  < </ng-option>
                                    <ng-option [value]="'>='" class="fs-6"> >= </ng-option>
                                    <ng-option [value]="'<='" class="fs-6">
                                      <= </ng-option>
                                        <ng-option [value]="'<>'" class="fs-6">
                                          <>
                                        </ng-option>
                                        <ng-option [value]="'='" class="fs-6">=</ng-option>
                                        <ng-option [value]="'LIKE'" class="fs-6">LIKE</ng-option>
                                        <ng-option [value]="'IN'" class="fs-6">IN</ng-option>
                                        <ng-option [value]="'BETWEEN'" class="fs-6">BETWEEN</ng-option>
                                        <ng-option [value]="'HAVING'" class="fs-6">HAVING</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                          <input class="form-control p-2 fs-6 height-28px" placeholder="Enter Value"
                          formControlName="ReportQueryWhereConditionValue">
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                          <input type="checkbox" id="or" class="mt-2" formControlName="ReportQueryIsOrAND"> 
                          <label for="or" class="fs-6 ms-2">OR</label>
                        </div>
                      </div>
                      <!-- Add more fields as needed -->
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary btn-sm mt-3 p-1"(click)="addRow()">Add Row</button>
                <!-- <button (click)="saveData()">Save Data</button> -->
              </form>

            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <label class="fs-5">Order By</label>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <ng-select data-control="select2" data-hide-search="true" id="statusDropdown" [clearable]="false"
                      [(ngModel)]="OrderByValue" placeholder="Select Column">
                      <ng-option [value]="''" >Select Columns</ng-option>
                      <ng-option [value]="'Ascending'" class="fs-6">Ascending</ng-option>
                      <ng-option [value]="'Descending'" class="fs-6">Descending</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <ng-select data-control="select2" data-hide-search="true" id="statusDropdown" [clearable]="false"
                      [multiple]="true" [(ngModel)]="OrderBycolumnsList" placeholder="Select Column" >
                      <ng-option [value]="''" >Select Columns
                      </ng-option>
                      <ng-option [value]="item" *ngFor="let item of columnNames">{{item}}</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2">
                <label class="fs-5">Group By</label>
                <ng-select data-control="select2" data-hide-search="true" id="statusDropdown" [clearable]="false"
                  [multiple]="true" [(ngModel)]="GroupByColumnsList" placeholder="Select Column">
                  <ng-option [value]="''" >Select Columns
                  </ng-option>
                  <ng-option [value]="item" *ngFor="let item of columnNames">{{item}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="mt-3">
              <a class="btn btn-success p-2 fs-6 text-white" (click)="Generatequery()">Generate Query</a>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-4 col-sm-4">
                <textarea class="form-control p-2 border-secondary fs-6 fw-normal" placeholder="Query"
                  [(ngModel)]="SqlQueryText"></textarea>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2">
                <a class="btn btn-success p-2 fs-6 text-white mt-3" id = "runQuery" (click)="RunQuery()">Run Query</a>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="float-end text-dark  p-2 fs-5 text-center fw-semibold rounded-0 mw-500px">
                    <smart-button #exportexcelbutton id="xlsxBtn"><i
                            class='fas fa-file-export fs-7 text-dark '></i>Excel</smart-button>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="card mt-4">
          <div class="p-4">
            <smart-grid [paging]="paging" [pager]="pager" [layout]="layout" [behavior]="behavior"
              [dataSource]="reportgridsource" [columns]="columns" [appearance]="appearance" #reportgrid id="reportgrid"
              class="w-100"></smart-grid>
          </div>
        </div>
      </ng-template>
      <ng-template #detail>
        <div class="card">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 mx-3 py-3">
              <label class="form" >Name</label>
              <input type="text" [(ngModel)]="QueryName" class="form-control col-lg-4">
              <label class="mb-2 fs-6">Description</label>
              <textarea class="form-control p-2 border-secondary fs-7 fw-normal" [(ngModel)]="QueryDescription"></textarea>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6"></div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>