import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class OptionsService {
  token_response: any = {};
  token: any;
  formComponentOptionId: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
  }

  get_ComponentDeatilsByComponentId(componentdetails: any) {
    return this.http.get(environment.GetComponentDeatilsByComponentId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + componentdetails.formid + '/' + componentdetails.sectionid + '/' + componentdetails.componentid
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_ComponentOptionsByFormComponentid(componentdetails: any) {
    return this.http.get(environment.GetFormComponentOptionsByFormComponentId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + componentdetails.formid + '/' + componentdetails.sectionid + '/' + componentdetails.componentid
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_ComponentOptionsDetailsByComponentoptionid(Optiondetails: any) {
    return this.http.get(environment.GetComponentOptionDeatilsByComponentOptionId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + Optiondetails.formid + '/' + Optiondetails.sectionid + '/' + Optiondetails.componentid
      + '/' + Optiondetails.componentoptionid).pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));;
  }

  save_FormComponentOptions(newOption: any) {
    return this.http.post(environment.SaveOrUpdateFormComponentOptions, newOption, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  update_FormComponentOptionsDisplayOrder(optionlist: any) {
    return this.http.post(environment.UpdateFormComponentOptionsDisplayOrder, optionlist, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}
