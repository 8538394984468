  <div class="menu-item px-3">
    <div class="menu-content d-flex align-items-center px-3">
      <div class="symbol symbol-50px me-5">
        <img alt="Logo" src="\assets\media\avatars\300-1.jpg" />
      </div>

      <div class="d-flex flex-column">
        <div class="-flex align-items-center fs-5">
          {{UserRole}}
        </div>
        <a class="text-muted text-hover-primary fs-7 cursor-pointer">
{{UserEamil}}
        </a>
      </div>
    </div>
  </div>

  <div class="separator my-2"></div>

  <div class="menu-item px-1">
    <a class="menu-link fw-100" (click)="redirectToProfile()">My
        Profile</a>
</div>
  <div class="menu-item px-1">
    <a class="menu-link fw-100">My
        Statements</a>
</div>

  <div
    class="menu-item px-5"
    data-kt-menu-trigger="hover"
    data-kt-menu-placement="left-start"
    data-kt-menu-flip="bottom">
   
    <div class="separator my-2"></div>
    <div class="menu-sub menu-sub-dropdown w-175px py-4">
      <div class="menu-item px-3">
        <a class="menu-link px-5 cursor-pointer" placement="top-start" ngbTooltip="Coming soon">Referrals</a>
      </div>

      <div class="menu-item px-3">
        <a class="menu-link px-5 cursor-pointer" placement="top-start" ngbTooltip="Coming soon">Billing</a>
      </div>

      <div class="menu-item px-3">
        <a class="menu-link px-5 cursor-pointer" placement="top-start" ngbTooltip="Coming soon">Payments</a>
      </div>

      <div class="menu-item px-3">
        <!-- <a class="menu-link d-flex flex-stack px-5 cursor-pointer" placement="top-start" ngbTooltip="Coming soon">
          Statements
          <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="View your statements"
          ></i>
        </a> -->
      </div>


      <div class="menu-item px-3">
        <div class="menu-content px-3">
          <label
            class="form-check form-switch form-check-custom form-check-solid"
          >
            <input
              class="form-check-input w-30px h-20px"
              type="checkbox"
              value="1"
              defaultChecked="{true}"
              name="notifications"
            />
            <span class="form-check-label text-muted fs-7 fw-100">Notifications</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="menu-item px-5">
    <a class="menu-link px-5 cursor-pointer" placement="top-start" ngbTooltip="Coming soon">My Statements</a>
  </div> -->


  <div
    class="menu-item px-5"
    data-kt-menu-trigger="hover"
    data-kt-menu-placement="left-start"
    data-kt-menu-flip="bottom"
  >


    <div class="menu-sub menu-sub-dropdown w-175px py-4">
      <div
        *ngFor="let l of langs"
        class="menu-item px-3 cursor-pointer"
        (click)="selectLanguage(l.lang)"
      >
        <a
          class="menu-link d-flex px-5"
          [ngClass]="l.lang === language.lang ? 'active' : ''"
        >
          <span class="symbol symbol-20px me-4">
            <img class="rounded-1" [src]="l.flag" alt="metronic" />
          </span>
          {{ l.name }}
        </a>
      </div>
    </div>
  </div>

  <div class="menu-item px-1 my-1">
    <a class="menu-link fw-100">Account
        Settings</a>
</div>

<div class="menu-item px-1">
  <a class="menu-link fw-100" routerLink="logout">Sign Out</a>
</div>
