<div
  class="d-flex flex-column bgi-no-repeat mt-10 fw-bolder">
  <h3 class="text-dark fw-semibold px-5 my-4">Notifications
  </h3>
  <div class="separator my-0"></div>

  <div class="tab-content">
    <!--begin::Tab panel-->
    <div class="tab-pane fade show active" id="kt_topbar_notifications_1" role="tabpanel">
        <!--begin::Items-->
        <div class="scroll-y mh-325px my-2 px-8">
            <!--begin::Item-->
            <div class="d-flex flex-stack py-4">
                <!--begin::Section-->
                <div class="d-flex align-items-center me-2">
                    <!--begin::Title-->
                    <a href="#"
                        class="text-gray-800 text-hover-primary fw-semibold">Notification 1</a>
                    <!--end::Title-->
                </div>
                <!--end::Section-->
                <!--begin::Label-->
                <span class="badge badge-light fs-8">Just now</span>
                <!--end::Label-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex flex-stack py-4">
                <!--begin::Section-->
                <div class="d-flex align-items-center me-2">
                    <!--begin::Title-->
                    <a href="#"
                        class="text-gray-800 text-hover-primary fw-semibold">Notification 2</a>
                    <!--end::Title-->
                </div>
                <!--end::Section-->
                <!--begin::Label-->
                <span class="badge badge-light fs-8">2 hrs</span>
                <!--end::Label-->
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="d-flex flex-stack py-4">
                <!--begin::Section-->
                <div class="d-flex align-items-center me-2">
                    <!--begin::Title-->
                    <a href="#"
                        class="text-gray-800 text-hover-primary fw-semibold">Notification 3</a>
                    <!--end::Title-->
                </div>
                <!--end::Section-->
                <!--begin::Label-->
                <span class="badge badge-light fs-8">5 hrs</span>
                <!--end::Label-->
            </div>
            <!--end::Item-->
            <!--end::Item-->
        </div>
        <!--end::Items-->
        <!--begin::View more-->
        <div class="py-3 text-center border-top">
            <a href="../../demo1/dist/pages/user-profile/activity.html"
                class="btn btn-color-gray-600 btn-active-color-primary">View All
                <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                <span class="svg-icon svg-icon-5">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2"
                            rx="1" transform="rotate(-180 18 13)"
                            fill="currentColor" />
                        <path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor" />
                    </svg>
                </span>
                <!--end::Svg Icon-->
            </a>
        </div>
        <!--end::View more-->
    </div>
    <!--end::Tab panel-->
</div>
<!--end::Tab content-->
</div>
