<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush toolbar-bar">
            <!--begin::Card header-->
            <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1 fs-5 text-white">History Of Events</h3>
                </div>
                <!--begin::Card title-->
                <!--begin::Card toolbar-->

            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body bg-white pt-0 px-5">
                <!--begin::Table container-->
                <div class="table-responsive">
                    <!--begin::Table-->
                    <table id="kt_profile_overview_table_allContacts"
                        class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                        <!--begin::Head-->
                        <thead class="fs-7 text-gray-400 text-uppercase">
                            <tr class="text-primary">
                                <th class="min-w-100px">Date</th>
                                <th class="min-w-100px">Time</th>
                                <th class="min-w-100px">Lifespark Provider</th>
                                <th class="min-w-150px">Type Of Session</th>
                                <th class="min-w-100px">Status</th>
                            </tr>
                        </thead>
                        <!--end::Head-->
                        <!--begin::Body-->
                        <tbody class="fs-7">
                            <tr class="fw-normal" *ngFor="let historyrecords of historyRecords">
                                <td class="p-2">
                                    {{historyrecords.date}}
                                </td>
                                <td class="p-2">
                                    {{historyrecords.time}}
                                </td>
                                <td class="p-2">
                                    {{historyrecords.provider}}
                                </td>
                                <td class="p-2">
                                    {{historyrecords.session}}
                                </td>
                                <td class="p-2">
                                    {{historyrecords.status}}
                                </td>
                            </tr>
                        </tbody>
                        <!--end::Body-->
                    </table>
                    <!--end::Table-->
                </div>
                <!--end::Table container-->
            </div>
            <!--end::Card body-->
        </div>
    </div>
</div>
<!--end::Content-->

<!--end::Table container-->
<!--end::Card body-->