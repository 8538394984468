<div class="app-main flex-column flex-row-fluid">
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <!--begin::Content-->
        <div id="kt_app_content" class="app-content flex-column-fluid p-4">
            <!-- <div class="row">
                <div class="col-lg-1 col-md-2 col-sm-2">
                    <label class="fs-6 fw-semibold form-label ms-5 mt-2">Locations</label>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <ng-select data-control="select2" data-hide-search="true" [(ngModel)]='locationId'
                        [clearable]="false" data-kt-select2="true" (change)="get_volunteersByLocationId($event)"
                        data-placeholder="Select option" data-allow-clear="true" placeholder="Select Location">
                        <ng-option value="">Select Location</ng-option>
                        <ng-option *ngFor='let item of locationsList' [value]="item.CRMCustomerId" class="text-dark">
                            {{item.CompanyName}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4"></div>
            </div> -->
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="fs-6 fw-semibold form-label">Locations</label>
                    <ng-select data-control="select2" data-hide-search="true" [(ngModel)]='locationId'
                        [clearable]="false" data-kt-select2="true"
                        data-placeholder="Select option" data-allow-clear="true" placeholder="Select Location">
                        <ng-option value="">Select Location</ng-option>
                        <ng-option *ngFor='let item of locationsList' [value]="item.CRMCustomerId" class="text-dark">
                            {{item.CompanyName}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="fs-6 fw-semibold form-label">Start Date</label>
                    <!-- <input type="date" autocomplete="off" [(ngModel)]="startDate" class="form-control p-1 fs-6"> -->
                    <smart-date-time-picker #datetimepicker3 [(ngModel)]="startDate" [calendarButton]="true" [enableMouseWheelAction]="true" [interval]="'new smartUtilities.TimeSpan(24, 0, 0)'"
                    [dropDownPosition]="'center-bottom'"
                    [editMode]="'partial'" formatString="MM/dd/yyyy"  [dropDownDisplayMode]="'calendar'" class="w-100"></smart-date-time-picker>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="fs-6 fw-semibold form-label">End Date</label>
                    <!-- <input type="date" autocomplete="off" [(ngModel)]="endDate" class="form-control p-1 fs-6"> -->
                    <smart-date-time-picker #datetimepicker2 [(ngModel)]="endDate" [calendarButton]="true" [enableMouseWheelAction]="true" [interval]="'new smartUtilities.TimeSpan(24, 0, 0)'"
                    [dropDownPosition]="'center-bottom'" 
                    [editMode]="'partial'" formatString="MM/dd/yyyy"  [dropDownDisplayMode]="'calendar'" class="w-100"></smart-date-time-picker>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1">
                    <label class="fs-6 fw-semibold form-label">Mile Radius</label>
                    <input type="number" autocomplete="off" (keydown)="onKeyDown($event)" [(ngModel)]="mileRadius" class="form-control p-1">
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <button class="btn btn-primary btn-sm py-2 mt-8" (click)="GetParticipantsByRadius()">Run</button>
                </div>
            </div>
            <div>
                <div class="card p-3 mt-2">
                    <!-- <div class="row"> -->
                        <!-- <div class="col-lg-12 col-md-12 col-sm-12"> -->
                            <!-- <h1
                                class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                List of Benificiaries</h1> -->
                            <div class="mt-3">
                                <smart-grid [paging]="paging" [pager]="pager" [sorting]="sorting"
                                    [filtering]="filtering" [layout]="layout" [behavior]="behavior" [selection]="selection"
                                    [dataSource]="benificiariesgridsource" [columns]="benificiariescolumns"
                                    [appearance]="appearance" #benificiariesgrid id="benificiariesgrid" (rowDoubleClick)="onRowDoubleClick($event)"
                                    class="w-100" style="height: calc(100vh - 300px);"></smart-grid>
                                <div class="float-end mt-0">Total Number of Records in Table: {{participantsLen}}</div>
                            </div>
                        <!-- </div> -->
                        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                            <h1
                                class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                                List of Volunteers</h1>
                            <div class="mt-3">
                                <smart-grid [paging]="volpaging" [sorting]="volsorting" [filtering]="volfiltering"
                                     [pager]="volpager" [layout]="vollayout"
                                    [behavior]="volbehavior" [dataSource]="volunteersgridsource"
                                    [columns]="volunteercolumns" [appearance]="volappearance" #volunteersgrid
                                     id="volunteersgrid"
                                    class="w-100" style="height: calc(100vh - 270px);"></smart-grid>
                                <div class="float-end mt-0">Total Number of Records in Table: {{volunteersLen}}</div>
                            </div>
                        </div> -->
                     
                    <!-- </div> -->
                    <div class="text-end py-2" *ngIf="participantsLen != 0">
                        <button type="button" class="btn background-primary px-2 py-2 fs-7 btn-sm text-white" [disabled]="btnSave" (click)="SaveMatchingStatus()">
                            <i class="fa fa-save fs-7 text-white"></i>Save</button>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
</div>