<div class="app-main flex-column flex-row-fluid ">
    <div class="d-flex flex-column flex-column-fluid">
        <div class="app-toolbar pt-3">
            <!-- <div class="container-fluid d-flex flex-stack ps-3 "> -->
            <div class="row">
                <div class="col-lg-10 col-md-10 col-sm-10">
                    <div class="d-flex">
                        <label
                            class="d-flex align-items-center fs-6 fw-semibold form-label ms-5 me-5 pt-1 mt-1 labelsize">
                            Forms
                        </label>
                        <div class="w-300px">
                            <select data-control="select2" data-hide-search="true" data-kt-select2="true"
                                data-placeholder="Select option" data-allow-clear="true"
                                (change)="get_formdetailsdropdown($event)" [(ngModel)]="tempformid"
                                class="form-select form-select-solid p-2 bg-white">
                                <option value="" disabled>Select Form</option>
                                <option *ngFor="let item of formslists" value="{{item.FormId}}">{{item.FormName}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 text-end" *ngIf="UserRole == 'Admin'">

                    <a class="btn background-success text-white  p-2 fs-5 text-center fw-semibold rounded-0 mw-500px"
                        (click)=" openmodal(addsection)">
                        <i class="fa fa-plus p-2 fs-7 text-white me-1 text-center"></i>New Section</a>

                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- 
        <div class="row m-0" *ngIf="UserRole == 'Admin'">
            <div class="col-lg-12 col-md-12 col-sm-12 text-end mt-2">
                <a class="btn background-success text-white  p-2 fs-5 text-center fw-semibold rounded-0 mw-500px"
                    (click)=" openmodal(addsection)">
                    <i class="fa fa-plus p-2 fs-7 text-white me-1 text-center"></i>New Section</a>
            </div>
        </div> -->
        <div *ngFor="let list of get_formdetails">
            <h3 class="col-lg-10 mt-2 ps-12 fw-bold">{{list.FormName}}</h3>
        </div>
        <div class="card-body" cdkDropListGroup>
            <div class="row m-0" class="dragnddropcontainer" *ngFor="let list of get_formdetails">
                <div cdkDropList [cdkDropListData]="list.FormSectionModelList" class="example-list pb-20"
                    (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="issectionPredicate">
                    <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let section of list.FormSectionModelList;"
                        class="dragndbox" cdkDrag [cdkDragData]="section" [class.item__moved]="section.FormSectionName">
                        <div class="card cardscroll">
                            <div class="card-header px-1 min-h-30px background-secondary cursormove">
                                <div class="card-title">
                                    <h3 class="fs-5 fw-semibold ms-1 text-white text-truncate mw-500px">
                                        {{section.FormSectionName}}</h3>
                                    <h3 class="fs-5 fw-semibold ms-1 text-white text-truncate mw-500px"
                                        *ngIf="section.FormComponentsIdModelList !=null">
                                        ({{section.FormComponentsIdModelList.length}})</h3>
                                </div>
                                <div class="d-flex align-items-center gap-2 gap-lg-3 me-3" *ngIf="UserRole == 'Admin'">
                                    <i class="fa fa-pencil fs-7 text-white cursor-pointer "
                                        (click)="openmodal(editsection)"
                                        (click)="get_sectiondetails(section.FormSectionId)" data-toggle="tooltip"
                                        title="Edit Section"></i>
                                    <i class="fa fa-plus fs-7 text-white cursor-pointer"
                                        (click)="editsectiondetails(addcomponent,section.FormSectionId)"
                                        data-toggle="tooltip" title="Add Component"></i>
                                </div>
                            </div>
                            <div class="componentscroll">
                                <div class="card-body vault-list-h px-5 py-2" cdkDropList #desk
                                    id="{{section.FormSectionName}}"
                                    [cdkDropListData]=" section.FormComponentsIdModelList" class="component-list"
                                    (cdkDropListDropped)="dropcomponent($event)"
                                    [cdkDropListEnterPredicate]="iscomponentPredicate">
                                    <ul class="list-unstyled " cdkDrag [cdkDragData]="section"
                                        *ngFor="let component of section.FormComponentsIdModelList; let i = index">
                                        <div>
                                            <li class="border-bottom ">
                                                <div class="row py-2 cursormove">
                                                    <div class="col-lg-8 col-md-11 col-sm-11">
                                                        <label
                                                            class="text-dark px-2 labelsize">{{component.FormComponentName}}</label>
                                                    </div>
                                                    <div class="col-lg-4 col-md-1 col-sm-1" *ngIf="UserRole == 'Admin'">
                                                        <div class="d-flex float-end">
                                                            <div>
                                                                <div> <i (click)="redirecttoOptions(section.FormSectionId,component.FormComponentId)"
                                                                        data-toggle="tooltip" title="Edit Component"
                                                                        class="fa fa-pencil me-3 fs-7 text-secondary cursor-pointer me-1"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #addsection let-modal>
        <div class="modal-lg">
            <div class="modal-header modal-lg background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrSection}}</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                    (click)="clearmodals()"></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <label class="py-1 text-dark fs-5 text-end">Section</label>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <input class="form-control p-2 fs-7" [(ngModel)]="newsection.FormSectionName"
                            placeholder="Enter Section">
                        <div class="mt-3">
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1"
                                    [(ngModel)]="newsection.IsRequired" />
                                <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                            </label>
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1"
                                    [(ngModel)]="newsection.IsActive" />
                                <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                            </label>
                            <label class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" name="toc" value="1"
                                    [(ngModel)]="newsection.IsArchived" />
                                <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal" (click)="saveformsection(newsection)"><i
                        class="fa fa-save fs-7 text-white"></i>
                    {{btnSection}}</button>
            </div>
        </div>
    </ng-template>
    <ng-template #editsection let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Edit Section</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Section</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" [(ngModel)]="sectiondetails.FormSectionName"
                        placeholder="Enter Section">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="sectiondetails.IsRequired" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="sectiondetails.IsActive" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="sectiondetails.IsArchived" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                (click)="updateformsection(sectiondetails)" data-bs-dismiss="modal"><i
                    class="fa fa-save fs-7 text-white"></i> Update Section</button>
        </div>
    </ng-template>

    <ng-template #addcomponent let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Add Component</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <div class="row pb-4">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Component</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" [(ngModel)]="newcomponent.FormComponentName"
                        placeholder="Enter Component">
                </div>
            </div>
            <div class="row pb-4">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5">What kind of data goes in this field?</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <ng-select data-control="select2" data-hide-search="true"
                        [(ngModel)]="newcomponent.FormComponentDataType" data-kt-select2="true"
                        data-placeholder="Select option" data-allow-clear="true" placeholder="Select Datatype"
                        [clearable]="false">
                        <ng-option value="" disabled>Select Data Type</ng-option>
                        <ng-option *ngFor='let datatype of datatypes' [value]="datatype">{{datatype}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5">How do you enter data in this field?</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <ng-select data-control="select2" data-hide-search="true"
                        [(ngModel)]="newcomponent.FormComponentOptionType" [clearable]="false" data-kt-select2="true"
                        data-placeholder="Select option" data-allow-clear="true">
                        <ng-option value="" disabled>Select Data Type</ng-option>
                        <ng-option *ngFor='let option of options' [value]="option">{{option}}</ng-option>
                    </ng-select>
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="newcomponent.IsRequired" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="newcomponent.IsActive" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" name="toc" value="1"
                                [(ngModel)]="newcomponent.IsArchived" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal" (click)="saveformcomponent(newcomponent)"><i
                    class="fa fa-save fs-7 text-white"></i> Save Component</button>
        </div>
    </ng-template>

    <ng-template #delete let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Delete</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <h3 class="fs-6 fw-semibold">Do you want to delete component?</h3>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal"><i class="fa fa-trash fs-7 text-white"></i> Delete</button>
        </div>
    </ng-template>
</div>