import {ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';

@Component({
  selector: 'app-search-result-inner',
  templateUrl: './search-result-inner.component.html',
})
export class SearchResultInnerComponent implements OnInit {
  @HostBinding('class') class = 'menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';
  @HostBinding('attr.data-kt-search-element') dataKtSearch = 'content';

  resultModels: Array<ResultModel> = resultModels;
  recentlySearchedModels: Array<ResultModel> = recentlySearchedModels;

  keyword: string = '';
  searching: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  search(keyword: string) {
    this.keyword = keyword;
    this.searching = true;

    setTimeout(() => {
      this.searching = false;
      this.cdr.detectChanges();
    }, 1000);
  }

  clearSearch() {
    this.keyword = '';
  }
}

interface ResultModel {
  icon?: string;
  image?: string;
  title: string;
  description: string;
}

const resultModels: Array<ResultModel> = [
  {
    'image': './assets/media/avatars/300-6.jpg',
    'title': 'Karina Clark',
    'description': 'Marketing Manager'
  },
  {
    'image': './assets/media/avatars/300-2.jpg',
    'title': 'Olivia Bold',
    'description': 'Software Engineer'
  },
  {
    'image': './assets/media/avatars/300-9.jpg',
    'title': 'Ana Clark',
    'description': 'UI/UX Designer'
  },
  {
    'image': './assets/media/avatars/300-14.jpg',
    'title': 'Nick Pitola',
    'description': 'Art Director'
  },
  {
    'image': './assets/media/avatars/300-11.jpg',
    'title': 'Edward Kulnic',
    'description': 'System Administrator'
  }
];

const recentlySearchedModels: Array<ResultModel> = [
  {

    'title': 'John',

    'description': 'John@gmail.com'

  }, {

    'title': 'Max Smith',

    'description': 'maxsmith@gmail.com'

  },
];
