import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { GridComponent, DataAdapter, GridColumn, Smart } from 'smart-webcomponents-angular/grid';
import { MembersService } from 'src/app/services/members.service';
import { Members } from '../member';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/SharedService.service';
@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.scss']
})
export class VolunteersComponent implements OnInit {
  @ViewChild('exportexcelbutton', { read: ButtonComponent, static: false }) xlsxBtn!: ButtonComponent;
  @ViewChild('contactsgrid', { read: GridComponent, static: false }) grid!: GridComponent;
  @ViewChild('volunteers', { static: false }) isVolunteerscheck: ElementRef<HTMLInputElement>;

  filterTerm: string;
  memberslist: any;
  members: Members[] | any = [];
  token_response: any = {};
  token: any;
  UserRole:any = "";
  customerType:string = "Provider";
  statusType:string = "Active";
  constructor(public router: Router,
    private memberService: MembersService,
    public toastralert: ToastralertService,
    public config: NgbModalConfig,
    private spinner: NgxSpinnerService,private sharedService: SharedService
    ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.UserRole = this.token_response.UserRole;
    this.sharedService.setActiveTab('Volunteers');
    this.GetCRMCustomersByCustomerType(this.customerType,this.statusType);
  }

  ngAfterViewInit(): void { 
    this.redirections();
  }

  
  ngOnDestroy() {
    const columnMenus = Array.from(document.querySelectorAll('.smart-grid-column-menu.open'))
    columnMenus.forEach(menu => menu.classList.remove('open'))
    const searchPanel = document.querySelector('.smart-data-view-header-drop-down.search-panel.open');
    searchPanel?.remove();
  }

  appearance = {
    alternationStart: 0,
    alternationCount: 2,
    allowSortAnimation: true
  }

  filtering = {
    enabled: true,
    filterRow: {
      visible: true
    }
  }

  sorting = {
    enabled: true,
    mode: 'many'
  }

  grouping = {
    enabled: true,
    renderMode: 'compact',
    groupBar: {
      visible: true
    }
  }

  layout: object = {
    rowHeight: 35
  }

  behavior = {
    allowColumnReorder: true
  }

  pager = {
    visible: true
  }

  paging = {
    enabled: true,
    pageSize: 100
  }

  header = {
    visible: true,
  }

  GetCRMCustomersByCustomerType(customerType:any,statusType:any) {
    this.spinner.show();
    this.memberService.GetCRMCustomersByCustomerType(customerType,statusType)
      .subscribe(results => {
        if (results != null && results != 'undefined') {
          this.spinner.hide();
          this.members = results;
          this.contactsgrid.dataSource = results;
          this.contactsgridsource = results;
          console.log( this.members);
          if(this.UserRole != "Volunteer"){
            this.grid.setColumnProperty("CRMPersonId","visible",true);
          }
          else{
            this.grid.setColumnProperty("CRMPersonId","visible",false);
          }
        }
      },
        (err) => {
          this.spinner.hide();
          this.toastralert.toastrerrorbottom("Unable to get section details.Please try after some time.");
        });
  }

  Redirecttocontactprofile() {
    this.router.navigate(['profiles/contactprofile']);
  }

  contactsgrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: [
        'FirstName: string',
        'LastName: string',
        'EMAIL: string',
        'PHONENUMBER: string',
        'Address1: string',
        'CITY: string',
        'STATE: string',
        'ZIPCODE: string',
        'CRMPersonId:string',
      ]
    })

  contactsgridsource: any = this.contactsgrid;
  columns =
    [
      { label: 'First Name', width: '10%', minWidth: 70, dataField: 'FirstName', align: 'center', columnGroup: 'name' },
      { label: 'Last Name', width: '10%', minWidth: 70, dataField: 'LastName', align: 'center', columnGroup: 'name' },
      { label: 'Email', width: '18%', minWidth: 70, dataField: 'EMAIL', align: 'center', columnGroup: 'name' },
      { label: 'Phone Number', width: '12%', minWidth: 70, dataField: 'PHONENUMBER', align: 'center', columnGroup: 'name' },
      { label: 'Address', width: '10%', minWidth: 70, dataField: 'Address1', align: 'center', columnGroup: 'name' },
      { label: 'City', width: '10%', minWidth: 70, dataField: 'CITY', align: 'center', columnGroup: 'name' },
      { label: 'State', width: '10%', minWidth: 70, dataField: 'STATE', align: 'center', columnGroup: 'name' },
      { label: 'Zipcode', width: '10%', minWidth: 70, dataField: 'ZIPCODE', align: 'center', columnGroup: 'name'},
      {
        label: 'Action', dataField: '', align: 'center',
         template: '<div style="margin-left:10px;" class=" fa fa-pencil cursor-pointer redirect-row-button" data-toggle="tooltip" title="Edit Profile"></div>', columnGroup: 'name'
      },
      // {
      //   label: 'Action', dataField: 'CRMPersonId', align: 'center',
      //   template: (formatObject:any) => {
      //     console.log(formatObject);
      //     if(this.token_response.CRMPersonId == formatObject.value){
      //       formatObject.template = '<div style="margin-left:10px;" class=" fa fa-pencil cursor-pointer redirect-row-button" data-toggle="tooltip" title="Edit Profile"></div>';
      //     }
      //     else{
      //       formatObject.template = '<div style="margin-left:10px;" data-toggle="tooltip" title="Edit Profile"></div>';
      //     }
      //   },
      // },
    ]

  columnGroups = [
    { label: 'Drag a column header here to group by that column', name: 'name' },
  ]

  // ngAfterViewInit(): void {

  // }

  getcrmcustomeridforredirect(member: Members): void {
    this.redirectprofiles(member.CRMCustomerId);
  }

  redirectprofiles(cRMCustomerId: any,) {
    this.router.navigate(['profiles/profile/' + cRMCustomerId]);
  }

  redirections(): void {
    const that = this,
      grid = this.grid;
    grid.addEventListener('rowClick', (event: Event) => {
      if (!isCustomEvent(event))
        throw new Error('not a custom event');
      const button = event.detail.originalEvent.target.closest('div') as HTMLElement;
      if (!button) {
        return;
      }
      const memberIndex = event.detail.row.index;
      if (button.classList.contains('redirect-row-button')) {
        this.getcrmcustomeridforredirect(this.members[memberIndex]);
      }
    });
    function isCustomEvent(event: Event): event is CustomEvent {
      return 'detail' in event;
    }
    this.xlsxBtn.addEventListener('click', () => {
      this.grid.dataExport.fileName = "Volunteers";
      this.grid.exportData('xlsx');
    });
  }

  getVolunteers(){
    const isChecked = this.isVolunteerscheck.nativeElement.checked;
    if(isChecked){
      this.statusType = "Active";
    }
    else{
      this.statusType = "All";
    }
    this.GetCRMCustomersByCustomerType(this.customerType,this.statusType);
    console.log('Checkbox value:', isChecked);
  }

}
