import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/services/profile.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateTimePickerComponent } from 'smart-webcomponents-angular/datetimepicker';
type Tabs = 'Profile' | 'Timeline' | 'Availability' | 'RelationShips';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  @ViewChild('datetimepicker3', { read: DateTimePickerComponent, static: false }) datetimepicker3!: DateTimePickerComponent;
  @ViewChild('addnewnote',{static:true}) addnewnote!: any;
  faUserCircle = faUserCircle;
  faTimeline = faTimeline;
  faTimesCircle = faClock;
  activeTab: Tabs = 'Profile';
  configLoading: boolean = false;
  resetLoading: boolean = false;
  cRMCustomerid: any;
  profilelist: any = { PrimaryContact: '' };
  phonenumberlist: any;
  Addresslist: any;
  timelinedetails: any;
  faEdit = faEdit;
  Emaillist: any;
  closeResult: any;
  token_response: any;
  token: any;
  userRole:any = "";
  hdrNote:any = "Add New Note";
  btnNote:any = "Save";
  notesModel:any = { CRMNoteId: '', NotesDate: '', NoteDescription: ''};
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private profileService: ProfileService,
    public config: NgbModalConfig,
    public toastralert: ToastralertService,
    private timelineService: TimelineService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.userRole = this.token_response.UserRole;
    this.route.params.subscribe(params => {
      this.cRMCustomerid = params['cRMCustomerId'];
    });
    this.get_profiledetails();
    this.setActiveTab('Timeline');
    this.get_CRMTimeLinesByCRMCustomerId();
  }

  openmodal(content: any) {
		this.modalService.open(content, { size: 'md', centered: true }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	openSaveOrUpdateNote(noteId: any) {
     if(noteId != ''){
        this.hdrNote = "Edit Note";
        this.btnNote = "Update";
        this.notesModel.CRMNoteId = noteId;
        // this.modalService.open(this.addnewnote, { size: 'md', centered: true });
        this.GetCRMTimeLinesByCRMTimeLineId(noteId);
     }
     else{
      this.hdrNote = "Add New Note";
      this.notesModel.NotesDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
      this.btnNote = "Save";
      // this.modalService.open(this.addnewnote, { size: 'md', centered: true });
     }
	}

  closeNotesModel(){
    this.hdrNote = "Add New Note";
    this.btnNote = "Save";
    this.notesModel.CRMNoteId = '';
    this.notesModel.NoteDescription = '';
    this.notesModel.CRMNoteId = '';
    this.notesModel.NotesDate = '';
    this.modalService.dismissAll();
  }

  SaveOrUpdateCRMNotes(notesModel:any){
    this.spinner.show();
    if ((typeof notesModel.NoteDescription == 'undefined' || notesModel.NoteDescription == "" || notesModel.NoteDescription == null)) {
      this.toastralert.toastrwarnningbottomright("Please Enter Note");
      this.spinner.hide();
      return false;
    }
    else if ((typeof notesModel.NotesDate == 'undefined' || notesModel.NotesDate == "" || notesModel.NotesDate == null)) {
      this.toastralert.toastrwarnningbottomright("Please Select Date");
      this.spinner.hide();
      return false;
    }
    else {
      let notesModeldetails:any = {};
      notesModeldetails = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        CRMCustomerId: this.cRMCustomerid,
        NotesDate: this.datePipe.transform(notesModel.NotesDate, 'MM/dd/yyyy'),
        NoteDescription: notesModel.NoteDescription,
      };
      if(notesModel.CRMNoteId != '' && notesModel.CRMNoteId != undefined && notesModel.CRMNoteId != null){
        notesModeldetails['CRMNoteId'] = notesModel.CRMNoteId;
      }

      this.timelineService.SaveOrUpdateCRMNotes(notesModeldetails).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          if(notesModel.CRMNoteId != '' && notesModel.CRMNoteId != undefined && notesModel.CRMNoteId != null){
            this.toastralert.toastersuccesstop("Note Updated Successfully.");
          }
          else{
            this.toastralert.toastersuccesstop("Note Added Successfully.");
          }
          this.get_CRMTimeLinesByCRMCustomerId();
          this.closeNotesModel();
          this.spinner.hide();
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving Notes');
          this.spinner.hide();
        }

      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save Notes.Please try after some time.");
          this.spinner.hide();
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  get_profiledetails() {
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "") {
        this.profilelist = results;
        this.phonenumberlist = this.profilelist.CRMPhoneNumbersModelList;
        this.Addresslist = this.profilelist.CRMAddressModelList;
        this.Emaillist = this.profilelist.CRMEmailsModelList;
      }
      else {
        this.toastralert.toastrerror('Something went wrong!');
      }
    },
      (err) => {
        console.log(err);
        this.toastralert.toastrerror('Unable to get profile details.Please try after some time.');
      }
    );
  }

  GetCRMTimeLinesByCRMTimeLineId(timelineId:any) {
    this.timelineService.GetCRMTimeLinesByCRMTimeLineId(this.cRMCustomerid, timelineId)
      .subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.notesModel = results;
          this.notesModel.NoteDescription = results.TimelineDescription;
          this.notesModel.NotesDate = new DatePipe('en-US').transform(results.TimelineDate, 'yyyy-MM-dd');
          this.notesModel.CRMNoteId = results.CRMTimeLineId;

          console.log(results);
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to get section details.Please try after some time.");
        });
  }

  get_CRMTimeLinesByCRMCustomerId() {
    this.spinner.show();
    this.timelineService.Get_CRMTimeLinesByCRMCustomerId(this.cRMCustomerid)
      .subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.timelinedetails = results;
          console.log(results);
          this.spinner.hide();
        }
        else{
          this.spinner.hide();
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to get section details.Please try after some time.");
          this.spinner.hide();
        });
  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  redirecttoavailability() {
    if(this.token_response.UserRole != "Volunteer"){
      this.router.navigate(['profiles/availability/' + this.cRMCustomerid]);
    }
    else{
      this.router.navigate(['myprofile/availability/' + this.cRMCustomerid]);
    }
  }

  redirecttorelationships() {
    if(this.token_response.UserRole != "Volunteer"){
      this.router.navigate(['profiles/relationships/' + this.cRMCustomerid]);
    }
    else{
      this.router.navigate(['myprofile/relationships/' + this.cRMCustomerid]);
    }
  }

  redirecttoprofile() {
    if(this.token_response.UserRole != "Volunteer"){
      this.router.navigate(['profiles/profile/' + this.cRMCustomerid]);
    }
    else{
      this.router.navigate(['myprofile/' + this.cRMCustomerid]);
    }
  }

  resetPreview(): void {
    this.resetLoading = true;
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }

}
