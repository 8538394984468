<div class="row px-4 py-0 containerpagescroll">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush toolbar-bar">
            <div class="card-header">
                <div class="card-title flex-column">
                    <h3 class="fw-bold  fs-5 text-white">Participant</h3>
                </div>
                <div class="card-toolbar ">
                    <div class="d-flex align-items-center gap-2 gap-lg-3 ">
                        <a class="btn btn-sm  bg-white text-primary p-1">Return To All Participants
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body py-2 px-5">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">
                            First Name:
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter First Name" name="firstName" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">Last
                            Name:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter Last Name" name="lastName" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">Email:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter Email" name="email" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">Phone:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter Phone" name="phone" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">
                            Address:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter Address" name="addressLine" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">City:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter City" name="city" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">State:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter State" name="state" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">Zip
                            Code:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="text" placeholder="Enter Zip Code" name="zipCode" autocomplete="off"
                            class="form-control bg-transparent p-2 fs-8" />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="row p-3">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6 text-end">Gender:</div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <select name="orders" data-control="select2" data-hide-search="true"
                            class="form-select form-select-solid form-select-sm bg-white border-form">
                            <option value="All" selected="selected" disabled>Gender
                            </option>
                            <option value="value1">Female</option>
                            <option value="value2">Male</option>
                            <option value="value3">Other</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="separator my-3">
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">Have you ever used Reiki, Healing Touch or silimar therapies?
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <select name="orders" data-control="select2" data-hide-search="true"
                            class="form-select form-select-solid form-select-sm bg-white border-form">
                            <option value="All" selected="selected" disabled>Select
                            </option>
                            <option value="value1">Yes</option>
                            <option value="value2">No</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">What type of cancer do you have?</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">Please list an emergency contact (name and phone)</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">What is the name and phone number of your doctor?</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">What cancer center ot hodpital do you go to?</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">How did you hear about us?</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="row p-3">
                    <div class="col-lg-8 col-md-6 col-sm-6">
                        <div class="py-1 text-dark fs-6">What are your pronouns?</div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <textarea class="form-control form-control-flush border-form" rows="1" data-kt-element="input"
                            placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
        </div>
        <div class="mt-3">
            <div class="fs-6 text-primary fw-semibold">
                Preferenes:</div>
            <div class="row my-1">
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">Remote sessions</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">In-Person sessions</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">Female Provider</span>
                    </label>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">Male Provider</span>
                    </label>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">Either Male or Female
                            Provider</span>
                    </label>
                </div>
                <div class="col-lg-1 col-md-1 col-sm-1">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base">Other</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="fs-6 text-primary fw-semibold">
                Please mark all that apply:</div>
            <div class="row my-1">
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Cancer
                            patient/survivor</span>
                    </label>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Primary caregiver of
                            cancer patient</span>
                    </label>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="form-check form-check-inline">
                        <input class="form-check-input mt-0" type="checkbox" value="1" />
                        <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Received LifeSpark
                            services in past</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="fs-6 text-primary fw-semibold">
            Availability:</div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Sunday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Monday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Tuesday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Wednesday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Thursday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Friday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>
        <div class="row my-1">
            <div class="col-lg-2 col-md-2 col-sm-2 fs-6 text-primary fw-semibold">
                Saturday</div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Morning</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Afternoon</span>
                </label>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 w-175px">
                <label class="form-check form-check-inline">
                    <input class="form-check-input mt-0" type="checkbox" value="1" />
                    <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Evening</span>
                </label>
            </div>
        </div>

        <div class="float-end">
            <div class="p-4">
                <a id="" class="btn btn-sm btn-primary text-white me-2 mb-5 p-2 float-end">Save
                    Participant</a>
            </div>
        </div>
    </div>
</div>