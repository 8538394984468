<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid ">
        <div class="card card-flush toolbar-bar">
            <!--begin::Card header-->
            <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1 fs-5 text-white">
                        Fall 2022 Community food Drive
                    </h3>
                </div>
                <!--begin::Card title-->
                <!--begin::Card toolbar-->
                <div class="card-toolbar">
                    <!--begin::Actions-->
                    <div class="d-flex align-items-center gap-2 gap-lg-3">
                        <a class="btn btn-sm me-2 p-1  bg-white text-primary" routerLink="/event">
                            Return To All Events
                        </a>
                    </div>
                    <!--end::Actions-->
                </div>
                <!--begin::Card toolbar-->
            </div>
            <!--end::Card header-->

        </div>
    </div>

    <div class="card card-custom">
        <!-- begin::Header -->
        <div class="card-header card-header-stretch overflow-auto ">
            <ul class="
          nav nav-stretch nav-line-tabs
          fw-bold
          border-transparent
          flex-nowrap
        " role="tablist">

                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Details' }"
                        (click)="setActiveTab('Details')" role="tab">
                        Details
                    </a>
                </li>
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Jobs' }"
                        (click)="setActiveTab('Jobs')" role="tab">
                        Jobs
                    </a>
                </li>

            </ul>
        </div>
        <!-- end::Header -->
        <!-- begin::Form -->
        <form class="form">
            <!-- begin::Body -->
            <div class="card-body">
                <div class="tab-content pt-3">

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Details' }">
                        <div class="row">
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Event Title:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 mt-1">
                                        Fall 2022mCommunity Food Drive
                                    </div>
                                </div>

                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Start Date:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Start Date" name="startdate"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">End Date:
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter End Date" name="enddate"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Event Description:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter description here" name="description"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-1 col-md-4 col-sm-4">

                            </div>
                            <div class="col-lg-5 col-md-4 col-sm-4">
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Location
                                            Name:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Last Name" name="lastName"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>

                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            street Address Line 1:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Address1" name="addressLine"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">
                                            Street Address Line 2:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Address2" name="addressLine"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row  p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">City:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter City" name="city" autocomplete="off"
                                            class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">State:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter State" name="state" autocomplete="off"
                                            class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                                <div class="row p-3">
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <div class="py-1 text-dark fs-6 text-end">Zip
                                            Code:</div>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6">
                                        <input type="text" placeholder="Enter Zip Code" name="zipCode"
                                            autocomplete="off" class="form-control bg-transparent p-2 fs-8" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Sidebar -->

                    <!-- begin::Sidebar -->
                    <div class="tab-pane" [ngClass]="{ active: activeTab === 'Jobs' }">
                        <div class="table-responsive">
                            <!--begin::Table-->
                            <table id="kt_profile_overview_table"
                                class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                                <!--begin::Head-->
                                <thead class="fs-6 text-primary text-uppercase">
                                    <tr>
                                        <th class="min-w-150px">Job Title</th>
                                        <th class="min-w-150px">#Needed</th>
                                        <th class="min-w-150px">#assigned</th>
                                        <th class="min-w-150px">Start Date</th>
                                        <th class="min-w-150px">End date</th>
                                        <th class="min-w-150px">active</th>
                                        <th class="min-w-150px"> <a class="btn btn-sm btn-primary me-2 p-1">Add
                                                a
                                                Job</a></th>

                                    </tr>
                                </thead>
                                <!--end::Head-->
                                <!--begin::Body-->
                                <tbody class="fs-7">
                                    <tr class="fw-normal">
                                        <td class="p-2">Food Collection Volunteer</td>
                                        <td class="p-2">10</td>
                                        <td class="p-2">5</td>
                                        <td class="p-2">8/15/2022</td>
                                        <td class="p-2">8/15/2022</td>
                                        <td class="p-2">
                                            <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="1" />
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                                <!--end::Body-->
                            </table>
                            <!--end::Table-->
                        </div>
                    </div>
                    <!-- end::Sidebar -->


                </div>
            </div>
            <!-- end::Body -->

            <!-- begin::Footer -->


            <!-- end::Footer -->
        </form>
        <!-- end::Form -->
    </div>
</div>