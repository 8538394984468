import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GridComponent, DataAdapter, GridColumn, Smart } from 'smart-webcomponents-angular/grid';
import { GetData } from '../matchStatusData';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { MembersService } from 'src/app/services/members.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-match-status',
  templateUrl: './match-status.component.html',
  styleUrls: ['./match-status.component.scss']
})
export class MatchStatusComponent implements OnInit {
  token_response: any = {};
  token: any;
  participantsLen: any = 0;
  matchedlist: any = [];
  closeResult: any;

  constructor(private spinner: NgxSpinnerService, private elementRef: ElementRef, private modalService: NgbModal,
    public toastralert: ToastralertService, private memberService: MembersService) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  @ViewChild('benificiariesgrid', { read: GridComponent, static: false }) benificiariesgrid!: GridComponent;
  @ViewChild('delete', { read: GridComponent, static: false }) delete!: GridComponent;


  ngAfterViewInit(): void {
    // afterViewInit code.
    this.init();
    this.deleteMatchRecord();
  }

  init(): void {
    // init code.

  }

  ngOnInit(): void {
    this.GetMatchingStatus();
  }

  GetMatchingStatus() {
    this.spinner.show();
    this.benificiariesgridsource = [];
    this.participantsLen = 0;
    this.memberService.GetMatchingStatus().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.participantsLen = results.length;
        this.matchedlist = results;
        this.benificiariesgrid.dataSource = results;
        this.benificiariesgridsource = results;
        this.spinner.hide();
        console.log(results);
      }
      if (results.length == 0) {
        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("Unable to get Participants.Please try again after some time.");
      }
    );
  }

  deleteMatchRecord() {
    const that = this,
      grid = this.benificiariesgrid;
    grid.addEventListener('rowClick', (event: Event) => {
      if (!isCustomEvent(event))
        throw new Error('not a custom event');
      const button = event.detail.originalEvent.target.closest('div') as HTMLElement;
      if (!button) {
        return;
      }
      const memberIndex = event.detail.row.index;
      if (button.classList.contains('redirect-row-button')) {
        this.DeleteMatchStatus(this.matchedlist[memberIndex]);
      }
    });
    function isCustomEvent(event: Event): event is CustomEvent {
      return 'detail' in event;
    }
  }

  openmodal(content: any) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  DeleteMatchStatus(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this matching record.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id);
        let cRMMatchStatusId = id.CRMMatchStatusId;
        this.SaveDeleteUserProfile(cRMMatchStatusId);
      } else {

      }
    });
  }

  SaveDeleteUserProfile(userId: any) {
    let deleteUserProfile = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMMatchStatusId: userId,
    };
    this.memberService.SaveDeleteMatchingStatusByMatchingId(deleteUserProfile).subscribe((results) => {
      console.log(results);
      if (results != null && results != "" && results != undefined) {
        this.toastralert.toastersuccesstop('Matched record deleted successfully'); 
        this.GetMatchingStatus();
      }
      else {
        this.toastralert.toastrerror('Error occurred while deleting matched data');
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to delete matched data.Please try after some time.");
      }
    );
  }

  appearance = {
    alternationStart: 0,
    alternationCount: 2,
    allowSortAnimation: true
  }

  filtering = {
    enabled: true,
    filterRow: {
      visible: true
    }
  }

  sorting = {
    enabled: true,
    mode: 'many'
  }

  grouping = {
    enabled: true,
    renderMode: 'compact',
    groupBar: {
      visible: true
    }
  }

  layout: object = {
    rowHeight: 35
  }

  behavior = {
    allowColumnReorder: true
  }

  pager = {
    visible: true
  }

  paging = {
    enabled: true,
    pageSize: 100
  }

  benificiariegrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: [
        'CRMMatchStatusId : string',
        'CRMBeneficiaryId: string',
        'CRMBeneficiaryFullName: string',
        'CRMVolunteerId: string',
        'CRMVolunteerFullName: string',
        'AvailableDay:string',
        'AvailableStartTime: string',
        'AvailableEndTime:string',
        'AvailableDayId:string',
        'DateIntake:string',
        'PreferredGender:string',
        'Distance:string',
      ]
    })

  benificiariesgridsource: any = this.benificiariegrid;
  benificiariescolumns =
    [
      { label: 'CRMBeneficiaryId', width: '15%', dataField: 'CRMBeneficiaryId', align: 'center', columnGroup: 'name', visible: false },
      { label: 'Beneficiary Name', width: '15%', dataField: 'CRMBeneficiaryFullName', align: 'center', columnGroup: 'name' },
      { label: 'Preferred Gender', width: '15%', dataField: 'PreferredGender', align: 'center', columnGroup: 'name' },
      {
        label: 'Distance', width: '13%', dataField: 'Distance', align: 'center', columnGroup: 'name',
        formatFunction: function (settings: any) {
          let distance = settings.value != null ? settings.value : 0.000;
          let color = "#FFD700";
          if (distance <= 1) {
            color = "#6B1CB0";
          }
          else if (distance > 1 && distance <= 1.5) {
            color = "#006400";
          }
          else {
            color = "#ff0000";
          }
          const formatedDate = '<span style="margin-right: 7px; padding: 4px 18px; border-radius: 10px 5px; color: #fff; background: ' + color + '";>' + distance + '</span>';
          settings.template = formatedDate;
        },
      },
      { label: 'CRMVolunteerId', width: '15%', dataField: 'CRMVolunteerId', align: 'center', columnGroup: 'name', visible: false },
      { label: 'Volunteer Name', width: '15%', dataField: 'CRMVolunteerFullName', align: 'center', columnGroup: 'name' },
      {
        label: 'Date Intake', width: '8%', dataField: 'DateIntake', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('MM/dd/yyyy') : "";
          settings.template = formatedDate;
        },
      },
      { label: 'Day', width: '8%', dataField: 'AvailableDay', align: 'center', columnGroup: 'name' },
      // {
      //   label: 'Distance', width: '12%', minWidth: 100, dataField: 'Distance', align: 'center', columnGroup: 'name',
      //   formatFunction: function (settings: any) {
      //     let distance = settings.value != null ? settings.value.toFixed(3) : 0.000;
      //     let color = "#FFD700";
      //     if (distance <= 1) {
      //       color = "#6B1CB0";
      //     }
      //     else if (distance > 1 && distance <= 1.5) {
      //       color = "#006400";
      //     }
      //     else {
      //       color = "#ff0000";
      //     }
      //     const formatedDate = '<span style="margin-right: 7px; padding: 4px 18px; border-radius: 10px 5px; color: #fff; background: '+ color +'";>' + distance + '</span>';
      //     settings.template = formatedDate;
      //   },
      // },
      { label: 'AvailableDayId', width: '15%', dataField: 'AvailableDayId', align: 'center', columnGroup: 'name', visible: false },
      {
        label: 'Time Start', width: '8%', dataField: 'AvailableStartTime', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('hh:mm tt') : "";
          settings.template = formatedDate;
        },
      },
      {
        label: 'Time End', width: '8%', dataField: 'AvailableEndTime', align: 'center', columnGroup: 'name', formatFunction: function (settings: any) {
          const formatedDate = settings.value != null ? new Smart.Utilities.DateTime(settings.value).toString('hh:mm tt') : "";
          settings.template = formatedDate;
        },
      },
      {
        label: 'Action', dataField: '', align: 'center',
        template: '<div style="margin-left:10px;" class=" fa fa-trash cursor-pointer redirect-row-button text-danger" data-toggle="tooltip" title="Edit Profile"></div>', columnGroup: 'name'
      },
      // {
      //   label: 'Action', width: '10%', minWidth: 100, dataField: 'CRMMatchStatusId', align: 'center', columnGroup: 'name',
      //   formatFunction: function (settings: any) {
      //     let id = settings.value != null ? settings.value : "";
      //     const formatedDate = '<div style="margin-left:10px;" class=" fa fa-trash cursor-pointer text-danger" data-toggle="tooltip" title="Delete Status"  data-id="${id}" onclick="DeleteMatchStatus(event)"></div>';
      //     settings.template = formatedDate;
      //   },
      // },
    ]


}
