import { Component, ViewChild, OnInit, AfterViewInit, ViewEncapsulation } from "@angular/core";
import { SchedulerComponent, SchedulerEvent, SchedulerViews, Scheduler, SchedulerTimelineDayScale } from "smart-webcomponents-angular/scheduler";
import { Window, WindowComponent } from "smart-webcomponents-angular/window";
import { DateTimePicker, DateTimePickerComponent } from "smart-webcomponents-angular/datetimepicker";
import { DateInputComponent } from 'smart-webcomponents-angular/dateinput';
import { DropDownListComponent } from "smart-webcomponents-angular/dropdownlist";
import { SwitchButtonComponent } from "smart-webcomponents-angular/switchbutton";
import { ButtonComponent } from "smart-webcomponents-angular/button";
import { InputComponent } from 'smart-webcomponents-angular/input';
import { Smart } from 'smart-webcomponents-angular/form';
import { CalendarService } from "src/app/services/calendar.service";
import { FormsService } from "src/app/services/forms.service";
import * as moment from "moment";
import { ToastralertService } from "src/app/services/toastralert.service";
import { NgxSpinnerService } from "ngx-spinner";
import { faM, faVideo } from '@fortawesome/free-solid-svg-icons';
import { RadioButtonComponent } from "smart-webcomponents-angular/radiobutton";
import { NumberInputComponent } from "smart-webcomponents-angular/numberinput";
import { CheckBoxComponent } from "smart-webcomponents-angular/checkbox";
import { TimeInputComponent } from 'smart-webcomponents-angular/timeinput';
import { MenuComponent } from 'smart-webcomponents-angular/menu';


import { isEmpty } from "rxjs";
import Swal from "sweetalert2";
import { ProfileService } from "src/app/services/profile.service";
@Component({
  selector: 'app-schedular',
  templateUrl: './schedular.component.html',
  styleUrls: ['./schedular.component.scss']
})
export class SchedularComponent implements OnInit, AfterViewInit {

  @ViewChild("scheduler", { read: SchedulerComponent, static: false })
  scheduler!: SchedulerComponent;
  @ViewChild("editingWindow", { read: WindowComponent, static: false })
  editingWindow!: WindowComponent;
  @ViewChild("dateStart", { read: DateTimePickerComponent, static: false })
  dateStart!: DateTimePickerComponent;
  @ViewChild("eventLabel", { read: InputComponent, static: false })
  eventLabel!: any;
  @ViewChild("dateEnd", { read: DateTimePickerComponent, static: false })
  dateEnd!: DateTimePickerComponent;
  @ViewChild("endBy", { read: DateInputComponent, static: false })
  endBy!: DateInputComponent;
  @ViewChild("staffInput", { read: DropDownListComponent, static: false })
  staffInput!: DropDownListComponent;
  @ViewChild("repeat", { read: SwitchButtonComponent, static: false })
  repeat!: SwitchButtonComponent;
  @ViewChild("repeatFreqInput", { read: DropDownListComponent, static: false })
  repeatFreqInput!: DropDownListComponent;
  @ViewChild("cancelBtn", { read: ButtonComponent, static: false })
  cancelBtn!: ButtonComponent;
  @ViewChild("submitBtn", { read: ButtonComponent, static: false })
  submitBtn!: ButtonComponent;
  @ViewChild("repeatafter", { read: NumberInputComponent, static: false })
  repeatafter!: NumberInputComponent;
  @ViewChild("onDay", { read: RadioButtonComponent, static: false })
  onDay!: any;
  @ViewChild("onThe", { read: RadioButtonComponent, static: false })
  onThe!: any;
  @ViewChild("monthOccur", { read: NumberInputComponent, static: false })
  monthOccur!: any;
  @ViewChild("selectedWeek", { read: DropDownListComponent, static: false })
  selectedWeek!: DropDownListComponent;
  @ViewChild("selectedDay", { read: DropDownListComponent, static: false })
  selectedDay!: DropDownListComponent;
  @ViewChild("chkMon", { read: CheckBoxComponent, static: true })
  chkMon!: any;
  @ViewChild("chkTue", { read: CheckBoxComponent, static: true })
  chkTue!: any;
  @ViewChild("chkWed", { read: CheckBoxComponent, static: true })
  chkWed!: any;
  @ViewChild("chkThu", { read: CheckBoxComponent, static: true })
  chkThu!: any;
  @ViewChild("chkFri", { read: CheckBoxComponent, static: true })
  chkFri!: any;
  @ViewChild("chkSat", { read: CheckBoxComponent, static: true })
  chkSat!: any;
  @ViewChild("chkSun", { read: CheckBoxComponent, static: true })
  chkSun!: any;
  @ViewChild('contextMenu', { read: MenuComponent, static: false }) contextMenu!: MenuComponent;
  @ViewChild("meetingLink", { read: InputComponent, static: false })
  meetingLink!: any;
  @ViewChild('timeinput', { read: TimeInputComponent, static: false }) timeinput: TimeInputComponent;
  @ViewChild('ddlOrganizers',{static:false}) ddlOrganizers: any;
  @ViewChild('ddlAttendeers',{static:false}) ddlAttendeers: any;

  token_response: any = {};
  token: any;
  schedularnewevent: any = { ScheduleDetailStartDateTime: '', ScheduleDetailEndDateTime: '' }
  schedularlist: any;
  participantslists: any = [{ FullName: '', ParticipantId: '' }];
  label1: any;
  emptydate: any;
  sdate: any;
  edate: any;
  startDate: any;
  endDate: any;
  staffData: any = [];
  participantIdList: any = [];
  selectedParticipants: any;
  staffDataSource: any = [{ FullName: '', ParticipantId: '' }];
  organizerDataSource: any = [{ FullName: '', ParticipantId: '' }];
  savedParticipants: any = [{ FullName: '', ParticipantId: '' }];
  editors: any = [];
  repeatdropdown: any;
  repeatfreq: any;
  startDate1: string;
  windowlabel: any;
  providedMeetingLink: any;
  isMultiEventParicipants: boolean = false;
  public show: boolean = false;
  public show1: boolean = false;
  public show3: boolean = false;
  participantId: any = "";
  faVideo = faVideo;
  scheduleEventsList: any = [];
  btnsubmit: string = 'Save';
  schedulardatasource: any;
  chkweek: any = false;
  savedWeek: any = [0];
  savedDay: any = [1];
  checkOnThe: any = true;
  checkOnDay: any = false;
  disableOnDay: any = true;
  disableOnThe: any = false;
  dbOnDay: any = null;
  dbOnTheDay: any = null;
  dbDay: any = null;
  dbIsSun: any = false;
  dbIsMon: any = false;
  dbIsTue: any = false;
  dbIsWed: any = false;
  dbIsThu: any = false;
  dbIsFri: any = false;
  dbIsSat: any = false;
  dbIsOnTheDay: any = false;
  dbIsOnDay: any = false;
  customScheduleId: any;
  customScheduleDetailId: any;
  customEvent: any;
  isRepeatShow: any = false;
  seriesOrOccurrence: any = 'Occurrence';
  scheduleDetails: any;
  monthOccurValue: number = 1;
  selectedDayIndex: any;
  selectedWeekIndex: any;
  contextMenuEventObj: any = null;
  organizerId: any = "";
  UserRole: string = "";
  finalOrganizerId: any = "";
  isTimeFieldDisabled: any = true;
  isCheckboxDisplayed: any = false;
  CalendarView: any = "";
  chkDisableOrEnable: any = false;
  CalendarStartTime: number = 6;
  CalendarEndTime: number = 21;
  isShowEndTimeEditOption: any = false;
  tenantRoleGUIDs: any = "";
  tenantRoles: any = "";
  isMeetingLableEditable:boolean = false;
  isMeetingLableEditableforSaving:boolean = false;
  isMeetingLableDisabled:boolean = false;
  isAddedOrUpdated:boolean = false;
  constructor(private calendarService: CalendarService, private profileService: ProfileService, private formsService: FormsService, public toastralert: ToastralertService,
    private spinner: NgxSpinnerService,) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
    this.CalendarView = this.token_response.CalendarView;
    this.CalendarStartTime = this.token_response.CalendarStartTime;
    this.CalendarEndTime = this.token_response.CalendarEndTime;
    console.log(this.CalendarView);
  }

  ngOnInit(): void {
    // onInit code.
    // this.Get_SchedularEvents();
    this.UserRole = this.token_response.UserRole;
    // this.isTimeFieldDisabled = this.token_response.IsScheduleEndTimeEditable;
    // this.isCheckboxDisplayed = this.token_response.IsScheduleEndTimeEditable;
    // this.isShowEndTimeEditOption = this.token_response.IsShowEndTimeEditOption;
    this.tenantRoleGUIDs = this.token_response.TenantRoleGUIDs;
    this.tenantRoles = this.token_response.TenantRoles;
    this.isMeetingLableEditable = this.token_response.IsMeetingLableEditable;
    this.isMeetingLableEditableforSaving = this.token_response.IsMeetingLableEditable;
    if ( this.tenantRoleGUIDs.includes(this.token_response.userRoleId) || this.tenantRoles.includes(this.UserRole)) {
      if ((this.token_response.IsScheduleEndTimeEditable && this.token_response.IsShowEndTimeEditOption) || (!this.token_response.IsScheduleEndTimeEditable && this.token_response.IsShowEndTimeEditOption)) {
        this.isCheckboxDisplayed = true;
        this.isTimeFieldDisabled = true;
      }
      else if (!this.token_response.IsScheduleEndTimeEditable && !this.token_response.IsShowEndTimeEditOption) {
        this.isCheckboxDisplayed = false;
        this.isTimeFieldDisabled = true;
      }
      else if (!this.token_response.IsShowEndTimeEditOption && this.token_response.IsScheduleEndTimeEditable) {
        this.isCheckboxDisplayed = false;
        this.isTimeFieldDisabled = false;
      }

    }

    // if (this.CalendarView != 'month') {
    //   this.isTimeFieldDisabled = true;
    //   this.isCheckboxDisplayed = true;
    // } else {
    //   this.isTimeFieldDisabled = false;
    //   this.isCheckboxDisplayed = false;
    // }
    this.get_schedulardetails();
    this.GetListOfParticipants();
    this.GetListOfOrganizations();
  }

  ngAfterViewInit(): void {
    this.init();
    this.UserRole = this.token_response.UserRole;
  }

  getview(event: any) {
    console.log(event.detail.value);
    // if (event.detail.value != 'month') {
    //   this.isTimeFieldDisabled = true;
    //   this.isCheckboxDisplayed = true;
    // }
    // else {
    //   this.isTimeFieldDisabled = false;
    //   this.isCheckboxDisplayed = false;
    // }
  }

  setIsDisableOrIsenable() {
    if (this.chkDisableOrEnable) {
      this.isTimeFieldDisabled = false;
    }
    else {
      this.isTimeFieldDisabled = true;
    }
  }

  get_schedulardetails() {
    this.calendarService.GetScheduleDetailsByTenantId().subscribe((results) => {
      if (results != null && results != "") {
        this.schedularlist = results;
        this.isMultiEventParicipants = results[0].IsMultiEventParicipants;
        console.log(this.schedularlist, 'render');
        this.scheduler.dataSource = new window.Smart.DataAdapter({
          dataSource: this.schedularlist,
          dataFields: [
            { name: 'id', map: 'CRMScheduleId', dataType: 'string', },
            { name: 'scheduleDetailId', map: 'CRMScheduleDetailId', dataType: 'string', },
            { name: 'dateStart', map: 'ScheduleDetailStartDateTime', dataType: 'date', },
            { name: 'dateEnd', map: 'ScheduleDetailEndDateTime', dataType: 'date', },
            { name: 'label', map: 'CRMScheduleName', dataType: 'string' },
          ],
        });
        this.scheduler.nativeElement.render();
      }
    },
      (err) => {
        console.log(err);
      });
  }

  get_schedularParticipants(id: any) {
    this.spinner.show();
    this.selectedParticipants = [];
    this.participantId = "";
    this.calendarService.GetScheduleDetailsByCRMScheduleDetailId(id).subscribe((results) => {
      if (results != null && results != "") {
        console.log(results, 'participantId');
        this.selectedParticipants = results;
        if (this.isMultiEventParicipants == false) {
          this.participantId = results[0].ParticipantId;
        }
        this.spinner.hide();
      }
      else {
        console.log(results, 'participantId');
        this.spinner.hide();
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  GetScheduleDetailsByCRMScheduleIdOccurrence(id: any, cRMScheduleDetailId: any, seriesOrOccurrence: any) {
    console.log(id, cRMScheduleDetailId);
    this.spinner.show();
    this.calendarService.GetScheduleDetailsByCRMScheduleIdOccurrence(id, cRMScheduleDetailId, seriesOrOccurrence).subscribe((results) => {
      if (results != null && results != "") {
        console.log(results, 'GetScheduleDetailsByCRMScheduleIdOccurrence');
        this.scheduleDetails = results;
        if (seriesOrOccurrence == "Occurrence") {
          this.dateStart.value = moment(results.ScheduleDetailStartDateTime).format('MM/DD/YYYY HH:mm');
          this.dateEnd.value = moment(results.ScheduleDetailEndDateTime).format('MM/DD/YYYY HH:mm');
          this.schedularnewevent.ScheduleDetailStartDateTime = moment(results.ScheduleDetailStartDateTime).format('MM/DD/YYYY HH:mm');
          this.schedularnewevent.ScheduleDetailEndDateTime = moment(results.ScheduleDetailEndDateTime).format('MM/DD/YYYY HH:mm');
          this.meetingLink.value = results.ScheduleDetailMeetingLink != null ? results.ScheduleDetailMeetingLink : "";
        }
        else {
          this.dateStart.value = moment(results.ScheduleStartDate).format('MM/DD/YYYY HH:mm');
          this.dateEnd.value = moment(results.ScheduleEndDate).format('MM/DD/YYYY HH:mm');
          this.meetingLink.value = results.MeetingLink != null ? results.MeetingLink : "";
        }
        this.eventLabel.value = results.CRMScheduleName;
        this.organizerId = results.OrganizerId,
          this.repeatafter.value = results.RepeatEvery != null ? results.RepeatEvery : 1;
        if (seriesOrOccurrence == 'Occurrence') {
          this.repeat.checked = false;
          this.isRepeatShow = true;
        }
        else {
          this.repeat.checked = results.IsRepeat;
          this.isRepeatShow = false;
          this.endBy.value = moment(results.EndByDate).format('MM/DD/YYYY');
          if (results.Repeat != undefined && results.Repeat != null && results.Repeat != "" && (results.Repeat == "Daily" || results.Repeat == "Weekly")) {
            if (results.Repeat == "Daily") {
              this.repeatFreqInput.selectedIndexes = [0];
              setTimeout(() => {
                new Smart.CheckBox('#chkMon', { checked: results.IsMon });
                new Smart.CheckBox('#chkTue', { checked: results.IsTue });
                new Smart.CheckBox('#chkWed', { checked: results.IsWed });
                new Smart.CheckBox('#chkThu', { checked: results.IsThu });
                new Smart.CheckBox('#chkFri', { checked: results.IsFri });
                new Smart.CheckBox('#chkSat', { checked: results.IsSat });
                new Smart.CheckBox('#chkSun', { checked: results.IsSun });
              }, 500);

            }
            if (results.Repeat == "Weekly") {
              this.repeatFreqInput.selectedIndexes = [1];
              new Smart.CheckBox('#chkMon', { checked: results.IsMon });
              new Smart.CheckBox('#chkTue', { checked: results.IsTue });
              new Smart.CheckBox('#chkWed', { checked: results.IsWed });
              new Smart.CheckBox('#chkThu', { checked: results.IsThu });
              new Smart.CheckBox('#chkFri', { checked: results.IsFri });
              new Smart.CheckBox('#chkSat', { checked: results.IsSat });
              new Smart.CheckBox('#chkSun', { checked: results.IsSun });
            }

          }
          else if (results.Repeat != undefined && results.Repeat != null && results.Repeat != "" && results.Repeat == "Monthly") {
            this.repeatFreqInput.selectedIndexes = [2];
            this.checkOnDay = results.IsOnDay;
            this.checkOnThe = results.IsOnTheDay;
            if (results.IsOnDay) {
              this.monthOccurValue = results.OnDay;
            }

            if (results.IsOnTheDay) {
              switch (results.OnTheDay) {
                case 'First':
                  this.selectedWeekIndex = [0];
                  break;
                case 'Second':
                  this.selectedWeekIndex = [1];
                  break;
                case 'Third':
                  this.selectedWeekIndex = [2];
                  break;
                case 'Fourth':
                  this.selectedWeekIndex = [3];
                  break;
                case 'Last':
                  this.selectedWeekIndex = [4];
                  break;
                default:
                  console.log("No such Number exists!");
                  break;
              }

              switch (results.ScheduleDay) {
                case 'Monday':
                  this.selectedDayIndex = [0];
                  break;
                case 'Tuesday':
                  this.selectedDayIndex = [1];
                  break;
                case 'Wednesday':
                  this.selectedDayIndex = [2];
                  break;
                case 'Thursday':
                  this.selectedDayIndex = [3];
                  break;
                case 'Friday':
                  this.selectedDayIndex = [4];
                  break;
                case 'Saturday':
                  this.selectedDayIndex = [5];
                  break;
                case 'Sunday':
                  this.selectedDayIndex = [6];
                  break;
                default:
                  console.log("No such day exists!");
                  break;
              }

            }

          }
        }

        this.spinner.hide();
      }
      else {
        this.spinner.hide();
      }
    },
      (err) => {
        console.log(err);
      }
    );
  }

  view: string = this.token_response.CalendarView;
  views: SchedulerViews | undefined[] = [];
  firstDayOfWeek: number = 1;
  updating: boolean = false;
  itemInUpdate: any = undefined;
  heading: string = "Add Appointment";

  // hourStart: number = 6;

  // hourEnd: number = 21;

  timelineDayScale: SchedulerTimelineDayScale = 'quarterHour';

  repeatChange(event: any) {
    const checked = event.detail.value;
    const element = this.editingWindow.nativeElement.querySelector(
      "#repeat-editor"
    ) as HTMLElement;
    if (checked) {
      element.style.display = "block";
    } else {
      element.style.display = "none";
    }
    //this.setRepeatEditor("hourly");
  }

  repeatFreqChange(event: any) {
    this.repeatdropdown = event.detail.label;
    let selectedValue = event.detail.value.toLowerCase();
    //this.setRepeatEditor(selectedValue);
  }

  repeatFreqChange1(event: any) {
    if (event.detail.label == "Daily") {
      this.show1 = true;
      this.chkweek = true;
      this.show3 = false;
    }
    else if (event.detail.label == "Weekly") {
      this.show1 = true;
      this.chkweek = false;
      this.show3 = false;

    }
    else if (event.detail.label == "Monthly") {
      this.show1 = false;
      this.checkOnThe = true;
      this.show3 = true;
      this.disableOnThe = false;
      this.disableOnDay = true;
    }
  }

  changeOnDayorOnThe() {
    console.log(new Smart.RadioButton('#onDay').checked, new Smart.RadioButton('#onThe').checked)
    if (new Smart.RadioButton('#onDay').checked) {
      this.disableOnThe = true;
      this.disableOnDay = false;
    } else {
      this.disableOnThe = false;
      this.disableOnDay = true;
    }

    if (new Smart.RadioButton('#onThe').checked) {
      this.disableOnThe = false;
      this.disableOnDay = true;
    }
    else {
      this.disableOnThe = true;
      this.disableOnDay = false;
    }
  }

  repeateditorsChange(event: any) {
    this.editors = event.detail;
    this.startDate1 = moment(event.detail.value).format('MM/DD/YYYY HH:mm');
  }


  FillWindowContent = (item: any, type: any) => {
    this.resetWindowContent();
    console.log(item.scheduleDetailId, 'scheduleDetailId');
    console.log(item.id, 'scheduleId');
    this.get_schedularParticipants(item.scheduleDetailId);
    this.GetScheduleDetailsByCRMScheduleIdOccurrence(item.id, item.scheduleDetailId, type);
    this.itemInUpdate = item;
    this.updating = true;
    this.heading = "Edit Meeting";
    this.btnsubmit = "Update";
    const headerElement: any =
      this.editingWindow.nativeElement.querySelector('.smart-header');
    headerElement.textContent = "Edit Meeting";
    // if (item.repeat) {
    //   this.repeat.checked = true;
    //   this.repeatFreqInput.selectedValues = [
    //     item.repeat.repeatFreq[0].toUpperCase() +
    //     item.repeat.repeatFreq.substring(1)
    //   ];
    //   setTimeout(() => {
    //     // this.setRepeatEditor(item.repeat.repeatFreq);
    //     this.fillRepeatEditors(item.repeat);
    //   });
    // }
  };

  toggle() {
    this.show = !this.show;
  }

  resetWindowContent = (dateStart: any = null, dateEnd: any = null, allday: any = null) => {
    this.isMeetingLableEditable = this.token_response.IsMeetingLableEditable;
    if(!this.isMeetingLableEditable){
      if(this.isAddedOrUpdated){
        this.isMeetingLableEditable = true;
        this.isMeetingLableDisabled = true;
      }
      else{
        this.isMeetingLableEditable = false;
        this.isMeetingLableDisabled = false;
      }
    }
    else{
      this.isMeetingLableDisabled = false;
      this.isMeetingLableEditable = this.token_response.IsMeetingLableEditable;
    }
    this.itemInUpdate = undefined;
    this.updating = true;
    this.heading = "Create New Meeting";
    this.dateStart.value = dateStart || new Date();
    this.dateEnd.value = dateEnd || new Date();
    if (this.tenantRoles.includes(this.UserRole) || this.tenantRoleGUIDs.includes(this.token_response.userRoleId)) {
      if ((this.token_response.IsScheduleEndTimeEditable && this.token_response.IsShowEndTimeEditOption) || (!this.token_response.IsScheduleEndTimeEditable && this.token_response.IsShowEndTimeEditOption)) {
        this.isCheckboxDisplayed = true;
        this.isTimeFieldDisabled = true;
        this.chkDisableOrEnable = false;
      }
      else if (!this.token_response.IsScheduleEndTimeEditable && !this.token_response.IsShowEndTimeEditOption) {
        this.isCheckboxDisplayed = false;
        this.isTimeFieldDisabled = true;
      }
      else if (!this.token_response.IsShowEndTimeEditOption && this.token_response.IsScheduleEndTimeEditable) {
        this.isCheckboxDisplayed = false;
        this.isTimeFieldDisabled = false;
      }
    }
    if (dateEnd != null && (allday == undefined || !allday)) {

      // if (dateStart.getMinutes() > 30 && dateStart.getMinutes() <= 45) {
      //   this.dateEnd.value = dateEnd.setMinutes(75) || new Date();
      // }
      // else if (dateStart.getMinutes() == 30) {
      //   this.dateEnd.value = dateEnd.setMinutes(120) || new Date();
      // }
      // else if (dateStart.getMinutes() == 15) {
      //   this.dateEnd.value = dateEnd.setMinutes(105) || new Date();
      // }
      // else {
      //   this.dateEnd.value = dateEnd.setMinutes(90) || new Date();
      // }
      this.changeStartTime("");

    }

    this.eventLabel.value = '';
    this.meetingLink.value = '';
    this.repeatFreqInput.value = '';
    this.repeatafter.value = 1;
    new Smart.DropDownList('#selectedWeek', { value: "" });
    new Smart.DropDownList('#selectedDay', { value: "" });
    this.endBy.value = dateStart || new Date();
    // new Smart.Input('#input', { value: "" });
    this.selectedParticipants = [];
    this.participantId = "";
    this.organizerId = "";
    // new Smart.DateTimePicker('#startdate', { value: "" });
    new Smart.DropDownList('#staffdrop', { value: "" });
    // this.walkIn.checked = false;
    this.repeat.checked = false;
    this.repeatFreqInput.selectedIndexes = [1];
    this.show1 = true;
    this.isRepeatShow = false;
    this.seriesOrOccurrence = 'Occurrence';
    this.monthOccurValue = 1;
    this.selectedDayIndex = '';
    this.selectedWeekIndex = '';
    this.btnsubmit = "Save";
    new Smart.CheckBox('#chkMon', { checked: false });
    new Smart.CheckBox('#chkTue', { checked: false });
    new Smart.CheckBox('#chkWed', { checked: false });
    new Smart.CheckBox('#chkThu', { checked: false });
    new Smart.CheckBox('#chkFri', { checked: false });
    new Smart.CheckBox('#chkSat', { checked: false });
    new Smart.CheckBox('#chkSun', { checked: false });
  };

  init(): void {
    // init code.
    this.resetWindowContent();
    this.scheduler.addEventListener('change', (event: any) => {

    });
    this.scheduler.addEventListener("editDialogOpening", (event: any) => {
      const headerElement: any =
        this.editingWindow.nativeElement.querySelector('.smart-header');
      headerElement.textContent = "Create New Meeting";
      const detail = event.detail,
        item = detail.item;
      this.emptydate = item;
      event.preventDefault();
      let isUpdate = item.id !== undefined;
      if (isUpdate) {
        this.isAddedOrUpdated = true;
        this.FillWindowContent(item, 'Occurrence');
      } else {
        this.isAddedOrUpdated = false;
        this.resetWindowContent(item.dateStart, item.dateEnd, item.allDay);
      }
      if (this.UserRole == 'Participant' && isUpdate) {
        this.editingWindow.open();
      }

      if (this.UserRole != 'Participant') {
        this.editingWindow.open();
      }

    });


    this.scheduler.contextMenuDataSource = [
      'create',
      { label: 'Edit Occurrence', value: 'Edit Occurrence' },
      { label: 'Edit Series', value: 'Edit Series' },
      { label: 'Delete Occurrence', value: 'Delete Occurrence' },
      { label: 'Delete Series', value: 'Delete Series' },
    ];

    this.scheduler.addEventListener('eventMenuOpening',
      (ev: any) => {
        const that = this;
        const tooltip = ev.detail.target;
        const events = ev.detail.eventObj;
        Array.from(tooltip.querySelectorAll('div.smart-scheduler-event')).forEach(
          (eventElem: any, index: number) => {
            eventElem.addEventListener('contextmenu',
              (ev: any) => {
                ev.preventDefault();
                console.log(ev, 'div.smart-scheduler-event');
                this.contextMenu.open(ev.pageX, ev.pageY - window.scrollY);
                this.contextMenuEventObj = events[index];
              });
          });
      }
    );

    this.scheduler.addEventListener(
      'contextMenuOpening',
      (event: any) => {
        const detail = event.detail,
          target = detail.target,
          eventObj = detail.eventObj;
        console.log(eventObj, 'eventObjeventObjeventObj');
        if (eventObj != undefined) {
          this.customScheduleDetailId = eventObj[0].scheduleDetailId;
          this.customScheduleId = eventObj[0].id;
          this.customEvent = eventObj;
        }
        else {
          this.customScheduleDetailId = "";
          this.customScheduleId = "";
          this.customEvent = undefined;

        }

        const createNewOption = target.querySelector(
          '.smart-scheduler-context-menu-item[value="create"]'
        );

        const Occurence = target.querySelector(
          '.smart-scheduler-context-menu-item[value="Edit Occurrence"]'
        );

        const Series = target.querySelector(
          '.smart-scheduler-context-menu-item[value="Edit Series"]'
        );

        const DelOccurence = target.querySelector(
          '.smart-scheduler-context-menu-item[value="Delete Occurrence"]'
        );

        const DelSeries = target.querySelector(
          '.smart-scheduler-context-menu-item[value="Delete Series"]'
        );

        const editOption = target.querySelector(
          '.smart-scheduler-context-menu-item[value="edit"]'
        );

        if (eventObj) {
          createNewOption?.remove();
        } else {
          editOption?.remove();
          Occurence?.remove();
          Series?.remove();
          DelOccurence?.remove();
          DelSeries?.remove();
        }

        if (this.UserRole == "Participant") {
          createNewOption?.remove();
          editOption?.remove();
          Occurence?.remove();
          Series?.remove();
          DelOccurence?.remove();
          DelSeries?.remove();
          // createNewOption.style.display = 'none';
          // editOption.style.display = 'none';
          // Occurence.style.display = 'none';
          // Series.style.display = 'none';
          // DelOccurence.style.display = 'none';
          // DelSeries.style.display = 'none';
          // Array.from(
          //   target.querySelectorAll('.smart-scheduler-context-menu-item')
          // ).forEach((el: any) => (el.style.display = 'none'));
        }


        // event handling code goes here.
      }
    );

    this.scheduler.addEventListener('itemClick', (event: any) => {
      const detail = event.detail,
        item = detail.item,
        type = detail.type,
        itemObj = detail.itemObj;
      console.log(item.textContent, 'textcontent');

      if (item.textContent.toLowerCase().includes('edit occurrence')) {
        const detail = event.detail,
          item = detail.item;
        this.emptydate = this.customEvent[0];
        console.log(event, 'occurencedetails');
        let isUpdate = this.customScheduleId !== undefined;
        if (isUpdate) {
          this.isAddedOrUpdated = true;
          this.FillWindowContent(this.customEvent[0], 'Occurrence');
        } else {
          this.isAddedOrUpdated = false;
          this.resetWindowContent(item.dateStart, item.dateEnd);
        }
        this.editingWindow.open();
        this.seriesOrOccurrence = 'Occurrence';
      }
      else if (item.textContent.toLowerCase().includes('edit series')) {
        const detail = event.detail,
          item = detail.item;
        this.emptydate = this.customEvent[0];

        let isUpdate = this.customScheduleId !== undefined;
        if (isUpdate) {
          this.isAddedOrUpdated = true;
          this.FillWindowContent(this.customEvent[0], 'Series');
        } else {
          this.isAddedOrUpdated = false;
          this.resetWindowContent(item.dateStart, item.dateEnd);
        }
        this.editingWindow.open();
        this.seriesOrOccurrence = 'Series';
      }
      else if (item.textContent.toLowerCase().includes('delete occurrence')) {
        this.seriesOrOccurrence = 'Occurrence';
        Swal.fire({
          title: 'Are you sure you want to delete this event?',
          text: 'You will not be able to recover this event!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4680ff',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.SaveDeleteCRMSchedule(this.customScheduleId, this.customScheduleDetailId, "Occurrence");
          }
        })
      }
      else if (item.textContent.toLowerCase().includes('delete series')) {
        this.seriesOrOccurrence = 'Series';
        Swal.fire({
          title: 'Are you sure you want to delete this event?',
          text: 'You will not be able to recover this event!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4680ff',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            this.SaveDeleteCRMSchedule(this.customScheduleId, this.customScheduleDetailId, "Series");
          }
        })
      }

    });

    this.cancelBtn.addEventListener("click", () => {
      this.editingWindow.close();
    });
  }

  GetListOfParticipants() {
    this.spinner.show();
    this.calendarService.GetListOfParticipants().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.staffDataSource = results;
        console.log(this.staffDataSource)
        this.spinner.hide();
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get Participants.Please try after some time.");
        this.spinner.hide();
      }
    );
  }

  GetListOfOrganizations() {
    this.spinner.show();
    const type = 'All';
    this.calendarService.GetListOfOrganizations(type).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.organizerDataSource = results;
        console.log(this.organizerDataSource)
        this.spinner.hide();
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get Participants.Please try after some time.");
        this.spinner.hide();
      }
    );
  }

  loadParticipantsList() {
    this.staffData = [];
    this.participantslists.forEach((Values: any, item: any) => {
      let model = {
        label: Values.FullName,
        value: Values.ParticipantId
      }
      this.staffData.push(model);
    })
    return this.staffData;
  }


  async save_schedularevent(details: any) {
    this.spinner.show();
    this.scheduleEventsList = [];
    this.windowlabel = this.eventLabel.value;
    this.providedMeetingLink = this.meetingLink.value;
    this.repeatfreq = this.repeatdropdown;
    let newcalendardetails: any = {};
    this.participantIdList = [];
    let VolunteerName: any = "";
    let ParticipantName: any = "";
    let VolunteerEmail: any = "";
    let ParticipantEmail: any = "";

    if (this.isMultiEventParicipants == true) {
      this.selectedParticipants.forEach((value: any, item: any) => {
        let model = {
          ParticipantId: value.ParticipantId,
          Attendees: "Present"
        }
        this.participantIdList.push(model);
      });
    }
    else {
      let model = {
        ParticipantId: this.participantId,
        Attendees: "Present"
      }
      this.participantIdList.push(model);
    }

    if (this.UserRole == 'Admin' || this.UserRole == 'Staff') {
      if (this.organizerId != "" && this.organizerId != undefined && this.organizerId != null) {
        this.finalOrganizerId = this.organizerId;
      }
    }
    else {
      this.finalOrganizerId = this.token_response.CRMCustomerId;
    }


    this.startDate = moment(await this.dateStart.getDate()).format('MM/DD/YYYY HH:mm');
    this.endDate = moment(await this.dateEnd.getDate()).format('MM/DD/YYYY HH:mm');

    // if (details.ScheduleDetailEndDateTime == "" && details.ScheduleDetailStartDateTime == "") {
    //   this.sdate = this.emptydate.dateStart;
    //   this.edate = this.emptydate.dateEnd;
    //   this.startDate = moment(this.sdate).format('MM/DD/YYYY HH:mm');
    //   this.endDate = moment(this.edate).format('MM/DD/YYYY HH:mm');
    // }
    // else if (details.ScheduleDetailEndDateTime !== "" && details.ScheduleDetailStartDateTime !== "") {
    //   this.sdate = details.ScheduleDetailStartDateTime;
    //   this.edate = details.ScheduleDetailEndDateTime;
    //   this.startDate = moment(this.sdate).format('MM/DD/YYYY HH:mm');
    //   this.endDate = moment(this.edate).format('MM/DD/YYYY HH:mm');
    // }
    // else if (details.ScheduleDetailEndDateTime == "" && details.ScheduleDetailStartDateTime !== "") {
    //   this.sdate = details.ScheduleDetailStartDateTime;
    //   this.edate = this.emptydate.dateEnd;
    //   this.startDate = moment(this.sdate).format('MM/DD/YYYY HH:mm');
    //   this.endDate = moment(this.edate).format('MM/DD/YYYY HH:mm');
    // }
    // else if (details.ScheduleDetailEndDateTime !== "" && details.ScheduleDetailStartDateTime == "") {
    //   this.sdate = this.emptydate.dateStart;
    //   this.edate = details.ScheduleDetailEndDateTime;
    //   this.startDate = moment(this.sdate).format('MM/DD/YYYY HH:mm');
    //   this.endDate = moment(this.edate).format('MM/DD/YYYY HH:mm');
    // }
    // if (this.windowlabel == '' || this.windowlabel == null || this.windowlabel == 'undefined') {
    //   this.toastralert.toastrwarnningbottomright("Please Enter New Meeting");
    //   return false;
    // }
    if (this.participantIdList[0].ParticipantId == '' || this.participantIdList[0].ParticipantId == 'undefined' || this.participantIdList[0].ParticipantId == null) {
      this.toastralert.toastrwarnningbottomright("Please Select Attendee");
      this.spinner.hide();
      return false;
    }
    else if ((this.UserRole == 'Admin' || this.UserRole == 'Staff') && (this.organizerId == "" || this.organizerId == undefined || this.organizerId == null)) {
      this.toastralert.toastrwarnningbottomright("Please Select Organizer");
      this.spinner.hide();
      return false;
    }
    else if (this.startDate >= this.endDate) {
      this.toastralert.toastrwarnningbottomright("please select valid End Date");
      this.spinner.hide();
      return false;
    }
    else if (this.providedMeetingLink == '' || this.providedMeetingLink == null || this.providedMeetingLink == 'undefined') {
      this.toastralert.toastrwarnningbottomright("Please Enter Meeting Link");
      this.spinner.hide();
      return false;
    }
    else if (new Smart.SwitchButton('#sbtnRepeat').checked) {
      if (new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value == null || new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value == "" || new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value == undefined) {
        this.toastralert.toastrwarnningbottomright("Please Select Repeat Field");
        this.spinner.hide();
        return false;
      }
      if (new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == 'Daily' || new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == 'Weekly') {
        let isMonChecked = new Smart.CheckBox('#chkMon').checked != undefined ? new Smart.CheckBox('#chkMon').checked : false;
        let isTueChecked = new Smart.CheckBox('#chkTue').checked != undefined ? new Smart.CheckBox('#chkTue').checked : false;
        let isWedChecked = new Smart.CheckBox('#chkWed').checked != undefined ? new Smart.CheckBox('#chkWed').checked : false;
        let isThuChecked = new Smart.CheckBox('#chkThu').checked != undefined ? new Smart.CheckBox('#chkThu').checked : false;
        let isFriChecked = new Smart.CheckBox('#chkFri').checked != undefined ? new Smart.CheckBox('#chkFri').checked : false;
        let isSatChecked = new Smart.CheckBox('#chkSat').checked != undefined ? new Smart.CheckBox('#chkSat').checked : false;
        let isSunChecked = new Smart.CheckBox('#chkSun').checked != undefined ? new Smart.CheckBox('#chkSun').checked : false;
        if (!isMonChecked && !isTueChecked && !isWedChecked && !isThuChecked && !isFriChecked && !isSatChecked && !isSunChecked) {
          this.toastralert.toastrwarnningbottomright("Please Check Atleast One Day");
          this.spinner.hide();
          return false;
        }
        else if (this.repeatafter.value == "" || this.repeatafter.value == null || this.repeatafter.value == undefined) {
          this.toastralert.toastrwarnningbottomright("Please Enter Repeat Every Field");
          this.spinner.hide();
          return false;
        }
      }
      if (new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == 'Monthly') {
        if (this.onDay.checked || this.onThe.checked) {
          if (this.onDay.checked) {
            if (this.repeatafter.value == "" || this.repeatafter.value == null || this.repeatafter.value == undefined) {
              this.toastralert.toastrwarnningbottomright("Please Enter Repeat Every Field");
              this.spinner.hide();
              return false;
            }
            else if (this.monthOccur.value == "" || this.monthOccur.value == null || this.monthOccur.value == undefined) {
              this.toastralert.toastrwarnningbottomright("Please Enter On Day Field");
              this.spinner.hide();
              return false;
            }
          }
          else if (this.onThe.checked) {
            if (this.repeatafter.value == "" || this.repeatafter.value == null || this.repeatafter.value == undefined) {
              this.toastralert.toastrwarnningbottomright("Please Enter Repeat Every Field");
              this.spinner.hide();
              return false;
            }
            else if (this.selectedWeek.value[0].value == undefined || this.selectedWeek.value[0].value == null || this.selectedWeek.value[0].value == "" || this.selectedDay.value[0].value == undefined || this.selectedDay.value[0].value == null || this.selectedDay.value[0].value == "") {
              this.toastralert.toastrwarnningbottomright("Please Select both On The Fields");
              this.spinner.hide();
              return false;
            }
          }
        }
        else {
          this.toastralert.toastrwarnningbottomright("Please Check On Day or OnThe Field");
          this.spinner.hide();
          return false;
        }

      }
      if (this.endBy.value == "" || this.endBy.value == undefined || this.endBy.value == null) {
        this.toastralert.toastrwarnningbottomright("Please Select End By Field");
        this.spinner.hide();
        return false;
      }
      if (this.sdate > new Date(this.endBy.value)) {
        this.toastralert.toastrwarnningbottomright("please select valid End By Date");
        this.spinner.hide();
        return false;
      }
    }
    else {
    }

    let attendeesName = '';
    let organizer = '';
    console.log(this.ddlAttendeers);
    console.log(this.ddlOrganizers);
    this.ddlAttendeers.selectedItems.forEach((value: any, item: any) => {
      if(attendeesName == ''){
        attendeesName = value.label;
      }
      else{
        attendeesName += ', ' + value.label;
      }
    });
    if (this.UserRole == 'Admin' || this.UserRole == 'Staff') {
      this.ddlOrganizers.selectedItems.forEach((value: any, item: any) => {
        if(organizer == ''){
          organizer = value.label;
        }
        else{
          organizer += ', ' + value.label;
        }
      });
    }
    else{
      organizer = this.token_response.UserName;
    }
    if(this.isMeetingLableEditableforSaving){
      this.windowlabel = this.eventLabel.value;
    }
    else{
      this.windowlabel = organizer + ' - ' + attendeesName;
    }

    if (new Smart.SwitchButton('#sbtnRepeat').checked) {
      if (this.repeatFreqInput.value[0].value != null) {
        if (this.repeatFreqInput.value[0].value == 'Daily' || this.repeatFreqInput.value[0].value == 'Weekly') {

          let isMonChecked = new Smart.CheckBox('#chkMon').checked;
          let isTueChecked = new Smart.CheckBox('#chkTue').checked;
          let isWedChecked = new Smart.CheckBox('#chkWed').checked;
          let isThuChecked = new Smart.CheckBox('#chkThu').checked;
          let isFriChecked = new Smart.CheckBox('#chkFri').checked;
          let isSatChecked = new Smart.CheckBox('#chkSat').checked;
          let isSunChecked = new Smart.CheckBox('#chkSun').checked;

          if (isMonChecked || isTueChecked || isWedChecked || isThuChecked || isFriChecked || isSatChecked || isSunChecked) {
            if (this.startDate != "") {
              let dateStart1: any = new Date(this.startDate);
              let dateEnd1: any = new Date(this.endDate);
              let enddateTime: any = new Date(this.endBy.value);
              let endmm: any = enddateTime.getMonth() + 1;
              let enddd: any = enddateTime.getDate();
              let endyyyy: any = enddateTime.getFullYear();
              let endDatewithTime: any = endmm + '/' + enddd + '/' + endyyyy + " " + moment(dateEnd1).format('HH:mm');
              let dateEnd2: any = new Date(endDatewithTime);

              let monValue: any = isMonChecked ? new Smart.CheckBox('#chkMon').value : -1;
              let tueValue: any = isTueChecked ? new Smart.CheckBox('#chkTue').value : -1;
              let wedValue: any = isWedChecked ? new Smart.CheckBox('#chkWed').value : -1;
              let thuValue: any = isThuChecked ? new Smart.CheckBox('#chkThu').value : -1;
              let friValue: any = isFriChecked ? new Smart.CheckBox('#chkFri').value : -1;
              let satValue: any = isSatChecked ? new Smart.CheckBox('#chkSat').value : -1;
              let sunValue: any = isSunChecked ? new Smart.CheckBox('#chkSun').value : -1;
              let weekRepeatno = this.repeatafter.value;
              let selectdate = new Date(this.startDate);
              let repeatno = 7;
              if (weekRepeatno > 1)
                repeatno = weekRepeatno > 1 ? (weekRepeatno * 7) : 7;

              let diff: any = new Date(dateEnd2 - dateStart1);
              let days: any = Math.floor(diff / 1000 / 60 / 60 / 24);

              let ismonfallsoutside: any = false;
              let istuefallsoutside: any = false;
              let iswedfallsoutside: any = false;
              let isthufallsoutside: any = false;
              let isfrifallsoutside: any = false;
              let issatfallsoutside: any = false;
              let issunfallsoutside: any = false;

              let weekdaysname = "";

              if (days < 6) {

                let starddateDay = dateStart1.getDay();
                let enddateDay = dateEnd1.getDay();

                if (monValue != "" && parseInt(monValue) > 0) {
                  ismonfallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(monValue), dateStart1, days);
                  if (ismonfallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Mon";
                    else
                      weekdaysname = weekdaysname + ", Mon"
                  }
                }
                if (tueValue != "" && parseInt(tueValue) > 0) {
                  istuefallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(tueValue), dateStart1, days);
                  if (istuefallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Tue";
                    else
                      weekdaysname = weekdaysname + ", Tue"
                  }
                }
                if (wedValue != "" && parseInt(wedValue) > 0) {
                  iswedfallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(wedValue), dateStart1, days);
                  if (iswedfallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Wed";
                    else
                      weekdaysname = weekdaysname + ", Wed"
                  }
                }
                if (thuValue != "" && parseInt(thuValue) > 0) {
                  isthufallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(thuValue), dateStart1, days);
                  if (isthufallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Thu";
                    else
                      weekdaysname = weekdaysname + ", Thu"
                  }
                }
                if (friValue != "" && parseInt(friValue) > 0) {
                  isfrifallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(friValue), dateStart1, days);
                  if (isfrifallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Fri";
                    else
                      weekdaysname = weekdaysname + ", Fri"
                  }
                }
                if (satValue != "" && parseInt(satValue) > 0) {
                  issatfallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(satValue), dateStart1, days);
                  if (issatfallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Sat";
                    else
                      weekdaysname = weekdaysname + ", Sat"
                  }
                }
                if (sunValue != "" && parseInt(sunValue) >= 0) {
                  issunfallsoutside = this.CheckisSelecteddayfallsoutside(parseInt(sunValue), dateStart1, days);
                  if (issunfallsoutside) {
                    if (weekdaysname == "")
                      weekdaysname = "Sun";
                    else
                      weekdaysname = weekdaysname + ", Sun"
                  }
                }
              }
              if (ismonfallsoutside || istuefallsoutside || iswedfallsoutside || isthufallsoutside || isfrifallsoutside || issatfallsoutside || issunfallsoutside) {

                this.toastralert.toastrwarnningbottomright("Selected Week day(s) " + weekdaysname + " falls outside the selected schedule date range");
                this.spinner.hide();
                return false;
              }
              else {
                for (let k = 0; k <= 6; k++) {
                  let date5: any = new Date(selectdate);
                  let istrue: any = false;
                  if (k == sunValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == monValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == tueValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == wedValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == thuValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == friValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (k == satValue) {
                    dateStart1 = this.getnextweekday(selectdate, date5, k);
                    if (dateStart1 <= dateEnd2)
                      istrue = true;
                  }
                  if (istrue) {

                    do {
                      let date3: any = new Date(dateStart1);
                      let dateendTime: any = new Date(dateEnd1);
                      let mm: any = date3.getMonth() + 1;
                      let dd: any = date3.getDate();
                      let yyyy: any = date3.getFullYear();
                      let startDatewithTime: any = mm + '/' + dd + '/' + yyyy + " " + moment(date3).format('HH:mm');
                      let endDatewithTime: any = mm + '/' + dd + '/' + yyyy + " " + moment(dateendTime).format('HH:mm');

                      let schedulemodel = {
                        CRMScheduleName: this.windowlabel,
                        ScheduleStartDateTime: startDatewithTime,
                        ScheduleEndDateTime: endDatewithTime,

                      }
                      this.scheduleEventsList.push(schedulemodel);
                      dateStart1.setDate(dateStart1.getDate() + repeatno);
                    } while ((dateEnd2 >= dateStart1) || ((dateEnd2 - dateStart1) >= (7 * 86400 * 1000)));
                  }

                }
              }
            }

          }
        }
        else if (this.repeatFreqInput.value[0].value == 'Monthly') {
          if (this.onDay.checked) {

            let monthOnDayValue: number = this.monthOccur.value;
            let monthOnDayValue1: number = this.monthOccur.value;
            let monthRepeatValue: number = this.repeatafter.value;
            let repeatMonth: any = 1;
            if (this.startDate != "" && this.endDate != "" && this.endBy.value != "") {
              let dateStart2: any = new Date(this.startDate);
              let dateEnd2: any = new Date(this.endDate);

              if (monthRepeatValue > 1) {
                repeatMonth = monthRepeatValue;
              }
              let monthStartDate: any = new Date(this.startDate);

              let monthEndDate: any = new Date(this.endDate);
              let enddateTime: any = new Date(this.endBy.value);
              let endmonth: any = enddateTime.getMonth() + 1;
              let enddate: any = enddateTime.getDate();
              let endyear: any = enddateTime.getFullYear();
              let endDatewithTime: any = endmonth + '/' + enddate + '/' + endyear + " " + moment(monthEndDate).format('HH:mm');
              let monthEndDate1: any = new Date(endDatewithTime);
              let startmm: number = monthStartDate.getMonth();
              let startdd: any = monthStartDate.getDate();
              let startyyyy: any = monthStartDate.getFullYear();
              let endmm: any = monthEndDate1.getMonth();
              let enddd: any = monthEndDate1.getDate();
              let endyyyy: any = monthEndDate1.getFullYear();
              let numberOfMonths: any = (endyyyy - startyyyy) * 12 + (endmm - startmm) + 1;
              let ismonthfallsoutside = false;
              let selecteddate: any = "";
              let onDayValue: number;
              if (endmm == startmm && endyyyy == startyyyy) {
                startmm = startmm + 1;
                endmm = endmm + 1;
                let currentDate: any = new Date();
                let lastDayOfSelectedMonth = new Date(startyyyy, startmm + 1, 0);
                let currentday: any = currentDate.getDate();
                if (monthOnDayValue >= startdd && monthOnDayValue <= enddd) {
                  onDayValue = monthOnDayValue;
                  let startDatewithTime: any = startmm + '/' + onDayValue + '/' + startyyyy + " " + moment(monthStartDate).format('HH:mm');
                  let endDatewithTime: any = endmm + '/' + onDayValue + '/' + endyyyy + " " + moment(monthEndDate).format('HH:mm');
                  let schedulemodel = {
                    CRMScheduleName: this.windowlabel,
                    ScheduleStartDateTime: startDatewithTime,
                    ScheduleEndDateTime: endDatewithTime,

                  }
                  this.scheduleEventsList.push(schedulemodel);
                }
                else if (monthOnDayValue > lastDayOfSelectedMonth.getDate()) {
                  onDayValue = lastDayOfSelectedMonth.getDate();
                  let startDatewithTime: any = startmm + '/' + onDayValue + '/' + startyyyy + " " + moment(monthStartDate).format('HH:mm');
                  let endDatewithTime: any = endmm + '/' + onDayValue + '/' + endyyyy + " " + moment(monthEndDate).format('HH:mm');
                  let schedulemodel = {
                    CRMScheduleName: this.windowlabel,
                    ScheduleStartDateTime: startDatewithTime,
                    ScheduleEndDateTime: endDatewithTime,

                  }
                  this.scheduleEventsList.push(schedulemodel);
                }
                else {
                }
              }
              else {
                let count: any = 1;
                let monthOnDayDate: any;
                let lastDayOfSelectedMonth: any;
                let onDayValue: number;
                let isStisfied: any = true;
                do {
                  if (startmm > 11) {
                    startmm = monthOnDayDate.getMonth();
                  }
                  let mntValue = startmm + 1;
                  if (monthOnDayDate == "" || monthOnDayDate == undefined) {
                    let dateString = mntValue + "/" + monthOnDayValue + "/" + startyyyy + " " + moment(monthStartDate).format('HH:mm');
                    monthOnDayDate = new Date(dateString);
                  }

                  let schYYY = monthOnDayDate.getFullYear();
                  let lastDayOfSelectedMonth = new Date(schYYY, mntValue, 0);
                  if (monthOnDayValue >= lastDayOfSelectedMonth.getDate()) {
                    let dateString = mntValue + "/" + lastDayOfSelectedMonth.getDate() + "/" + schYYY + " " + moment(monthStartDate).format('HH:mm');
                    monthOnDayDate = new Date(dateString);
                  }
                  else {
                    let dateString = mntValue + "/" + monthOnDayValue1 + "/" + schYYY + " " + moment(monthStartDate).format('HH:mm');
                    monthOnDayDate = new Date(dateString);
                  }

                  if (monthOnDayDate >= monthStartDate && monthOnDayDate <= monthEndDate1) {
                    let monthOndateValue = monthOnDayDate.getDate();
                    onDayValue = monthOndateValue;

                    let startDatewithTime: any = mntValue + '/' + onDayValue + '/' + schYYY + " " + moment(monthStartDate).format('HH:mm');
                    let endDatewithTime: any = mntValue + '/' + onDayValue + '/' + schYYY + " " + moment(monthEndDate).format('HH:mm');
                    let schedulemodel = {
                      CRMScheduleName: this.windowlabel,
                      ScheduleStartDateTime: startDatewithTime,
                      ScheduleEndDateTime: endDatewithTime,

                    }
                    this.scheduleEventsList.push(schedulemodel);
                  }
                  else {
                    isStisfied = false;
                  }

                  //startmm = monthOnDayDate.getMonth();
                  startmm = this.addNumbers(startmm, repeatMonth);
                  monthOnDayDate.setMonth(startmm);

                } while (isStisfied);
              }

            }
            else {
              //implement
            }
          }
          else if (this.onThe.checked) {
            let monthOnTheRepeatValue: number = this.repeatafter.value;
            let selectedWeedDay: number = 1;
            let selectedWeekNumber: number = 1;
            if (this.selectedDay.value[0].value != null && this.selectedDay.value[0].value != undefined && this.selectedWeek.value[0].value != null && this.selectedWeek.value[0].value != undefined) {

              switch (this.selectedDay.value[0].value) {
                case 'Sunday':
                  selectedWeedDay = 0;
                  break;
                case 'Monday':
                  selectedWeedDay = 1;
                  break;
                case 'Tuesday':
                  selectedWeedDay = 2;
                  break;
                case 'Wednesday':
                  selectedWeedDay = 3;
                  break;
                case 'Thursday':
                  selectedWeedDay = 4;
                  break;
                case 'Friday':
                  selectedWeedDay = 5;
                  break;
                case 'Saturday':
                  selectedWeedDay = 6;
                  break;
                default:
                  console.log("No such day exists!");
                  break;
              }

              switch (this.selectedWeek.value[0].value) {
                case 'First':
                  selectedWeekNumber = 1;
                  break;
                case 'Second':
                  selectedWeekNumber = 2;
                  break;
                case 'Third':
                  selectedWeekNumber = 3;
                  break;
                case 'Fourth':
                  selectedWeekNumber = 4;
                  break;
                case 'Last':
                  selectedWeekNumber = 5;
                  break;
                default:
                  console.log("No such Number exists!");
                  break;
              }

              let repeatMonth: any = 1;
              if (this.startDate != "" && this.endDate != "" && this.endBy.value != "") {

                if (monthOnTheRepeatValue > 1) {
                  repeatMonth = monthOnTheRepeatValue;
                }
                let onThedateStart: any = new Date(this.startDate);
                let onThedateEnd: any = new Date(this.endDate);
                let enddateTime: any = new Date(this.endBy.value);
                let endmonth: any = enddateTime.getMonth() + 1;
                let enddate: any = enddateTime.getDate();
                let endyear: any = enddateTime.getFullYear();
                let endDatewithTime: any = endmonth + '/' + enddate + '/' + endyear + " " + moment(onThedateEnd).format('HH:mm');
                let onThedateEnd1: any = new Date(endDatewithTime);
                let startmm: number = onThedateStart.getMonth();
                let startdd: any = onThedateStart.getDate();
                let startyyyy: any = onThedateStart.getFullYear();
                let endmm: any = onThedateEnd1.getMonth();
                let enddd: any = onThedateEnd1.getDate();
                let endyyyy: any = onThedateEnd1.getFullYear();
                let ismonthfallsoutside = false;
                let selecteddate: any = "";
                let onDayValue: number;
                let onMonthValue: number;
                let onYearValue: number;

                if (endmm == startmm && endyyyy == startyyyy) {
                  let monthOnTheValue: any;
                  if (selectedWeekNumber != 5) {
                    monthOnTheValue = this.getNthOfMonth(startyyyy, startmm, selectedWeekNumber, selectedWeedDay);
                  }
                  else {
                    monthOnTheValue = this.getLastSelectedDayOfMonth(startyyyy, startmm, selectedWeedDay);
                  }
                  // let monthOnTheValue:Date =  this.getNthOfMonth(startyyyy,startmm,selectedWeekNumber,selectedWeedDay);

                  if (monthOnTheValue >= onThedateStart && monthOnTheValue <= onThedateEnd1) {
                    onDayValue = monthOnTheValue.getDate();
                    onMonthValue = monthOnTheValue.getMonth() + 1;
                    onYearValue = monthOnTheValue.getFullYear();
                    let startDatewithTime: any = onMonthValue + '/' + onDayValue + '/' + onYearValue + " " + moment(onThedateStart).format('HH:mm');
                    let endDatewithTime: any = onMonthValue + '/' + onDayValue + '/' + onYearValue + " " + moment(onThedateEnd).format('HH:mm');
                    let schedulemodel = {
                      CRMScheduleName: this.windowlabel,
                      ScheduleStartDateTime: startDatewithTime,
                      ScheduleEndDateTime: endDatewithTime,

                    }
                    this.scheduleEventsList.push(schedulemodel);
                  }
                  else {

                  }
                }
                else {
                  let count: any = 1;
                  let monthOnDayDate: any = new Date(this.startDate);
                  let lastDayOfSelectedMonth: any;
                  let onDayValue: number;
                  do {
                    if (startmm > 11) {
                      startmm = monthOnDayDate.getMonth();
                      startyyyy = monthOnDayDate.getFullYear();
                    }
                    let monthOnTheValue: any;
                    if (selectedWeekNumber != 5) {
                      monthOnTheValue = this.getNthOfMonth(startyyyy, startmm, selectedWeekNumber, selectedWeedDay);
                    }
                    else {
                      monthOnTheValue = this.getLastSelectedDayOfMonth(startyyyy, startmm, selectedWeedDay);
                    }

                    // let monthOnTheValue:Date  = this.getNthOfMonth(startyyyy,startmm,selectedWeekNumber,selectedWeedDay);
                    if (monthOnTheValue >= onThedateStart && monthOnTheValue <= onThedateEnd1) {
                      let OnTheDayValue = monthOnTheValue.getDate();
                      let monthValue = monthOnTheValue.getMonth() + 1;
                      let yearValue = monthOnTheValue.getFullYear();
                      let startDatewithTime: any = monthValue + '/' + OnTheDayValue + '/' + yearValue + " " + moment(onThedateStart).format('HH:mm');
                      let endDatewithTime: any = monthValue + '/' + OnTheDayValue + '/' + yearValue + " " + moment(onThedateEnd).format('HH:mm');
                      let schedulemodel = {
                        CRMScheduleName: this.windowlabel,
                        ScheduleStartDateTime: startDatewithTime,
                        ScheduleEndDateTime: endDatewithTime,

                      }
                      this.scheduleEventsList.push(schedulemodel);

                    }
                    else {
                    }

                    startmm = this.addNumbers(startmm, repeatMonth);
                    monthOnDayDate.setMonth(startmm);

                  } while ((onThedateEnd1 >= monthOnDayDate));

                }
              }
              else {
                //implement
              }
            }
            else {
            }

          }
          else {
          }
        }
        else {
        }
      }
      else {
        this.toastralert.toastrwarnningbottomright("please Select Repeat Field");
        this.spinner.hide();
        return false;
      }
    }
    else {
      if (this.startDate != "" && this.endDate != "") {

        let date3: any = new Date(this.startDate);
        let dateendTime: any = new Date(this.endDate);
        let mm: any = date3.getMonth() + 1;
        let dd: any = date3.getDate();
        let yyyy: any = date3.getFullYear();
        let emm: any = dateendTime.getMonth() + 1;
        let edd: any = dateendTime.getDate();
        let eyyyy: any = dateendTime.getFullYear();
        let startDatewithTime: any = mm + '/' + dd + '/' + yyyy + " " + moment(this.startDate).format('HH:mm');
        let endDatewithTime: any = emm + '/' + edd + '/' + eyyyy + " " + moment(this.endDate).format('HH:mm');
        let schedulemodel = {
          CRMScheduleName: this.windowlabel,
          ScheduleStartDateTime: startDatewithTime,
          ScheduleEndDateTime: endDatewithTime,

        }
        this.scheduleEventsList.push(schedulemodel);

      }
      else {
        //implement
      }
    }
    if (new Smart.SwitchButton('#sbtnRepeat').checked && this.scheduleEventsList.length == 0) {
      this.toastralert.toastrwarnningbottomright("Something went wrong. Edit event to try again.");
      this.spinner.hide();
      return false;
    }
    console.log(this.selectedWeek, 'selectedWeek');
    if (new Smart.SwitchButton('#sbtnRepeat').checked) {
      if (new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == "Daily" || new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == "Weekly") {
        this.dbOnDay = null;
        this.dbOnTheDay = null;
        this.dbDay = null;
        this.dbIsSun = new Smart.CheckBox('#chkSun').checked != undefined ? new Smart.CheckBox('#chkSun').checked : false;
        this.dbIsMon = new Smart.CheckBox('#chkMon').checked != undefined ? new Smart.CheckBox('#chkMon').checked : false;
        this.dbIsTue = new Smart.CheckBox('#chkTue').checked != undefined ? new Smart.CheckBox('#chkTue').checked : false;
        this.dbIsWed = new Smart.CheckBox('#chkWed').checked != undefined ? new Smart.CheckBox('#chkWed').checked : false;
        this.dbIsThu = new Smart.CheckBox('#chkThu').checked != undefined ? new Smart.CheckBox('#chkThu').checked : false;
        this.dbIsFri = new Smart.CheckBox('#chkFri').checked != undefined ? new Smart.CheckBox('#chkFri').checked : false;
        this.dbIsSat = new Smart.CheckBox('#chkSat').checked != undefined ? new Smart.CheckBox('#chkSat').checked : false;
        this.dbIsOnTheDay = false;
        this.dbIsOnDay = false;

      }
      else if (new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value == "Monthly") {
        if (this.onDay.checked) {
          this.dbOnDay = this.monthOccur.value;
          this.dbOnTheDay = null;
          this.dbDay = null;
          this.dbIsSun = false;
          this.dbIsMon = false;
          this.dbIsTue = false;
          this.dbIsWed = false;
          this.dbIsThu = false;
          this.dbIsFri = false;
          this.dbIsSat = false;
          this.dbIsOnTheDay = false;
          this.dbIsOnDay = true;

        }
        else if (this.onThe.checked) {
          this.dbOnDay = null;
          this.dbOnTheDay = this.selectedWeek.value[0].value;
          this.dbDay = this.selectedDay.value[0].value;
          this.dbIsSun = false;
          this.dbIsMon = false;
          this.dbIsTue = false;
          this.dbIsWed = false;
          this.dbIsThu = false;
          this.dbIsFri = false;
          this.dbIsSat = false;
          this.dbIsOnTheDay = true;
          this.dbIsOnDay = false;
        }
      }
      else {
      }

    }
    else {
      this.dbOnDay = null;
      this.dbOnTheDay = null;
      this.dbDay = null;
      this.dbIsSun = false;
      this.dbIsMon = false;
      this.dbIsTue = false;
      this.dbIsWed = false;
      this.dbIsThu = false;
      this.dbIsFri = false;
      this.dbIsSat = false;
      this.dbIsOnTheDay = false;
      this.dbIsOnDay = false;
    }


    if (this.emptydate.id != undefined) {
      newcalendardetails = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        CRMScheduleId: this.emptydate.id,
        CRMScheduleDetailId: this.emptydate.scheduleDetailId,
        CRMScheduleName: this.windowlabel,
        ScheduleStartDateTime: this.startDate,
        ScheduleEndDateTime: this.endDate,
        SeriesOrOccurrence: this.seriesOrOccurrence,
        EndByDate: new Smart.SwitchButton('#sbtnRepeat').checked ? moment(this.endBy.value).format('MM/DD/YYYY HH:mm') : this.endDate,
        OrganizerId: this.finalOrganizerId,
        MeetingLink: this.providedMeetingLink,
        IsRepeat: new Smart.SwitchButton('#sbtnRepeat').checked,
        Repeat: (new Smart.SwitchButton('#sbtnRepeat').checked && new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value) ? new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value : "",
        RepeatEvery: (new Smart.SwitchButton('#sbtnRepeat').checked && this.repeatafter.value) ? this.repeatafter.value : null,
        OnDay: this.dbOnDay,
        OnTheDay: this.dbOnTheDay,
        Day: this.dbDay,
        IsSun: this.dbIsSun,
        IsMon: this.dbIsMon,
        IsTue: this.dbIsTue,
        IsWed: this.dbIsWed,
        IsThu: this.dbIsThu,
        IsFri: this.dbIsFri,
        IsSat: this.dbIsSat,
        IsOnTheDay: this.dbIsOnTheDay,
        IsOnDay: this.dbIsOnDay,
        CRMScheduleMembersList: this.participantIdList,
        CRMScheduleDetailsList: this.scheduleEventsList
      };
    }
    else {
      newcalendardetails = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        CRMScheduleName: this.windowlabel,
        ScheduleStartDateTime: this.startDate,
        ScheduleEndDateTime: this.endDate,
        SeriesOrOccurrence: this.seriesOrOccurrence,
        EndByDate: new Smart.SwitchButton('#sbtnRepeat').checked ? moment(this.endBy.value).format('MM/DD/YYYY HH:mm') : this.endDate,
        OrganizerId: this.finalOrganizerId,
        MeetingLink: this.providedMeetingLink,
        IsRepeat: new Smart.SwitchButton('#sbtnRepeat').checked,
        Repeat: (new Smart.SwitchButton('#sbtnRepeat').checked && new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value) ? new Smart.DropDownList('#ddlRepeat', { selectionMode: "one" }).value[0].value : "",
        RepeatEvery: (new Smart.SwitchButton('#sbtnRepeat').checked && this.repeatafter.value) ? this.repeatafter.value : null,
        OnDay: this.dbOnDay,
        OnTheDay: this.dbOnTheDay,
        Day: this.dbDay,
        IsSun: this.dbIsSun,
        IsMon: this.dbIsMon,
        IsTue: this.dbIsTue,
        IsWed: this.dbIsWed,
        IsThu: this.dbIsThu,
        IsFri: this.dbIsFri,
        IsSat: this.dbIsSat,
        IsOnTheDay: this.dbIsOnTheDay,
        IsOnDay: this.dbIsOnDay,
        CRMScheduleMembersList: this.participantIdList,
        CRMScheduleDetailsList: this.scheduleEventsList

      };
    }

    console.log(this.scheduleEventsList, 'eventlist');
    console.log(newcalendardetails, 'newcalendardetails');
    console.log(moment(newcalendardetails.ScheduleEndDateTime).format('MMMM Do YYYY, h:mm A'), "SentScheduleEmailsToUsers");

    this.calendarService.Save_CRMSchedule(newcalendardetails).subscribe((results) => {
      if (results != undefined && results != "") {
        this.spinner.hide();
        let isUpdated: boolean = false;
        let isProvider: boolean = false;
        if (this.emptydate.id != undefined && this.emptydate.id != null && this.emptydate.id != '') {
          isUpdated = true;
        }
        else {
          isUpdated = false;
        }

        // setTimeout(() => {
        this.profileService.Get_CRMCustomersByTenantId(this.participantIdList[0].ParticipantId).subscribe((result1) => {
          if (result1 != null && result1 != "" && result1 != 'undefined') {
            ParticipantName = result1.PrimaryContact;
            ParticipantEmail = result1.EmailAddress;
            this.profileService.Get_CRMCustomersByTenantId(this.finalOrganizerId).subscribe((result2) => {
              if (result2 != null && result2 != "" && result2 != 'undefined') {
                VolunteerName = result2.PrimaryContact;
                VolunteerEmail = result2.EmailAddress;
                let selectedDays: any = "";
                let endBy: any = "";
                let onDay: number = 0;
                let onTheDay: any = "";
                let day: any = "";
                let isOnTheDay: any = false;
                let isOnDay: any = false;
                let repeatEvery: number = (newcalendardetails.RepeatEvery != null && newcalendardetails.RepeatEvery != "" && newcalendardetails.RepeatEvery != undefined) ? newcalendardetails.RepeatEvery : 0;
                if (newcalendardetails.IsRepeat) {
                  endBy = moment(newcalendardetails.EndByDate).format('MM/DD/YYYY')
                  if (newcalendardetails.Repeat != 'Monthly') {
                    if (this.dbIsSun) {
                      if (selectedDays == "") {
                        selectedDays += "Sunday";
                      }
                      else {
                        selectedDays += ", Sunday";
                      }
                    }
                    if (this.dbIsMon) {
                      if (selectedDays == "") {
                        selectedDays += "Monday";
                      }
                      else {
                        selectedDays += ", Monday";
                      }
                    }
                    if (this.dbIsTue) {
                      if (selectedDays == "") {
                        selectedDays += "Tuesday";
                      }
                      else {
                        selectedDays += ", Tuesday";
                      }
                    }
                    if (this.dbIsWed) {
                      if (selectedDays == "") {
                        selectedDays += "Wednesday";
                      }
                      else {
                        selectedDays += ", Wednesday";
                      }
                    }
                    if (this.dbIsThu) {
                      if (selectedDays == "") {
                        selectedDays += "Thursday";
                      }
                      else {
                        selectedDays += ", Thursday";
                      }
                    }
                    if (this.dbIsFri) {
                      if (selectedDays == "") {
                        selectedDays += "Friday";
                      }
                      else {
                        selectedDays += ", Friday";
                      }
                    }
                    if (this.dbIsSat) {
                      if (selectedDays == "") {
                        selectedDays += "Saturday";
                      }
                      else {
                        selectedDays += ", Saturday";
                      }
                    }
                    if (selectedDays.includes(',')) {
                      const lastCommaIndex = selectedDays.lastIndexOf(',');
                      selectedDays = selectedDays.substring(0, lastCommaIndex) + ' and' + selectedDays.substring(lastCommaIndex + 1);
                    }
                  }
                  else {
                    onDay = newcalendardetails.OnDay != null ? newcalendardetails.OnDay : 0;
                    onTheDay = (newcalendardetails.OnTheDay != "" && newcalendardetails.OnTheDay != null) ? newcalendardetails.OnTheDay : "";
                    console.log(newcalendardetails.Day);
                    day = (newcalendardetails.Day != "" && newcalendardetails.Day != null) ? newcalendardetails.Day : "";
                    isOnTheDay = newcalendardetails.IsOnTheDay;
                    isOnDay = newcalendardetails.IsOnDay;
                  }
                }

                let emailModel = {
                  CRMPersonId: this.token_response.CRMPersonId,
                  ParticipantEmail: ParticipantEmail,
                  VolunteerEmail: VolunteerEmail,
                  ParticipantName: ParticipantName,
                  VolunteerName: VolunteerName,
                  MeetingLink: newcalendardetails.MeetingLink,
                  ScheduleStartDate: moment(newcalendardetails.ScheduleStartDateTime).format('MM/DD/YYYY'),
                  ScheduleEndDate: moment(newcalendardetails.ScheduleEndDateTime).format('MM/DD/YYYY'),
                  ScheduleStartTime: moment(newcalendardetails.ScheduleStartDateTime).format('h:mm A'),
                  ScheduleEndTime: moment(newcalendardetails.ScheduleEndDateTime).format('h:mm A'),
                  IsUpdated: isUpdated,
                  IsProvider: isProvider,
                  LoginMail: this.token_response.UserName,
                  IsRepeat: newcalendardetails.IsRepeat,
                  Repeat: newcalendardetails.Repeat,
                  RepeatEvery: repeatEvery,
                  EndByDate: endBy,
                  OnDay: onDay,
                  OnTheDay: onTheDay,
                  Day: day,
                  IsOnTheDay: isOnTheDay,
                  IsOnDay: isOnDay,
                  SelectedDays: selectedDays
                }
                this.calendarService.SentScheduleEmailsToUsers(emailModel).subscribe((result3) => {
                  if (result3 != null && result3 != "" && result3 != 'undefined' && result3 == "Sent") {
                    this.spinner.hide();
                  }
                },
                  (err) => {
                    console.log(err);
                    this.toastralert.toastrerror('Unable to send schedule email to participant.Please try after some time.');
                    this.spinner.hide();
                  });

                if (this.UserRole == 'Admin' || this.UserRole == 'Staff') {
                  isProvider = true;
                  let emailModel = {
                    CRMPersonId: this.token_response.CRMPersonId,
                    ParticipantEmail: ParticipantEmail,
                    VolunteerEmail: VolunteerEmail,
                    ParticipantName: ParticipantName,
                    VolunteerName: VolunteerName,
                    MeetingLink: newcalendardetails.MeetingLink,
                    ScheduleStartDate: moment(newcalendardetails.ScheduleStartDateTime).format('MM/DD/YYYY'),
                    ScheduleEndDate: moment(newcalendardetails.ScheduleEndDateTime).format('MM/DD/YYYY'),
                    ScheduleStartTime: moment(newcalendardetails.ScheduleStartDateTime).format('h:mm A'),
                    ScheduleEndTime: moment(newcalendardetails.ScheduleEndDateTime).format('h:mm A'),
                    IsUpdated: isUpdated,
                    IsProvider: isProvider,
                    LoginMail: this.token_response.UserName,
                    IsRepeat: newcalendardetails.IsRepeat,
                    Repeat: newcalendardetails.Repeat,
                    RepeatEvery: repeatEvery,
                    EndByDate: endBy,
                    OnDay: onDay,
                    OnTheDay: onTheDay,
                    Day: day,
                    IsOnTheDay: isOnTheDay,
                    IsOnDay: isOnDay,
                    SelectedDays: selectedDays
                  }
                  this.calendarService.SentScheduleEmailsToUsers(emailModel).subscribe((result3) => {
                    if (result3 != null && result3 != "" && result3 != 'undefined' && result3 == "Sent") {
                      this.spinner.hide();

                    }
                  },
                    (err) => {
                      console.log(err);
                      this.toastralert.toastrerror('Unable to send schedule email to participant.Please try after some time.');
                      this.spinner.hide();
                    });

                }

              }
            },
              (err) => {
                console.log(err);
                this.toastralert.toastrerror('Unable to get details of participant.Please try after some time.');
                this.spinner.hide();
              }
            );
          }
        },
          (err) => {
            console.log(err);
            this.toastralert.toastrerror('Unable to get details of participant.Please try after some time.');
            this.spinner.hide();
          }
        );
        this.editingWindow.close();
        this.schedularnewevent.ScheduleDetailStartDateTime = '';
        this.schedularnewevent.ScheduleDetailEndDateTime = '';
        if (this.emptydate.id != undefined && this.emptydate.id != null && this.emptydate.id != '') {
          this.emptydate = "";
          this.toastralert.toastersuccesstop("Event Updated Successfully");
          this.resetWindowContent();
          this.get_schedulardetails();
        }
        else {
          this.emptydate = "";
          this.toastralert.toastersuccesstop("Event Created Successfully");
          this.resetWindowContent();
          this.get_schedulardetails();
        }
        // }, 3000);
      }

    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to Save Schedule.Please try after some time.");
        this.spinner.hide();
      }
    );
  }

  daysInMonth(month: any, year: any) {
    return new Date(year, month, 0).getDate();
  }

  CheckisSelecteddayfallsoutside(selectedvisitweek: any, sstartDate: any, days: any) {
    let starddateDay: any = sstartDate.getDay();

    let isvistdayfallsoutside: any = false;

    if (selectedvisitweek >= 0) {
      let daystart: any = starddateDay;
      let isvisitweekexist: any = false;
      for (let t = 0; t <= days; t++) {
        if (daystart == selectedvisitweek) {
          isvisitweekexist = true;
          return isvistdayfallsoutside;
        }
        if (daystart == 6)
          daystart = 0;
        else
          ++daystart;
      }
      if (!isvisitweekexist)
        isvistdayfallsoutside = true;
    }
    return isvistdayfallsoutside;
  }

  getnextweekday(selectdate: any, date5: any, weekvalue: any) {

    let repeatdate: any = new Date(date5);

    if (selectdate.getDay() == weekvalue)
      repeatdate.setDate(selectdate.getDate());
    else {
      repeatdate = new Date(selectdate);
      repeatdate.setDate(selectdate.getDate() + 1);
      if (repeatdate.getDay() == weekvalue) {
        return repeatdate;
      } else {
        repeatdate = new Date(selectdate);
        repeatdate.setDate(selectdate.getDate() + 2);
        if (repeatdate.getDay() == weekvalue) {
          return repeatdate;
        }
        else {
          repeatdate = new Date(selectdate);
          repeatdate.setDate(selectdate.getDate() + 3);
          if (repeatdate.getDay() == weekvalue) {
            return repeatdate;
          }
          else {
            repeatdate = new Date(selectdate);
            repeatdate.setDate(selectdate.getDate() + 4);
            if (repeatdate.getDay() == weekvalue) {
              return repeatdate;
            }
            else {
              repeatdate = new Date(selectdate);
              repeatdate.setDate(selectdate.getDate() + 5);
              if (repeatdate.getDay() == weekvalue) {
                return repeatdate;
              }
              else {
                repeatdate = new Date(selectdate);
                repeatdate.setDate(selectdate.getDate() + 6);
                if (repeatdate.getDay() == weekvalue) {
                  return repeatdate;
                }
              }
            }
          }
        }
      }
    }
    return repeatdate;
  }

  addNumbers(firstNum: number, secondNum: any) {
    secondNum = parseInt(secondNum);
    return firstNum + secondNum;
  }

  getLastSelectedDayOfMonth(year: number, month: number, day: number): Date {
    let days = new Date(year, month + 1, 0).getDate();
    let lastSelectedDay: any;
    for (let day1 = days; day1 >= 1; day1--) {
      const date = new Date(`${year}-${month + 1}-${day1}`);
      if (date.getDay() === day) {
        lastSelectedDay = date;
        break;
      }
    }
    return lastSelectedDay;
  }

  getNthOfMonth(year: number, month: number, n: number, day: number): Date {
    let firstdayOfMonth = new Date(year, month, 1);
    while (firstdayOfMonth.getDay() !== day) {
      firstdayOfMonth.setDate(firstdayOfMonth.getDate() + 1);
    }
    const nthdayOfMonth = new Date(firstdayOfMonth);
    nthdayOfMonth.setDate(nthdayOfMonth.getDate() + 7 * (n - 1));
    return nthdayOfMonth;
  }

  getSecondTuesdayOfMonth(year: number, month: number): Date {
    const firstDayOfMonth = new Date(year, month, 1);
    let firstTuesdayOfMonth = new Date(year, month, 1);
    while (firstTuesdayOfMonth.getDay() !== 2) { // 2 represents Tuesday
      firstTuesdayOfMonth.setDate(firstTuesdayOfMonth.getDate() + 1);
    }
    const secondTuesdayOfMonth = new Date(firstTuesdayOfMonth);
    secondTuesdayOfMonth.setDate(secondTuesdayOfMonth.getDate() + 7);
    if (secondTuesdayOfMonth.getMonth() !== month) {
      throw new Error("There is no second Tuesday in this month.");
    }
    return secondTuesdayOfMonth;
  }

  onItemClick(event: any) {
    const detail = event.detail,
      item = detail.item,
      label = detail.label,
      value = detail.value;
    if (this.contextMenuEventObj != undefined) {
      this.customScheduleDetailId = this.contextMenuEventObj.scheduleDetailId;
      this.customScheduleId = this.contextMenuEventObj.id;
      this.customEvent = this.contextMenuEventObj;
    }
    else {
      this.customScheduleDetailId = "";
      this.customScheduleId = "";
      this.customEvent = undefined;
    }

    if (value == 'Edit Occurrence') {
      this.seriesOrOccurrence = 'Occurrence';
      const detail = event.detail,
        item = detail.item;
      this.emptydate = this.customEvent;
      let isUpdate = this.customScheduleId !== undefined;
      if (isUpdate) {
        this.FillWindowContent(this.customEvent, 'Occurrence');
      } else {
        this.resetWindowContent(item.dateStart, item.dateEnd);
      }
      this.editingWindow.open();
    }
    else if (value == 'Edit Series') {
      this.seriesOrOccurrence = 'Series';
      const detail = event.detail,
        item = detail.item;
      this.emptydate = this.customEvent;
      let isUpdate = this.customScheduleId !== undefined;
      if (isUpdate) {
        this.isAddedOrUpdated = true;
        this.FillWindowContent(this.customEvent, 'Series');
      } else {
        this.isAddedOrUpdated = false;
        this.resetWindowContent(item.dateStart, item.dateEnd);
      }
      this.editingWindow.open();
    }
    else if (value == 'Delete Occurrence') {
      Swal.fire({
        title: 'Are you sure you want to delete this event?',
        text: 'You will not be able to recover this event!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4680ff',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.SaveDeleteCRMSchedule(this.customScheduleId, this.customScheduleDetailId, "Occurrence");
        }
      })
    }
    else if (value == 'Delete Series') {
      Swal.fire({
        title: 'Are you sure you want to delete this event?',
        text: 'You will not be able to recover this event!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4680ff',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.SaveDeleteCRMSchedule(this.customScheduleId, this.customScheduleDetailId, "Series");
        }
      })
    }
    else {

    }

  }

  SaveDeleteCRMSchedule(scheduleId: any, scheduleDetailId: any, seriesOrOccurrence: any) {
    let scheduledetails = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMScheduleId: scheduleId,
      CRMScheduleDetailId: scheduleDetailId,
      SeriesOrOccurrence: seriesOrOccurrence
    };
    this.calendarService.SaveDeleteCRMSchedule(scheduledetails).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.toastralert.toastersuccesstop("Event Deleted successfully");
        this.get_schedulardetails();
      }
      else {
        this.toastralert.toastrerror('Error occurred while Deleting Schedule');
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to Delete Schedule");
      }
    );
  }

  async changeStartTime(event: any) {
    let date = await this.dateStart.getDate();
    let date1 = date.setMinutes(date.getMinutes() + this.token_response.SchedulingTimeDifference);
    // if (this.scheduler.view != 'month') {
    this.dateEnd.setDate(date1);
    // }
    // console.log(moment(await this.dateStart.getDate()).format('MMMM Do YYYY, h:mm A'));
    // console.log(moment(await this.dateEnd.getDate()).format('MMMM Do YYYY, h:mm A'));
  }
}


