// shared.service.ts
import { Injectable } from '@angular/core';
type Tabs = 'Volunteers' | 'Beneficiaries' | 'Admins';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private activeTab: Tabs = 'Volunteers';

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  getActiveTab(): Tabs {
    return this.activeTab;
  }
}
