<div class="
    d-flex
    flex-column flex-column-fluid
    bgi-position-y-bottom
    position-x-center
    bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-secondary
  ">
  <div class="flex-center flex-column flex-column-fluid p-10">
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2">
        <img alt="Logo" src="./assets/media/logos/volunteers_llc.png" height="150px" width="150px" />
      </div>
      <div class="col-sm-4 col-lg-2 col-md-2 text-center d-flex welcometext">
        <div class="row">
          Welcome to
          Volunteers.llc
        </div>
      </div>
      <div class="col-sm-6 col-lg-8 col-md-8 userdetailscard">
        <div class="w-lg-500px bg-white rounded shadow-sm p-lg-15 mx-auto cardpadding">
          <div class="text-center mb-10">
            <h1 class="text-primary mb-3">Sign Up</h1>
          </div>
          <div>
            <div class="fv-row mb-5 mt-2">
              <input [(ngModel)]="registerCredentials.FirstName" placeholder="First Name"
                class="inputpading form-control">
              <div name="mail-outline"></div>
            </div>
            <div class="fv-row mb-5 mt-2">
              <input [(ngModel)]="registerCredentials.LastName" placeholder="Last Name"
                class="inputpading form-control">
              <div name="mail-outline"></div>
            </div>
            <div class="fv-row mb-5 mt-2">
              <input [(ngModel)]="registerCredentials.Email" placeholder="Email" class="inputpading form-control">
              <div name="mail-outline"></div>
            </div>
            <div class="fv-row mb-5 mt-2 position-relative">
              <input [(ngModel)]="registerCredentials.UserPassword" [type]="changetype?'password':'text'" placeholder="Password"
                class="inputpading form-control">
                <span class="position-absolute translate-middle top-50 end-0 me-4" (click)="viewpass()"><i [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'" ></i></span>
              <div name="lock-closed-outline"></div>
            </div>
            <div class="fv-row mb-5 mt-2 position-relative">
              <input [(ngModel)]="registerCredentials.ConfirmUserPassword" [type]="confirmchangetype?'password':'text'"
                placeholder="Confirm Password" class="inputpading form-control"
                (keydown.enter)='register(registerCredentials)'>
                <span class="position-absolute translate-middle top-50 end-0 me-4" (click)="confirmviewpass()"><i [ngClass]="confirmvisible?'fa fa-eye':'fa fa-eye-slash'" ></i></span>
              <div name="lock-closed-outline"></div>
            </div>
            <button (click)="register(registerCredentials)" expand="block" color="red"
              class="btn btn-lg btn-primary1 w-100 mb-5">Create Account</button>
            <div class="text-center text-muted fw-bolder mb-5">Or</div>
            <button class="btn btn-lg w-100 mb-2 btn-primary1" (click)="Redirecttologin()">
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>