<div class="row px-4 py-0 pb-5">
    <div class="bgcolor stickybar ">
        <ul class="breadcrumb pb-2" *ngIf="userRole != 'Volunteer'">
            <li><a class="routingcontacts" routerLink="/profiles">Profiles </a> >> </li>
            <li> Profile</li>
        </ul>
        <div class="card card-body py-3">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark ">{{profilelist.PrimaryContact}}</span>

                                </a></div>
                            <div class="text-secondary cursor-pointer item-hover">{{profilelist.AccountNumber}}</div>
                            <div class="text-secondary cursor-pointer item-hover" *ngFor="let emaillist of Emaillist">
                                <div *ngIf="emaillist.IsPrimary==true">
                                    {{emaillist.EmailAddress}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div *ngFor="let address of Addresslist ">
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.Address1}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover"
                                    *ngIf="address.IsPrimary==true && address.Address2 != '' && address.Address2 != null">
                                    {{address.Address2}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.City}}, {{address.StateAbbreviation}} {{address.PostalCode}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-secondary cursor-pointer item-hover"
                                *ngFor="let phonelist of phonenumberlist">
                                <div *ngIf="phonelist.IsPrimary==true">
                                    {{phonelist.PhoneType}} {{phonelist.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex my-4">
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <!-- <i class="fa fa-ellipsis-h fs-4"></i> -->
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <!-- <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                        </div> -->
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3 my-1">
                                <a (click)="openmodal(addnewnote)" (click)="openSaveOrUpdateNote('')" class="menu-link px-3">New Note</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header card-header-stretch overflow-auto mt-4">
            <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="redirecttoprofile()" role="tab">
                        <fa-icon [icon]="faUserCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5" *ngIf="userRole != 'Volunteer'">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Timeline' }"
                        (click)="setActiveTab('Timeline')" role="tab">
                        <fa-icon [icon]="faTimeline" class="fs-4 me-3  cursor-pointer"></fa-icon>
                        History
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="redirecttoavailability()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Availability
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'RelationShips' }"
                        (click)="redirecttorelationships()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        RelationShips
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="card-custom">

        <div class="tab-content pt-3">
            <div class="tab-pane" [ngClass]="{ active: activeTab === 'Timeline' }">
                <div class="card p-5 mt-0">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="timeline-label" *ngFor="let details of timelinedetails;">
                                <div class="timeline-item">
                                    <div class="timeline-label fw-bold text-gray-800 fs-6 ">
                                        {{details.TimelineDate | date:'longDate'}}</div>
                                    <div class="timeline-badge">
                                        <i *ngIf="details.TimelineType=='Interactions'" class='far fa-comment fs-1'></i>
                                        <fa-icon *ngIf="details.TimelineType=='Notes'" [icon]="faEdit"
                                            class="fs-1 text-muted">
                                        </fa-icon>
                                        <i *ngIf="details.TimelineType=='Transaction'"
                                            class='fas fa-gift fs-1 text-muted'></i>
                                    </div>
                                    <div class="fw-bold timeline-content text-primary ps-3 fs-5">
                                        {{details.TimelineSource}}
                                        <div *ngIf="details.TimelineType == 'Notes'"
                                            class="fw-normal timeline-content text-muted cursor-pointer"
                                            (click)="openmodal(addnewnote)" (click)="openSaveOrUpdateNote(details.CRMTimeLineId)">
                                            {{details.TimelineDescription}}</div>
                                        <div *ngIf="details.TimelineType != 'Notes'"
                                            class="fw-normal timeline-content text-muted cursor-pointer">
                                            {{details.TimelineDescription}}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #addnewnote let-modal>
        <div class="modal-lg">
            <div class="modal-header modal-lg background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrNote}}</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="closeNotesModel()"></a>
            </div>
           <div class="modal-body">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <label class="py-1 text-dark fs-5 text-end">Date</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                        <smart-date-time-picker #datetimepicker3 [(ngModel)]="notesModel.NotesDate"
                            [calendarButton]="true" [enableMouseWheelAction]="true"
                             [dropDownPosition]="'center-bottom'"
                            [editMode]="'partial'" formatString="MM/dd/yyyy"
                            [dropDownDisplayMode]="'calendar'" class="w-100"></smart-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <label class="py-1 text-dark fs-5 text-end">Note</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                        <div class="mt-3">
                            <textarea class="form-control p-2 border-secondary fs-6 fw-normal" placeholder="Description"
                                [(ngModel)]="notesModel.NoteDescription" rows="6"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="closeNotesModel()">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal" (click)="SaveOrUpdateCRMNotes(notesModel)"><i class="fa fa-save fs-7 text-white"></i>
                    {{btnNote}}</button>
            </div>
        </div>
    </ng-template>

    <!-- <ng-template #addnewnote let-modal>
        <div class="modal-lg">
            <div class="modal-header background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrNote}}</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="closeNotesModel()"></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <label class="py-1 text-dark fs-5 text-end">Date</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                        <smart-date-time-picker #datetimepicker3 [(ngModel)]="notesModel.NotesDate"
                            [calendarButton]="true" [enableMouseWheelAction]="true"
                            [interval]="'new smartUtilities.TimeSpan(24, 0, 0)'" [dropDownPosition]="'center-bottom'"
                            [editMode]="'partial'" formatString="MM/dd/yyyy"
                            [dropDownDisplayMode]="'calendar'" class="w-100"></smart-date-time-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2">
                        <label class="py-1 text-dark fs-5 text-end">Note</label>
                    </div>
                    <div class="col-lg-10 col-md-10 col-sm-10">
                        <div class="mt-3">
                            <textarea class="form-control p-2 border-secondary fs-6 fw-normal" placeholder="Description"
                                [(ngModel)]="notesModel.NoteDescription" rows="6"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="closeNotesModel()">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal" (click)="SaveOrUpdateCRMNotes(notesModel)"><i
                        class="fa fa-save fs-7 text-white"></i>
                    {{btnNote}}</button>
            </div>
        </div>
    </ng-template> -->
    <ng-template #editnote let-modal>
        <div class="modal-lg">
            <div class="modal-header modal-lg background-secondary px-3 py-2">
                <h5 class="modal-title text-white fs-4 fw-semibold">{{hdrNote}}</h5>
                <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="py-1 text-dark fs-5 text-end">Date</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <input type="date" autocomplete="off" class="form-control bg-transparent p-2">
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <label class="py-1 text-dark fs-5 text-end">Note</label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="mt-3">
                            <textarea class="form-control p-2 border-secondary fs-6 fw-normal"
                                placeholder="Description"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                    (click)="modal.dismiss('Cross click')">Cancel</button>
                <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                    data-bs-dismiss="modal"><i class="fa fa-save fs-7 text-white"></i>
                    Save</button>
            </div>
        </div>
    </ng-template>
</div>