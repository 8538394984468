<div class="row px-4 py-0 pb-15">
    <div class="card-custom">
        <ul class="breadcrumb pb-2" *ngIf="userRole != 'Volunteer'">
            <li><a class="routingcontacts" routerLink="/profiles">Profiles </a> >> </li>
            <li> Profile</li>
        </ul>
        <div class="card card-body py-3">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark ">{{profilelist.PrimaryContact}}</span>
                                </a></div>
                            <div class="text-secondary cursor-pointer item-hover">{{profilelist.AccountNumber}}</div>
                            <div class="text-secondary cursor-pointer item-hover" *ngFor="let emaillist of Emaillist">
                                <div *ngIf="emaillist.IsPrimary==true">
                                    {{emaillist.EmailAddress}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div *ngFor="let address of Addresslist ">
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.Address1}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover"
                                    *ngIf="address.IsPrimary==true && address.Address2 != '' && address.Address2 != null">
                                    {{address.Address2}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.City}}, {{address.StateAbbreviation}} {{address.PostalCode}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-secondary cursor-pointer item-hover"
                                *ngFor="let phonelist of phonenumberlist">
                                <div *ngIf="phonelist.IsPrimary==true">
                                    {{phonelist.PhoneType}} {{phonelist.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex my-4">
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                        </div>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Payments</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Generate
                                    Bill</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Settings</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="card-header card-header-stretch overflow-auto mt-4">
            <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" (click)="redirecttoprofile()" role="tab">
                        <fa-icon [icon]="faUserCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5" *ngIf="userRole != 'Volunteer'">
                    <a class="nav-link cursor-pointer" (click)="redirecttotimeline()" role="tab">
                        <fa-icon [icon]="faTimeline" class="fs-4 me-3  cursor-pointer"></fa-icon>
                        History
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="setActiveTab('Availability')" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Availability
                    </a>
                </li>
                <li class="nav-item py-2 px-5">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'RelationShips' }"
                        (click)="redirecttorelationships()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        RelationShips
                    </a>
                </li>
            </ul>
        </div>
        <div class="card-custom">
            <div class="tab-content pt-3">
                <div class="tab-pane" [ngClass]="{ active: activeTab === 'Availability' }">
                    <div class="card p-5">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-4">
                                <select name="days" data-control="select2" data-hide-search="true"
                                    class="form-select form-select-solid form-select-sm mt-6 bg-transparent p-2 border-secondary fs-7 fw-normal"
                                    [(ngModel)]="Selectedweek" (ngModelChange)="onSelectName($event)">
                                    <option value="8" selected="selected" disabled>Select Day</option>
                                    <option *ngFor="let week of weekdays" [ngValue]="{ id: week.id, name: week.name}">
                                        {{week.name}}</option>
                                </select>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3">
                                <div class="">Start Time</div>
                                <input type="time" placeholder="8:00 AM" name="startTime" autocomplete="off"
                                    class="form-control bg-transparent p-2"
                                    [(ngModel)]="updateavailable.AvailableStartTime">
                                <!-- <smart-date-time-picker #startdate [calendarButton]="true" [enableMouseWheelAction]="true" 
                                    [dropDownPosition]="'center-bottom'" [spinButtons]="true" [spinButtonsPosition]="'left'" ></smart-date-time-picker>            -->

                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-3">
                                <div class="">End Time</div>
                                <input type="time" placeholder="8:00 AM" name="endTime" autocomplete="off"
                                    class="form-control bg-transparent p-2"
                                    [(ngModel)]="updateavailable.AvailableEndTime">
                                <!-- <smart-date-time-picker #enddate [calendarButton]="true" [enableMouseWheelAction]="true" 
                                    [dropDownPosition]="'center-bottom'" [spinButtons]="true" [spinButtonsPosition]="'left'" drop-down-display-mode="timePicker"></smart-date-time-picker>   -->
                                <!-- <smart-date-time-picker #enddate calendar-button drop-down-position="bottom"
                                    drop-down-display-mode="timePicker"></smart-date-time-picker> -->
                            </div>

                            <div class="col-lg-2 col-md-2 col-sm-2 text-end">
                                <a class="btn background-success p-1 fs-7 rounded-0 text-white mt-6" data-bs-target="#"
                                    data-bs-toggle="modal" data-toggle="tooltip" title="Add Timeslot"
                                    (click)="update_CRMCustomerAvailableTimeSlots(updateavailable)">
                                    <i class="fa fa-plus p-1 fs-7 text-white me-1"></i>Add</a>
                            </div>
                        </div>
                        <div class="mt-3">
                            <smart-grid [paging]="paging" [pager]="pager" [layout]="layout"
                                [dataSource]="availabilitygridsource" [columns]="columns" [appearance]="appearance"
                                #availabilitygrid id="availabilitygrid"></smart-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #edittimeslot let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Edit Timeslot</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="clearmodal()"
                (click)="modal.dismiss('Cross click')"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Day</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <select name="days" data-control="select2" data-hide-search="true"
                        class="form-select form-select-solid form-select-sm bg-transparent p-2 border-secondary fs-7 fw-normal"
                        [ngModel]="updatetimesolt.AvailableDay" (ngModelChange)="onSelectweek($event)">
                        <option value="All" selected="selected" disabled>Select Day</option>
                        <option *ngFor="let week of weekdays" [ngValue]="week.name">
                            {{week.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4">
                    <label class="py-1 text-dark fs-5 text-end">Start Time</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 mt-4">
                    <input type="time" placeholder="8:00 AM" name="startTime" autocomplete="off"
                        class="form-control bg-transparent p-2" [(ngModel)]="updatetimesolt.AvailableStartTime">
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end mt-4">End Time</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 mt-4">
                    <input type="time" placeholder="8:00 AM" name="startTime" autocomplete="off"
                        class="form-control bg-transparent p-2 " [(ngModel)]="updatetimesolt.AvailableEndTime">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodal()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal" (click)="update_CRMCustomerAvailableTimeSlots(updatetimesolt)"><i
                    class="fa fa-save fs-7 text-white"></i> Update Timeslot</button>
        </div>
    </ng-template>
</div>