<div class="app-main flex-column flex-row-fluid ">
    <div class="d-flex flex-column flex-column-fluid">
        <div class="app-toolbar py-1 py-lg-3">
            <div class="container-fluid d-flex flex-stack ps-3">
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 class="page-heading d-flex text-dark fw-bold fs-4 flex-column justify-content-center my-0">
                        {{componentname}}</h1>
                </div>
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                </div>
            </div>
        </div>

        <div class="app-content flex-column-fluid p-4 pt-0">
            <div class="card mb-xxl-8">
                <div class="card-header px-1 min-h-30px background-secondary">
                    <div class="card-title">
                        <h3 class="fs-5 fw-semibold ms-1 text-white text-truncate mw-500px">Edit Details
                        </h3>
                    </div>
                    <div class="d-flex align-items-center gap-2 gap-lg-3 me-3">
                        <i class="fa fa-close fs-7 text-white cursor-pointer" data-toggle="tooltip" title="Cancel"
                            data-bs-target="#" data-bs-toggle="modal" (click)="redirecttoform()"></i>
                        <i class="fa fa-trash fs-7 text-white cursor-pointer" data-toggle="tooltip"
                            title="Delete Component" (click)="open(delete)"></i>
                        <a (click)="saveformcomponent(componentidlist)"><i
                                class="fa fa-save fs-7 text-white cursor-pointer" data-toggle="tooltip"
                                title="Save Component" data-bs-target="#" data-bs-toggle="modal"></i></a>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="text-secondary fs-6 mb-1">Name</label>
                            <input type="text" name="name" autocomplete="off"
                                class="form-control bg-transparent p-2 fs-7 text-dark"
                                [(ngModel)]="componentidlist.FormComponentName">
                            <div class="mt-3">
                                <label class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="1"
                                        [(ngModel)]="componentidlist.IsRequired" />
                                    <span class="form-check-label fw-semibold text-dark fs-base ms-1">Required</span>
                                </label>
                                <label class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="1"
                                        [(ngModel)]="componentidlist.IsActive" />
                                    <span class="form-check-label fw-semibold  text-dark fs-base ms-1">Active</span>
                                </label>
                                <label class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="1"
                                        [(ngModel)]="componentidlist.IsArchived" />
                                    <span class="form-check-label fw-semibold text-dark fs-base ms-1">Archived</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <label class="text-secondary fs-6 mb-1">Data Type</label>
                            <ng-select [disabled]="true" data-control="select2" data-hide-search="true"
                                [(ngModel)]='componentidlist.FormComponentDataType'
                                [clearable]="false" data-kt-select2="true"
                                data-placeholder="Select option" data-allow-clear="true" placeholder="Select Datatype">
                                <ng-option value="" disabled>Select Data Type</ng-option>
                                <ng-option *ngFor='let datatype of datatypes' [value]="datatype" class="text-dark">
                                    {{datatype}}</ng-option>
                            </ng-select>
                            <label class="text-secondary fs-6 my-1">Option Type</label>
                            <ng-select [disabled]="true" data-control="select2" data-hide-search="true"
                                [(ngModel)]='componentidlist.FormComponentOptionType'
                                [clearable]="false" data-kt-select2="true"
                                data-placeholder="Select option" data-allow-clear="true" placeholder="Select Datatype">
                                <ng-option value="" disabled>Select Data Type</ng-option>
                                <ng-option *ngFor='let option of options' [value]="option" class="text-dark">{{option}}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-header px-1 min-h-30px background-secondary">
                    <div class="card-title">
                        <h3 class="fs-5 fw-semibold ms-1 text-white text-truncate mw-500px">Available
                            Values ({{Optionslist.length}})</h3>
                    </div>
                    <div class="d-flex align-items-center gap-2 gap-lg-3 me-3">
                        <i class="fa fa-plus fs-7 text-white cursor-pointer" data-toggle="tooltip" title="Add Value"
                            (click)="open(addcomponent)"></i>
                    </div>
                </div>
                <div class="card-body px-5 py-2">
                    <ul class="list-unstyled">
                        <div cdkDropList class="dragnddroplist" (cdkDropListDropped)="drop($event)">
                            <li class="border-bottom dragnddropbox" *ngFor="let option of Optionslist" cdkDrag>
                                <div class="row py-2">
                                    <div class="col-lg-10 col-md-10 col-sm-10">
                                        <label class="text-dark">{{option.FormComponentOptionValue}}</label>
                                    </div>
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        <div class="d-flex float-end">
                                            <div> <i class="fa fa-pencil fs-7 text-secondary cursor-pointer me-1"
                                                    data-toggle="tooltip" title="Edit Option" (click)="open(editoption)"
                                                    (click)="get_optiondetails(option.FormComponentOptionId)"></i>
                                                <i class="fa fa-trash fs-6 text-danger cursor-pointer me-1"
                                                    (click)="open(delete)"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <ng-template #addsection let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Add Section</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Section</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" placeholder="Enter Section">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal"><i class="fa fa-save fs-7 text-white"></i>
                Save
                Section</button>
        </div>
    </ng-template>
    <ng-template #addcomponent let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Add Option</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Option</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" [(ngModel)]="newoption.FormComponentOptionValue"
                        placeholder="Enter Option Name">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="newoption.IsRequired" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="newoption.IsActive" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="newoption.IsArchived" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal" (click)="saveformcomponentoption(newoption)"><i
                    class="fa fa-save fs-7 text-white"></i>
                Save
                Option</button>
        </div>
    </ng-template>
    <ng-template #editoption let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Edit Option</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label class="py-1 text-dark fs-5 text-end">Option</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <input class="form-control p-2 fs-7" [(ngModel)]="Optioneditdetails.FormComponentOptionValue"
                        placeholder="Enter Section">
                    <div class="mt-3">
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="Optioneditdetails.IsRequired" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Required</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="Optioneditdetails.IsActive" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Active</span>
                        </label>
                        <label class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="1"
                                [(ngModel)]="Optioneditdetails.IsArchived" />
                            <span class="form-check-label fw-semibold text-gray-700 fs-base ms-1">Archived</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-primary px-2 py-1 fs-7 rounded-0 text-white"
                (click)="updateformcomponentoption(Optioneditdetails)" data-bs-dismiss="modal"><i
                    class="fa fa-save fs-7 text-white"></i> Update Option</button>
        </div>
    </ng-template>
    <ng-template #delete let-modal>
        <div class="modal-header background-secondary px-3 py-2">
            <h5 class="modal-title text-white fs-4 fw-semibold">Delete</h5>
            <a type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                (click)="clearmodals()"></a>
        </div>
        <div class="modal-body">
            <h3 class="fs-6 fw-semibold">Do you want to delete?</h3>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn background-info px-2 py-1 fs-7 rounded-0 text-white"
                (click)="modal.dismiss('Cross click')" (click)="clearmodals()">Cancel</button>
            <button type="button" class="btn background-danger px-2 py-1 fs-7 rounded-0 text-white"
                data-bs-dismiss="modal"><i class="fa fa-trash fs-7 text-white"></i> Delete</button>
        </div>
    </ng-template>

</div>