<div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center  bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-secondary
  ">
  <div class="flex-center flex-column flex-column-fluid p-10">
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2">
        <img alt="Logo" src="./assets/media/logos/volunteers_llc.png" height="150px" width="150px" />
      </div>
      <div class="col-sm-4 col-lg-2 col-md-2 text-center d-flex welcometext">
        <div class="row">
          Welcome to
          Volunteers.llc
        </div>
      </div>
      <div class="col-sm-6 col-lg-8 col-md-8 userdetailscard">
        <div class="w-lg-500px bg-white rounded shadow-sm p-lg-15 mx-auto cardpadding">
          <div class="text-center mb-10">
            <h1 class="text-primary mb-3">Log In</h1>
          </div>
          <div>
            <div class="fv-row mb-5 mt-2 position-relative">
              <input [(ngModel)]='loginCredentials.username' placeholder="Enter Your Email"
                class="inputpading form-control">
              <span class="position-absolute translate-middle top-50 end-0 me-4"><i class="fa fa-envelope"
                  aria-hidden="true"></i></span>
              <div name="mail-outline"></div>
            </div>
            <div class="position-relative">
              <input [(ngModel)]='loginCredentials.password' [type]="changetype?'password':'text'"
                placeholder="Enter Your Password" class="inputpading form-control"
                (keydown.enter)='doLogin(loginCredentials)'>
              <span class="position-absolute translate-middle top-50 end-0 me-4" (click)="viewpass()"><i
                  [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
              <div name="lock-closed-outline"></div>
            </div><br>
            <div class="row">
              <div class="col-lg-7 col-sm-3">
              </div>
              <div class="col-lg-5 col-sm-9 mb-2">
                <a routerLink="/forgot-password" class="link-primary fs-6 fw-bolder text-primary" id="kt_login_forgot"
                  [style.margin-left]="'5px'">
                  Forgot Password?
                </a>
              </div>
            </div>
            <br>
            <button (click)="doLogin(loginCredentials)"
            (click)="toggleLoading()" class="btn btn-lg w-100 mb-2 btn-primary1">
              {{loginbtn}}
            </button>
            <div class="text-center text-muted fw-bolder mb-5">Or</div>
            <button (click)="Redirecttosignup()" class="btn btn-lg w-100 mb-2 btn-primary1">
              Sign Up
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>