import { DatePipe } from '@angular/common';
import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { GridComponent, GridColumn, DataAdapter, Smart } from 'smart-webcomponents-angular/grid';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

type Tabs = 'ApiLog' | 'DbLog';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
    activeTab: Tabs = 'ApiLog';
    faLayerGroup = faLayerGroup;

    @ViewChild('reportgrid', { read: GridComponent, static: false }) grid!: GridComponent;
    daterange: any = { startTime: '', endTime: '' }
    reports: any = [];
    constructor(public router: Router,
        private reportsService: ReportsService,
        public toastralert: ToastralertService,
        private datePipe: DatePipe) { }
  
    ngOnInit(): void {
    }
 
    setActiveTab(tab: Tabs) {
        this.activeTab = tab;
      }

   
}
