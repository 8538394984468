import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  token_response: any = {};
  token: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  get_DSBConstituentsCountbyTenantId() {
    return this.http.get(`${environment.GetDSBConstituentsCountbyTenantId}`).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_DSBThisMonthCounts() {
    return this.http.get(`${environment.GetDSBThisMonthCounts}`+ '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  

}
  