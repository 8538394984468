import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { GridComponent, DataAdapter, GridColumn, Smart } from 'smart-webcomponents-angular/grid';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { ButtonComponent } from 'smart-webcomponents-angular/button';
import { ActivatedRoute, Router } from '@angular/router';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-volunteersreports',
  templateUrl: './volunteersreports.component.html',
  styleUrls: ['./volunteersreports.component.scss']
})
export class VolunteersreportsComponent implements OnInit {
  @ViewChild('reportgrid', { read: GridComponent, static: false }) grid!: GridComponent;
  @ViewChild('exportexcelbutton', { read: ButtonComponent, static: false }) xlsxBtn!: ButtonComponent;
  databases = [];
  connectionDataBases = [];
  columnNames = [];
  selectedColumnslist = [];
  databaseName: any = "";
  tableName: any = "";
  radiobtnVal: any = "";
  generateQueryList: any = [];
  groupCol: any = [];
  orderCol: any = [];
  selectCol: any = [];
  whereConditionColumn: any = "";
  whereConditionValue: any = "";
  GroupByColumnsList: any = [];
  OrderByValue: any = "";
  OrderBycolumnsList: any = [];
  aggFun: any = "";
  agColumnnames: any = "";
  conditionVal: any = "";
  Query: any = "";
  reports: any = [];
  daterange: any = { startTime: '', endTime: '' }
  reportDataFields: any[] = [];
  reportColumns: any = [];
  SqlQueryText: any = "";
  form: FormGroup;
  dataList: any[] = [];
  rowsList: any = [];
  token_response: any = {};
  token: any;
  QueryName: any = "";
  QueryDescription: any = "";
  isSave:any=0;
  aggFunctionsList: any = [];
  reportQueryId: any;
  header: string = 'New';
  reportDetails: any;


  constructor(private reportservice: ReportsService,  public toastralert: ToastralertService,
    private datePipe: DatePipe, private router: Router, private formBuilder: FormBuilder, public route: ActivatedRoute,) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.reportQueryId = params['reportQueryId'];
    });
    console.log(this.reportQueryId);
    this.header = this.reportQueryId != undefined ? 'Edit' : 'New';
    if (this.reportQueryId != undefined) {
      this.GetReportQueryByReportQueryId(this.reportQueryId);
    }
    this.reportservice.PageLoad();
    this.GetDatabases();
    this.GetConnectDatabase();
    this.form = this.formBuilder.group({
      rows: this.formBuilder.array([]) // Initialize an empty form array
    });
    this.addRow();
  
  };

 

  addRow():void { 
    const newRow = this.formBuilder.group({
       ReportQueryAggFunctions: [''],
        ReportQueryAggFunColumnName: [''],
         ReportQueryWhereConditionColumnName: [''],
          ReportQueryWhereConditionOperator: [''],
           ReportQueryWhereConditionValue: [''], 
           ReportQueryIsOrAND: [true], 
          }); this.rowsFormArray.push(newRow); 
        }

  ngAfterViewInit(): void {
    this.xlsxBtn.addEventListener('click', () => {
      this.grid.exportData('xlsx');
    });
  }

  get rowsFormArray() {
    return this.form.get('rows') as FormArray;
  }

  GetDatabases() {
    this.reportservice.Get_FetchDatabase().subscribe((results) => {
      console.log(results);
      this.databases = results;
    },
      (err) => {

      });
  }
  GetRadioBtnData(e: any) {
    this.radiobtnVal = e.target.value;
    console.log(this.radiobtnVal);
  }

  GetConnectDatabase() {
    this.connectionDataBases = [];
    this.reportservice.Get_ConnectDatabase('VolunteersLLCDev', 'table').subscribe((results) => {
      this.connectionDataBases = results;
    },
      (err) => {

      });
  }

  GetReportQueryByReportQueryId(reportQueryId: any) {
    // let groupByColumns = "";
    // let sqlQuery = "";
    this.reportservice.GetReportQueryByReportQueryId(reportQueryId).subscribe((results) => {
      this.reportDetails = results;
      console.log(this.reportDetails);
      this.tableName = results.ReportQueryTables;
      this.GetTableColumns();
      if (results.ReportQuerySelectedColumns) {
        this.selectedColumnslist = results.ReportQuerySelectedColumns.split(",");
      }
      if (results.ReportQueryTT && results.ReportQueryTT.length > 0) {
        for (let i = 0; i < results.ReportQueryTT.length; i++) {
          this.addRow();
        }
      }
      // this.form.setValue({
      //   rows: results.ReportQueryTT
      // })
      const rows = this.form.get('rows') as FormArray;

      // Clear existing rows
      rows.clear();

      // Iterate over the API data and add a new row for each item
      results.ReportQueryTT.forEach((value: any) => {
        console.log(value.ReportQueryIsOrAND)
        rows.push(this.formBuilder.group({
          "ReportQueryAggFunctions": value.ReportQueryAggFunctions,
          "ReportQueryAggFunColumnName": value.ReportQueryAggFunColumnName,
          "ReportQueryWhereConditionColumnName": value.ReportQueryWhereConditionColumnName,
          "ReportQueryWhereConditionOperator": value.ReportQueryWhereConditionOperator,
          "ReportQueryWhereConditionValue": value.ReportQueryWhereConditionValue,
          "ReportQueryIsOrAND": value.ReportQueryIsOrAND,
        }));
      });
      console.log(this.rowsFormArray)
      if (results.ReportQueryOrderByCols) {
        this.OrderBycolumnsList = results.ReportQueryOrderByCols.split(",");
      }
      if (results.ReportQueryGroupByColumns) {
        this.GroupByColumnsList = results.ReportQueryGroupByColumns.split(",");
      }
      this.OrderByValue = results.ReportQueryOrderBy;
      this.SqlQueryText = results.ReportQuery;
      this.QueryName = results.ReportQueryName;
      this.QueryDescription = results.ReportQueryDescription;
      const myButton = document.getElementById('runQuery') as HTMLButtonElement;
      myButton.click();
    },
      (err) => {

      });
  }

  GetTableColumns() {
    this.columnNames = [];
    this.reportservice.Get_Columns('VolunteersLLCDev', this.tableName).subscribe((results) => {
      this.columnNames = results;
      console.log(this.columnNames);
    },
      (err) => {

      });

  }

  Generatequery() {
    this.selectCol = [];
    this.groupCol = [];
    this.orderCol = [];
    this.rowsList = [];
    const formData = this.form.value;
    this.dataList = formData.rows;
    if (this.selectedColumnslist.length != 0) {
      console.log(this.selectedColumnslist);
      this.selectedColumnslist.forEach((value: any, item: any) => {

        const model = {
          "ColumnName": value
        }
        this.selectCol.push(model);
      });
    }
    if (this.GroupByColumnsList.length != 0) {
      console.log(this.GroupByColumnsList);
      this.GroupByColumnsList.forEach((value: any, item: any) => {

        const model = {
          "ColumnName": value
        }
        this.groupCol.push(model);
      });
    }
    if (this.OrderBycolumnsList.length != 0) {
      console.log(this.OrderBycolumnsList);
      this.OrderBycolumnsList.forEach((value: any, item: any) => {

        const model = {
          "ColumnName": value
        }
        this.orderCol.push(model);
      });
    }
    if (this.dataList.length != 0) {
      this.dataList.forEach((value: any, item: any) => {
        const model = {
          "aggFun": value.ReportQueryAggFunctions,
          "agColumnnames": value.ReportQueryAggFunColumnName,
          "whereConditionColumn": value.ReportQueryWhereConditionColumnName,
          "conditionVal": value.ReportQueryWhereConditionOperator,
          "whereConditionValue": value.ReportQueryWhereConditionValue,
          "ORcondition": false,
        }
        this.rowsList.push(model);
      });
    }

    console.log(this.rowsList);
    let queryList = {
      SelectedColumns: this.selectCol,
      WhereColumn: this.whereConditionColumn,
      WhereColumnValue: this.whereConditionValue,
      GroupBycolumns: this.groupCol,
      OrderBy: this.OrderByValue,
      OrderByColumns: this.orderCol,
      TableName: this.tableName,
      AgregatenCb: this.aggFun,
      AvgfiledSelectionCb: this.agColumnnames,
      conditionalOpCB: this.conditionVal,
      databaseName: 'VolunteersLLCDev',
      FormData: this.rowsList
    };

    console.log(queryList);
    this.reportservice.GenerateQuery(queryList).subscribe((results) => {
      this.SqlQueryText = results;
      console.log(results);
    },
      (err) => {

      });
  }

  appearance = {
    allowSortAnimation: true
  }

  layout: object = {
    rowHeight: 35
  }

  pager = {
    visible: true
  }

  paging = {
    enabled: true,
    pageSize: 15
  }
  RunQuery() {
    this.reportgrid.dataSource = [];
    this.reportgridsource = [];
    this.reportColumns = [];
    this.reportservice.RunSqlQuery('VolunteersLLCDev', this.SqlQueryText)
      .subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.reports = results;
          this.reportgrid.dataSource = results;
          this.reportgridsource = results;  
          this.reportDataFields = results[results.length - 1]
          console.log(this.reportDataFields);
          if(this.reportDataFields && this.reportDataFields.length > 0){
            this.reportDataFields.forEach((value: any, item: any) => {
              const model = {
                "label": value,
                "dataField": value
              }
              this.reportColumns.push(model);
            });
          }
          console.log(results[results.length - 1]);
          console.log(results);
        }
      },
        (err) => {

        });
  }
  behavior = { allowColumnReorder: true, rowResizeMode: 'growAndShrink', columnResizeMode: 'growAndShrink' }

  reportgrid: DataAdapter = new window.Smart.DataAdapter(
    {
      dataSource: [],
      dataSourceType: 'json',
      dataFields: this.reportDataFields
    })
  reportgridsource: any = this.reportgrid;
  columns = this.reportColumns;



  SaveOrUpdateQuery() {
  
    console.log(this.tableName,this.SqlQueryText,this.QueryName)
    if ( this.tableName == 'undefined' || this.tableName == "" || this.tableName == null) {
      this.toastralert.toastrwarnningbottomright("Please select a Table Name");
      return false;
    }
    // else if ( this.SqlQueryText == 'undefined' || this.SqlQueryText == "" || this.SqlQueryText == null){
    //   this.toastralert.toastrwarnningbottomright("Please Generate the sql Query");
    //   return false;
    // }
    else if ( this.QueryName == 'undefined' || this.QueryName == "" || this.QueryName == null){
      this.toastralert.toastrwarnningbottomright("Please enter Report Name");
      return false;
    }
  
    else {
      let selectedColumns = "";
      let groupByColumns = "";
      let  orderByColumns = "";
      this.aggFunctionsList = [];
      let QueryId = "00000000-0000-0000-0000-000000000000";
      if (this.reportQueryId != undefined) {
        QueryId = this.reportQueryId;
      }
      else {
        QueryId = '00000000-0000-0000-0000-000000000000';
      }
      if (this.selectedColumnslist.length != 0) {
        console.log(this.selectedColumnslist);
        this.selectedColumnslist.forEach((value: any, item: any) => {
          if (selectedColumns == '') {
            selectedColumns = value;
          }
          else {
            selectedColumns += ', ' + value;
          }
        });
      }
      if (this.GroupByColumnsList.length != 0) {
        console.log(this.GroupByColumnsList);
        this.GroupByColumnsList.forEach((value: any, item: any) => {

          if (groupByColumns == '') {
            groupByColumns = value;
          }
          else {
            groupByColumns += ', ' + value;
          }
        });
      }
      if (this.OrderBycolumnsList.length != 0) {
        console.log(this.OrderBycolumnsList);
        this.OrderBycolumnsList.forEach((value: any, item: any) => {

          if (orderByColumns == '') {
            orderByColumns = value;
          }
          else {
            orderByColumns += ', ' + value;
          }
        });
      }

      const formData = this.form.value;

      //let isSave =0 ;
      if (formData.rows.length != 0) {
        // formData.rows.some((value: any, item: any) => {
        //   console.log(value.ReportQueryAggFunctions);
        //   console.log(value.ReportQueryAggFunColumnName);
        //  if(value.ReportQueryAggFunctions !=null && value.ReportQueryAggFunctions != "" &&value.ReportQueryAggFunctions != undefined){
        //   if(value.ReportQueryAggFunColumnName == "" || value.ReportQueryAggFunColumnName==undefined){
        //      this.toastralert.toastrwarnningbottomright("Please select aggfun column name");
        //      //this.isSave = 1;
        //    return false;
        //   }
        //  }
        //   const model = {
        //     "ReportQueryAggFunctionId": '00000000-0000-0000-0000-000000000000',
        //     "ReportQueryAggFunctions": value.ReportQueryAggFunctions,
        //     "ReportQueryAggFunColumnName": value.ReportQueryAggFunColumnName,
        //     "ReportQueryWhereConditionColumnName": value.ReportQueryWhereConditionColumnName,
        //     "ReportQueryWhereConditionOperator": value.ReportQueryWhereConditionOperator,
        //     "ReportQueryWhereConditionValue": value.ReportQueryWhereConditionValue,
        //     "ReportQueryIsOrAND":value.ReportQueryIsOrAND,
        //   }
        //   this.aggFunctionsList.push(model);
        // });

        for(let i =0;i< formData.rows.length; i++){
          if(formData.rows[i].ReportQueryAggFunctions !=null && formData.rows[i].ReportQueryAggFunctions != "" &&formData.rows[i].ReportQueryAggFunctions != undefined){
            if(formData.rows[i].ReportQueryAggFunColumnName == "" || formData.rows[i].ReportQueryAggFunColumnName==undefined){
               this.toastralert.toastrwarnningbottomright("Please select aggfun column name");
               //this.isSave = 1;
             return false;
            }
           }
           if(formData.rows[i].ReportQueryWhereConditionColumnName !=null && formData.rows[i].ReportQueryWhereConditionColumnName != "" &&formData.rows[i].ReportQueryWhereConditionColumnName != undefined){
            if(formData.rows[i].ReportQueryWhereConditionOperator == "" || formData.rows[i].ReportQueryWhereConditionOperator==undefined){
              this.toastralert.toastrwarnningbottomright("Please select Where Condition Operator");
              //this.isSave = 1;
            return false;
            }
           }
           if(formData.rows[i].ReportQueryWhereConditionColumnName !=null && formData.rows[i].ReportQueryWhereConditionColumnName != "" &&formData.rows[i].ReportQueryWhereConditionColumnName != undefined){
            if(formData.rows[i].ReportQueryWhereConditionValue == "" || formData.rows[i].ReportQueryWhereConditionValue==undefined){
              this.toastralert.toastrwarnningbottomright("Please select Where Conditionn Value");
              //this.isSave = 1;
            return false;
            }
           }
            const model = {
              "ReportQueryAggFunctionId": '00000000-0000-0000-0000-000000000000',
              "ReportQueryAggFunctions": formData.rows[i].ReportQueryAggFunctions,
              "ReportQueryAggFunColumnName": formData.rows[i].ReportQueryAggFunColumnName,
              "ReportQueryWhereConditionColumnName": formData.rows[i].ReportQueryWhereConditionColumnName,
              "ReportQueryWhereConditionOperator": formData.rows[i].ReportQueryWhereConditionOperator,
              "ReportQueryWhereConditionValue": formData.rows[i].ReportQueryWhereConditionValue,
              "ReportQueryIsOrAND":formData.rows[i].ReportQueryIsOrAND,
            }
            this.aggFunctionsList.push(model);
        }
      }
       if(this.OrderByValue !=null && this.OrderByValue != "" && this.OrderByValue != undefined){
        if(this.OrderBycolumnsList ==""|| this.OrderBycolumnsList== undefined){
          this.toastralert.toastrwarnningbottomright("Please select order column");
          
                //this.isSave = 1;
       
              return false;            
        }
        }
      //if(this.isSave == 0){
       let saveModel = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        ReportQueryId: QueryId,
        ReportQueryName: this.QueryName,
        ReportQueryDescription: this.QueryDescription,
        ReportQueryTables: this.tableName,
        ReportQuerySelectedColumns: selectedColumns,
        ReportQueryOrderBy: this.OrderByValue,
        ReportQueryOrderByCols: orderByColumns,
        ReportQueryGroupByColumns: groupByColumns,
        ReportQuery: this.SqlQueryText,
        AggFunctionsTypeList: this.aggFunctionsList,
       };
       console.log(saveModel);
       this.reportservice.SaveOrUpdateReportQuery(saveModel)
        .subscribe(results => {
          if (results) {
            this.router.navigate(['/reportList']);
          }
        })
      //}
    }

  }
}


