import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
reportslist:any=[];
 
  constructor(private reportservice: ReportsService, private router: Router) { }

  ngOnInit(): void {
    this.GetReportsListByTenantId();
  }

  GetReportsListByTenantId() {
    this.reportservice.GetReportsListByTenantId().subscribe((results) => {
      console.log(results);
      this.reportslist = results;
    },
      (err) => {

      });
  }

  redirectToVolunteersReports(reportQueryId:any){
    this.router.navigate(['/volunteersreports/' + reportQueryId ]);
  }

}
