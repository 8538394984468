import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OptionsService } from 'src/app/services/options.service';
import { FormsService } from 'src/app/services/forms.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { IDeactiveCompoenet } from 'src/app/services/candeactive-guard.service';

@Component({
	selector: 'app-options',
	templateUrl: './options.component.html',
	styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit, IDeactiveCompoenet {

	closeResult: any;
	newoption: any = { FormComponentOptionValue: '', formid: '', sectionid: '', componentid: '', IsRequired: true, IsActive: true, IsArchived: false };
	optionlist: any;
	formId: any;
	sectionId: any;
	componentId: any;
	componentdetails: any = { formid: '', sectionid: '', componentid: '' };
	componentidlist: any = { FormComponentName: '', IsActive: '', IsRequired: true, IsArchived: false };
	editoption: any = { OptionName: '', formid: '', sectionid: '', componentid: '', IsRequired: true, IsActive: true, IsArchived: false };
	editcomponent: any = { FormComponentName: '', FormId: '', FormSectionId: '', FormComponentDataType: '', FormComponentOptionType: '', IsRequired: true, IsActive: true, IsArchived: false };
	options: any = ["Freeform", "PickOne", "PickMultiple"];
	datatypes: any = ["Date", "Decimal", "Text", "Year"];
	componentdatatype: any;
	editomponentresult: string;
	Optionslist: any;
	Optiondetails: any = { formid: '', sectionid: '', componentid: '', componentoptionid: '' };
	Optioneditdetails: any = { FormComponentOptionValue: '', formid: '', sectionid: '', componentid: '', IsRequired: true, IsActive: true, IsArchived: false };
	optionsreorderedlist: any = [];
	optionsorderlist: any = { formid: '', optionslist: '', sectionid: '', componentid: '' };
	optionorder: string;
	formComponentOptionId: string;
	token_response: any;
	token: any;
	componentname: any;
	optionsLength: number = 0;
	dbIsRequired: boolean = false;

	constructor(private modalService: NgbModal, public route: ActivatedRoute, public router: Router,
		private optionsService: OptionsService, private formsService: FormsService, config: NgbModalConfig, public toastralert: ToastralertService) {
		config.backdrop = 'static';
		config.keyboard = false;
		this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
		this.token = this.token_response.access_token;
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.formId = params['formid'];
			this.sectionId = params['sectionid'];
			this.componentId = params['componentid'];
		});
		this.get_componentDeatils();
		this.get_componentoptions();

	}

	@HostListener('window:beforeunload', ['$event'])
	onbeforeunload(event: any) {
		this.canExit();
	}

	redirecttoform() {
		if (this.optionsLength == 0 && this.dbIsRequired && (this.componentidlist.FormComponentOptionType == "PickMultiple" || this.componentidlist.FormComponentOptionType == "PickOne")) {
			this.toastralert.toastrerrorbottom("Please add atleast one option");
		}
		else {
			this.router.navigate(['/setup']);
		}
	}

	get_componentDeatils() {
		this.componentdetails.formid = this.formId,
			this.componentdetails.sectionid = this.sectionId,
			this.componentdetails.componentid = this.componentId
		this.optionsService.get_ComponentDeatilsByComponentId(this.componentdetails).subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.componentidlist = results;
				localStorage.setItem('localFormId', this.formId);
				this.dbIsRequired = results.IsRequired;
				this.componentname = this.componentidlist.FormComponentName;
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get component details.Please try after some time");
			}
		);
	}

	get_componentoptions() {
		this.componentdetails.formid = this.formId,
			this.componentdetails.sectionid = this.sectionId,
			this.componentdetails.componentid = this.componentId
		this.optionsService.get_ComponentOptionsByFormComponentid(this.componentdetails).subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.Optionslist = results;
				this.optionsLength = results.length;
				// if (results.length > 0) {
				// 	localStorage.setItem('isDeActive', "true");
				// }
				// if (this.dbIsRequired && this.optionsLength == 0) {
				// 	localStorage.setItem('isDeActive', "false");
				// }

			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get component option details.Please try after some time")
			}
		);
	}

	get_optiondetails(optionId: any) {
		this.Optiondetails.componentoptionid = optionId,
			this.Optiondetails.formid = this.formId,
			this.Optiondetails.sectionid = this.sectionId,
			this.Optiondetails.componentid = this.componentId
		this.optionsService.get_ComponentOptionsDetailsByComponentoptionid(this.Optiondetails).subscribe((results) => {
			if (results != null && results != 'undefined') {
				this.Optioneditdetails = results;
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to get option details.Please try after some time");
			}
		);
	}

	saveformcomponentoption(newoption: any) {
		if ((typeof newoption.FormComponentOptionValue == 'undefined' || newoption.FormComponentOptionValue == "" || newoption.FormComponentOptionValue == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Option Name');
			return false;
		}
		else {
			let newOptiondetails = {};
			if (newoption.FormSectionId == "") {
				newOptiondetails = {
					CRMPersonId: this.token_response.CRMPersonId,
					TenantId: this.token_response.tenantId,
					FormId: this.formId,
					FormSectionId: this.sectionId,
					FormComponentId: this.componentId,
					FormComponentOptionValue: newoption.FormComponentOptionValue,
					IsRequired: newoption.IsRequired,
					IsActive: newoption.IsActive,
					IsArchived: newoption.IsArchived,
				};
			}
			else {
				newOptiondetails = {
					CRMPersonId: this.token_response.CRMPersonId,
					TenantId: this.token_response.tenantId,
					FormId: this.formId,
					FormSectionId: this.sectionId,
					FormComponentId: this.componentId,
					FormComponentOptionId: newoption.FormComponentOptionId,
					FormComponentOptionValue: newoption.FormComponentOptionValue,
					IsRequired: newoption.IsRequired,
					IsActive: newoption.IsActive,
					IsArchived: newoption.IsArchived,
				};
			}
			this.optionsService.save_FormComponentOptions(newOptiondetails).subscribe((results) => {
				if (results != null && results != "" && results != 'undefined') {
					if (results == '99999999-9999-9999-9999-999999999999') {
						this.toastralert.toastrerrorbottom("This option is already exits, please check once.");
					}
					else {
						this.optionlist = results;
						this.toastralert.toastersuccesstop('Option has been saved successfully');
						this.clearmodals();
						this.get_componentoptions();
					}
				}
				else {
					this.toastralert.toastrerror('Error occurred while saving component options');
				}
			},
				(err) => {
					this.toastralert.toastrerrorbottom("Unable to save component options.Please try after some time");
				}
			);
		}
	}

	updateformcomponentoption(newoption: any) {
		if ((typeof newoption.FormComponentOptionValue == 'undefined' || newoption.FormComponentOptionValue == "" || newoption.FormComponentOptionValue == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Option Name');
			return false;
		}
		else {
			let newOptiondetails = {};
			if (newoption.FormSectionId == "") {
				newOptiondetails = {
					CRMPersonId: this.token_response.CRMPersonId,
					TenantId: this.token_response.tenantId,
					FormId: this.formId,
					FormSectionId: this.sectionId,
					FormComponentId: this.componentId,
					FormComponentOptionValue: newoption.FormComponentOptionValue,
					IsRequired: newoption.IsRequired,
					IsActive: newoption.IsActive,
					IsArchived: newoption.IsArchived,
				};
			}
			else {
				newOptiondetails = {
					CRMPersonId: this.token_response.CRMPersonId,
					TenantId: this.token_response.tenantId,
					FormId: this.formId,
					FormSectionId: this.sectionId,
					FormComponentId: this.componentId,
					FormComponentOptionId: newoption.FormComponentOptionId,
					FormComponentOptionValue: newoption.FormComponentOptionValue,
					IsRequired: newoption.IsRequired,
					IsActive: newoption.IsActive,
					IsArchived: newoption.IsArchived,
				};
			}

			this.optionsService.save_FormComponentOptions(newOptiondetails).subscribe((results) => {
				if (results != null && results != "" && results != 'undefined') {
					if (results == '99999999-9999-9999-9999-999999999999') {
						this.toastralert.toastrerrorbottom("This option is already exits, please check once.");
					}
					else {
						this.optionlist = results;
						this.toastralert.toastersuccesstop('Option has been updated successfully');
						this.clearmodals();
						this.get_componentoptions();
					}
				}
				else {
					this.toastralert.toastrerror('Error occurred while update component options');
				}
			},
				(err) => {
					this.toastralert.toastrerrorbottom("Unable to update component options.Please try after some time");
				}
			);
		}
	}

	saveformcomponent(editcomponent: any) {
		if ((typeof editcomponent.FormComponentName == 'undefined' || editcomponent.FormComponentName == "" || editcomponent.FormComponentName == null)) {
			this.toastralert.toastrwarnningbottomright('Please enter Option Name');
			return false;
		}
		else {
			let newcomponentdetails = {
				CRMPersonId: this.token_response.CRMPersonId,
				TenantId: this.token_response.tenantId,
				FormId: this.formId,
				FormSectionId: this.sectionId,
				FormComponentId: this.componentId,
				FormComponentName: this.componentidlist.FormComponentName,
				FormComponentDataType: this.componentidlist.FormComponentDataType,
				FormComponentOptionType: this.componentidlist.FormComponentOptionType,
				IsRequired: this.componentidlist.IsRequired,
				IsActive: this.componentidlist.IsActive,
				IsArchived: this.componentidlist.IsArchived,
			};
			// editcomponent.FormComponentDataType = this.componentidlist.FormComponentDataType;
			// editcomponent.FormComponentOptionType = this.componentidlist.FormComponentOptionType;
			if (this.optionsLength == 0 && this.componentidlist.IsActive && this.componentidlist.IsRequired && (this.componentidlist.FormComponentOptionType == "PickMultiple" || this.componentidlist.FormComponentOptionType == "PickOne")) {
				this.toastralert.toastrerrorbottom("Please add atleast one option");
				// localStorage.setItem('isDeActive', "false");
			}
			else {
				this.formsService.save_Formcomponentsbyformid(newcomponentdetails).subscribe((results) => {
					if (results != null && results != "" && results != 'undefined') {
						if (results == '99999999-9999-9999-9999-999999999999') {
							this.toastralert.toastrerrorbottom("This component is already exits, please check once.");
						}
						else {
							this.editomponentresult = results;
							this.dbIsRequired = false;
							this.toastralert.toastersuccesstop('Component has been updated successfully');
							localStorage.setItem('localFormId', this.formId);
							this.clearmodals();
							this.router.navigate(['/setup']);
						}
					}
					else {
						this.toastralert.toastrerror('Error occurred while saving component');
					}
				},
					(err) => {
						this.toastralert.toastrerrorbottom("Unable to save component.Please try after some time");
					}
				);
			}

		}
	}

	open(content: any) {
		this.modalService.open(content, { centered: true }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.Optionslist, event.previousIndex, event.currentIndex);
		this.emptyarray();
		this.Optionslist.forEach((i: any, item: any) => {
			const modal = {
				FormComponentOptionId: i.FormComponentOptionId,
				DisplayOrder: item + 1
			}
			this.optionsreorderedlist.push(modal);
		});
		this.optionsreordrlist(this.optionsreorderedlist);
	}

	emptyarray() {
		this.optionsreorderedlist = [];
	}

	optionsreordrlist(optionslist: any) {
		let optionreorderlist = {
			CRMPersonId: this.token_response.CRMPersonId,
			TenantId: this.token_response.tenantId,
			FormId: this.formId,
			FormSectionId: this.sectionId,
			FormComponentId: this.componentId,
			FormComponentOptionOrderModelList: optionslist
		}
		this.optionsService.update_FormComponentOptionsDisplayOrder(optionreorderlist).subscribe((results) => {
			if (results != null && results != "" && results != 'undefined') {
				this.optionorder = results;
			}
			else {
				this.toastralert.toastrerror('Error occurred while saving the options order');
			}
		},
			(err) => {
				this.toastralert.toastrerrorbottom("Unable to save options order.Please try after some time");
			}
		);
	}

	clearmodals() {
		this.newoption = {
			FormComponentOptionValue: '',
			formid: '',
			sectionid: '',
			componentid: '',
			IsRequired: true,
			IsActive: true,
			IsArchived: false
		};
		this.modalService.dismissAll();
	}

	canExit() {
		console.log(this.componentidlist);
		console.log(this.dbIsRequired, this.optionsLength)
		if (this.optionsLength == 0 && this.dbIsRequired && (this.componentidlist.FormComponentOptionType == "PickMultiple" || this.componentidlist.FormComponentOptionType == "PickOne")) {
			this.toastralert.toastrerrorbottom("Please add atleast one option");
			// localStorage.setItem('isDeActive', "false");
			return false;
		}
		else {
			return true;
		}
	}

}
