<div class="row px-4 py-0">
    <div class="app-content flex-column-fluid">
        <div class="card card-flush toolbar-bar">
            <!--begin::Card header-->
            <div class="card-header">
                <!--begin::Card title-->
                <div class="card-title flex-column">
                    <h3 class="fw-bold  fs-5 text-white">Members List</h3>
                </div>
                <!--begin::Card title-->
                <!--begin::Card toolbar-->
                <div class="card-toolbar">
                    <!--begin::Actions-->
                    <div class="d-flex align-items-center gap-2 gap-lg-3">
                        <a class="btn btn-sm p-1 bg-white text-primary">Add
                            New
                            Member
                        </a>
                        <div class="me-2">
                            <select name="Type" placeholder="Type" data-control="select2" data-hide-search="true"
                                class="form-select form-select-solid form-select-sm">
                                <option value="Type" disabled selected>Type
                                </option>
                                <option value="value1">Volunteer</option>
                                <option value="value2">Beneficiary</option>
                                <option value="value3">Admin</option>
                            </select>
                        </div>
                    </div>
                    <!--end::Actions-->

                    <!--begin::Search-->
                    <div class="d-flex align-items-center position-relative my-1">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span class="svg-icon svg-icon-3 position-absolute ms-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="currentColor" />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input type="text" id="kt_filter_search"
                            class="form-control form-control-solid form-select-sm w-150px ps-9" placeholder="Search" />
                    </div>
                    <!--end::Search-->
                </div>
                <!--begin::Card toolbar-->
            </div>
            
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body bg-white pt-0 px-5">
                <!--begin::Table container-->
                <div class="table-responsive">
                    <!--begin::Table-->
                    <table id="kt_profile_overview_table_events"
                        class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                        <!--begin::Head-->
                        <thead class="fs-6 text-gray-400">
                            <tr class="text-primary">
                                <th class="min-w-100px">First Name</th>
                                <th class="min-w-100px">Last Name</th>
                                <th class="min-w-100px">Email</th>
                                <th class="min-w-150px">Phone Number</th>
                                <th class="min-w-100px">Location</th>
                                <th class="min-w-100px">Address</th>
                                <th class="min-w-100px">City</th>
                                <th class="min-w-100px">State</th>
                                <th class="min-w-100px">Zip Code</th>
                                <th class="min-w-50px">Volunteer</th>
                                <th class="min-w-50px">Beneficiary</th>
                                <th class="min-w-50px">Admin</th>
                            </tr>
                        </thead>
                        <!--end::Head-->
                        <!--begin::Body-->
                        <tbody class="fs-7">
                            <tr class="fw-normal cursor-pointer">
                                <td class="p-2">John</td>
                                <td class="p-2">Smith</td>
                                <td class="p-2">johnsmith@gmail.com</td>
                                <td class="p-2">(123)-456-7891</td>
                                <td class="p-2">Location 1</td>
                                <td class="p-2">101</td>
                                <td class="p-2">Main St Denver</td>
                                <td class="p-2">CO</td>
                                <td class="p-2">80021</td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                            </tr>
                            <tr class="fw-normal cursor-pointer">
                                <td class="p-2">John</td>
                                <td class="p-2">Smith</td>
                                <td class="p-2">johnsmith@gmail.com</td>
                                <td class="p-2">(123)-456-7891</td>
                                <td class="p-2">Location 1</td>
                                <td class="p-2">101</td>
                                <td class="p-2">Main St Denver</td>
                                <td class="p-2">CO</td>
                                <td class="p-2">80021</td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                                <td class="p-2">
                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                        <input class="form-check-input rounded-circle" type="checkbox" value="1" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <!--end::Body-->
                    </table>
                    <!--end::Table-->
                </div>
                <!--end::Table container-->
            </div>
            <!--end::Card body-->
        </div>
    </div>
    <!--end::Content-->

</div>