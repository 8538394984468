import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { ToastralertService } from 'src/app/services/toastralert.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
  email: any;
  token: any;

  constructor(public http: HttpClient, public router: Router, private userService: UserService, public toastralert: ToastralertService) {
  }

  ngOnInit(): void {
  }

  validateEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  forgotpswd(email: any) {
    if (typeof email == 'undefined' || email == "" || email == null) {
      this.toastralert.toastrwarnning('Please Enter your Email');
      return false;
    }
    else if (!this.validateEmail(email)) {
      this.toastralert.toastrwarnning('Please Enter Valid Email');
      return false;
    }
    else {
      this.userService.forgotpswd(email).subscribe(results => {
        if (results != null && results != "" && results !='undefined') {
          if (results == 'ForgotPasswordConfirmation') {

            Swal.fire({
              icon: 'success',
              title: 'An email has been sent to the provided address for resetting your password',
              showConfirmButton: false,
              timer: 1500
            })
            this.router.navigate(['/login']);
          }
          else {
            this.toastralert.toastrerror('Unable to fetch the details for the given EmailId');
          }
        }
        else {
          this.toastralert.toastrerror('Error occured while fecting the details for the given EmailId');
        }
      }, err => {
        if (err.error.text == "ForgotPasswordConfirmation") {
          Swal.fire({
            icon: 'success',
            title: 'Verified!',
            text: "An Email has been sent to the provided address for resetting your password",
            showConfirmButton: true,
            confirmButtonColor: "#a5dc86",
          })
          this.router.navigate(['/login']);
        } else {
          this.toastralert.toastrerror('Unable to fetch the details for the given EmailId');
        }
      });
    }
  }
}