import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TranslationService } from '../../../../../i18n';
import { Router } from '@angular/router';
import { OptionsComponent } from 'src/app/components/options/options.component';
import { ToastralertService } from 'src/app/services/toastralert.service';
@Component({
  selector: 'app-user-inner',
  templateUrl: './user-inner.component.html',
})
export class UserInnerComponent implements OnInit, OnDestroy,AfterViewInit {
  @HostBinding('class')
  class = `menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`;
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';
  token_response: any = {};
  token: any;
  UserRole : string = "";
  UserEamil : string = "";
  language: LanguageFlag;
  langs = languages;
  private unsubscribe: Subscription[] = [];

  constructor(
    private translationService: TranslationService, public router: Router, public toastralert: ToastralertService
  ) {
     this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
     this.token = this.token_response.access_token; 
    }

  ngOnInit(): void {
    this.setLanguage(this.translationService.getSelectedLanguage());
    this.UserRole = this.token_response.UserRole;
    this.UserEamil = this.token_response.UserLoginEmail;
  }
  
  ngAfterViewInit(): void {
  
  }

  redirectToProfile() {
    this.router.navigate(['myprofile/' + this.token_response.CRMCustomerId]);
  }

  // logout() {
  //  let isdeactivate = localStorage.getItem('isDeActive');
  //  if(isdeactivate == "false"){
  //   this.toastralert.toastrerrorbottom("Please add atleast one option");
  //  }
  //  else{
  //   localStorage.clear();
  //   document.location.reload();
  //   this.router.navigate(['/login']);
  //  }
   
  // }

  selectLanguage(lang: string) {
    this.translationService.setLanguage(lang);
    this.setLanguage(lang);
    // document.location.reload();
  }

  setLanguage(lang: string) {
    this.langs.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: './assets/media/flags/united-states.svg',
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: './assets/media/flags/china.svg',
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: './assets/media/flags/spain.svg',
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: './assets/media/flags/japan.svg',
  },
  {
    lang: 'de',
    name: 'German',
    flag: './assets/media/flags/germany.svg',
  },
  {
    lang: 'fr',
    name: 'French',
    flag: './assets/media/flags/france.svg',
  },
];
