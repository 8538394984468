<div class="row px-4 py-0 pb-5">
    <div class="bgcolor stickybar">
        <ul class="breadcrumb pb-2">
            <li><a class="routingcontacts" routerLink="/profiles">Profiles </a> >> </li>
            <li> Profile</li>
        </ul>
        <div class="card card-body py-3">
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <div class="me-7">
                    <div class="symbol symbol-50px symbol-lg-80px symbol-fixed position-relative">
                        <img _ngcontent-qqw-c280="" src="./assets/media/avatars/300-1.jpg" alt="metronic">
                    </div>
                </div>
                <div class="flex-grow-1">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-sm-4">
                            <div><a class="d-flex align-items-center text-gray-400 text-hover-primary">
                                    <span class="svg-icon svg-icon-4 me-1">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3"
                                                d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                fill="currentColor" />
                                            <path
                                                d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                fill="currentColor" />
                                        </svg>
                                    </span>
                                    <span class="fs-5 fw-bold text-dark ">{{profilelist.PrimaryContact}}</span>
                                </a></div>
                            <div class="text-secondary cursor-pointer item-hover">{{profilelist.AccountNumber}}</div>
                            <div class="text-secondary cursor-pointer item-hover" *ngFor="let emaillist of Emaillist">
                                <div *ngIf="emaillist.IsPrimary==true">
                                    {{emaillist.EmailAddress}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5">Address</div>
                            <div *ngFor="let address of Addresslist ">
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.Address1}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover"
                                    *ngIf="address.IsPrimary==true && address.Address2 != '' && address.Address2 != null">
                                    {{address.Address2}}
                                </div>
                                <div class="text-secondary cursor-pointer item-hover" *ngIf="address.IsPrimary==true">
                                    {{address.City}}, {{address.StateAbbreviation}} {{address.PostalCode}}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <div class="fw-bold fs-5 cursor-pointer">Phone</div>
                            <div class="text-secondary cursor-pointer item-hover"
                                *ngFor="let phonelist of phonenumberlist">
                                <div *ngIf="phonelist.IsPrimary==true">
                                    {{phonelist.PhoneType}} {{phonelist.PhoneNumber}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex my-4">
                    <div class="me-0">
                        <button class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                            <i class="fa fa-chevron-down fs-4"></i>
                        </button>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Action</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Activate</a>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Inactivate</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Delete</a>
                            </div>
                        </div>
                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                            data-kt-menu="true">
                            <div class="menu-item px-3">
                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                    Payments</div>
                            </div>
                            <div class="menu-item px-3">
                                <a class="menu-link px-3">Generate
                                    Bill</a>
                            </div>
                            <div class="menu-item px-3 my-1">
                                <a class="menu-link px-3">Settings</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="card-header card-header-stretch overflow-auto mt-4 ">
            <ul class="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap " role="tablist">
                <li class="nav-item py-2 px-5 ">
                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Profile' }"
                        (click)="setActiveTab('Profile')" role="tab">
                        <fa-icon [icon]="faUserCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Profile
                    </a>
                </li>
                <li class="nav-item py-2 px-5" *ngIf="userRole != 'Volunteer'">

                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Timeline' }"
                        (click)="redirecttotimeline()" role="tab">
                        <fa-icon [icon]="faTimeline" class="fs-4 me-3  cursor-pointer"></fa-icon>
                        Timeline
                    </a>
                </li>
                <li class="nav-item py-2 px-5">

                    <a class="nav-link cursor-pointer" [ngClass]="{ active: activeTab === 'Availability' }"
                        (click)="redirecttoavailability()" role="tab">
                        <fa-icon [icon]="faTimesCircle" class="fs-4 me-3 cursor-pointer"></fa-icon>
                        Availability
                    </a>
                </li>

            </ul>
            <div *ngIf="activeTab=='Profile'" class="desktopshowedit">

                <div class="btn-group float-end me-3 topmargin" role="group" aria-label="Basic example">
                    <button
                        class="btn background-danger text-white px-2 py-1 m-0 rounded-0 cursor-pointer topedit fs-6 text-center fw-semibold rounded-0 mw-500px me-3"
                        data-toggle="tooltip" title="Archive"><i
                            class="fa fa-archive fs-5 text-white"></i>Archive</button>
                    <button
                        class="btn background-info text-white px-2 py-1 m-0 rounded-0 cursor-pointer topedit fs-6 text-center fw-semibold rounded-0 mw-500px me-3"
                        data-toggle="tooltip" title="Cancel" (click)="redirecttoprofile()">Cancel</button>
                    <button
                        class="btn background-secondary text-white px-2 py-1 m-0 rounded-0 cursor-pointer  topedit fs-6 text-center fw-semibold rounded-0 mw-500px "
                        data-toggle="tooltip" title="Save Profile" [disabled]="isBtndisabled"
                        (click)="update_CRMCustomerDetailsByCRMCustomerId(customerDetails)"
                        (click)="SaveOrUpdate_ComponentOptionsSelectedValues(getselectedFormSections)"><i
                            class="fa fa-save fs-5 text-white"></i>Save</button>
                </div>
            </div>
        </div>
        <div *ngIf="activeTab=='Profile'" class="mobileshowedit m-2">

            <div class="btn-group float-end me-3 topmargin" role="group" aria-label="Basic example">
                <button
                    class="btn background-danger text-white px-2 py-1 m-0 rounded-0 cursor-pointer float-end fs-6 text-center fw-semibold rounded-0 mw-500px me-10"
                    data-toggle="tooltip" title="Archive"><i class="fa fa-archive fs-5 text-white"></i>Archive</button>
                <button
                    class="btn background-info text-white px-2 py-1 m-0 rounded-0 cursor-pointer float-endfs-6 text-center fw-semibold rounded-0 mw-500px "
                    data-toggle="tooltip" title="Cancel" (click)="redirecttoprofile()">Cancel</button>
                <button
                    class="btn background-secondary text-white px-2 py-1 m-0 rounded-0 cursor-pointer float-end  fs-6 text-center fw-semibold rounded-0 mw-500px "
                    data-toggle="tooltip" title="Save Profile" [disabled]="isBtndisabled"
                    (click)="update_CRMCustomerDetailsByCRMCustomerId(customerDetails)"
                    (click)="SaveOrUpdate_ComponentOptionsSelectedValues(getselectedFormSections)"><i
                        class="fa fa-save fs-5 text-white"></i>Save</button>
            </div>
        </div>
    </div>

    <div class="card-custom">
        <div class="tab-content pt-3">
            <div class="tab-pane" [ngClass]="{ active: activeTab === 'Profile' }">
                <div class="card">
                    <div class="row py-2 mt-2">
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <h3 class="fs-4 fw-bold ms-3">Edit Profile</h3>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Status</label>
                                <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                    [(ngModel)]="customerDetails.Status" [clearable]="false">
                                    <ng-option value="''" disabled>Select Status
                                    </ng-option>
                                    <ng-option *ngFor='let status of statuslist' [value]="status" class="fs-6">
                                        {{status}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Primary Contact</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal" placeholder=""
                                    [(ngModel)]="customerDetails.PrimaryContact" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2 ">Informal
                                    Name</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal" placeholder="Pam"
                                    [(ngModel)]="customerDetails.InformalName" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Formal
                                    Name</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                    placeholder="Ms Pam Rubins" [(ngModel)]="customerDetails.FormalName" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2 ">Envelope
                                    Name</label>
                                <textarea class="form-control p-2 border-secondary fs-6 fw-normal"
                                    placeholder="1 Spot Info" [(ngModel)]="customerDetails.EnvelopeName"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Recognition
                                    Name</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                    placeholder="1 Spot Info" [(ngModel)]="customerDetails.RecognitionName" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card mt-3">
                    <h3 class="fs-4 fw-bold ms-3 py-3">Personal Information</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Gender</label>
                                <ng-select data-control="select2" data-hide-search="true" id="statusDropdown"
                                    [(ngModel)]="customerDetails.Gender" [clearable]="false" placeholder="Show All">
                                    <ng-option [value]="''" disabled>Select Gender
                                    </ng-option>
                                    <ng-option *ngFor='let gender of genderlist' [value]="gender" class="fs-6">
                                        {{gender}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Birthdate</label>
                                <!-- <input type="date" class=" form-control p-2 border-secondary fs-6 fw-normal"
                                    [(ngModel)]="customerDetails.Birthday" /> -->
                                <!-- <smart-date-time-picker class="form-control p-1 border-secondary fs-4 fw-normal"
                                    #datetimepicker3 [(ngModel)]="customerDetails.Birthday" [calendarButton]="true"
                                    [enableMouseWheelAction]="true" [interval]="'new smartUtilities.TimeSpan(24, 0, 0)'"
                                    [dropDownPosition]="'center-bottom'" [editMode]="'partial'"
                                    formatString="MM/dd/yyyy"
                                    [dropDownDisplayMode]="'calendar'"></smart-date-time-picker> -->
                                    <smart-date-input class="form-control p-1 border-secondary fs-4 fw-normal"
                                    [(ngModel)]="customerDetails.Birthday" formatString="MM/dd/yyyy" placeholder="mm/dd/yyyy" ></smart-date-input>
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Employer</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                    placeholder="1 Spot Info" [(ngModel)]="customerDetails.Employer" />
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Job</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                    [(ngModel)]="customerDetails.JobTitle" />
                            </div>

                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Website</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal" placeholder=""
                                    [(ngModel)]="customerDetails.Website" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Facebook Page</label>
                                <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                <div class="input-group mb-3 border-0">
                                    <input type="text" class="form-control p-2 border-end-0 fs-6 fw-normal"
                                        placeholder="" [(ngModel)]="customerDetails.FacebookId">
                                    <div class="input-group-append">
                                        <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                            id="basic-addon2"> <i class="fa-brands fa-facebook-f text-white"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Twitter Handle</label>
                                <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                <div class="input-group mb-3 border-0">
                                    <input type="text" class="form-control p-2 border-end-0 fs-6 fw-normal"
                                        placeholder="" [(ngModel)]="customerDetails.TwitterId">
                                    <div class="input-group-append">
                                        <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                            id="basic-addon2"><i class="fab fa-twitter text-white"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">LinkedIn Page</label>
                                <!-- <input class="form-control p-2 border-secondary"
                                                placeholder="" /> -->
                                <div class="input-group mb-3 border-0">
                                    <input type="text" class="form-control p-2 border-end-0 fs-6 fw-normal"
                                        placeholder="" [(ngModel)]="customerDetails.LinkedInId">
                                    <div class="input-group-append">
                                        <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                            id="basic-addon2"><i class="fa-brands fa-linkedin-in text-white"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Instagram Page</label>
                                <div class="input-group mb-3 border-0">
                                    <input type="text" class="form-control p-2 border-end-0 fs-6 fw-normal"
                                        placeholder="" [(ngModel)]="customerDetails.InstagramId">
                                    <div class="input-group-append">
                                        <span class="input-group-text rounded-0 border-start-0 bg-primary"
                                            id="basic-addon2"><i class="fa-brands fa-linkedin-in text-white"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <h3 class="fs-4 fw-bold ms-3 py-3">Communication Preferences</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 mt-0">
                            <div class="m-3 mt-0">
                                <label class="fs-4 text-secondary mb-2">Email Interests</label>
                                <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                    [(ngModel)]="customerDetails.EmailInterestType" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-3 pb-2" *ngFor="let profileformsections of getselectedFormSections">
                    <h3 class="fs-4 fw-bold ms-3 py-3 ">{{profileformsections.FormSectionName}}</h3>
                    <div class="row m-1 mt-0">
                        <div class="col-lg-6 col-md-6 col-sm-6 "
                            *ngFor="let profilesectionscomponents of profileformsections.SelectedFormComponentDetailsModelList">
                            <div class="fs-4 text-secondary mb-2">
                                {{profilesectionscomponents.FormComponentName}}<span
                                    *ngIf="profilesectionscomponents.IsRequired == true" class="required"></span>
                            </div>
                            <div class="row twocolumns">
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Text' && profilesectionscomponents.FormComponentOptionType=='Freeform'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Text' && profilesectionscomponents.FormComponentOptionType=='PickMultiple'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList.length == 0 && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Text' && profilesectionscomponents.FormComponentOptionType=='PickOne'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false">
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null) && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false">
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Date' && profilesectionscomponents.FormComponentOptionType=='Freeform' ">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Date' && profilesectionscomponents.FormComponentOptionType=='PickOne'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <!-- <input type="date" class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" /> -->
                                        <!-- <smart-date-time-picker class="form-control p-1 border-secondary fs-4 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue"
                                            [calendarButton]="true" [enableMouseWheelAction]="true"
                                            [dropDownPosition]="'center-bottom'" [editMode]="'partial'"
                                            formatString="MM/dd/yyyy"
                                            [dropDownDisplayMode]="'calendar'"></smart-date-time-picker> -->
                                            <smart-date-input class="form-control p-1 border-secondary fs-4 fw-normal" *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" formatString="MM/dd/yyyy" placeholder="mm/dd/yyyy"></smart-date-input>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null) && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <!-- <input type="date" class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" /> -->
                                        <!-- <smart-date-time-picker class="form-control p-1 border-secondary fs-4 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue"
                                            [calendarButton]="true" [enableMouseWheelAction]="true"
                                            [dropDownPosition]="'center-bottom'" [editMode]="'partial'"
                                            formatString="MM/dd/yyyy"
                                            [dropDownDisplayMode]="'calendar'"></smart-date-time-picker> -->
                                        <smart-date-input class="form-control p-1 border-secondary fs-4 fw-normal" *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                        [(ngModel)]="selectedoptions.FormComponentOptionValue" formatString="MM/dd/yyyy" placeholder="mm/dd/yyyy"></smart-date-input>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Date' && profilesectionscomponents.FormComponentOptionType=='PickMultiple'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList.length == 0 && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Decimal' && profilesectionscomponents.FormComponentOptionType=='Freeform'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                    </div>

                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Decimal' && profilesectionscomponents.FormComponentOptionType=='PickOne'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false"
                                            required>
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null) && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false">
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Decimal' && profilesectionscomponents.FormComponentOptionType=='PickMultiple'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList.length == 0 && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Year' && profilesectionscomponents.FormComponentOptionType=='Freeform'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <input class="form-control p-2 border-secondary fs-6 fw-normal"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" />
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Year' && profilesectionscomponents.FormComponentOptionType=='PickOne'">

                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false">
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == undefined || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == '' || profilesectionscomponents.SelectedFormValuesDetailsModelList[0].FormComponentOptionValue == null) && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select data-hide-search="true"
                                            *ngFor='let selectedoptions of profilesectionscomponents.SelectedFormValuesDetailsModelList'
                                            [(ngModel)]="selectedoptions.FormComponentOptionValue" [clearable]="false">
                                            <ng-option [value]="''">Select Item</ng-option>
                                            <ng-option
                                                *ngFor="let options of profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                                [value]="options.FormComponentOptionValue">
                                                {{options.FormComponentOptionValue}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div
                                    *ngIf="profilesectionscomponents.FormComponentDataType == 'Year' && profilesectionscomponents.FormComponentOptionType=='PickMultiple'">
                                    <div *ngIf="profilesectionscomponents.IsRequired == true">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                        <div
                                            *ngIf="submited && (profilesectionscomponents.SelectedFormValuesDetailsModelList.length == 0 && profilesectionscomponents.SelectedFormOptionsDetailsModelList.length != 0)">
                                            <span class="text-danger">{{profilesectionscomponents.FormComponentName}} is
                                                Required</span>
                                        </div>
                                    </div>
                                    <div *ngIf="profilesectionscomponents.IsRequired == false">
                                        <ng-select
                                            [items]="profilesectionscomponents.SelectedFormOptionsDetailsModelList"
                                            bindLabel="FormComponentOptionValue" placeholder="Select Item(s)"
                                            [multiple]="true"
                                            [(ngModel)]="profilesectionscomponents.SelectedFormValuesDetailsModelList"
                                            [clearable]="false" [searchable]="true" [closeOnSelect]="false">
                                        </ng-select>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>