<div class="app-main flex-column flex-row-fluid ">
    <div class="d-flex flex-column flex-column-fluid">
        <div class="app-toolbar py-3">
            <div class="d-flex justify-content-between">
                <div class="fs-3 fw-bold">Report</div>
                <a routerLink="/volunteersreports"
                    class="btn background-success text-white  p-2 fs-5 text-center fw-semibold rounded-0 mw-500px">
                    <i class="fa fa-plus p-2 fs-7 text-white me-1 text-center"></i>New</a>
            </div>
        </div>
    </div>

    <div class="card cardscroll">
        <div class="componentscroll">
            <div class="d-flex p-3">
                <div>
                    <input class="form-control d-inline-block rounded-3" type="text" placeholder="Search....">
                </div>
                <div class="form-check form-switch mb-3 ms-4 mt-1">
                    <label class="d-inline-flex align-items-center text-dark">Active</label>
                    <input class="form-check-input mt-1" type="checkbox" style="width:30px; height: 15px;">
                </div>
            </div>
            <div class="card-body vault-list-h px-5 py-2" class="component-list">
                <ul class="list-unstyled ">
                    <li class="border-bottom" *ngFor="let item of reportslist">
                        <div class="row py-2 cursormove">
                            <div class="col-lg-8 col-md-8 col-sm-8">
                                <div class="py-1 px-3">
                                    <div class="pb-1 text-primary fs-5" style="cursor: pointer;" (click)="redirectToVolunteersReports(item.ReportQueryId)">
                                        {{item.ReportQueryName}}
                                    </div>
                                    <div class="fs-6">Modified today at 1.20 PM by Angela Pendergraft</div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4"  >
                                <div class="d-flex float-end px-4">
                                    <div class="text-secondary fs-6">Transaction</div>
                                    <span class="dropdown ms-2" ngbDropdown>
                                        <a class="btn btn-primary px-2 py-1" type="button" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                            <i class="fa fa-angle-down"></i></a>
                                        <ul class="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton1" ngbDropdownMenu>
                                            <li><a class="dropdown-item" href="javascript:void(0);">
                                                    <i class="fa fa-pencil me-2"></i>Edit</a>
                                            </li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">
                                                    <i class="fa fa-copy me-2"></i> Copy</a>
                                            </li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">
                                                    <i class="fa fa-file-excel me-2"></i>Export to Excel</a>
                                            </li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">
                                                    <i class="fa fa-times me-2"></i>Deactivate </a>
                                            </li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>