import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete'; 

// #fake-start#
import { ContactsComponent } from './components/contacts/contacts.component';
import { CalenderComponent } from './components/calendar/calendar.component';
import { NewcontactComponent } from './components/newcontact/newcontact.component';
import { ContactprofileComponent } from './components/contactprofile/contactprofile.component';
import { EventdetailsComponent } from './components/eventdetails/eventdetails.component';
import { NeweventComponent } from './components/newevent/newevent.component';
import { EventComponent } from './components/event/event.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { JobdetailsComponent } from './components/jobdetails/jobdetails.component';
import { jqxSchedulerModule } from 'jqwidgets-ng/jqxscheduler';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { ReportsComponent } from './components/reports/reports.component';
import { jqxDataTableModule } from 'jqwidgets-ng/jqxdatatable';
import { SetupComponent } from './components/setup/setup.component';// #fake-end#
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxFormModule } from 'jqwidgets-ng/jqxform';
import { jqxPanelModule } from 'jqwidgets-ng/jqxpanel';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { FacilityComponent } from './components/facility/facility.component';
import { HistoryofeventsComponent } from './components/historyofevents/historyofevents.component';
import { ParticipantComponent } from './components/participant/participant.component';
import { ProviderComponent } from './components/provider/provider.component';
import { MemberslistComponent } from './components/memberslist/memberslist.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { UserService } from './services/user.service';
import { DashboardService } from './services/dashboard.service';
import { MembersService } from './services/members.service';
import { ProfileComponent } from './components/profile/profile.component';
import { CategoryComponent } from './components/category/category.component';
import { OptionsComponent } from './components/options/options.component';
import { Category1Component } from './components/category1/category1.component';
import { SchedulerModule } from 'smart-webcomponents-angular/scheduler';
import { ButtonModule } from 'smart-webcomponents-angular/button';
import { CalendarModule } from 'smart-webcomponents-angular/calendar';
import { InputModule } from 'smart-webcomponents-angular/input';
import { TreeModule } from 'smart-webcomponents-angular/tree';
import { ToastrModule } from "ngx-toastr";
import { GridModule } from 'smart-webcomponents-angular/grid';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { AvailabilityComponent } from './components/availability/availability.component';
import { TimelineComponent } from './components/timeline/timeline.component';
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { DateTimePickerModule } from 'smart-webcomponents-angular/datetimepicker';
import { DateInputModule } from 'smart-webcomponents-angular/dateinput';
import { TimePickerModule } from 'smart-webcomponents-angular/timepicker';
import { DropDownListModule } from 'smart-webcomponents-angular/dropdownlist';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BuilderComponent } from './components/builder/builder.component'
import{NgxMaskModule} from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';   
import { NgxSpinnerModule } from "ngx-spinner";
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { CanDeactivateGurdService } from './services/candeactive-guard.service';
import { LogoutComponent } from './components/logout/logout.component';
import { DatePipe } from '@angular/common';
import { ApiLogErrorReportsComponent } from './components/api-log-error-reports/api-log-error-reports.component';
import { DbLogErrorReportsComponent } from './components/db-log-error-reports/db-log-error-reports.component';
import { SchedularComponent } from './components/schedular/schedular.component';
import { SwitchButtonModule } from "smart-webcomponents-angular/switchbutton";
import { WindowModule } from "smart-webcomponents-angular/window";
// import { Calendar1Component } from './components/calendar1/calendar1.component';
import { RadioButtonModule } from 'smart-webcomponents-angular/radiobutton';
import { NumberInputModule } from 'smart-webcomponents-angular/numberinput';
import { CheckBoxModule } from 'smart-webcomponents-angular/checkbox';
import { TimeInputModule } from 'smart-webcomponents-angular/timeinput';
import { MenuModule } from 'smart-webcomponents-angular/menu';
import { LocationMappingComponent } from './components/location-mapping/location-mapping.component';
import { MatchStatusComponent } from './components/match-status/match-status.component';
import {FormatedDatePipe} from 'src/app/components/CustomPipes/formatDate.pipe';
import { VolunteersreportsComponent } from './components/volunteersreports/volunteersreports.component';
import { ReportListComponent } from './components/report-list/report-list.component';
import { RelationshipsComponent } from './components/relationships/relationships.component';
import { VolunteersComponent } from './components/volunteers/volunteers.component';
import { BeneficiariesComponent } from './components/beneficiaries/beneficiaries.component';
import { AdminsComponent } from './components/admins/admins.component';
import { AuthorizationInterceptor } from './Interceptors/authorization.interceptor';

window.Smart.License = 'F4620CFA-232B-4283-87A0-90E194B90949';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    BuilderComponent,
    ContactsComponent,
    CalenderComponent,
    NewcontactComponent,
    ContactprofileComponent,
    EventdetailsComponent,
    NeweventComponent,
    EventComponent,
    JobsComponent,
    JobdetailsComponent,
    ReportsComponent,
    SetupComponent,
    FacilityComponent,
    HistoryofeventsComponent,
    ParticipantComponent,
    ProviderComponent,
    MemberslistComponent,
    ResetpasswordComponent,
    ProfileComponent,
    CategoryComponent,
    OptionsComponent,
    Category1Component,
    AvailabilityComponent,
    TimelineComponent,
    EditprofileComponent,
    LogoutComponent,
    ApiLogErrorReportsComponent,
    DbLogErrorReportsComponent,
    SchedularComponent,
    LocationMappingComponent,
    MatchStatusComponent,
    VolunteersreportsComponent,
    FormatedDatePipe,
    ReportListComponent,
    RelationshipsComponent,
    VolunteersComponent,
    BeneficiariesComponent,
    AdminsComponent
    // Calendar1Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    ClipboardModule, jqxGridModule,
    FormsModule, jqxDataTableModule,
    jqxSchedulerModule,
    jqxDropDownListModule,
    jqxFormModule,
    GridModule,
    ToastrModule.forRoot(),
    jqxButtonModule,
    jqxPanelModule,
    jqxDateTimeInputModule,
    Ng2SearchPipeModule, MatTabsModule, MatNativeDateModule,
    jqxCheckBoxModule, MatTableModule,
    jqxTabsModule, MatCardModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatDatepickerModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    SchedulerModule,
    ButtonModule,
    CalendarModule,
    InputModule,
    TreeModule,
    FusionChartsModule,
    FontAwesomeModule,
    DateTimePickerModule,
    TimePickerModule,
    DropDownListModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    NgxSpinnerModule,
    GooglePlaceModule,
    SwitchButtonModule,
    WindowModule,
    RadioButtonModule,
    NumberInputModule,
    CheckBoxModule,
    DateInputModule,
    MenuModule,
    TimeInputModule ,
   
  ],
  providers: [
    DatePipe,
    UserService,
    DashboardService,
    MembersService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    CanDeactivateGurdService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
