import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historyofevents',
  templateUrl: './historyofevents.component.html',
  styleUrls: ['./historyofevents.component.scss']
})
export class HistoryofeventsComponent implements OnInit {
  historyRecords = [
    {
      date: "Monday, October 07,2013",
      time: "	1:00pm",
      provider: "	Katie Gerhard",
      session:"		LifeSpark Session (weekly)",
      status: "	Completed Session",
    },
    {
      date: "Tuesday, October 01,2013",
      time: "	12:00pm",
      provider: "	Katie Gerhard",
      session:"		LifeSpark Session (weekly)",
      status: "	Completed Session",
    },
    {
      date: "Wednesday, September 25,2013",
      time: "	11:00pm",
      provider: "	Katie Gerhard",
      session:"		LifeSpark Session (weekly)",
      status: "	Completed Session",
    },
    {
      date: "Monday, October 07,2013",
      time: "	10:00pm",
      provider: "	Katie Gerhard",
      session:"		LifeSpark Session (weekly)",
      status: "	Completed Session",
    },
    {
      date: "Wednesday, September 18,2013	",
      time: "	12:00pm",
      provider: "	Katie Gerhard",
      session:"		LifeSpark Session (weekly)",
      status: "	Completed Session",
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
