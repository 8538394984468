import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/services/profile.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { TimelineService } from 'src/app/services/timeline.service';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MembersService } from 'src/app/services/members.service';
import { NgxSpinnerService } from 'ngx-spinner';
type Tabs = 'Profile' | 'Timeline' | 'Availability' | 'RelationShips';

@Component({
  selector: 'app-relationships',
  templateUrl: './relationships.component.html',
  styleUrls: ['./relationships.component.scss']
})
export class RelationshipsComponent implements OnInit {

  faUserCircle = faUserCircle;
  faTimeline = faTimeline;
  faTimesCircle = faClock;
  activeTab: Tabs = 'Profile';
  configLoading: boolean = false;
  resetLoading: boolean = false;
  cRMCustomerid: any;
  profilelist: any = { PrimaryContact: '' };
  phonenumberlist: any;
  Addresslist: any;
  timelinedetails: any;
  faEdit = faEdit;
  Emaillist: any;
  closeResult: any;
  token_response: any;
  token: any;
  userRole: any = "";
  relationshipLists: any = [];
  membersList: any = [];
  customerReationshipList: any = [];
  hdrRelationship: string = 'New Relationship';
  btnRelationship: string = 'Save';
  dependentName: string = '';
  delRelationshipId: any = '';
  HouseholdRelationshipList:any =[];
  HouseholdId:any =[];
  relationshipDetails: any = { CRMConstituentRelationshipId: '', RelationCRMCustomerId: '', RelationshipRole1_Id: '', RelationshipRole2_Id: '', Note: '' }
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private profileService: ProfileService,
    public config: NgbModalConfig,
    public toastralert: ToastralertService,
    private timelineService: TimelineService,
    private modalService: NgbModal,
    private memberService: MembersService,
    private spinner: NgxSpinnerService,

  ) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit(): void {
    this.userRole = this.token_response.UserRole;
    this.route.params.subscribe(params => {
      this.cRMCustomerid = params['cRMCustomerId'];
    });
    this.GetMembers();
    this.get_profiledetails();
    this.setActiveTab('RelationShips');
    this.get_CRMTimeLinesByCRMCustomerId();
    this.GetRelationshipRoles();
    this.GetCustomerRelationshipsByCRMCustomerId();
    this.Get_HouseholdRelationshipByCRMCustomerId();
  }
  openmodal(content: any) {
    this.modalService.open(content, { size: 'lg', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  openDeletemodal(content: any) {
    this.modalService.open(content, { size: 'sm', centered: true }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get_profiledetails() {
    this.profileService.Get_CRMCustomersByTenantId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != "") {
        this.profilelist = results;
        this.phonenumberlist = this.profilelist.CRMPhoneNumbersModelList;
        this.Addresslist = this.profilelist.CRMAddressModelList;
        this.Emaillist = this.profilelist.CRMEmailsModelList;
      }
      else {
        this.toastralert.toastrerror('Something went wrong!');
      }
    },
      (err) => {
        console.log(err);
        this.toastralert.toastrerror('Unable to get profile details.Please try after some time.');
      }
    );
  }

  get_CRMTimeLinesByCRMCustomerId() {
    this.timelineService.Get_CRMTimeLinesByCRMCustomerId(this.cRMCustomerid)
      .subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.timelinedetails = results;
          console.log(results);
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to get section details.Please try after some time.");
        });
  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  redirecttoavailability() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/availability/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/availability/' + this.cRMCustomerid]);
    }
  }

  redirecttotimeline() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/timeline/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/timeline/' + this.cRMCustomerid]);
    }
  }

  redirecttoprofile() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/profile/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/' + this.cRMCustomerid]);
    }
  }

  redirecttorelationships() {
    if (this.token_response.UserRole != "Volunteer") {
      this.router.navigate(['profiles/relationships/' + this.cRMCustomerid]);
    }
    else {
      this.router.navigate(['myprofile/relationships/' + this.cRMCustomerid]);
    }
  }

  resetPreview(): void {
    this.resetLoading = true;
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }

  GetRelationshipRoles() {
    this.spinner.show();
    this.memberService.GetRelationshipRoles().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.relationshipLists = results;
        console.log(results);
        this.spinner.hide();
      }
    },
      (err) => {
        this.spinner.hide();
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    );
  }

  GetMembers() {
    this.memberService.GetMembers().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.membersList = results;
        console.log(results);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get members details.Please try again after some time.");
      }
    );
  }


  GetCustomerRelationshipsByCRMCustomerId() {
    this.memberService.GetCustomerRelationshipsByCRMCustomerId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.customerReationshipList = results;
        console.log(results);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    );
  }

  getselectedMember() {
    if (this.relationshipDetails.RelationCRMCustomerId != '') {
      let member = this.membersList.filter((item: any) => {
        return item.CRMCustomerId === this.relationshipDetails.RelationCRMCustomerId; // Filter out the appropriate one
      });
      this.dependentName = member[0].FullName;
    }
    else {
      this.dependentName = '';
    }
  }

  closeRelationshipModel() {
    this.hdrRelationship = 'New Relationship';
    this.btnRelationship = 'Save';
    this.dependentName = '';
    this.relationshipDetails.CRMConstituentRelationshipId = '';
    this.relationshipDetails.RelationCRMCustomerId = '';
    this.relationshipDetails.RelationshipRole1_Id = '';
    this.relationshipDetails.RelationshipRole2_Id = '';
    this.relationshipDetails.Note = '';
    this.modalService.dismissAll();
  }

  UpdateRelation(id: any) {
    // this.relationshipDetails.CRMConstituentRelationshipId = id;
    this.hdrRelationship = 'Edit Relationship';
    this.btnRelationship = 'Update';
    this.memberService.GetCustomerRelationshipByCRMConstituentRelationshipId(id).subscribe((results) => {
      if (results != null && results != 'undefined') {
        let member = this.membersList.filter((item: any) => {
          return item.CRMCustomerId === results.RelationshipToCRMCustomerId; // Filter out the appropriate one
        });
        this.dependentName = member[0].FullName;
        this.relationshipDetails.CRMConstituentRelationshipId = results.CRMConstituentRelationshipId;
        this.relationshipDetails.RelationCRMCustomerId = results.RelationshipToCRMCustomerId;
        this.relationshipDetails.RelationshipRole1_Id = results.CRMRelationshipRoleId1;
        this.relationshipDetails.RelationshipRole2_Id = results.CRMRelationshipRoleId2;
        this.relationshipDetails.Note = results.Note;
        console.log(this.relationshipDetails);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    );
    console.log(id);
  }

  SaveorUpdateRelationShip(relationshipDetails: any) {
    if ((typeof relationshipDetails.RelationCRMCustomerId == 'undefined' || relationshipDetails.RelationCRMCustomerId == "" || relationshipDetails.RelationCRMCustomerId == null)) {
      this.toastralert.toastrwarnningbottomright('Please select member');
      return false;
    }
    else if ((typeof relationshipDetails.RelationshipRole1_Id == 'undefined' || relationshipDetails.RelationshipRole1_Id == "" || relationshipDetails.RelationshipRole1_Id == null)) {
      this.toastralert.toastrwarnningbottomright('Please select member relation');
      return false;
    }
    else if ((typeof relationshipDetails.RelationshipRole2_Id == 'undefined' || relationshipDetails.RelationshipRole2_Id == "" || relationshipDetails.RelationshipRole2_Id == null)) {
      this.toastralert.toastrwarnningbottomright('Please select customer relation');
      return false;
    }
    else {
      let details: any = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        CRMCustomerId: this.cRMCustomerid,
        RelationCRMCustomerId: relationshipDetails.RelationCRMCustomerId,
        CRMRelationshipRoleId1: relationshipDetails.RelationshipRole1_Id,
        CRMRelationshipRoleId2: relationshipDetails.RelationshipRole2_Id,
        Note: relationshipDetails.Note
      };
      if (relationshipDetails.CRMConstituentRelationshipId != undefined && relationshipDetails.CRMConstituentRelationshipId != '' && relationshipDetails.CRMConstituentRelationshipId != null) {
        details['CRMConstituentRelationshipId'] = relationshipDetails.CRMConstituentRelationshipId;
      }
      this.memberService.SaveCustomerRelationship(details).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          if (relationshipDetails.CRMConstituentRelationshipId != undefined && relationshipDetails.CRMConstituentRelationshipId != '' && relationshipDetails.CRMConstituentRelationshipId != null) {
            this.toastralert.toastersuccesstop('Relationship updated successfully');
          }
          else {
            this.toastralert.toastersuccesstop('Relationship saved successfully');
          }
          this.closeRelationshipModel();
          this.GetCustomerRelationshipsByCRMCustomerId();
        }
        else {
          this.toastralert.toastrerror('Error occurred while saving relationship details');
        }
      },
        (err) => {
          this.toastralert.toastrerrorbottom("Unable to save relationship details.Please try after some time");
        }
      );

    }
  }
  DeleteCustomerRelationship() {
    if (this.delRelationshipId != '') {
      let model = {
        CRMPersonId: this.token_response.CRMPersonId,
        TenantId: this.token_response.tenantId,
        CRMConstituentRelationshipId:this.delRelationshipId
      }
      this.memberService.DeleteCustomerRelationshipByCRMConstituentRelationshipId(model).subscribe((results) => {
        if (results != null && results != 'undefined' && results != '') {
          this.toastralert.toastersuccesstop('Relationship deleted successfully');
          this.GetCustomerRelationshipsByCRMCustomerId();
          this.clearDeletModel();
        }
        else{
          this.toastralert.toastrerrorbottom("Unable to delete relationship details.Please try after some time");
        }
      },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to delete relationship details.Please try after some time");
      });
    }
    else{
      this.toastralert.toastrerrorbottom("Unable to delete relationship details.Please try after some time");
    }
  }

  DeleteRelationship(id: any) {
    this.delRelationshipId = id;
  }

  clearDeletModel() {
    this.modalService.dismissAll();
    this.delRelationshipId = '';
  }

  
  Get_HouseholdRelationshipByCRMCustomerId() {
    this.memberService.GetHouseholdRelationshipByCRMCustomerId(this.cRMCustomerid).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.HouseholdRelationshipList = results;
        console.log(results);
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    );
  }

  UpdateHouseholdRelation(id: any) {
    this.HouseholdId = id;
  }

  clearUpdateModel() {
    this.modalService.dismissAll();
    this.HouseholdId = '';
  }

  Update_HeadOfHouseholdStatusByCRMCustomerId() {
    let details: any = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMCustomerId: this.HouseholdId,
    }
    this.memberService.UpdateHeadOfHouseholdStatusByCRMCustomerId(details).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
          this.toastralert.toastersuccesstop('Relationship updated successfully');
        this.clearUpdateModel();
        this.Get_HouseholdRelationshipByCRMCustomerId();
      }
    },
      (err) => {
        this.toastralert.toastrerrorbottom("Unable to get details.Please try again after some time.");
      }
    
    );
   
  }
}
