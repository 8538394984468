import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  token_response: any = {};
  token: any;
  formSectionId: any;
  emailId: string;
  phoneId: string;
  addressId: string;

  constructor(
    private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  Get_CRMCustomersByTenantId(cRMCustomerId: any) {
    return this.http.get(environment.GetCRMCustomerDetailsById + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveOrUpdate_phonedetails(newphones: any) {
    return this.http.post(environment.SaveOrUpdateCRMPhones, newphones, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));

  }

  Get_CRMPhonesDeatilsByCRMPhoneId(phonedetails: any) {
    return this.http.get(environment.GetCRMPhonesDeatilsByCRMPhoneId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + phonedetails.CRMCUSTOMERId + '/' + phonedetails.CRMPhoneId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdate_CRMEmails(newemail: any) {
    return this.http.post(environment.SaveOrUpdateCRMEmails, newemail, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdate_CRMAddresses(newAddress: any) {
    return this.http.post(environment.SaveOrUpdateCRMAddresses, newAddress, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_CRMEmailsDeatilsByCRMEmailId(crmEmailId: any, cRMCustomerId: any) {
    return this.http.get(environment.GetCRMEmailsDeatilsByCRMEmailId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId + '/' + crmEmailId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_CRMAddressDetailsByCRMAddressId(crmAddressId: any, cRMCustomerId: any) {
    return this.http.get(environment.GetCRMAddressDeatilsByCRMAddressId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId + '/' + crmAddressId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_CRMCustomerDetailsCRMCustomerId(cRMCustomerId: any) {
    return this.http.get(environment.GetCRMCustomerDetailsByCRMCustomerId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  update_CRMCustomerDetailsByCRMCustomerId(savecustomerdetails:any) {
    return this.http.post(environment.UpdateCRMCustomerDetailsByCRMCustomerId,savecustomerdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdate_ComponentOptionsSelectedValues(newcomponentoptions: any) {
    return this.http.post(environment.SaveOrUpdateComponentOptionsSelectedValues, newcomponentoptions, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_ComponentOptionsSelectedValues(formName:any,crmCustomerId:any) {   
    return this.http.get(environment.GetComponentOptionsSelectedValues + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + formName + '/' + crmCustomerId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Get_ComponentOptionsSelectedValuesDetailsById(formName:any,crmCustomerId:any){
    return this.http.get(environment.GetComponentOptionsSelectedValuesDetailsById + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + formName + '/' + crmCustomerId
    ).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
