import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { ToastralertService } from 'src/app/services/toastralert.service';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  token_response: any = {};
  token: any;
  userId: any;
  passwordModel: any = { PersonId: '', Code: '', NewPassword: '', ConfirmPassword: '' };
  visible: boolean = false;
  changetype: boolean = true;
  confirmvisible: boolean = false;
  confirmchangetype: boolean = true;
  
  constructor(public http: HttpClient,
    public router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    public toastralert: ToastralertService
  ) { }

  ngOnInit(): void {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
    this.userId = this.token_response.userId;
    this.route.queryParams.subscribe(params => {
      this.passwordModel.PersonId = params['personId'];
      this.passwordModel.Code = params['code'];
    });
  }

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  confirmviewpass() {
    this.confirmvisible = !this.confirmvisible;
    this.confirmchangetype = !this.confirmchangetype;
  }

  UpdatePassword(passwordModel: any) {
    let capletrfrmt = /[A-Z]/;
    let numberformat = /\d/;
    let specialcharfrmt = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
    if (typeof passwordModel.NewPassword == 'undefined' || passwordModel.NewPassword == "" || passwordModel.NewPassword == null) {
      this.toastralert.toastrwarnning("Please enter your New Password");
      return false;
    }
    else if (passwordModel.NewPassword !== "" && !passwordModel.NewPassword.match(capletrfrmt)) {
      this.toastralert.toastrwarnning("Password must contain at least one capital letter.");
      return false;
    }
    else if (passwordModel.NewPassword !== "" && !passwordModel.NewPassword.match(numberformat)) {
      this.toastralert.toastrwarnning("Password must contain at least one number.");
      return false;
    }
    else if (passwordModel.NewPassword !== "" && !passwordModel.NewPassword.match(specialcharfrmt)) {
      this.toastralert.toastrwarnning("Password must contain at least one special character.");
      return false;
    }
    else if (passwordModel.NewPassword.length < 8) {
      this.toastralert.toastrwarnning("Password must contain at least 8 characters.");
      return false;
    }
    else if (typeof passwordModel.ConfirmPassword == 'undefined' || passwordModel.ConfirmPassword == "" || passwordModel.ConfirmPassword == null) {
      this.toastralert.toastrwarnning("Please enter your New Confirm Password");
      return false;
    }
    else if (passwordModel.NewPassword != passwordModel.ConfirmPassword) {
      this.toastralert.toastrwarnning("New password and confirm password must be same");
      return false;
    }
    else {
      let resetdata = {
        TenantId: "",
        PersonId: this.passwordModel.PersonId,
        Code: this.passwordModel.Code,
        Password: passwordModel.ConfirmPassword
      };
      this.userService.resetpassword(resetdata).subscribe(results => {
         if (results != null && results !='undefined') {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Password has been successfully updated",
            showConfirmButton: true,
            confirmButtonColor: "#a5dc86",
          })
          this.router.navigate(['dashboard']);
        }
        else {
          this.toastralert.toastrerror('Error occurred while updating password');
        }
      }, err => {
        if(err.status == '200'){
          Swal.fire({
            icon: 'success',
            title: 'Verified!',
            text: "Password has been successfully updated",
            showConfirmButton: true,
            confirmButtonColor: "#a5dc86",
          })
          this.router.navigate(['dashboard']);
        }
        else{
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: "Password has been successfully updated",
            showConfirmButton: true,
            confirmButtonColor: "#a5dc86",
          })
          this.router.navigate(['dashboard']);
        }
      });
    }
  }
}
