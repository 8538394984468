import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
type Tabs = 'Profile' | 'Timeline' ;

@Component({
  selector: 'app-category1',
  templateUrl: './category1.component.html',
  styleUrls: ['./category1.component.scss']
})
export class Category1Component implements OnInit {
  closeResult: any;
  activeTab: Tabs = 'Profile';
  model: any;
  @ViewChild('form', { static: true }) form: NgForm;
  configLoading: boolean = false;
  resetLoading: boolean = false;

  constructor( private modalService:NgbModal) {}

  ngOnInit(): void {
   
  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;

  }

  resetPreview(): void {
    this.resetLoading = true;
   
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }
 
 
div1:boolean=true;
    div2:boolean=false;
    showMainContent:boolean=true; 

    showEditProfile(): void{
        this.div1=false;
        this.div2=true;
        this.showMainContent =  false;
    }
    hideEditProfile(){
      this.div1=true;
      this.div2=false;
      this.showMainContent =  true;
    }
    open(content:any) {
      this.modalService.open(content, { centered: true }).result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }
  
    private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }
    
  
}
