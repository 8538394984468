import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  str: any;
  token_response:any;
  userRole:any;
  constructor(private router: Router, public toastr: ToastrService) { }

  authguard() {
    localStorage.setItem("User_loggedin", "userloggedin");
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.userRole = this.token_response.UserRole;
    this.str = localStorage.getItem("User_loggedin");
    if(state.url != "/setup"){
       localStorage.setItem('localFormId',"")
    }
    if (this.str == "userloggedin") {
      if (route.data.role && route.data.role.indexOf(this.userRole) === -1) {
        if(this.userRole == 'Admin' || this.userRole == 'Staff'){
          this.router.navigate(['/dashboard']);
        }
        else{
          this.router.navigate(['/calendar']);
        }
        return false;
      }
      return true;
    }
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

}
