import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MembersService {
  token_response: any = {};
  token: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  GetVolunteerLocationsByTenantId() {
    return this.http.get(environment.GetVolunteerLocationsByTenantId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  get_formsectionbyformmembersid() {   
  return this.http.get(environment.GetCRMCustomersByTenantId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId 
  ).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

// get_VolunteersByLocationId(locationId: any) {
//   return this.http.get(environment.GetVolunteersByLocationId + '/' + this.token_response.CRMPersonId + '/' + locationId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
//     return res;
//   }), catchError(error => {
//     console.log(error);
//     return throwError(error);
//   }));
// }

// GetParticipantLocationsByVolunteerId(volunteerId: any) {
//   return this.http.get(environment.GetParticipantLocationsByVolunteerId + '/' + this.token_response.CRMPersonId + '/' + volunteerId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
//     return res;
//   }), catchError(error => {
//     console.log(error);
//     return throwError(error);
//   }));
// }

GetParticipantLocationsByLocationId(locationId: any,mileRadius: any,startDate:any,endDate:any) {
  let model = {
    CRMPersonId: this.token_response.CRMPersonId,
    CRMCustomerId: locationId,
    TenantId: this.token_response.tenantId,
    mileRadius: mileRadius,
    StartDate: startDate,
    EndDate: endDate
  }
  return this.http.post(environment.GetParticipantLocationsByLocationId, model,{
    responseType: 'json'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetPreMatchingListByTenantId(locationId: any,mileRadius: any,startDate:any,endDate:any) {
  let model = {
    CRMPersonId: this.token_response.CRMPersonId,
    LocationId: locationId,
    TenantId: this.token_response.tenantId,
    mileRadius: mileRadius,
    StartDate: startDate,
    EndDate: endDate
  }
  return this.http.post(environment.GetPreMatchingListByTenantId, model,{
    responseType: 'json'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetVolunteersByParticipantLocationId(participantId: any) {
  return this.http.get(environment.GetVolunteersByParticipantLocationId + '/' + this.token_response.CRMPersonId + '/' + participantId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetMatchingStatus() {
  return this.http.get(environment.GetMatchingStatus + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetMembers() {
  return this.http.get(environment.GetMembers + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetCustomerRelationshipsByCRMCustomerId(cRMCustomerId:any) {
  return this.http.get(environment.GetCustomerRelationshipsByCRMCustomerId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetCustomerRelationshipByCRMConstituentRelationshipId(cRMConstituentRelationshipId:any) {
  return this.http.get(environment.GetCustomerRelationshipByCRMConstituentRelationshipId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMConstituentRelationshipId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

SaveCustomerRelationship(relationshipDetails: any) {
  return this.http.post(environment.SaveCustomerRelationship, relationshipDetails, {
    responseType: 'text'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

SaveMatchingStatus(matchingData: any) {
  return this.http.post(environment.SaveMatchingStatus, matchingData, {
    responseType: 'text'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

SaveDeleteMatchingStatusByMatchingId(matchingData: any) {
  return this.http.post(environment.SaveDeleteMatchingStatusByMatchingId, matchingData, {
    responseType: 'text'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetRelationshipRoles() {
  return this.http.get(environment.GetRelationshipRoles + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

DeleteCustomerRelationshipByCRMConstituentRelationshipId(deleteData: any) {
  return this.http.post(environment.DeleteCustomerRelationshipByCRMConstituentRelationshipId, deleteData, {
    responseType: 'text'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetHouseholdRelationshipByCRMCustomerId(cRMCustomerId:any) {
  return this.http.get(environment.GetHouseholdRelationshipByCRMCustomerId + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + cRMCustomerId).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

UpdateHeadOfHouseholdStatusByCRMCustomerId(Householdlist: any) {
  return this.http.post(environment.UpdateHeadOfHouseholdStatusByCRMCustomerId, Householdlist, {
    responseType: 'text'
  }).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

GetCRMCustomersByCustomerType(customerType:any,statusType:any) {
  return this.http.get(environment.GetCRMCustomersByCustomerType + '/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId + '/' + customerType + '/' + statusType).pipe(map((res: any) => {
    return res;
  }), catchError(error => {
    console.log(error);
    return throwError(error);
  }));
}

}
