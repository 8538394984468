import { Component, ViewChild, OnInit, AfterViewInit, ViewEncapsulation, } from '@angular/core';
import { SchedulerComponent, SchedulerViews, SchedulerTimelineDayScale, SchedulerResource } from 'smart-webcomponents-angular/scheduler';
import { CalendarService } from 'src/app/services/calendar.service';
import { FormsService } from 'src/app/services/forms.service';
import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalenderComponent implements AfterViewInit, OnInit {

  @ViewChild('scheduler', { read: SchedulerComponent, static: false })
  scheduler!: SchedulerComponent;
  view: string = 'month';
  views: SchedulerViews = ['day', 'week', 'month'];
  displayStyle = 'none';
  filterTerm: string;
  reports: any = [];
  token_response: any = {};
  token: any;
  formslists: any = [{ FormName: '' }];
  constructor(private calendarService: CalendarService, private formsService: FormsService,) {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }
  calendarRecords = [
    {
      id: 1,
      date: "May 16, 2022 8:00 AM - 3:00 PM",
      location: "City Foof Pantry",
      address: "101 Main St Denver, CO 80021",
      task: "Food Pantry Assistance",
      volunteersassigned: "5",
      totalvolunteersneeded: 6,
    },
    {
      id: 2,
      date: "May 28, 2022 2:00 PM - 7:30 PM",
      location: "Museum of History",
      address: "1400 Mapie Ave Denver, CO 80021",
      task: "Fundraiser",
      volunteersassigned: "4",
      totalvolunteersneeded: 7,
    },
  ];
  ngOnInit(): void {
    this.Get_ApplicationLogErrorsByDateRange();
    this.get_formdata();
  }

  ngAfterViewInit(): void {
    // afterViewInit code.
    this.init();

  }

  reportsService = {
    Get_ApplicationLogErrorsByDateRange: () =>
      new Promise((resolve, reject) => {
        //Make request
        const url =
          'https://devapis.volunteers.llc/api/Calendar/GetScheduleDetailsByTenantId/' + this.token_response.CRMPersonId + '/' + this.token_response.tenantId;
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', url, false); // false for synchronous request
        xmlHttp.send(null);
        resolve(xmlHttp.responseText);
      }),
  };

  Get_ApplicationLogErrorsByDateRange() {
    this.reportsService
      .Get_ApplicationLogErrorsByDateRange()
      /*.subscribe*/.then((results) => {
        if (results != null && results != '' && results != 'undefined') {
          this.reports = results;
          console.log(results);
          let resultsArray = JSON.parse(results as string);
          this.scheduler.dataSource = new window.Smart.DataAdapter({
            dataSource: resultsArray,
            dataFields: [
              { name: 'OrganizerId', map: 'OrganizerId', dataType: 'number', },
              { name: 'dateStart', map: 'ScheduleDetailStartDateTime', dataType: 'date', },
              { name: 'dateEnd', map: 'ScheduleDetailEndDateTime', dataType: 'date', },
              { name: 'label', map: 'CRMScheduleName', dataType: 'string' },
              { name: 'description', map: 'ScheduleDay', dataType: 'string', },
              { name: 'participantId', map: 'participantId', dataType: 'string', },
            ],
          });

        }
      });

    return this.reports;
  }



  init(): void {
  }

  handleItemInsert(event: CustomEvent) {
    console.log(event.detail);
    // let CRMScheduleMembersType = {
    //   CRMScheduleMembersId: null,
    //   ParticipantId: 'f86e5bb9-8f8f-48d4-be73-3cea80c3bd2c',
    //   Attendees: 'Present'
    // }
  
  let  startDate = moment(event.detail.item.dateStart).format('MM/DD/YYYY HH:mm');
  let  endDate = moment(event.detail.item.dateEnd).format('MM/DD/YYYY HH:mm');
    let newcalendardetails = {
      CRMPersonId: this.token_response.CRMPersonId,
      TenantId: this.token_response.tenantId,
      CRMScheduleId: '00000000-0000-0000-0000-000000000000',
      CRMScheduleName: event.detail.item.label,
      ScheduleStartDateTime: startDate,
      ScheduleEndDateTime: endDate,
      OrganizerId: '81eb934e-97c1-481a-ab5a-249f8990121c',
      CRMScheduleMembersList: [
        {
          ParticipantId: "f86e5bb9-8f8f-48d4-be73-3cea80c3bd2c",
          Attendees: "Present"
        }
      ]

    };

    this.calendarService.Save_CRMSchedule(newcalendardetails).subscribe((results) => {
      console.log(results);
    },
    );
  }

  messages = {
    "en": {
      ok: 'Confirm',
      label: 'Label',
      dateStart: 'Date Start',
      dateEnd: 'Date End'
    }
  }

  openPopup() {
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = "none";
  }

  get_formdata() {
    this.formsService.get_FormsByTenantId().subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.formslists = results;
        console.log(this.formslists);
      }
    },
    );
  }

  resources: SchedulerResource[] = [
    {
      label: 'Participants',
      value: 'participantId',
      dataSource: [
        {
          label: this.formslists[0].FormName,
          id: 1,
          type: 'Conference',
          capacity: 10,
          backgroundColor: '#919191'
        }, {
          label: 'Participant2',
          id: 2,
          type: 'Common',
          capacity: 20,
          backgroundColor: '#208056'
        },
        {
          label: 'Participant3',
          id: 3,
          type: 'Conference',
          capacity: 25,
          backgroundColor: '#BFA300'
        },
        {
          label: 'Participant4',
          id: 4,
          type: 'Common',
          capacity: 10,
          backgroundColor: '#7B60BF'
        },
        {
          label: 'participant5',
          id: 5,
          type: 'Common',
          capacity: 20,
          backgroundColor: '#800000'
        },
        {
          label: 'participant6',
          id: 6,
          type: 'Conference',
          capacity: 15,
          backgroundColor: '#6097BF'
        },
        {
          label: 'participant7',
          id: 7,
          type: 'Conference',
          capacity: 4,
          backgroundColor: '#BF00A6'
        },

      ]
    }
  ];

  scheduledetails() {
    let result = [{
      CRMPersonId
        :
        "c81f94df-8505-4b7b-8a92-d03358208776",
      CreatedBy
        :
        "00000000-0000-0000-0000-000000000000",
      CreatedDate
        :
        null,
      CreatedDateTime
        :
        "2023-01-13T11:35:32.31",
      CreatedDateEndTime
        :
        "2023-01-15T11:35:32.31",
      ErrorLogId
        :
        "a7f9e736-76ff-4bd2-b6bf-713a05cba9f5",
      ErrorMessage
        :
        "Procedure or function 'usp_Update_CRMCustomerDetailsByCRMCustomerId' expects parameter '@DOB', which was not supplied.",
      ErrorProcedure
        :
        "usp_Update_CRMCustomerDetailsByCRMCustomerId",
      ErrorScreenName
        :
        "Profile",
      LastUpdatedBy
        :
        "00000000-0000-0000-0000-000000000000",
      LastUpdatedDate
        :
        null,
      ProCode
        :
        null,
      ProName
        :
        null,
      RootTenantId
        :
        "00000000-0000-0000-0000-000000000000",
      RootTenantName
        :
        null,
      Status
        :
        "Error",
      TenantId
        :
        "3eb3f880-8849-4e8a-acd0-31c58e873372",
      TenantName
        :
        null,
      UpdatedBy
        :
        "00000000-0000-0000-0000-000000000000",
      UpdatedDateTime
        :
        null
    },
    {
      CreatedDateTime
        :
        "2023-01-09T12:06:34",
      CreatedDateEndTime
        :
        "2023-01-10T11:35:32.31",
      ErrorLogId
        :
        "8bffe276-9727-4bc0-84db-cd47a8544fe8",
      ErrorMessage
        :
        "Timeout expired.  The timeout period elapsed prior to completion of the operation or the server is not responding.",
      ErrorProcedure
        :
        "Hello",
      ErrorScreenName
        :
        "Dashboard"
    },
    ];

    return result

  }
}
