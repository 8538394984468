import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  token_response: any = {};
  token: any;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.token_response = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }
  //User Authentication

  userAuthentication(formData:any) {
    return this.http.post(environment.tokenUrl, formData).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));

  }

  //User Registration

  userregistration(registerCredentials: any) {
    return this.http.post(environment.SaveUserAccount, registerCredentials, {
      responseType: 'text',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }


  //Forgot Password Functionality

  forgotpswd(email: any) {
    const httpOptions = {
      responseType: 'text',
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }
    return this.http.post(environment.PostForgotPassword + '?email=' + email + "&type=Angular", httpOptions).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  //Reset Password API Call
  resetpassword(passworddetails: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      })
    }
    return this.http.post(environment.ResetPassword, passworddetails, httpOptions).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      return error;
    }));
  }

  GetAllMenusByUserIdAndUserRoleId(personId:any, userRoleId:any) {
    return this.http.get(environment.GetAllMenusByUserIdAndUserRoleId + '/' + personId + '/' + userRoleId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

}
