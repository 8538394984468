 <!-- Home -->
 <!-- <div class="menu-item" *ngIf="currentrole =='Admin' || currentrole =='Staff'">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/dashboard"
    routerLinkActive="active"
    ><span class="menu-icon">
      <fa-icon [icon]="faTachometer" class="fs-4 "></fa-icon>
    </span>
    <span class="menu-title text-dark" >Dashboard</span
  ></a>
</div> -->

<!-- Calendar -->
<!-- <div class="menu-item">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/calendar"
    routerLinkActive="active"
    ><span class="menu-icon">
   <fa-icon [icon]="faCalendar" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark" >Calendar</span></a
  >
</div> -->
<!-- Contacts -->
<!-- <div class="menu-item" *ngIf="currentrole =='Admin' || currentrole =='Staff'">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="contacts"
    routerLinkActive="active"
    ><span class="menu-icon">
      <fa-icon [icon]="faUser" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark" (click)="navcontacts()">Contacts</span></a
  >
</div> -->



<!-- Events -->
<!-- <div class="menu-item">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/event"
    routerLinkActive="active"
    ><span class="menu-icon">
       </span
    ><span class="menu-title" >Events</span></a
  >
</div> -->

<!-- Jobs -->
<!-- <div class="menu-item">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/jobs"
    routerLinkActive="active"
    ><span class="menu-icon">
       </span
    ><span class="menu-title" >Jobs</span></a
  >
</div> -->

<!-- <div class="menu-item" *ngIf="currentrole == 'Admin' || currentrole == 'Staff'">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/setup"
    routerLinkActive="active"
    ><span class="menu-icon">
      <fa-icon [icon]="faGear" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark" >Setup</span></a>
</div> -->

<!-- Reports -->
<!-- <div class="menu-item" *ngIf="currentrole == 'Admin' || currentrole == 'Staff'">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/reports"
    routerLinkActive="active" 
    ><span class="menu-icon">
      <fa-icon [icon]="faFlag" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark" >Reports</span></a
  >
</div> -->

<div class="menu-item" *ngFor='let menu of menusList'>
  <a
    class="menu-link menu-link-new without-sub"
    routerLink= "{{menu.SysPageURL}}"
    routerLinkActive="active" 
    ><span class="menu-icon">
      <fa-icon [icon]=menu.SysMenuIcon class="fs-4"></fa-icon></span>
      <span class="menu-title text-dark" >{{menu.SysMenu}}</span></a>
</div>
<div class="menu-item" *ngIf="currentrole =='Volunteer'">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="myprofile"
    routerLinkActive="active"
    ><span class="menu-icon">
      <fa-icon [icon]="faUser" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark" (click)="redirectToProfile()">My Profile</span></a
  >
</div>
<div class="menu-item">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="matchStatus"
    routerLinkActive="active"
    ><span class="menu-icon">
      <fa-icon [icon]="faLink" class="fs-4 "></fa-icon>
       </span
    ><span class="menu-title text-dark">Match Status</span></a
  >
</div>
<!-- form -->
<!-- <div class="menu-item">
  <a
    class="menu-link menu-link-new without-sub"
    routerLink="/form"
    routerLinkActive="active"
    ><span class="menu-icon">
       </span
    ><span class="menu-title" >Form</span></a
  >
</div> -->
