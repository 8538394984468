<app-explore-main-drawer></app-explore-main-drawer>
<app-help-drawer></app-help-drawer>

<!--begin::Engage toolbar-->
<div class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2">


  <app-purchase-toolbar></app-purchase-toolbar>
</div>
<!--end::Engage toolbar-->
