<div class="card p-5">
    <div class="row ms-1">
        <div class="col-lg-1 col-md-1 col-sm-1 mt-2">
            Date Range:
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
            <input type="date" autocomplete="off" class="form-control bg-transparent p-2" [(ngModel)]="daterange.startTime">                          
        </div>
        <div class="col-to col-md-1 col-sm-1">
            <p class="mt-2 ms-1">to</p>                        
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
            <input type="date" class="form-control bg-transparent p-2" [(ngModel)]="daterange.endTime">
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 text-end mt-1">
            <a class="btn background-success p-1 fs-7 rounded-0 text-white" (click)="Get_ApplicationLogErrorsByDateRange(daterange)" >
                Generate Report</a>
        </div>
    </div>
    <div class="mt-3">
        <smart-grid [paging]="paging" [pager]="pager" [layout]="layout" [behavior]="behavior" [dataSource]="reportgridsource"
            [columns]="columns" [appearance]="appearance" #reportgrid id="reportgrid" class="w-100"></smart-grid>
            <div class="float-end mt-0">Total Number of Records in Table: {{reports.length}}</div>
    </div>
</div>
