import { DatePipe } from '@angular/common';
import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { GridComponent, GridColumn, DataAdapter, Smart } from 'smart-webcomponents-angular/grid';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastralertService } from 'src/app/services/toastralert.service';

@Component({
  selector: 'app-api-log-error-reports',
  templateUrl: './api-log-error-reports.component.html',
  styleUrls: ['./api-log-error-reports.component.scss']
})
export class ApiLogErrorReportsComponent implements OnInit {
  @ViewChild('reportgrid', { read: GridComponent, static: false }) grid!: GridComponent;
  daterange: any = { startTime: '', endTime: '' }
  reports: any = [];
  constructor(private reportsService: ReportsService,
      public toastralert: ToastralertService,
      private datePipe: DatePipe) { }

  ngOnInit(): void {
      this.lastWeekFilter();
      this.Get_ApplicationLogErrorsByDateRange(this.daterange);
  }

  lastWeekFilter() {
      let lastweek = new Date();
      lastweek.setDate(lastweek.getDate() - 7);
      this.daterange.startTime = this.datePipe.transform(lastweek, 'yyyy-MM-dd');
      this.daterange.endTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  appearance = {
      alternationStart: 0,
      alternationCount: 2,
      allowSortAnimation: true
  }

  layout: object = {
      rowHeight: 35
  }

  pager = {
      visible: true
  }

  paging = {
      enabled: true,
      pageSize: 15
  }

  Get_ApplicationLogErrorsByDateRange(range: any) {
      if ((typeof range.startTime == 'undefined' || range.startTime == "" || range.startTime == null) && (typeof range.endTime == 'undefined' || range.endTime == "" || range.endTime == null)) {
          this.toastralert.toastrwarnningbottomright("Please Select Start Date and End Date");
          return false;
      }
      else {
          if ((typeof range.startTime == 'undefined' || range.startTime == "" || range.startTime == null)) {
              this.toastralert.toastrwarnningbottomright("Please Select Start Date");
              return false;
          }
          if ((typeof range.endTime == 'undefined' || range.endTime == "" || range.endTime == null)) {
              this.toastralert.toastrwarnningbottomright("Please Select End Date");
              return false;
          }
          if ((range.startTime > range.endTime)) {
              this.toastralert.toastrwarnningbottomright("Please Enter a valid Date");
              return false;
          }
      }
      this.reportgrid.dataSource = [];
      this.reportgridsource = [];
      this.reportsService.Get_ApplicationLogErrorsByDateRange(range.startTime, range.endTime)
          .subscribe(results => {
              if (results != null && results != "" && results != 'undefined') {
                  this.reports = results;
                  this.reportgrid.dataSource = results;
                  this.reportgridsource = results;
                  console.log(results);
              }
          },
              (err) => {
                  this.toastralert.toastrerrorbottom("Unable to get Api Log details.Please try after some time.");
              });
  }
  behavior = { allowColumnReorder: true, rowResizeMode: 'growAndShrink', columnResizeMode: 'growAndShrink' }

  reportgrid: DataAdapter = new window.Smart.DataAdapter(
      {
          dataSource: [],
          dataSourceType: 'json',
          dataFields: [
              'ErrorScreenName: string',
              'ErrorMessage: string',
              'CreatedDateTime: date',
          ]
      })
  reportgridsource: any = this.reportgrid;
  columns = [
      {
          label: 'Error Screen Name', dataField: 'ErrorScreenName', align: 'center', width:'20%'
      },
      { label: 'Error Message', dataField: 'ErrorMessage', align: 'center', width:'50%' },
      {
          label: 'Error Occurred At', dataField: 'CreatedDateTime', align: 'center',
          formatFunction: function (settings: any) {
              const formatedDate = new Smart.Utilities.DateTime(settings.value).toString('F');
              settings.template = formatedDate;
          }, width:'30%'
      },
  ]
}
