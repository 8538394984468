import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

type Tabs = 'Profile' | 'Schedule' | 'History' | 'Availability' | 'Skills';

@Component({
  selector: 'app-contactprofile',
  templateUrl: './contactprofile.component.html',
  styleUrls: ['./contactprofile.component.scss']
})
export class ContactprofileComponent implements OnInit {

  activeTab: Tabs = 'Profile';
  model: any;
  @ViewChild('form', { static: true }) form: NgForm;
  configLoading: boolean = false;
  resetLoading: boolean = false;
  constructor(public router: Router) {}

  ngOnInit(): void {
   
  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  resetPreview(): void {
    this.resetLoading = true;
   
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }

  Redirecttocontact(){
    this.router.navigate(['profiles']);
  }
}
