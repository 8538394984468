import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { AuthguardService } from 'src/app/services/authguard.service';
import { ToastralertService } from 'src/app/services/toastralert.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginCredentials: any = { username: '', password: '' };
  token_response: any = {};
  token: any;
  visible: boolean = false;
  changetype: boolean = true;
  loginbtn='Log In';
  constructor(
    private router: Router,
    public http: HttpClient,
    private userService: UserService,
    private authGuardservice: AuthguardService,
    public toastralert: ToastralertService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    let lstoken = JSON.parse(localStorage.getItem("volunteers_token_responseuat") || '{}');
    this.loginCredentials.username = '';
    this.loginCredentials.password = '';

  }

  ngOnDestroy() {
  }

  Redirecttosignup() {
    this.router.navigate(['/registration']);
  }
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  trim(str: string) {
    return str.replace(/ /g, '');
  }

  toggleLoading = () => {
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
  }

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async doLogin(loginCredentials: { username: any; password: any; }) {
    this.loginbtn='Processing...';
    if ((typeof loginCredentials.username == 'undefined' || this.trim(loginCredentials.username) == "" || loginCredentials.username == null)) {
      this.toastralert.toastrwarnning('Please enter your Email');
      this.loginbtn='Log In';
      return false;
    }
    else if ((!this.validateEmail(this.trim(loginCredentials.username)))) {
      this.toastralert.toastrwarnning('Please enter a valid Email');
      this.loginbtn='Log In';
      return false;
    }
    else if ((typeof loginCredentials.password == 'undefined' || loginCredentials.password == "" || loginCredentials.password == null)) {
      this.toastralert.toastrwarnning('Please enter your Password');
      this.loginbtn='Log In';
      return false;
    }
    else {
     this.spinner.show();
      const formData = new FormData();
      formData.append('username', loginCredentials.username);
      formData.append('password', loginCredentials.password);
      this.userService.userAuthentication(formData).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          this.loginbtn='Authenticating...';
          // this.spinner.show();
          this.token_response = results;
          console.log(results);
          if (this.token_response.userRoleId != null) {
            this.token = this.token_response.access_token;
            localStorage.setItem("volunteers_token_responseuat", JSON.stringify(this.token_response));          
            this.authGuardservice.authguard();
            // this.spinner.show();
            // setTimeout(() => {
            //   /** spinner ends after 5 seconds */
            //   this.spinner.hide();
            // }, 2000);
            if(this.token_response.UserRole != "Volunteer"){
              this.router.navigate(['/dashboard']);
            }
            else{
              this.router.navigate(['/calendar']);
            }
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            this.toastralert.toastrwarnning('Access not allowed for this user');
            this.loginbtn='Log In';
          }
        }
        else {
          this.toastralert.toastrerror('Access not allowed for this user');
          this.loginbtn='Log In';
        }
      },
        (err) => {
          this.toastralert.toastrerror(err.error);
          this.loginbtn='Log In';
        }
      );
    }

  }

}
