import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
type Tabs = 'Details' | 'Jobs' ;
@Component({
  selector: 'app-eventdetails',
  templateUrl: './eventdetails.component.html',
  styleUrls: ['./eventdetails.component.scss']
})
export class EventdetailsComponent implements OnInit {

  activeTab: Tabs = 'Details';
  model: any;
  @ViewChild('form', { static: true }) form: NgForm;
  configLoading: boolean = false;
  resetLoading: boolean = false;
  constructor() {}

  ngOnInit(): void {
   
  }

  setActiveTab(tab: Tabs) {
    this.activeTab = tab;
  }

  resetPreview(): void {
    this.resetLoading = true;
   
  }

  submitPreview(): void {
    this.configLoading = true;
    // it will refresh the page
  }


}
