<!--begin::Main-->
<div class="app-main flex-column flex-row-fluid">
    <!--begin::Content wrapper-->
    <div class="d-flex flex-column flex-column-fluid">
        <div class="app-toolbar py-3 py-lg-6">
            <!--begin::Toolbar container-->
            <div class="container-fluid d-flex flex-stack ps-3">
                <!--begin::Page title-->
                <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <!--begin::Title-->
                    <h1 class="page-heading d-flex text-dark fw-bold fs-4 flex-column justify-content-center my-0">
                        Custom Fields for Constituents</h1>
                    <!--end::Title-->
                </div>
                <!--end::Page title-->
                <!--begin::Actions-->
                <div class="d-flex align-items-center gap-2 gap-lg-3">
                    <a class="btn btn-primary text-white me-2 p-2 fs-7 fw-bold rounded-0" (click)="openPopup()"
                        data-bs-target="#addCategory" data-bs-toggle="modal">New Category</a>
                </div>

                <!--end::Actions-->
            </div>
            <!--end::Toolbar container-->
        </div>
        <!--begin::Content-->
        <div class="app-content flex-column-fluid p-4">
            <!--begin::Content container-->
            <div class="card">
                <div class="card-header px-2">
                    <div class="card-title">
                        <h3 class="fs-4 fw-bold">General</h3>
                    </div>
                    <div class="d-flex align-items-center gap-2 gap-lg-3">
                        <a class="btn btn-light p-2 fs-7 rounded-0" data-bs-target="#editField" (click)="openPopup()"
                            data-bs-toggle="modal">Edit</a>
                        <a class="btn btn-primary p-2 fs-7 rounded-0" data-bs-target="#addField" (click)="openPopup()"
                            data-bs-toggle="modal">New
                            Field</a>
                    </div>
                </div>
                <div class="card-body px-5 py-2">
                    <ul class="list-unstyled">
                        <li class="border-bottom">
                            <div class="row py-2">
                                <div class="col-lg-11 col-md-11 col-sm-11">
                                    <a href="edit-field.html" class="text-primary">Constituent
                                        Comments</a>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <span class="justify-content-end align-items-end float-end"><button
                                            class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="fa fa-ellipsis-h fs-4"></i>
                                        </button>
                                        <!--begin::Menu 3-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                            data-kt-menu="true">
                                            <!--begin::Heading-->
                                            <div class="menu-item px-3">
                                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                    Action</div>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Activate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Inactivate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3 my-1">
                                                <a class="menu-link px-3" data-bs-target="#deleteField"
                                                    data-bs-toggle="modal">Delete</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom">
                            <div class="row py-2">
                                <div class="col-lg-11 col-md-11 col-sm-11">
                                    <a href="edit-field.html" class="text-primary">Constituent
                                        Comments</a>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <span class="justify-content-end align-items-end float-end"><button
                                            class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="fa fa-ellipsis-h fs-4"></i>
                                        </button>
                                        <!--begin::Menu 3-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                            data-kt-menu="true">
                                            <!--begin::Heading-->
                                            <div class="menu-item px-3">
                                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                    Action</div>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Activate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3 my-1">
                                                <a class="menu-link px-3" data-bs-target="#deleteField"
                                                    data-bs-toggle="modal">Delete</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card my-2">
                <div class="card-header px-2">
                    <div class="card-title">
                        <h3 class="fs-4 fw-bold">LifeSpark Participant</h3>
                    </div>
                    <div class="d-flex align-items-center gap-2 gap-lg-3">
                        <a class="btn btn-light p-2 fs-7 rounded-0" data-bs-target="#editField" 
                            data-bs-toggle="modal" (click)="openPopup()">Edit</a>
                        <a class="btn btn-primary p-2 fs-7 rounded-0" data-bs-target="#addField" (click)="openPopup()"
                            data-bs-toggle="modal">New
                            Field</a>
                    </div>
                </div>
                <div class="card-body px-5 py-2">
                    <ul class="list-unstyled">
                        <li class="border-bottom">
                            <div class="row py-2">
                                <div class="col-lg-11 col-md-11 col-sm-11">
                                    <a href="edit-field.html" class="text-primary">Constituent
                                        Comments</a>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <span class="justify-content-end align-items-end float-end"><button
                                            class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="fa fa-ellipsis-h fs-4"></i>
                                        </button>
                                        <!--begin::Menu 3-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                            data-kt-menu="true">
                                            <!--begin::Heading-->
                                            <div class="menu-item px-3">
                                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                    Action</div>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Activate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Inactivate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3 my-1">
                                                <a class="menu-link px-3" data-bs-target="#deleteField"
                                                    data-bs-toggle="modal">Delete</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom">
                            <div class="row py-2">
                                <div class="col-lg-11 col-md-11 col-sm-11">
                                    <a href="edit-field.html" class="text-primary">Constituent
                                        Comments</a>
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <span class="justify-content-end align-items-end float-end"><button
                                            class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-20px h-20px"
                                            data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                            <i class="fa fa-ellipsis-h fs-4"></i>
                                        </button>
                                        <!--begin::Menu 3-->
                                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                                            data-kt-menu="true">
                                            <!--begin::Heading-->
                                            <div class="menu-item px-3">
                                                <div class="menu-content text-muted pb-2 px-3 fs-7">
                                                    Action</div>
                                            </div>
                                            <!--end::Heading-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Activate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3">
                                                <a class="menu-link px-3">Inactivate</a>
                                            </div>
                                            <!--end::Menu item-->
                                            <!--begin::Menu item-->
                                            <div class="menu-item px-3 my-1">
                                                <a class="menu-link px-3" data-bs-target="#deleteField"
                                                    data-bs-toggle="modal">Delete</a>
                                            </div>
                                            <!--end::Menu item-->
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!--end::Content container-->
        </div>
        <!--end::Content-->
    </div>
    <!--end::Content wrapper-->
    <div class="modal fade" id="addCategory" class="modal" tabindex="-1" role="dialog"
        [ngStyle]="{'display':displayStyle}" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-md">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header  border-0 pb-0">
                    <h5 class="modal-title">Add Category</h5>
                    <!--begin::Close-->
                    <div class="btn btn-sm btn-icon justify-content-end" data-bs-dismiss="modal" (click)="closePopup()">
                        <!--begin::Close-->
                        <div class="btn btn-sm btn-icon btn-active-color-primary justify-content-end    "
                            data-bs-dismiss="modal">
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Close-->
                    </div>
                </div>
                <!--begin::Modal body-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="w-100 p-2">
                                <h6>Category</h6>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div>
                                <input type="text" placeholder="Enter Category" name="name" autocomplete="off"
                                    class="form-control bg-transparent p-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary p-1 px-2" data-dismiss="modal"
                        (click)="closePopup()"><small>Cancel</small></button>
                    <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="closePopup()"
                        data-bs-dismiss="modal"><small>Save
                            Category</small></button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="addField" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}"
        aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-md">
            <!--begin::Modal content-->
            <div class="modal-content rounded">
                <!--begin::Modal header-->
                <div class="modal-header  border-0 pb-0">
                    <h5 class="modal-title">Add Field</h5>
                    <!--begin::Close-->
                    <div class="btn btn-sm btn-icon justify-content-end" data-bs-dismiss="modal" (click)="closePopup()">
                        <!--begin::Close-->
                        <div class="btn btn-sm btn-icon btn-active-color-primary justify-content-end    "
                            data-bs-dismiss="modal">
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor" />
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Close-->
                    </div>
                </div>
                <!--begin::Modal body-->
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="w-100 p-2">
                                <h6>Field</h6>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div>
                                <input type="text" placeholder="Enter Field" name="name" autocomplete="off"
                                    class="form-control bg-transparent p-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary p-1 px-2" data-dismiss="modal"
                        (click)="closePopup()"><small>Cancel</small></button>
                    <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="closePopup()"
                        data-bs-dismiss="modal"><small>Save
                            Field</small></button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="editField" class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}"
    aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-md">
        <!--begin::Modal content-->
        <div class="modal-content rounded">
            <!--begin::Modal header-->
            <div class="modal-header  border-0 pb-0">
                <h5 class="modal-title">Edit Field</h5>
                <!--begin::Close-->
                <div class="btn btn-sm btn-icon justify-content-end" data-bs-dismiss="modal" (click)="closePopup()">
                    <!--begin::Close-->
                    <div class="btn btn-sm btn-icon btn-active-color-primary justify-content-end    "
                        data-bs-dismiss="modal">
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
                        <span class="svg-icon svg-icon-1">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                    transform="rotate(-45 6 17.3137)" fill="currentColor" />
                                <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                    transform="rotate(45 7.41422 6)" fill="currentColor" />
                            </svg>
                        </span>
                        <!--end::Svg Icon-->
                    </div>
                    <!--end::Close-->
                </div>
            </div>
            <!--begin::Modal body-->
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="w-100 p-2">
                            <h6>Field</h6>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div>
                            <input type="text" placeholder="Enter Field" name="name" autocomplete="off"
                                class="form-control bg-transparent p-2" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary p-1 px-2" data-dismiss="modal"
                    (click)="closePopup()"><small>Cancel</small></button>
                <button type="button" class="btn bg-primary text-white p-1 px-2" (click)="closePopup()"
                    data-bs-dismiss="modal"><small>Save
                        Field</small></button>
            </div>
        </div>
    </div>
</div>
</div>
<!--end:::Main-->